// import EditableGeoJsonLayer from "../layers/hacked-editable-geojson-layer";
import { EditableGeoJsonLayer } from "@nebula.gl/layers";
import { EWAModelWrapper } from "../EWAModelWrapper";
import { EditMode } from "../EditMode";
import { ModifyMode } from "@nebula.gl/edit-modes";
import { BaseLinestringFeature } from "../../../data/BaseLinestringFeature";
import { BasePointFeature } from "../../../data/BasePointFeature";
import { BaseFeature } from "../../../data/BaseFeature";
import { EWAComponentType } from "../../../data/EWAComponentType";
import { ValveComponentType } from "../../../data/ValveComponentType";
import { HackedTranslateMode } from "../../../modes/hacked-translate-mode";
import { getIcon } from "../util/getIcon";
import { DataFilterExtension } from "@deck.gl/extensions";
import colorMap from "../util/ColorMap";
import { RGBAColor } from "deck.gl";


export interface GeojsonLayerProps {
    modelWrapper: EWAModelWrapper
    setModelWrapper: (_: EWAModelWrapper) => void
    editMode: any
    setEditMode: (_: any) => void
    selectedFeatureIndices: number[]
    setSelectedFeatureIndices: (_: number[]) => void
    currentDate: number
    editModeSelection: EditMode
    editCreateMode: EWAComponentType | ValveComponentType | null
}

export default function createGeojsonLayer(props: GeojsonLayerProps): EditableGeoJsonLayer {
    const {
        modelWrapper,
        setModelWrapper,
        editMode,
        setEditMode,
        selectedFeatureIndices,
        setSelectedFeatureIndices,
        currentDate,
        editModeSelection,
        editCreateMode
    } = props

    function getLineWidth(feature: BaseFeature<any, any, any>) {
        if (feature.componentType === EWAComponentType.pipe) {
            let props = feature._timedProperties.getValue(currentDate)
            if (props?.diameter !== undefined) {
                return (props.diameter / 50)
            }
            return 5
        } else {
            return 5
        }
    }

    function onClick(info: any, event: any) {
        if (editModeSelection === EditMode.Select) {
            if ((event.srcEvent.shiftKey != null && event.srcEvent.shiftKey) || (event.srcEvent.ctrlKey != null && event.srcEvent.ctrlKey) || (event.srcEvent.metaKey != null && event.srcEvent.metaKey)) {
                if (selectedFeatureIndices.length > 0) {
                    if (modelWrapper.model.features[selectedFeatureIndices[0]].constructor.name === info.object.constructor.name) {
                        setSelectedFeatureIndices(Array.from(new Set([...selectedFeatureIndices, ...[info.index]])));
                        return;
                    }
                }
            }
            setSelectedFeatureIndices([info.index]);
        } else if (editModeSelection === EditMode.Modify) {
            if (info.object.constructor.name !== "Object") {
                let componentType = modelWrapper.model.features[info.index].componentType
                if ([EWAComponentType.junction, EWAComponentType.tank, EWAComponentType.reservoir, EWAComponentType.spring, EWAComponentType.well].includes(componentType)) {
                    setEditMode(() => HackedTranslateMode);
                    setSelectedFeatureIndices([info.index]);
                } else {
                    setEditMode(() => ModifyMode);
                    setSelectedFeatureIndices([info.index])
                }
            }
        }
    }

    function getLineColor(feature: BaseFeature<any, any, any>): RGBAColor {
        let color: RGBAColor = [0, 0, 0, 255]

        if (selectedFeatureIndices?.some(i => modelWrapper.model.features[i] === feature)) {
            color = [0, 255, 0, 255]
        } else {
            if (feature.componentType === EWAComponentType.pipe) {
                let props = feature._timedProperties.getValue(currentDate as number)
                color = [71, 180, 235, 255];
                if (props !== undefined) {
                    if (props.diameter !== undefined) {
                        const colorRange = colorMap(props.diameter);
                        color = [Math.round(colorRange[0] * 255), Math.round(colorRange[1] * 255), Math.round(colorRange[2] * 255), 255];
                    }
                }

            }
            if (feature.componentType === EWAComponentType.pump) {
                // color = [204, 102, 0, 255]; // orange
                color = [241, 203, 86, 255]; // same yellow as icon
            }
        }

        return color
    }

    async function onEdit(event: any) {
        const ewaModel = modelWrapper.model;
        let newFeatureIndex: boolean | number = false
        let updateModelWrapper = false
        if (event.editType === "addFeature") {
            if (editModeSelection === EditMode.Add) {
                if (!(event.editContext.featureIndexes?.length) || editCreateMode === null) {
                    return
                }

                const newFeature = event.updatedData.features[event.editContext.featureIndexes[0]];
                newFeatureIndex = await ewaModel.addComponent(editCreateMode, newFeature, currentDate)
            }
            updateModelWrapper = true
        } else if (event.editType === "translated") {
            const newFeature = event.updatedData.features[event.editContext.featureIndexes[0]];
            let geometry = ewaModel.features[event.editContext.featureIndexes[0]] as BasePointFeature<any, any>;
            await geometry.updateCoordinates(newFeature.geometry.coordinates)
            updateModelWrapper = true
        } else if (event.editType === "addPosition" || event.editType === "movePosition") {
            const newFeature = event.updatedData.features[event.editContext.featureIndexes[0]];
            const geometry = ewaModel.features[event.editContext.featureIndexes[0]] as BaseLinestringFeature<any, any>;
            const isAdd = event.editType === "addPosition"
            if (event.editContext.positionIndexes.length > 0) {
                geometry.addPosition(newFeature.geometry.coordinates, event.editContext.positionIndexes[0], isAdd)
            }

            if (isAdd) {
                updateModelWrapper = true
            }
        } else if (event.editType === "finishMovePosition") {
            const newFeature = event.updatedData.features[event.editContext.featureIndexes[0]];
            const geometry = ewaModel.features[event.editContext.featureIndexes[0]] as BaseLinestringFeature<any, any>

            await geometry.updateZCoordinates(newFeature.geometry.coordinates, event.editContext.positionIndexes[0])
            updateModelWrapper = true
        }

        if (updateModelWrapper) {
            setModelWrapper(new EWAModelWrapper(modelWrapper.model))
        }
        if (newFeatureIndex !== false) {
            // setSelectedFeatureIndices([newFeatureIndex])
        }
    }

    const layer = new EditableGeoJsonLayer({
        id: 'geojson-layer',
        data: modelWrapper.model,
        mode: editMode,
        selectedFeatureIndexes: selectedFeatureIndices,
        pointRadiusMinPixels: 12,
        lineWidthMinPixels: 1,
        lineWidthMaxPixels: 20,
        getLineWidth,
        modeConfig: {
            enableSnapping: true,
        },
        onClick,
        getLineColor,
        updateTriggers: {
            getLineColor: selectedFeatureIndices,
        },
        pickable: true,
        _subLayerProps: {
            guides: {
                getFillColor: (guide: any) => [255, 73, 240, 50],
                getLineColor: (guide: any) => [255, 73, 240],
                getLineWidth: 5,
                pointType: "circle",
                pointRadiusUnits: 'pixels',
                getPointRadius: 15
            },
            geojson: {
                lineBillboard: true,
                dataComparator: (newData: any, data: any) => data.id !== newData.id,
                pickable: true,
                parameters: {
                    depthTest: false,
                },
                updateTriggers: {
                    getIcon: selectedFeatureIndices,
                },
                pointType: "icon",
                extensions: [new DataFilterExtension({ filterSize: 3 })],
                getFilterValue: (d: any) => [d.visible, d.visibleFrom, d.visibleTo],
                filterRange: [[1, 1], [(currentDate as number) - 10000, currentDate], [currentDate, (currentDate as number) + 10000]],
                getIcon: (obj: any) => getIcon(obj, selectedFeatureIndices, modelWrapper),
                iconSizeScale: 20
            }
        },
        onEdit
    })

    return layer
}
