import { EWAResult, SingleResult } from "../../api/client";
import { nanoid } from '@reduxjs/toolkit';
import { EWAResultType } from "../../views/model/simulation/EWAResultType";
import { EWAModel } from "../../data/EWAModel";

export type DebugSimulationOptions = {
    model?: EWAModel
}

export default class DebugSimulations {
    private static instance: DebugSimulations;
    public static getInstance(): DebugSimulations {
        if (!DebugSimulations.instance) {
            DebugSimulations.instance = new DebugSimulations()
        }

        return DebugSimulations.instance
    }

    public simulations: Array<{ fn: (options: DebugSimulationOptions) => EWAResult, text: string }> = [
        { fn: this.simDefault, text: 'Default Result' },
        { fn: this.simNoTimed, text: 'No Timed Results' }
    ];

    public get simulationCount(): number {
        return this.simulations.length;
    }

    protected _years: Array<number> = [2025, 2035, 2045, 2055];
    public get years() {
        return this._years;
    }

    protected _scenarios: Array<string> = ["favorable", "unfavorable"]
    public get scenarios() {
        return this._scenarios;
    }

    protected _performanceIndicators: Array<EWAResultType> = [
        EWAResultType.ResilienceIndex,
        EWAResultType.UnsatisfiedNodes,
        EWAResultType.InflowDemandRatio,
        EWAResultType.AveragePipeAge
    ];
    public get performanceIndicators() {
        return this._performanceIndicators;
    }

    public run(index: number, options: DebugSimulationOptions): EWAResult {
        if (index > this.simulationCount) {
            throw Error("Invalid simulation index")
        }

        const fn = this.simulations[index].fn.bind(this)
        return fn(options)
    }

    /**
     * create a default simulation result for:
     * - all existing operating conditions in model
     * - years: DebugSimulations.years
     * - scenarios DebugSimulations.scenarios
     * - performance indicators: DebugSimulations.performanceIndicators
     *
     * @param options DebugSimulationOptions
     * @returns EWAResult
     */
    public simDefault(options: DebugSimulationOptions): EWAResult {
        const operatingConditions = options.model?.conditions.map(item => item.id) ?? []
        if (operatingConditions.length === 0) {
            throw Error("DebugSimulations@simDefault: No operating conditions")
        }

        const timedResults: Record<string, Array<SingleResult>> = {};
        for (const year of this.years) {
            const singleResults: SingleResult[] = []

            for (const scenario of this.scenarios) {
                for (const operatingCondition of operatingConditions) {
                    for (const performanceIndicator of this.performanceIndicators) {
                        const singleResult: SingleResult = {
                            name: performanceIndicator,
                            scenario: scenario,
                            operating_condition: operatingCondition,
                        }

                        switch (performanceIndicator) {
                            case EWAResultType.ResilienceIndex:
                            case EWAResultType.AveragePipeAge:
                                singleResult.value = 0
                                break
                            case EWAResultType.UnsatisfiedNodes:
                                singleResult.value = 0
                                singleResult.details = []
                                break
                            case EWAResultType.InflowDemandRatio:
                                singleResult.value = 1
                                break
                        }

                        singleResults.push(singleResult)
                    }
                }
            }

            timedResults[year] = singleResults
        }

        const result: EWAResult = {
            id: nanoid(),
            timed_results: timedResults,
            global_result: [],
            simulation_errors: {}
        }

        return result;
    }

    public simNoTimed(options: DebugSimulationOptions): EWAResult {
        const result: EWAResult = {
            id: nanoid(),
            timed_results: {},
            global_result: [],
            simulation_errors: {}
        }

        return result
    }
}
