import { ReactNode, useEffect, useMemo, useState } from 'react'
import MaterialReactTable, { MRT_Row, type MRT_ColumnDef, MRT_TableInstance, MRT_Cell } from 'material-react-table';
import { ComponentTableProps, TableFeature, filterAndMapComponents } from "./Table";
import { PipeConditionProps, PipeTimedProps } from '../../../api/client';
import { useTranslation } from 'react-i18next';
import { EWAComponentType } from '../../../data/EWAComponentType';
import { minYear } from '../../../utils/constants';
import parseFloatOrUndefined from '../../../utils/parseFloatOrUndefined';
import validNumber from '../../../utils/validNumber';
import { Pipe } from '../../../data/Pipe';
import { PipeApply } from '../../forms/PipeApply';
import { EWAModelWrapper } from '../EWAModelWrapper';
import RowActions from './RowActions';
import { findTimed } from '../../../utils/findTimed';

type PipeTableFeature = TableFeature<PipeTimedProps, PipeConditionProps>

interface ValidationErrors {
  diameter?: string
  length?: string
  roughness?: string
  constructionYear?: string
}

let isFirstYear = true
let currentDate = minYear
function validateRequiredNonNegative(value: string): boolean {
  if (isFirstYear || value !== '') {
    const parsed = parseFloatOrUndefined(value)
    if (!validNumber(parsed) || parsed! <= 0) {
      return false
    }
  }
  return true
}

export default function PipeTable(props: ComponentTableProps) {
  const {
    data,
    currentCondition,
    localization,
    setModelWrapper
  } = props
  currentDate = props.currentDate
  const { t, i18n } = useTranslation()

  const numberFormatter = new Intl.NumberFormat(i18n.language, { maximumFractionDigits: 5 })
  isFirstYear = currentDate === minYear
  const [validationErrors, setValidationErrors] = useState<ValidationErrors>({})
  const [editingRow, setEditingRow] = useState<MRT_Row<PipeTableFeature> | null>(null)

  useEffect(() => {
    setEditingRow(null)
  }, [currentDate])

  const pipeStatusOptions = useMemo(() => [
    {
      text: t('model.components.pipe.status.OPEN'),
      value: 'OPEN'
    },
    {
      text: t('model.components.pipe.status.CLOSED'),
      value: 'CLOSED'
    },
  ], [i18n.language])

  const columns = useMemo<MRT_ColumnDef<PipeTableFeature>[]>(
    () => {
      return [
        {
          header: 'ID',
          accessorKey: 'id'
        },
        {
          header: `${t('model.components.pipe.construction_year')}`,
          accessorKey: 'timedProps.construction_year',
          filterVariant: 'range',
          filterFn: 'betweenInclusive',
          muiTableBodyCellEditTextFieldProps: {
            error: !!validationErrors.constructionYear,
            helperText: validationErrors.constructionYear,
            type: 'number',
            onChange(event) {
              const value = event.target.value
              let error: string | undefined = undefined
              if (!validateRequiredNonNegative(value)) {
                error = t('validation.invalid_value') as string
              }
              setValidationErrors((prev) => ({ ...prev, constructionYear: error }))
            }
          }
        },
        {
          header: `${t('model.components.pipe.diameter')} (mm)`,
          accessorKey: 'timedProps.diameter',
          accessorFn: (row) => row.timedProps.diameter ?? 0,
          Cell: ({ cell }) => renderNumber(cell.getValue<number | undefined>()),
          filterVariant: 'range',
          filterFn: 'betweenInclusive',
          muiTableBodyCellEditTextFieldProps: {
            error: !!validationErrors.diameter,
            helperText: validationErrors.diameter,
            type: 'number',
            onChange(event) {
              const value = event.target.value
              let error: string | undefined = undefined
              if (!validateRequiredNonNegative(value)) {
                error = t('validation.invalid_value') as string
              }
              setValidationErrors((prev) => ({ ...prev, diameter: error }))
            }
          }
        },
        {
          header: `${t('model.components.pipe.length')} (m)`,
          accessorKey: 'feature.properties.length',
          Cell: ({ cell }) => renderNumber(cell.getValue<number | undefined>()),
          filterVariant: 'range',
          filterFn: 'betweenInclusive',
          muiTableBodyCellEditTextFieldProps: {
            error: !!validationErrors.length,
            helperText: validationErrors.length,
            type: 'number',
            onChange(event) {
              const value = event.target.value
              let error: string | undefined = undefined
              if (!validateRequiredNonNegative(value)) {
                error = t('validation.invalid_value') as string
              }
              setValidationErrors((prev) => ({ ...prev, length: error }))
            }
          }
        },
        {
          header: `${t('model.components.pipe.roughness')} (mm)`,
          accessorKey: 'timedProps.roughness',
          accessorFn: (row) => row.timedProps.roughness ?? 0,
          Cell: ({ cell }) => renderNumber(cell.getValue<number | undefined>()),
          filterVariant: 'range',
          filterFn: 'betweenInclusive',
          muiTableBodyCellEditTextFieldProps: {
            error: !!validationErrors.length,
            helperText: validationErrors.length,
            type: 'number',
            onChange(event) {
              const value = event.target.value
              let error: string | undefined = undefined
              if (!validateRequiredNonNegative(value)) {
                error = t('validation.invalid_value') as string
              }
              setValidationErrors((prev) => ({ ...prev, length: error }))
            }
          }
        },
        {
          header: t('model.components.pipe.status.label') as string,
          accessorKey: 'conditionProps.status',
          accessorFn: (row) => row.conditionProps.status ?? '',
          filterVariant: 'select',
          filterSelectOptions: pipeStatusOptions,
          editVariant: 'select',
          editSelectOptions: pipeStatusOptions,
          Cell: ({ cell }) => renderStatus(cell.getValue<string | undefined>()),
          align: 'center',
        }
      ]
    },
    [i18n.language, validationErrors, pipeStatusOptions]
  )

  const tableData = useMemo<PipeTableFeature[]>(() => {
    const tableData = filterAndMapComponents<PipeTimedProps, PipeConditionProps>(
      data.features,
      EWAComponentType.pipe,
      currentDate,
      {},
      currentCondition,
      {}
    )

    return tableData
  }, [data.features, currentDate, currentCondition, i18n.language, data.id]);

  function onDeleteRow(props: {
    row: MRT_Row<PipeTableFeature>,
    table: MRT_TableInstance<PipeTableFeature>,
  }) {
    const { row } = props

    const feature = row.original.feature

    feature.delete()
    setModelWrapper(new EWAModelWrapper(data))
  }

  function onSaveRow(props: {
    exitEditingMode: () => void,
    row: MRT_Row<PipeTableFeature>,
    table: MRT_TableInstance<PipeTableFeature>,
    values: Record<string, any>
  }) {
    const { values, row, exitEditingMode } = props
    // first validate values
    if (values['id'] === '' ||
      !validateRequiredNonNegative(values['timedProps.diameter']) ||
      !validateRequiredNonNegative(values['timedProps.roughness']) ||
      !validateRequiredNonNegative(values['feature.properties.length'])) {
      return
    }

    // create the Apply object
    const pipe = row.original.feature as Pipe

    const currentTimed = findTimed({
      item: pipe,
      get: _ => _
    }, currentDate, {}) as Partial<PipeTimedProps>

    const timed: Partial<PipeTimedProps> = {
      minorloss: currentTimed.minorloss
    }

    const diameter = parseFloatOrUndefined(values['timedProps.diameter'])
    if (diameter !== undefined) {
      timed.diameter = diameter
    }

    const roughness = parseFloatOrUndefined(values['timedProps.roughness'])
    if (roughness !== undefined) {
      timed.roughness = roughness
    }

    const constructionYear = parseFloatOrUndefined(values['timedProps.construction_year'])
    if (constructionYear !== undefined) {
      timed.construction_year = constructionYear
    }

    const apply: PipeApply = {
      id: values['id'],
      comment: pipe.comment,
      lifetime: pipe.data.lifetime,
      length: parseFloat(values['feature.properties.length']),
      conditional: {
        status: values['conditionProps.status']
      },
      timed
    }

    pipe.apply(apply)
    setModelWrapper(new EWAModelWrapper(data))
    exitEditingMode()
    setValidationErrors({})
  }

  function renderNumber(value?: number) {
    return value !== undefined ? numberFormatter.format(value) : ''
  }

  function renderStatus(status?: string) {
    if (status === undefined) return

    return status !== '' ? t(`model.components.pipe.status.${status}`) : ''
  }

  return <MaterialReactTable
    columns={columns}
    data={tableData}
    initialState={{
      showColumnFilters: true
    }}
    localization={localization}
    enableEditing
    editingMode="row"
    state={{
      editingRow
    }}
    onEditingRowChange={(_: any) => setEditingRow(_)}
    renderRowActions={(props: {
      cell: MRT_Cell<PipeTableFeature>;
      row: MRT_Row<PipeTableFeature>;
      table: MRT_TableInstance<PipeTableFeature>;
    }): ReactNode => <RowActions<PipeTableFeature>
      {...props}
      onSaveRow={onSaveRow}
      onDeleteRow={onDeleteRow}
    ></RowActions>}
  />
}
