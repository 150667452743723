import { Paper, ToggleButton, Fade, Popper, useTheme, ClickAwayListener } from "@mui/material";
import { ShowResults } from "../../../data/ShowResults";
import { useTranslation } from "react-i18next";
import useToolboxStyles from "../util/useToolboxStyles";
import { usePopupState, bindPopper, bindTrigger } from "material-ui-popup-state/hooks";
import { Insights, FormatColorReset, LocalDrink } from "@mui/icons-material";
import EventManager from "../../../events/EventManager";
import addAlphaToHexColor from "../../../utils/addAlphaToHexColor";
import { EWAModelWrapper } from "../EWAModelWrapper";

export interface ShowResultsMenuProps {
    modelWrapper: EWAModelWrapper
    showResults: ShowResults
    setShowResults: (_: ShowResults) => void
}

const resultsIcon: Record<ShowResults, JSX.Element> = {
    [ShowResults.None]: <Insights />,
    [ShowResults.Demand]: <LocalDrink />,
    [ShowResults.HeatMap]: <FormatColorReset />,
}

export default function ShowResultsMenu(props: ShowResultsMenuProps) {
    const {
        modelWrapper,
        showResults,
        setShowResults
    } = props
    const classes = useToolboxStyles()
    const popupState = usePopupState({ variant: 'popper', popupId: 'showResultsMenu' })
    const { t } = useTranslation()
    const theme = useTheme()
    const backgroundColor = addAlphaToHexColor(theme.palette.darkBlue.main, 0.75)

    function toggleShowResults(prefShowResults: ShowResults) {
        const oldShowResults = showResults;
        const newShowResults = prefShowResults === oldShowResults ? ShowResults.None : prefShowResults;
        setShowResults(newShowResults);

        EventManager.getInstance().onResultsShown({
            oldShowResults,
            newShowResults,
            model: modelWrapper.model
        });
    }

    return <>
        <ClickAwayListener onClickAway={() => popupState.close()}>
            <div>
                <ToggleButton
                    value="showResults"
                    classes={{ selected: classes.selected }}
                    selected={showResults !== ShowResults.None}
                    title={t('model.controls.show_results.label') as string}
                    style={{ padding: '11px' }}
                    {...bindTrigger(popupState)}
                >
                    {resultsIcon[showResults]}
                </ToggleButton>
                <Popper {...bindPopper(popupState)} placement={"right"} transition>
                    {({ TransitionProps }) => (
                        <Fade {...TransitionProps} timeout={350}>
                            <Paper sx={{ backgroundColor }}>
                                <ToggleButton
                                    title={t('model.controls.show_results.heat_map') as string}
                                    classes={{ selected: classes.selected }}
                                    value="heatmap"
                                    selected={showResults === ShowResults.HeatMap}
                                    onChange={(event) => toggleShowResults(ShowResults.HeatMap)}
                                >
                                    {resultsIcon[ShowResults.HeatMap]}
                                </ToggleButton>
                                <ToggleButton
                                    title={t('model.controls.show_results.demand') as string}
                                    classes={{ selected: classes.selected }}
                                    value="demand"
                                    selected={showResults === ShowResults.Demand}
                                    onChange={(event) => toggleShowResults(ShowResults.Demand)}
                                >
                                    {resultsIcon[ShowResults.Demand]}
                                </ToggleButton>
                            </Paper>
                        </Fade>
                    )}
                </Popper>
            </div>
        </ClickAwayListener>
    </>
}
