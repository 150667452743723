import EventManager from "../../events/EventManager";
import EventType from "../../events/EventType";
import Task from "./base/Task";
import SimulationStartedEvent from "../../events/events/SimulationStartedEvent";
import TaskOptions, { ITaskOptions } from "./options/TaskOptions";
import i18n from "../../i18n";

export default class StartSimulationTask extends Task {
  protected _type: string = 'StartSimulationTask';
  public constructor(options?: TaskOptions) {
    super(options);

    this.onSimulationStarted = this.onSimulationStarted.bind(this);

  }

  public static fromPlainTaskOptions(plainOptions: ITaskOptions) {
    const options = new TaskOptions(plainOptions);
    return new StartSimulationTask(options);
  }

  protected onSimulationStarted(e: SimulationStartedEvent) {
    this.currentCount++;
    this.checkCompletion();
    this.triggerTaskViewUpdate();
  }

  public get description(): string {
    return i18n.t('challenge.task.start_simulation');
  }

  public activate() {
    super.activate();
    EventManager.getInstance().attachListener(EventType.SimulationStarted, this.onSimulationStarted);
  }

  public deactivate() {
    super.deactivate();
    EventManager.getInstance().detachListener(EventType.SimulationStarted, this.onSimulationStarted);
  }

}
