import { EWAComponentType } from "../../../data/EWAComponentType";
import Challenge from "../../Challenge";
import ChallengeOptions from "../../ChallengeOptions";
import TaskGroup from "../../TaskGroup";
import CreateComponentTask from "../../tasks/CreateComponentTask";
import DeleteComponentTask from "../../tasks/DeleteComponentTask";
import StayWithinBudgetTask from "../../tasks/StayWithinBudgetTask";
import ComponentTaskOptions from "../../tasks/options/ComponentTaskOptions";

export default function WithEconomy(): Challenge {
  const taskGroups: TaskGroup[] = [
    new TaskGroup([
      new StayWithinBudgetTask(),
      new CreateComponentTask(new ComponentTaskOptions({
        componentType: EWAComponentType.junction
      })),
      new DeleteComponentTask(new ComponentTaskOptions({
        componentType: EWAComponentType.junction
      }))
    ])
  ];

  const options = new ChallengeOptions({
    economyEnabled: true,
    economyBudget: 5000,
    priceMap: [
      [EWAComponentType.junction, 2500]
    ]
  });

  return new Challenge(taskGroups, options);
}
