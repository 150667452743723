import { Button } from '@mui/material';
import React from 'react'
import { EWAModel } from '../../../data/EWAModel';
import SimulationResultFaker from './simulations/SimulationResultFaker';

interface Props {
  model?: EWAModel
}

interface State {
  resultFakerOpen: boolean
}

class Simulations extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      resultFakerOpen: false
    };

    this.fakeNewSimulationResultEvent = this.fakeNewSimulationResultEvent.bind(this)
    this.closeResultFaker = this.closeResultFaker.bind(this)
  }

  fakeNewSimulationResultEvent() {
    this.setState({
      resultFakerOpen: true
    })
  }

  closeResultFaker() {
    this.setState({
      resultFakerOpen: false
    })
  }

  render() {
    return <div>
      <h3>Simulations</h3>
      <div>
        <Button variant="outlined" onClick={this.fakeNewSimulationResultEvent}>open simulation result faker</Button>
      </div>
      {this.state.resultFakerOpen && <SimulationResultFaker onClose={this.closeResultFaker} model={this.props.model} />}
    </div>
  }
}

export default Simulations;
