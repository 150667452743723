import { Box, Portal } from "@mui/material";
import Intro from "./sections/Intro";
import About from "./sections/About";
import HowTo from "./sections/HowTo";
import Features from "./sections/Features";
// import Background from "./sections/Background";
import Publications from "./sections/Publications";
import SectionNavButton from "./sections/SectionNavButton";
import { useMemo } from "react";
import Section from "./sections/Section";
import { useTheme } from "@mui/material/styles";

export interface LandingContentProps {
    portalContainer?: Element
}

type NavItem = {
    key: string
    component: JSX.Element
    color?: string
    backgroundColor?: string
}

export default function LandingContent(props: LandingContentProps) {
    const theme = useTheme()
    const navItems = useMemo<NavItem[]>(() => {
        return [
            { key: 'intro', component: <Intro />, backgroundColor: theme.palette.darkBlue.variant },
            { key: 'about', component: <About /> },
            { key: 'features', component: <Features />, backgroundColor: 'white', color: theme.palette.darkBlue.main },
            { key: 'howto', component: <HowTo />, backgroundColor: theme.palette.darkBlue.variant },
            // { key: 'background', component: <Background />, backgroundColor: 'white', color: theme.palette.darkBlue.main },
            { key: 'publications', component: <Publications /> }
        ]
    }, [])
    return <>
        <Portal container={props.portalContainer}>
            <Box display="flex" alignItems="center" justifyContent="space-between">
                <Box sx={{ paddingLeft: 2, paddingRight: 2 }}>
                    {navItems.map(_ => <SectionNavButton targetId={_.key} key={_.key} />)}
                </Box>
            </Box>
        </Portal>
        <Box flexGrow={1}>
            {navItems.map(_ => <Section
                id={_.key}
                key={_.key}
                color={_.color}
                backgroundColor={_.backgroundColor}
            >
                {_.component}
            </Section>)}
        </Box>
    </>
}
