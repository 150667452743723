import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { EWAComponentType } from "../../../data/EWAComponentType";
import EventManager from "../../../events/EventManager";

export interface ComponentToggleVisibilityButtonProps {
    componentType: EWAComponentType
    showComponents: EWAComponentType[]
    setShowComponents: (_: EWAComponentType[]) => void
}

export function ComponentToggleVisibilityButton(props: ComponentToggleVisibilityButtonProps) {
    const {
        componentType,
        showComponents,
        setShowComponents
    } = props
    const { t } = useTranslation()

    const isVisible = showComponents.includes(componentType)

    function toggleComponentVisibility() {
        const newShowComponents = isVisible ? showComponents.filter(_ => _ !== componentType) : [...showComponents, componentType]
        setShowComponents(newShowComponents)

        EventManager.getInstance().onComponentVisibilityChanged({
            componentType,
            visible: !isVisible
        })
    }

    const title = t(`model.controls.show_components.${componentType.toLowerCase()}`) as string
    const icon = isVisible ? <Visibility htmlColor="white" /> : <VisibilityOff htmlColor="white"/>
    return <Button
        value={componentType}
        onClick={() => toggleComponentVisibility()}
        title={title}
        style={{ padding: '11px' }}
    >
        <span>{icon}</span>
    </Button>

}
