import { EWAComponentType } from "../../../data/EWAComponentType";
import Challenge from "../../Challenge";
import ChallengeOptions from "../../ChallengeOptions";
import TaskGroup from "../../TaskGroup";
import TaskGroupOptions from "../../TaskGroupOptions";
import CreateComponentTask from "../../tasks/CreateComponentTask";
import JunctionUpdateDemandTask from "../../tasks/JunctionUpdateDemandTask";
import ComponentTaskOptions from "../../tasks/options/ComponentTaskOptions";
import UpdateStringTaskOptions from "../../tasks/options/UpdateStringTaskOptions";
import UpdateValueTaskOptions from "../../tasks/options/UpdateValueTaskOptions";
import PipeUpdateStatusTask from "../../tasks/PipeUpdateStatusTask";

export default function UiTest(): Challenge {
  const taskGroups = [
    new TaskGroup(
      [
        new CreateComponentTask(new ComponentTaskOptions({
          componentType: EWAComponentType.junction,
          tooltip: { en: 'Create 2 junctions anywhere on the map.', de: 'Erstelle 2 Knotenpunkte irgendwo auf der Karte.'},
          name: { en: 'Junction Creation', de: 'Knotepunkte Erstellen'},
          requiredCount: 2
        })),
        new CreateComponentTask(new ComponentTaskOptions({
          componentType: EWAComponentType.pipe,
          tooltip: { en: 'Connect the previously created junctions with a pipe', de: 'Verbinde die 2 erstellten Knotenpunkte mit einer Rohrleitung'},
          name: { en: 'Pipe Creation', de: 'Rohr Erstellen'},
          requiredCount: 1
        }))
      ], new TaskGroupOptions({
        name: { en: 'First TaskGroup', de: 'Erste Aufgabengruppe' },
        description: [
          {
            en: `
### Header

* item 1
* item 2
        `,
        de: `
### Überschrift

* Gegenstand 1
* Gegenstand 2
        `
          }
        ]
      })
    ),
    new TaskGroup(
      [
        new JunctionUpdateDemandTask(new UpdateValueTaskOptions<number>({
          tooltip: { en: 'Now update the base demand of any junction', de: 'Nun verändere den Wasserverbrauch bei irgendeinem Knotenpunkt'},
          name: { en: 'Update Base Demand', de: 'Wasserverbrauch ändern' }
        })),
        new PipeUpdateStatusTask(new UpdateStringTaskOptions({
          tooltip: { en: 'Update thet status type of the pipe', de: 'Ändere den Status des Rohres'},
          name: { en: 'Update pipe status', de: 'Rohrstatus Ändern' }
        }))
      ],
      new TaskGroupOptions({
        name: { en: 'Second TaskGroup', de: 'Zweite Aufgabengruppe' }
      })
    )
  ];

  const options = new ChallengeOptions({
    economyBudget: 10000,
    economyEnabled: true,
    completionText: {
      en: "Congratulations",
      de: "Kongratulation"
    }
  });

  return new Challenge(taskGroups, options)
}
