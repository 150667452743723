/**
 * parse hex color string
 * taken and adapted from deck.gl - @deckl.gl/core/util/color
 *
 * @param color
 * @returns
 */
export default function parseHexColor(color: string, ): number[] {
  const result = []

  const value = parseInt(color.substring(1), 16);
  result[0] = Math.floor(value / 65536);
  result[1] = Math.floor((value / 256) % 256);
  result[2] = value % 256;

  return result;
}
