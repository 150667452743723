import { BaseFeature } from "../data/BaseFeature";

export interface MultiConditionGetter<T extends BaseFeature<any, U, any>, U> {
    items: T[]
    get: (_: U) => any
}

export function findConditionMulti(multiConditionGetter: MultiConditionGetter<any, any>, condition: string|undefined, alternative: any): any {
    if (condition === undefined) return alternative

    const values = new Array<any>();
    for (let i = 0; i < multiConditionGetter.items.length; i++) {
        if (multiConditionGetter.items[i].data.properties.conditional[condition] !== undefined) {
            values.push(multiConditionGetter.get(multiConditionGetter.items[i].data.properties.conditional[condition]))
        } else
            return alternative;
    }
    const result = new Set(values).size === 1;
    if (result) {
        return values[0];
    }
    return alternative;
}
