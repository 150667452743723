import { ComponentApplyEventArgs } from "../../events/events/ComponentApplyEvent";
import EventManager from "../../events/EventManager";
import EventType from "../../events/EventType";
import { JunctionApply } from "../../views/forms/JunctionApply";
import BooleanValueUpdateTask from "./base/BooleanValueUpdateTask";
import { ValuesPair } from "./base/ValueUpdateTask";
import UpdateBooleanTaskOptions from "./options/UpdateBooleanTaskOptions";
import { IUpdateValueTaskOptions } from "./options/UpdateValueTaskOptions";

export default class JunctionUpdateScaleDemandTask extends BooleanValueUpdateTask<JunctionApply> {
  protected _type: string = 'JunctionUpdateScaleDemandTask';
  protected componentName: string = "ewa_component_type.Junction_one";
  protected fieldName: string = "model.components.junction.scale_demand";

  public static fromPlainTaskOptions(plainOptions: IUpdateValueTaskOptions<boolean>) {
    const options = new UpdateBooleanTaskOptions(plainOptions);
    return new JunctionUpdateScaleDemandTask(options)
  }

  public activate() {
    super.activate();
    EventManager.getInstance().attachListener(EventType.JunctionApply, this.onValueUpdate);
  }

  public deactivate() {
    super.deactivate();
    EventManager.getInstance().detachListener(EventType.JunctionApply, this.onValueUpdate);
  }

  protected retrieveValues(args: ComponentApplyEventArgs<JunctionApply>): ValuesPair<boolean> {
    return {
      newValue: args.apply.timed.scale_demand,
      oldValue: args.oldApply.timed.scale_demand
    }
  }
}
