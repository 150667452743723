/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Consumption mode of a Junction.
 *
 * classic mode: regular base demand
 * ewa mode: base demand is calculated for different component types
 * 
 */
export enum ConsumptionMode {
    CLASSIC = 'classic',
    EWA = 'ewa',
}