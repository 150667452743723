import { ComponentApplyEventArgs } from "../../events/events/ComponentApplyEvent";
import EventManager from "../../events/EventManager";
import EventType from "../../events/EventType";
import { ValveGPVApply } from "../../views/forms/ValveGPVApply";
import NumberValueUpdateTask from "./base/NumberValueUpdateTask";
import { ValuesPair } from "./base/ValueUpdateTask";
import UpdateValueTaskOptions, { IUpdateValueTaskOptions } from "./options/UpdateValueTaskOptions";

export default class GPVUpdateDiameterTask extends NumberValueUpdateTask<ValveGPVApply> {
  protected _type: string = 'GPVUpdateDiameterTask';
  protected componentName: string = "valve_component_type.GPV";
  protected fieldName: string = "model.components.valve.diameter";

  public static fromPlainTaskOptions(plainOptions: IUpdateValueTaskOptions<number>) {
    const options = new UpdateValueTaskOptions<number>(plainOptions);
    return new GPVUpdateDiameterTask(options)
  }

  public activate() {
    super.activate();
    EventManager.getInstance().attachListener(EventType.ValveGPVApply, this.onValueUpdate);
  }

  public deactivate() {
    super.deactivate();
    EventManager.getInstance().detachListener(EventType.ValveGPVApply, this.onValueUpdate);
  }

  protected retrieveValues(args: ComponentApplyEventArgs<ValveGPVApply>): ValuesPair<number> {
    return {
      newValue: args.apply.timed.diameter,
      oldValue: args.oldApply.timed.diameter
    };
  }
}
