import { BaseFeature } from "../data/BaseFeature";

export interface SingleTimedGetter<T extends BaseFeature<any, any, U>, U> {
    item: T
    get: (_: U) => any
}

export function findTimed(singleTimedGetter: SingleTimedGetter<any, any>, currentDate: number, alternative: any): any {
    const value = singleTimedGetter.item._timedProperties.findValue(currentDate)
    return internalFinder(value, singleTimedGetter, alternative)
}

export function findTimedIfDefined(singleTimedGetter: SingleTimedGetter<any, any>, currentDate: number, alternative: any): any {
    const value = singleTimedGetter.item._timedProperties.findValueIfDefined(currentDate)
    return internalFinder(value, singleTimedGetter, alternative)
}

export function findComputedTimed(singleTimedGetter: SingleTimedGetter<any, any>, currentDate: number, alternative: any): any {
    const value = singleTimedGetter.item._timedProperties.findComputedValue(currentDate)
    return internalFinder(value, singleTimedGetter, alternative)
}

function internalFinder(value: any, singleTimedGetter: SingleTimedGetter<any, any>, alternative: any): any {
    if (value !== undefined) {
        const result = singleTimedGetter.get(value);
        if (result !== undefined) {
            return result;
        }
    }
    return alternative;
}
