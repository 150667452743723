import { useState } from "react";
import { ValvePRV } from "../../data/ValvePRV";
import { TextField } from "@mui/material";
import { PRVTimedProps } from "../../api/client";
import { findTimed, findComputedTimed } from "../../utils/findTimed";
import { useTranslation } from "react-i18next";
import parseFloatOrUndefined from "../../utils/parseFloatOrUndefined";
import validNumber from "../../utils/validNumber";
import { useFormContext } from "./FormContext";
import { singleFormComputeIfFirstYear } from "./util";
import { SingleValveForm } from "./SingleValveForm";

export interface SingleValvePRVFormProps {
    valve: ValvePRV
}

export function SingleValvePRVForm(props: SingleValvePRVFormProps) {
    const { valve } = props;
    const context = useFormContext();

    const currentDate = context.currentDate as number
    const [isFirstYear, setIsFirstYear] = useState<boolean>(singleFormComputeIfFirstYear(valve, currentDate))

    const currentTimed = findTimed({
        item: valve,
        get: _ => _
    }, currentDate, {}) as Partial<PRVTimedProps>
    const fallbackTimed = findComputedTimed({
        item: valve,
        get: _ => _
    }, isFirstYear ? currentDate : currentDate - 10, {}) as Partial<PRVTimedProps>
    const [maximumPressure, setMaximumPressure] = useState<string>(currentTimed.maximum_pressure?.toString() ?? '');
    const { t } = useTranslation();

    function updateTimedState(timed: Partial<PRVTimedProps>) {
        setMaximumPressure(timed.maximum_pressure?.toString() ?? '')
    }

    function validate() {
        if (isFirstYear || maximumPressure !== '') {
            const parsedMaximumPressure = parseFloatOrUndefined(maximumPressure)

            if (!validNumber(parsedMaximumPressure) || parsedMaximumPressure! < 0) {
                return false
            }
        }

        return true
    }

    function computeTimedApply(): Partial<PRVTimedProps> {
        const timed: Partial<PRVTimedProps> = {}

        const applyMaximumPressure = parseFloatOrUndefined(maximumPressure)
        if (applyMaximumPressure !== undefined) {
            timed.maximum_pressure = applyMaximumPressure
        }
        return timed
    }

    return <SingleValveForm
        isFirstYear={isFirstYear}
        setIsFirstYear={setIsFirstYear}
        fallbackTimed={fallbackTimed}
        currentTimed={currentTimed}
        validate={validate}
        valve={valve}
        updateTimedState={updateTimedState}
        computeTimedApply={computeTimedApply}
        timedChildren={<>
            <TextField
                type="number"
                required
                value={(maximumPressure)}
                onChange={(event) => setMaximumPressure((event.target.value))}
                style={{ flexGrow: 1, marginBottom: "10px" }}
                label={t('model.components.valve.prv.maximum_pressure')}
                helperText={isFirstYear ? undefined : fallbackTimed.maximum_pressure?.toString()}
            />
        </>}></SingleValveForm>
}
