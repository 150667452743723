import { PBVConditionProps, PBVModel, PBVTimedProps } from "../api/client";
import EventManager from "../events/EventManager";
import { ValvePBVApply } from "../views/forms/ValvePBVApply";
import { BaseLinestringFeature } from "./BaseLinestringFeature";
import { EWAComponentType } from "./EWAComponentType";
import { ValveComponentType } from "./ValveComponentType";

export class ValvePBV extends BaseLinestringFeature<PBVConditionProps, Partial<PBVTimedProps>> {

    public get componentType(): EWAComponentType {
        return EWAComponentType.valve;
    }
    public get valveType(): ValveComponentType {
        return ValveComponentType.pbv;
    }

    //Must return number here for GPU filtering
    public get visible() {
        return this.collection.showComponents.includes(EWAComponentType.valve) ? 1 : 0;
    }

    public apply(apply: ValvePBVApply): void {
        const currentDate = this.collection.currentDate;
        const oldApply: ValvePBVApply = {
            id: this.id,
            comment: this.comment,
            lifetime: this.data.lifetime,
            conditional: { ...this.data.properties.conditional[this.collection.currentCondition!] } as PBVConditionProps,
            timed: { ...this._timedProperties.getValue(currentDate) } as Partial<PBVTimedProps>
        }

        super.apply(apply);
        EventManager.getInstance().onValvePBVApply({
            id: this.id,
            apply,
            oldApply,
            year: currentDate,
            model: this.collection
        });
    }

    public delete(): void {
        super.delete();
        EventManager.getInstance().onComponentDeleted({
            id: this.id,
            type: EWAComponentType.valve,
            valveType: ValveComponentType.pbv,
            year: this.collection.currentDate,
            model: this.collection
        });
    }

    public toComponentModel() {
        const model = {
            id: this.id,
            startnode: this.startnode,
            endnode: this.endnode,
            type: "Feature",
            bbox: this.bbox,
            geometry: this._geometry,
            properties: {
                comment: this.comment,
                timed: this._timedProperties.items(),
                conditional: this.apiConditional
            },
            component_type: ValveComponentType.pbv,
            lifetime: [this.visibleFrom, this.visibleTo]
        } as PBVModel

        return model
    }
}
