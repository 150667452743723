import { Button, TextField } from "@mui/material";
import { useState } from "react";
import { PerformanceIndicatorFormProps } from "../SingleResultForm";
import SaveIcon from "@mui/icons-material/Save";
import { SingleResult } from "../../../../../api/client";
import { EWAResultType } from "@/views/model/simulation/EWAResultType";
import { useTranslation } from "react-i18next";

export function ResilienceIndexForm(props: PerformanceIndicatorFormProps) {
    const [value, setValue] = useState(props.singleResult?.value?.toString() ?? '')
    const { t } = useTranslation()
    function onTextFieldChange(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        setValue(e.target.value)
    }

    function onSave() {
        const parsedValue = Number.parseFloat(value ?? '')

        const newSingleResult: SingleResult = {
            ...(props.singleResult ?? {}),
            value: Number.isNaN(parsedValue) ? undefined : parsedValue
        }

        props.handleSave(newSingleResult)
    }

    return <>
        <TextField label={t(`ewa_result_type.${EWAResultType.ResilienceIndex}`)} value={value} onChange={onTextFieldChange} fullWidth sx={{ marginBottom: '1rem' }}></TextField>
        <Button variant="outlined" onClick={onSave}><SaveIcon></SaveIcon>Save</Button>
    </>
}
