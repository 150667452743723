import EventType from "../EventType";

export interface HomeChallengeDeletedEventArgs {
    modelId: string
}

export default class HomeChallengeDeletedEvent extends CustomEvent<HomeChallengeDeletedEventArgs> {
    constructor(detail: HomeChallengeDeletedEventArgs) {
        super(EventType.HomeChallengeUploaded, { detail })
    }
}
