import { Language } from "@mui/icons-material"
import { Button, Menu, MenuItem } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useRef, useState, useMemo } from 'react'
import { supportedLngs } from "../i18n"
import { useDispatch } from "react-redux"
import { setLanguage } from "../store/slices/configurationSlice"

export interface LanguageSelectProps {
    variant?: 'text'|'outlined'|'contained'
}

export default function LanguageSelect(props: LanguageSelectProps) {
    const {
        variant
    } = props

    const anchorRef = useRef<any>()
    const {t, i18n} = useTranslation()
    const [open, setOpen] = useState<boolean>(false)
    const dispatch = useDispatch()

    const menuItems = useMemo<JSX.Element[]>(() => {
        const items: JSX.Element[] = []
        for (const locale of supportedLngs) {
            items.push(<MenuItem
                key={locale}
                onClick={() => setLocale(locale)}
                selected={locale === i18n.language}
            >{t(`locale.${locale}`)}</MenuItem>)
        }
        return items
    }, [i18n.language])

    function setLocale(locale: string) {
        dispatch(setLanguage(locale))
        setOpen(false)
    }

    return <>
    <div ref={anchorRef}>
        <Button
            variant={variant}
            startIcon={<Language />}
            onClick={() => setOpen(true)}
            sx={{color: 'white'}}
        >
            {i18n.language}
        </Button>
        </div>
        <Menu
            anchorEl={anchorRef.current}
            open={open}
            onClose={() => setOpen(false)}
        >
            {menuItems}
        </Menu>
    </>
}
