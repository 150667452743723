import { BaseFeature } from "../data/BaseFeature";

export interface SingleConditionGetter<T extends BaseFeature<any, U, any>, U> {
    item: T
    get: (_: U) => any
}

export function findCondition(singleConditionGetter: SingleConditionGetter<any, any>, condition: string|undefined, alternative: any): any {
    if (condition !== undefined && singleConditionGetter.item.data.properties.conditional[condition] !== undefined) {
        const result = singleConditionGetter.get(singleConditionGetter.item.data.properties.conditional[condition]);
        if (result !== undefined) {
            return result;
        }
    }
    return alternative;
}
