export enum ValveComponentType {
    fcv = "FCV",
    gpv = "GPV",
    pbv = "PBV",
    prv = "PRV",
    psv = "PSV",
    tcv = "TCV"
}

export function valveComponentTypeFromString(value: string): ValveComponentType {
    switch (value) {
        case 'FCV':
        case 'GPV':
        case 'PBV':
        case 'PRV':
        case 'PSV':
        case 'TCV':
            return value as ValveComponentType
        default:
            throw Error(`Unable to determine type for: ${value}`)
    }
}
