import { EWAModelDTO, EWAModelVersionDto, ModellingService } from "@/api/client";
import { useEffect, useState } from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import { Button } from "@mui/material";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { Launch } from "@mui/icons-material";
import CardList from "@/widgets/CardList";
import VersionCard from "./VersionCard";
import { useHref } from 'react-router-dom';

export interface VersionsTableProps {
    model: EWAModelDTO;
}
export function VersionsTable(props: VersionsTableProps) {
    const { model } = props;
    const { enqueueSnackbar } = useSnackbar();
    const [versions, setVersions] = useState<Array<EWAModelVersionDto>>()
    const [selection, setSelection] = useState<EWAModelVersionDto | undefined>(undefined);
    const { t } = useTranslation();

    let versionSearch = undefined
    if (selection !== undefined &&  selection.version !== model.version) {
        versionSearch = `version=${selection.version}`
    }
    const hrefOpen = useHref({
        pathname: `/tool/model/${model.id}`,
        search: versionSearch
    })

    async function loadVersions() {
        setSelection(undefined)
        try {
            const versions = await ModellingService.modelVersionsV1ModelsModelIdVersionsGet(model.id)
            setVersions(versions)
        } catch(e: any) {
            enqueueSnackbar(e.message, {
                variant: 'error',
            });
        }
    }

    useEffect(() => {
        loadVersions()
    }, [model, model.id])

    function rowClick(item: EWAModelVersionDto) {
        setSelection(item)
    }

    function rowIsSelected(item: EWAModelVersionDto) {
        return selection === item
    }


    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%"
        }}>
            <div style={{ flexGrow: 1 }}>
                <AutoSizer>
                    {({ height, width }: { height: number, width: number }) => (
                        <div style={{ width }}>
                            <CardList
                                rowKey="id"
                                items={versions ?? []}
                                height={height}
                                rowRenderer={item => <VersionCard version={item as EWAModelVersionDto} />}
                                rowClick={item => rowClick(item as EWAModelVersionDto)}
                                rowIsSelected={item => rowIsSelected(item as EWAModelVersionDto)}
                            />
                        </div>
                    )}
                </AutoSizer>
            </div>
            <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: 'flex-end' }}>
                <Button
                variant="contained"
                color="success"
                    type="submit"
                    disabled={selection === undefined}
                    href={hrefOpen}
                    startIcon={<Launch />}
                >
                    {t('general.open')}
                </Button>
            </div>
        </div>)

}
