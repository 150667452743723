import { Tank } from "../../data/Tank";
import { TankApply } from "./TankApply";
import { BaseMultiForm, BaseMultiFormApply } from "./BaseMultiForm";
import { useState, useEffect } from "react";
import { findProp } from "../../utils/findProp";
import { TankConditionProps, TankTimedProps } from "../../api/client";
import { EWAModelWrapper } from "../model/EWAModelWrapper";
import { TextField, InputAdornment } from "@mui/material";
import { useTranslation } from "react-i18next";
import parseFloatOrUndefined from "../../utils/parseFloatOrUndefined";
import validNumber from "../../utils/validNumber";
import { useFormContext } from "./FormContext";
import { findComputedTimedMulti, findTimedIfDefinedMulti, findTimedMulti } from "../../utils/findTimedMulti";
import { multiFormComputeIfFirstYear } from "./util";

export interface MultiTankFormProps {
    tanks: Tank[]
}

export function MultiTankForm(props: MultiTankFormProps) {
    const { tanks } = props;
    const context = useFormContext();
    const currentDate = context.currentDate as number

    const [isFirstYear, setIsFirstYear] = useState<boolean>(multiFormComputeIfFirstYear(tanks, currentDate))
    const [initLevel, setInitLevel] = useState<string>(findTimedMulti({
        items: tanks,
        get: _ => _.initlevel
    }, context.currentDate, undefined)?.toString() ?? '');
    const [fallbackInitLevel, setFallbackInitLevel] = useState<number | undefined>(findComputedTimedMulti({
        items: tanks,
        get: _ => _.initlevel
    }, context.currentDate, undefined))
    const [diameter, setDiameter] = useState<string>(findTimedMulti({
        items: tanks,
        get: _ => _.diameter
    }, context.currentDate, undefined)?.toString() ?? '');
    const [fallbackDiameter, setFallbackDiameter] = useState<number | undefined>(findComputedTimedMulti({
        items: tanks,
        get: _ => _.diameter
    }, context.currentDate, undefined))
    const { t } = useTranslation();

    useEffect(() => {
        const currentDate = context.currentDate as number
        const newIsFirstYear = multiFormComputeIfFirstYear(tanks, currentDate)
        setIsFirstYear(newIsFirstYear)

        setDiameter(findTimedIfDefinedMulti({
            items: tanks,
            get: (props) => props.diameter
        }, currentDate, undefined)?.toString() ?? '')
        setFallbackDiameter(findComputedTimedMulti({
            items: tanks,
            get: _ => _.diameter
        }, newIsFirstYear ? currentDate : currentDate - 10, undefined))

        setInitLevel(findTimedIfDefinedMulti({
            items: tanks,
            get: (props) => props.initlevel
        }, currentDate, undefined)?.toString() ?? '')
        setFallbackInitLevel(findComputedTimedMulti({
            items: tanks,
            get: _ => _.initlevel
        }, newIsFirstYear ? currentDate : currentDate - 10, undefined))
    }, [tanks, context.currentDate, context.condition, context.modelWrapper?.id])

    function handleApply(a: BaseMultiFormApply) {
        const timed: Partial<TankTimedProps> = {}

        const applyInitLevel = parseFloatOrUndefined(initLevel)
        if (applyInitLevel !== undefined) {
            timed.initlevel = applyInitLevel
        }

        const applyDiameter = parseFloatOrUndefined(diameter)
        if (applyDiameter !== undefined) {
            timed.diameter = applyDiameter
        }

        for (const tank of tanks) {
            const geometry = tank._geometry
            const apply = {
                id: tank.id,
                comment: tank.comment,
                lifetime: a.lifetime,
                longitude: geometry.coordinates[0],
                latitude: geometry.coordinates[1],
                elevation: geometry.coordinates[2],
                strength: findProp({ item: tank, get: (props) => props.strength }, undefined),
                conditional: {} as TankConditionProps,
                timed: timed
            } as TankApply

            tank.apply(apply)
        }
        context.setModelWrapper(new EWAModelWrapper(context.modelWrapper!.model));
    }

    function validate() {
        if (isFirstYear || initLevel !== '') {
            const parsedInitLevel = parseFloatOrUndefined(initLevel)
            if (!validNumber(parsedInitLevel) || parsedInitLevel! <= 0) {
                return false
            }
        }

        if (isFirstYear || diameter !== '') {
            const parsedDiameter = parseFloatOrUndefined(diameter)
            if (!validNumber(parsedDiameter) || parsedDiameter! <= 0) {
                return false
            }
        }

        return true
    }

    return <BaseMultiForm
        handleApply={handleApply}
        features={tanks}
        validate={validate}
        timedChildren={<>
            <TextField
                required
                type="number"
                value={initLevel}
                onChange={(event) => setInitLevel(event.target.value)}
                style={{ flexGrow: 1, marginBottom: "10px" }}
                label={t('model.components.tank.initlevel')}
                InputProps={{ endAdornment: <InputAdornment position="end">m</InputAdornment> }}
                helperText={isFirstYear ? undefined : fallbackInitLevel?.toString()}
            />
            <TextField
                required
                type="number"
                value={diameter}
                onChange={(event) => setDiameter(event.target.value)}
                style={{ flexGrow: 1, marginBottom: "10px" }}
                label={t('model.components.tank.diameter')}
                InputProps={{ endAdornment: <InputAdornment position="end">m</InputAdornment> }}
                helperText={isFirstYear ? undefined : fallbackDiameter?.toString()}
            />
        </>}
    ></BaseMultiForm>
}
