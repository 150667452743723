import { Box, Container } from '@mui/material'
import logoTugraz from '../../assets/landing/logo_tugraz.png'
import logoSww from '../../assets/landing/logo_sww.png'
import logoIsds from '../../assets/landing/logo_isds_300px.jpg'
import logoCarinthia from '../../assets/landing/ktn-logo.png'
import logoSalzburg from '../../assets/landing/logo-sbg.png'
import logoStyria from '../../assets/landing/logo-stmk.png'
import logoWasserverband from '../../assets/landing/Wasserverband_GrazerfeldSuedost_Bubble.png'
import logoEww from '../../assets/landing/eww.png'
import logoBml from '../../assets/landing/BML_Logo_srgb.png'
import logoUpperAustria from '../../assets/landing/logo_oberoesterreich.png'
import logoHolding from '../../assets/landing/logo_holding.png'

interface FooterIconProps {
    src: string
    alt: string
}

function FooterIcon(props: FooterIconProps) {
    const { src, alt } = props

    return <Box>
        <img src={src} alt={alt} className="footer-img responsive-img" style={{ maxHeight: '100px' }} />
    </Box>
}

export interface LandingFooterProps {

}

export default function LandingFooter() {
    function renderLogos() {
        return <Box display='flex' flexDirection="row" flexWrap="wrap" alignItems="center" justifyContent="center" gap={3}>
            <FooterIcon src={logoBml} alt="Logo Bundesministerium für Landwirtschaft, Regionen und Tourismus" />
            <FooterIcon src={logoUpperAustria} alt="Logo Land Oberoesterreich" />
            <FooterIcon src={logoStyria} alt="Logo Land Steiermark" />
            <FooterIcon src={logoCarinthia} alt="Logo Land Kaernten" />
            <FooterIcon src={logoSalzburg} alt="Logo Land Salzburg" />
            <FooterIcon src={logoHolding} alt="Logo Graz Holding" />
            <FooterIcon src={logoEww} alt="Logo EWW Gruppe" />
            <FooterIcon src={logoWasserverband} alt="Logo Wasserverband Grazerfeld Sued" />
            <FooterIcon src={logoTugraz} alt="TU Graz Logo" />
            <FooterIcon src={logoSww} alt="SWW Logo" />
            <FooterIcon src={logoIsds} alt="ISDS Logo" />
        </Box>
    }

    return <Box sx={{
        backgroundColor: 'white',
        paddingTop: 10
    }}>
        <Container>
            {renderLogos()}
        </Container>
    </Box>
}
