import { useEffect, useState } from "react";
import { PipeConditionProps, PipeMaterial, PipeTimedProps } from "../../api/client";
import { Pipe } from "../../data/Pipe";
import { EWAModelWrapper } from "../model/EWAModelWrapper";
import { PipeApply } from "./PipeApply";
import { TextField, InputAdornment } from "@mui/material";
import { BaseSingleLinestringForm, BaseSingleLinestringFormApply } from "./BaseSingleLineStringForm";
import { findTimed, findTimedIfDefined, findComputedTimed } from "../../utils/findTimed";
import { useTranslation } from "react-i18next";
import PipeStatusSelect from "./PipeStatusSelect";
import parseFloatOrUndefined from "../../utils/parseFloatOrUndefined";
import validNumber from "../../utils/validNumber";
import { useFormContext } from "./FormContext";
import { findCondition } from "../../utils/findCondition";
import PipeMaterialSelect from "@/widgets/PipeMaterialSelect";

export interface SinglePipeFormProps {
    pipe: Pipe
}

export function SinglePipeForm(props: SinglePipeFormProps) {
    const { pipe } = props;
    const context = useFormContext();

    const currentDate = context.currentDate as number
    const lifetime = pipe.data.lifetime as number | number[]
    const isFirstYear = Array.isArray(lifetime) ? context.currentDate === lifetime[0] : context.currentDate === lifetime

    const currentTimed = findTimed({
        item: pipe,
        get: _ => _
    }, currentDate, {}) as Partial<PipeTimedProps>
    const fallbackTimed = findComputedTimed({
        item: pipe,
        get: _ => _
    }, isFirstYear ? currentDate : currentDate - 10, {}) as Partial<PipeTimedProps>

    const [constructionYear, setConstructionYear] = useState<string>(currentTimed.construction_year?.toString() ?? '')
    const [material, setMaterial] = useState<PipeMaterial|undefined>(currentTimed.material)
    const [roughness, setRoughness] = useState<string>(currentTimed.roughness?.toString() ?? '');
    const [length, setLength] = useState<string>(pipe.length?.toString() ?? '');
    const [diameter, setDiameter] = useState<string>(currentTimed.diameter?.toString() ?? '');
    const [status, setStatus] = useState<string>(findCondition({
        item: pipe,
        get: _ => _.status
    }, context.condition, ''));
    const { t } = useTranslation();

    useEffect(() => {
        const timed = findTimedIfDefined({
            item: pipe,
            get: _ => _
        }, currentDate, {}) as Partial<PipeTimedProps>

        setConstructionYear(timed.construction_year?.toString() ?? '')
        setRoughness(timed.roughness?.toString() ?? '')
        setDiameter(timed.diameter?.toString() ?? '')
        setLength(pipe.length?.toString() ?? '')
        setStatus(findCondition({
            item: pipe,
            get: _ => _.status
        }, context.condition, ''));

    }, [pipe, context.currentDate, context.condition, context.modelWrapper?.id])

    function validate() {
        if (isFirstYear || diameter !== '') {
            const parsedDiameter = parseFloatOrUndefined(diameter)
            if (!validNumber(parsedDiameter) || parsedDiameter! <= 0) {
                return false
            }
        }

        if (isFirstYear || length !== '') {
            const parsedLength = parseFloatOrUndefined(length)
            if (!validNumber(parsedLength) || parsedLength! <= 0) {
                return false
            }
        }

        if (isFirstYear || roughness !== '') {
            const parsedRoughness = parseFloatOrUndefined(roughness)
            if (!validNumber(parsedRoughness) || parsedRoughness! <= 0) {
                return false
            }
        }

        if (constructionYear !== '') {
            const parsedConstructionYear = parseFloatOrUndefined(constructionYear)
            if (!validNumber(parsedConstructionYear) || parsedConstructionYear! < 0) {
                return false
            }
        }

        return true
    }

    function handleApply(a: BaseSingleLinestringFormApply) {
        const timed: Partial<PipeTimedProps> = {
            minorloss: currentTimed.minorloss,
            material
        }

        const applyRoughness = parseFloatOrUndefined(roughness)
        if (applyRoughness !== undefined) {
            timed.roughness = applyRoughness
        }
        const applyDiameter = parseFloatOrUndefined(diameter)
        if (applyDiameter !== undefined) {
            timed.diameter = applyDiameter
        }
        const applyConstructionYear = parseFloatOrUndefined(constructionYear)
        if (applyConstructionYear !== undefined) {
            timed.construction_year = applyConstructionYear
        }

        let apply = {
            id: a.id,
            comment: a.comment,
            lifetime: a.lifetime,
            length: parseFloat(length),
            conditional: {
                status: status
            } as PipeConditionProps,
            timed: timed
        } as PipeApply
        pipe.apply(apply);
        context.setModelWrapper(new EWAModelWrapper(context.modelWrapper!.model));
    }

    return (<BaseSingleLinestringForm validate={validate} handleApply={handleApply} feature={pipe} timedChildren={<>
        <TextField
            required
            type="number"
            value={diameter}
            onChange={(event) => setDiameter(event.target.value)}
            style={{ flexGrow: 1, marginBottom: "10px" }}
            label={t('model.components.pipe.diameter')}
            InputProps={{ endAdornment: <InputAdornment position="end">mm</InputAdornment> }}
            helperText={isFirstYear ? undefined : fallbackTimed.diameter?.toString()}
        />
        <TextField
            required
            type="number"
            value={(length)}
            onChange={(event) => setLength((event.target.value))}
            style={{ flexGrow: 1, marginBottom: "10px" }}
            label={t('model.components.pipe.length')}
            InputProps={{ endAdornment: <InputAdornment position="end">m</InputAdornment> }}
        />
        <TextField
            required
            type="number"
            value={(roughness)}
            onChange={(event) => setRoughness((event.target.value))}
            style={{ flexGrow: 1, marginBottom: "10px" }}
            label={t('model.components.pipe.roughness')}
            InputProps={{ endAdornment: <InputAdornment position="end">mm</InputAdornment> }}
            helperText={isFirstYear ? undefined : fallbackTimed.roughness?.toString()}
        />
        <TextField
            type="number"
            value={constructionYear}
            onChange={(event) => setConstructionYear(event.target.value)}
            style={{ flexGrow: 1, marginBottom: "10px" }}
            label={t('model.components.pipe.construction_year')}
            helperText={isFirstYear ? undefined : fallbackTimed.construction_year?.toString()}
        />
        <PipeMaterialSelect value={material} setValue={setMaterial} />
    </>
    } conditionChildren={<PipeStatusSelect status={status} setStatus={setStatus} />}>
    </BaseSingleLinestringForm>)
}
