import { Grid } from "@mui/material";
import { withTranslation } from "react-i18next";
import { OperatingConditionType } from "../../../../api/client";
import UpdateTaskType from "../../../misc/UpdateTaskType";
import { IChangeOperatingConditionTaskOptions } from "../../../tasks/options/ChangeOperatingConditionTaskOptions";
import UpdateTaskTypeSelect from "./common_inputs/UpdateTaskTypeSelect";
import OperatingConditionTypeSelect from "./common_inputs/OperatingConditionTypeSelect";
import TaskOptionsExtrasForm, { TaskSubformPropsAlias } from "./TaskOptionsExtrasForm";


interface State {
  updateTaskType: UpdateTaskType
  type?: OperatingConditionType
}
class ChangeOperatingConditionTaskOptionsForm extends TaskOptionsExtrasForm<State, IChangeOperatingConditionTaskOptions> {


  constructor(props: TaskSubformPropsAlias<IChangeOperatingConditionTaskOptions>) {
    super(props);

    this.renderSelect = this.renderSelect.bind(this);
    this.renderUpdateTypeField = this.renderUpdateTypeField.bind(this);
  }

  stateFromProps(props: TaskSubformPropsAlias<IChangeOperatingConditionTaskOptions>): State {
    return {
      updateTaskType: props.options.updateTaskType ?? UpdateTaskType.Any,
      type: props.options.type
    };
  }

  public extractOptions() {
    return {
      type: this.state.type,
      updateTaskType: this.state.updateTaskType
    }
  }

  renderSelect() {
    return <OperatingConditionTypeSelect value={this.state.type} onChange={(_) => this.setState({ type: _ })} />
  }

  renderUpdateTypeField() {
    return <UpdateTaskTypeSelect
      value={this.state.updateTaskType}
      onValueChange={(_: UpdateTaskType) => this.setState({ updateTaskType: _ })}
      noRelative
    />
  }

  render() {
    return <Grid container columnSpacing={2} rowSpacing={0}>
      <Grid item sm={6} xs={12}>{this.renderUpdateTypeField()}</Grid>
      <Grid item sm={6} xs={12}>{this.renderSelect()}</Grid>
    </Grid>;
  }
}


export default withTranslation('translation', { withRef: true })(ChangeOperatingConditionTaskOptionsForm)
