import { Box, useTheme } from "@mui/material";
import { useRef, useState, useEffect, RefObject } from "react"
import { OrthoLayer } from "../../../data/OrthoLayer";
import { SingleTimedGetter, findTimed } from "../../../utils/findTimed";
import { EWAComponentType } from "../../../data/EWAComponentType";
import { BaseFeature } from "../../../data/BaseFeature";
import { EWAModelWrapper } from "../EWAModelWrapper";
import createGeojsonLayer from "../layers/createGeojsonLayer";
import { EditMode } from "../EditMode";
import { ValveComponentType } from "../../../data/ValveComponentType";
import { ShowResults } from "../../../data/ShowResults";
import createHeatmapLayer from "../layers/createHeatMapLayer";
import createDemandLayer from "../layers/createDemandLayer";
import { createTileLayerBasemapGrau, createTileLayerBasemapOrtho, createTileLayerOSM } from "../layers/TileLayers";
import DeckSelectionOverlay from "../DeckSelectionOverlay";
import DeckGL from "@deck.gl/react";
import { ContextProviderValue } from "@deck.gl/core/lib/deck";
import { EWAResult } from "../../../api/client";
import { DrawPointMode, ViewMode, } from "@nebula.gl/edit-modes";
import { ControllerOptions } from "@deck.gl/core/controllers/controller";
import { Toolbox } from "./Toolbox";
import { ConnectTheDotsMode } from "../../../modes/connect-mode";
import { ModelViewMode } from "../../../data/ModelViewMode";
import ChallengePanel from "../../../challenges/components/ChallengePanel";
import { WebMercatorViewport } from "@deck.gl/core";
import { cloneDeep } from "lodash";
import FeatureEditDialog from "../FeatureEditDialog";
import SelectedFeatureOverlay from "./SelectedFeatureOverlay";
import addAlphaToHexColor from "../../../utils/addAlphaToHexColor";
import { pointComponents } from "../../../data/EWAModel";
// import { FormatColorReset } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import CurrentYearOverlay from "./CurrentYearOverlay";
import { useTranslation } from "react-i18next";
import { Junction } from "@/data/Junction";
import { Pipe } from "@/data/Pipe";
import { Well } from "@/data/Well";
import { Reservoir } from "@/data/Reservoir";
import { Tank } from "@/data/Tank";
import { Pump } from "@/data/Pump";
import Valve from "@/data/Valve";

const INITIAL_VIEW_STATE = {
    longitude: 15.421371,
    latitude: 47.076668,
    zoom: 11.34,
    bearing: 0,
    pitch: 0,
}
export type InitialViewStateType = typeof INITIAL_VIEW_STATE

export interface MapViewProps {
    modelWrapper: EWAModelWrapper,
    setModelWrapper: (_: EWAModelWrapper) => void,
    currentDate: number
    setCurrentDate: (_: number) => void
    minDate: number
    maxDate: number
    lastResult: EWAResult | undefined
    currentCondition: string | undefined
    showComponents: EWAComponentType[]
    setShowComponents: (_: EWAComponentType[]) => void
    viewMode: ModelViewMode
    setViewMode: (_: ModelViewMode) => void
    deckRef: RefObject<DeckGL<ContextProviderValue>>
    active: boolean
    modelLoaded: boolean
}



export default function MapView(props: MapViewProps) {
    const {
        // active,
        modelWrapper,
        setModelWrapper,
        currentCondition,
        minDate,
        maxDate,
        currentDate,
        setCurrentDate,
        lastResult,
        showComponents,
        setShowComponents,
        viewMode,
        setViewMode,
        deckRef,
        modelLoaded
    } = props

    const theme = useTheme()
    // const overlayBackgroundColor = addAlphaToHexColor(theme.palette.darkBlue.main, 0.75)
    const mainContainerRef = useRef<any>();
    const { t } = useTranslation()

    const defaultOrthoLayer: OrthoLayer = useSelector(
        (state: RootState) => state.configuration.defaultOrthoLayer
    )
    const [editMode, setEditMode] = useState<any>(() => ViewMode);
    const [editModeSelection, setEditModeSelection] = useState(EditMode.Select);
    const [editLassoMode, setEditLassoMode] = useState<EWAComponentType | ValveComponentType | null>(null);
    const [editCreateMode, setEditCreateMode] = useState<EWAComponentType | ValveComponentType | null>(null);
    const [junctions, setJunctions] = useState<any[]>([]);
    const [junctionMinDemand, setJunctionMinDemand] = useState(Number.MAX_VALUE);
    const [junctionMaxDemand, setJunctionMaxDemand] = useState(Number.MIN_VALUE);
    const [showResults, setShowResults] = useState<ShowResults>(ShowResults.None);
    const [orthoLayer, setOrthoLayer] = useState<OrthoLayer>(defaultOrthoLayer);
    const [selectedFeatureIndices, setSelectedFeatureIndices] = useState<number[]>([])
    const [initialViewState, setInitialViewState] = useState<InitialViewStateType>(INITIAL_VIEW_STATE)
    const [editOpen, setEditOpen] = useState<boolean>(false)

    const selectionModeActive = [EditMode.Lasso, EditMode.Rectangle].includes(editModeSelection)

    useEffect(() => {
        if (modelLoaded) {
            computeBoundingBoxCenter()
        }
    }, [modelLoaded])

    useEffect(() => {
        if (editModeSelection === EditMode.Add && editCreateMode !== null) {
            if (pointComponents.includes(editCreateMode)) {
                setEditMode(() => DrawPointMode);
            } else {
                setEditMode(() => ConnectTheDotsMode);
            }
        } else {
            setEditMode(() => ViewMode);
        }
    }, [editModeSelection, editCreateMode]);

    useEffect(() => {
        if (editModeSelection === EditMode.Add) {
            setSelectedFeatureIndices([]);
        } else if (editModeSelection !== EditMode.Select) {
            if (selectedFeatureIndices.length > 0) {
                setSelectedFeatureIndices([]);
            }
        }
    }, [editModeSelection]);

    useEffect(() => {
        if (showResults !== ShowResults.None) {
            let minDemand = Number.MAX_VALUE
            let maxDemand = Number.MIN_VALUE
            for (let i = 0; i < modelWrapper.model.features.length; i++) {
                let element = modelWrapper.model.features[i];

                if (element.componentType === EWAComponentType.junction) {
                    let props = element._timedProperties.getValue(currentDate as number);
                    if (props !== undefined && props.demand !== undefined) {
                        if (props.demand > maxDemand) {
                            maxDemand = props.demand
                        }
                        if (props.demand < minDemand) {
                            minDemand = props.demand
                        }
                    }
                }

            }
            setJunctionMinDemand(minDemand)
            setJunctionMaxDemand(maxDemand)

            setJunctions(modelWrapper.model.features.filter(element =>
                element.componentType === EWAComponentType.junction &&
                element.visibleFrom <= currentDate &&
                element.visibleTo >= currentDate
            ));
        }

        // also done when operatingCondition changes to ensure e.g. heatmap is rendered again
    }, [modelWrapper, showResults, currentDate, currentCondition])

    // unselect all components that are no longe visible
    useEffect(() => {
        if (selectedFeatureIndices.length > 0) {
            const newSelectedFeatureIndices: number[] = []
            for (const selectedFeatureIndex of selectedFeatureIndices) {
                const visibleFrom = modelWrapper.model.features[selectedFeatureIndex].visibleFrom;
                const visibleTo = modelWrapper.model.features[selectedFeatureIndex].visibleTo;
                if (!(visibleFrom > (currentDate as number) || visibleTo < (currentDate as number))) {
                    newSelectedFeatureIndices.push(selectedFeatureIndex)
                }
            }
            setSelectedFeatureIndices(newSelectedFeatureIndices)
        }
    }, [currentDate]);

    function computeBoundingBoxCenter() {
        const bbox = modelWrapper.model.bbox
        if (bbox === undefined) return

        try {
            const div = mainContainerRef.current

            const width = div.offsetParent.offsetWidth
            const height = div.offsetParent.offsetHeight

            const webMercatorViewport = new WebMercatorViewport({
                width: width,
                height: height
            })

            const half = Math.ceil(bbox.length / 2)
            const lower = bbox.slice(0, half)
            const upper = bbox.slice(half)
            const deckGlBBox = [lower, upper]
            const fitResult = webMercatorViewport.fitBounds(deckGlBBox, { padding: 100 }) as any

            const viewState = cloneDeep(initialViewState)
            viewState.longitude = fitResult.longitude
            viewState.latitude = fitResult.latitude
            viewState.zoom = fitResult.zoom
            setInitialViewState(viewState);
        } catch (e) {
            console.log("center error", e)
        }
    }

    function componentTooltip(info: any): any {
        if (!info.object || info.object.id === undefined) return

        const componentType: EWAComponentType | ValveComponentType = info.object.data.component_type

        let header = ''
        switch (componentType) {
            case ValveComponentType.fcv:
            case ValveComponentType.tcv:
            case ValveComponentType.gpv:
            case ValveComponentType.prv:
            case ValveComponentType.pbv:
            case ValveComponentType.psv:
                header = `${t(`valve_component_type.${componentType}`)}`
                break
            default:
                header = `${t(`ewa_component_type.${componentType}`, { count: 1 })}`
        }

        let html = `<b>${header}</b><br><b>id:</b> ${info.object.id}`

        const addElevation = () => {
            const elevation = (info.object as BaseFeature<any, any, any>).geometry.realCoordinates[2]?.toFixed(2)
            if (elevation !== undefined) {
                html += `<br><b>${t('general.elevation')}:</b> ${elevation} m`
            }
        }

        function addTimedProp<T>(html: string, getter: SingleTimedGetter<BaseFeature<any, any, T>, T>, label: string, unit: string = '') {
            const value = findTimed(getter, currentDate, undefined)

            if (value !== undefined) {
                html += `<br><b>${label}:</b> ${value} ${unit}`
            }

            return html
        }

        switch (componentType) {
            case EWAComponentType.pipe:
                html = addTimedProp(html, {
                    item: info.object as Pipe,
                    get: _ => _.diameter?.toFixed(2)
                }, t('model.components.pipe.diameter'), 'mm')
                break
            case EWAComponentType.pump:
                html = addTimedProp(html, {
                    item: info.object as Pump,
                    get: _ => _.power?.toFixed(2)
                }, t('model.components.pump.power'), 'kW')
                break
            case EWAComponentType.valve:
            case ValveComponentType.fcv:
            case ValveComponentType.tcv:
            case ValveComponentType.gpv:
            case ValveComponentType.prv:
            case ValveComponentType.pbv:
            case ValveComponentType.psv:
                html = addTimedProp(html, {
                    item: info.object as Valve,
                    get: _ => _.diameter?.toFixed(2)
                }, t('model.components.valve.diameter'), 'mm')
                break
            case EWAComponentType.well:
            case EWAComponentType.spring:
                addElevation()

                html = addTimedProp(html, {
                    item: info.object as Well,
                    get: _ => _.inflow?.toFixed(2)
                }, t('model.components.well.inflow'), 'L/s')
                break
            case EWAComponentType.junction:
                addElevation()

                html = addTimedProp(html, {
                    item: info.object as Junction,
                    get: _ => _.demand?.toFixed(2)
                }, t('model.components.junction.demand'), 'L/s')
                break
            case EWAComponentType.reservoir:
                addElevation()

                html = addTimedProp(html, {
                    item: info.object as Reservoir,
                    get: _ => _.head?.toFixed(2)
                }, t('model.components.reservoir.head'), 'm')
                break
            case EWAComponentType.tank:
                addElevation()

                html = addTimedProp(html, {
                    item: info.object as Tank,
                    get: _ => _.diameter?.toFixed(2)
                }, t('model.components.tank.diameter'), 'm')

                html = addTimedProp(html, {
                    item: info.object as Tank,
                    get: _ => _.initlevel?.toFixed(2)
                }, t('model.components.tank.initlevel'), 'm')
                break

        }

        return {
            html: html,
            style: {
                backgroundColor: theme.palette.darkBlue.main,
                color: theme.palette.darkBlue.contrastText
            },
        }
    }

    function renderDeckContainer() {
        const controller: ControllerOptions = {
            doubleClickZoom: false,
            inertia: true,
            dragPan: !selectionModeActive,
            dragMode: "pan"
        }
        const layers: any[] = []

        switch (orthoLayer) {
            case OrthoLayer.BasemapGrau:
                layers.push(createTileLayerBasemapGrau())
                break
            case OrthoLayer.OSM:
                layers.push(createTileLayerOSM())
                break
            case OrthoLayer.BasemapOrtho:
                layers.push(createTileLayerBasemapOrtho())
                break
        }

        const geoJsonLayer = createGeojsonLayer({
            modelWrapper,
            setModelWrapper,
            editMode,
            setEditMode,
            selectedFeatureIndices,
            setSelectedFeatureIndices,
            currentDate,
            editModeSelection,
            editCreateMode
        })

        layers.push(geoJsonLayer)

        // results layer
        switch (showResults) {
            case ShowResults.HeatMap:
                layers.push(createHeatmapLayer({
                    showResults,
                    junctions,
                    lastResult,
                    currentDate,
                    currentCondition
                }))
                break
            case ShowResults.Demand:
                layers.push(createDemandLayer({
                    showResults,
                    junctions,
                    currentDate,
                    junctionMinDemand,
                    junctionMaxDemand
                }))
                break
        }

        let deckSelectionOverlay = <></>
        if (selectionModeActive) {
            deckSelectionOverlay = <DeckSelectionOverlay
                mapContainerRef={mainContainerRef}
                deckRef={deckRef}
                setSelectedFeatureIndices={setSelectedFeatureIndices}
                editLassoMode={editLassoMode}
                modelWrapper={modelWrapper}
            />
        }

        return <>
            <DeckGL
                ref={deckRef}
                initialViewState={initialViewState}
                controller={controller}
                getCursor={geoJsonLayer.getCursor.bind(geoJsonLayer)}
                layers={layers}
                onClick={(info: any) => {
                    if (info.layer === null && (editModeSelection === EditMode.Select || editModeSelection === EditMode.Modify)) {
                        setSelectedFeatureIndices([]);
                    }
                }}
                getTooltip={componentTooltip}
                pickingRadius={5}
            />
            {deckSelectionOverlay}
        </>;
    }

    function renderChallengePanelOverlay() {
        const backgroundColor = addAlphaToHexColor(theme.palette.darkBlue.main, 0.75)

        const maxHeightOffset = lastResult === undefined ? 20 : 50

        return <Box sx={{ position: "absolute", top: 10, right: 10, maxHeight: `calc(100% - ${maxHeightOffset}px)`, overflow: 'auto' }}>
            <ChallengePanel backgroundColor={backgroundColor} minifiable width={500} />
        </Box>
    }

    function closeEditDialog() {
        setEditOpen(false)
        setSelectedFeatureIndices([])
    }

    // function renderPIOverlay() {
    //     if (lastResult === undefined) return

    //     const currentYearResult = lastResult.timed_results?.[currentDate]

    //     if (currentYearResult === undefined) return

    //     const unsatisfiedNodesValues: JSX.Element[] = []
    //     for (const result of currentYearResult) {
    //         if (result.name !== "Unsatisfied Nodes") continue

    //         unsatisfiedNodesValues.push(<span key={result.scenario} title={result.scenario}>{result.value}</span>)
    //         unsatisfiedNodesValues.push(<span key={result.scenario + '|'}>&nbsp;|&nbsp;</span>)
    //     }

    //     unsatisfiedNodesValues.pop()

    //     return <Box
    //         title={t('ewa_result_type.Unsatisfied Nodes') as string}
    //         sx={{ position: "absolute", bottom: 10, right: 10, backgroundColor: overlayBackgroundColor, padding: 1, borderRadius: 1, zIndex: 1, display: 'flex', alignContent: 'center' }}
    //     >
    //         <FormatColorReset /><span>{unsatisfiedNodesValues}</span>
    //     </Box>
    // }

    function renderOverlays() {
        return <>
            <Toolbox
                modelWrapper={modelWrapper}
                showComponents={showComponents}
                setShowComponents={setShowComponents}
                viewMode={viewMode}
                showResults={showResults}
                setShowResults={setShowResults}
                orthoLayer={orthoLayer}
                setOrthoLayer={setOrthoLayer}
                setViewMode={setViewMode}
                setEditMode={setEditModeSelection}
                setEditCreateMode={setEditCreateMode}
                setEditLassoMode={setEditLassoMode}
                editLassoMode={editLassoMode}
                editCreateMode={editCreateMode}
                editModeSelection={editModeSelection}
            />
            <CurrentYearOverlay currentYear={currentDate} />
            {selectedFeatureIndices.length > 0 && <SelectedFeatureOverlay
                selectedFeatureIndices={selectedFeatureIndices}
                setSelectedFeatureIndices={setSelectedFeatureIndices}
                modelWrapper={modelWrapper}
                setModelWrapper={setModelWrapper}
                openEdit={() => setEditOpen(true)}
            />}
            {editOpen && <FeatureEditDialog
                onClose={closeEditDialog}
                selectedFeatureIndices={selectedFeatureIndices}
                setSelectedFeatureIndices={setSelectedFeatureIndices}
                model={modelWrapper.model}
                modelWrapper={modelWrapper}
                setModelWrapper={setModelWrapper}
                minDate={minDate}
                maxDate={maxDate}
                condition={currentCondition}
                currentDate={currentDate}
                setCurrentDate={setCurrentDate}
            />}
            {renderChallengePanelOverlay()}
            {/* {renderPIOverlay()} */}
        </>
    }

    return <>
        <div ref={mainContainerRef}>
            {renderDeckContainer()}
            {renderOverlays()}
        </div>
    </>
}
