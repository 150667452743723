import { DateTime } from "luxon";
import i18n from "../i18n";
import ChallengeOptions, { IChallengeOptions } from "./ChallengeOptions";
import ChallengeManager from "./managers/ChallengeManager";
import SaveData, { ISaveData } from "./SaveData";
import TaskGroup, { PlainTaskGroup } from "./TaskGroup";
import { v4 as uuidv4 } from 'uuid';

export interface PlainChallenge {
  options: IChallengeOptions
  taskGroups: PlainTaskGroup[]
  saveData?: ISaveData
  id?: string
  requiredChallenges?: string[]
}

export default class Challenge {
  //#region member variables
  protected _id: string;
  public get id(): string {
    return this._id
  }

  protected _requiredChallenges: string[]
  public get requiredChallenges(): string[] {
    return this._requiredChallenges
  }

  public get name(): string {
    const names = this._options.name
    return names[i18n.language] ?? (names.en ?? '')
  }

  protected _taskGroups: TaskGroup[] = [];
  public get taskGroups(): TaskGroup[] {
    return this._taskGroups;
  }
  protected set taskGroups(taskGroups: TaskGroup[]) {
    this._taskGroups = taskGroups;
  }

  protected _taskGroupIndex: number = 0;
  public get taskGroupIndex(): number {
    return this._taskGroupIndex
  }

  protected _options: ChallengeOptions;
  public get options(): ChallengeOptions {
    return this._options;
  }
  protected set options(options: ChallengeOptions) {
    this._options = options;
  }

  protected _saveData: SaveData;
  public get saveData(): SaveData {
    return this._saveData;
  }
  //#endregion

  //#region constructor
  /**
   * @param taskGroups
   * @param options
   */
  public constructor(taskGroups: TaskGroup[], options: ChallengeOptions = new ChallengeOptions(), saveData: SaveData = new SaveData(), id?: string, requiredChallenges?: string[]) {
    this._taskGroups = taskGroups;
    this._options = options;
    this._saveData = saveData;
    this._id = id ?? uuidv4()
    this._requiredChallenges = requiredChallenges ?? []

    this._taskGroupIndex = saveData.taskGroupIndex;
  }
  //#endregion

  //#region getters/setters
  /**
   * get active task group or undefined
   */
  public get activeTaskGroup(): TaskGroup | undefined {
    if (this.taskGroups.length === 0) {
      return undefined
    }

    return this.taskGroups[this.taskGroupIndex];
  }

  /**
   * compute progress in a range of 0-100
   */
  public get progress(): number {
    if (this.taskGroups.length === 0) return 0

    let progress = 0

    for (const taskGroup of this.taskGroups) {
      if (taskGroup.completedTasks === taskGroup.totalTasks) {
        progress++
      }
    }

    return Math.round((progress / this.taskGroups.length) * 100)
  }

  /**
   * get number of completed tasks in active task group
   */
  public get completedTasks(): number {
    return this.activeTaskGroup !== undefined ? this.activeTaskGroup.completedTasks : 0;
  }

  /**
   * get number of total tasks in active task group
   */
  public get totalTasks(): number {
    return this.activeTaskGroup !== undefined ? this.activeTaskGroup.totalTasks : 0;
  }

  /**
   * check if at last task group
   */
  public get atLastTaskGroup(): boolean {
    return ((this.taskGroupIndex + 1) === this.taskGroups.length);
  }

  /**
   * determine if tasks are finished
   */
  public get tasksFinished(): boolean {
    return this.completedTasks >= this.totalTasks;
  }

  /**
   * challenge is completed
   */
  public get completed(): boolean {
    return this.atLastTaskGroup && this.tasksFinished;
  }
  //#endregion

  //#region methods
  /**
   * activate challenge
   */
  public activate() {
    this._options.activate();
    this.activeTaskGroup?.activate();
  }

  /**
   * deactivate challenge
   */
  public deactivate() {
    this.activeTaskGroup?.deactivate();
    this._options.deactivate();
  }

  /**
   * move to next task group
   */
  public nextTaskGroup() {
    if (this.atLastTaskGroup) {
      return;
    }

    this.activeTaskGroup?.deactivate();
    this._taskGroupIndex++;
    this.activeTaskGroup?.activate();
  }
  /**
   * reset the challenge
   */
  public reset() {
    this.activeTaskGroup?.deactivate();

    if (this.taskGroups.length > 0) {
      for(const taskGroup of this.taskGroups) {
        taskGroup.reset();
      }
    }

    this._taskGroupIndex = 0;

    this.activeTaskGroup?.activate();
  }

  public save() {
    let elapsedTime: number = 0;
    const challengeStart = ChallengeManager.getInstance().challengeStart;
    const challengeEnd = ChallengeManager.getInstance().challengeEnd ?? DateTime.now();
    if (challengeStart !== undefined) {
      elapsedTime = challengeEnd.valueOf() - challengeStart.valueOf();
    }

    this._saveData = new SaveData({
      elapsedTime,
      taskGroupIndex: this._taskGroupIndex
    })
  }

  public toJSON(): PlainChallenge {
    const taskGroups = []
    for (const taskGroup of this.taskGroups) {
      taskGroups.push(taskGroup.toJSON())
    }

    const object = {
      options: this._options.toJSON(),
      saveData: this._saveData as ISaveData,
      taskGroups: taskGroups,
      id: this._id,
      requiredChallenges: this._requiredChallenges
    };

    return object;
  }

  public static deserialize(plainChallenge: PlainChallenge) {
    const taskGroups = [];
    for (const taskGroup of plainChallenge.taskGroups) {
      taskGroups.push(TaskGroup.deserialize(taskGroup))
    }

    const options = new ChallengeOptions(plainChallenge.options);

    const saveData = new SaveData(plainChallenge.saveData);

    return new Challenge(taskGroups, options, saveData, plainChallenge.id, plainChallenge.requiredChallenges);
  }
  //#endregion
}
