import { Cancel, Delete, Edit, Save } from "@mui/icons-material"
import { Box, IconButton } from "@mui/material"
import { MRT_Cell, MRT_Row, MRT_TableInstance } from "material-react-table"

export interface RowActionsProps<T extends Record<string, any> = {}> {
    cell: MRT_Cell<T>
    row: MRT_Row<T>
    table: MRT_TableInstance<T>
    onDeleteRow?: (props: {
        row: MRT_Row<T>,
        table: MRT_TableInstance<T>
    }) => void
    onSaveRow?: (props: {
        exitEditingMode: () => void,
        row: MRT_Row<T>,
        table: MRT_TableInstance<T>
        values: Record<string, any>
    }) => void
    onStartRowEdit?: (props: {
        row: MRT_Row<T>,
        table: MRT_TableInstance<T>
    }) => void
}

export default function RowActions<T extends Record<string, any> = {}>(props: RowActionsProps<T>) {
    const {
        row,
        table,
        onDeleteRow,
        onSaveRow,
        onStartRowEdit
    } = props

    const {
        refs: { editInputRefs },
        setEditingRow,
        getState
    } = table
    const { editingRow } = getState()
    const isEditingRow = row.id === editingRow?.id

    function startRowEdit() {
        if (onStartRowEdit !== undefined) {
            onStartRowEdit({ row, table })
        } else {
            setEditingRow(row)
        }
    }

    function cancelRowEdit() {
        setEditingRow(null)
    }

    // taken and adapted from MRT's MRT_EditActionButtons
    function saveRow() {
        Object.values(editInputRefs?.current)?.forEach((input) => {
            if (input.value !== undefined && Object.hasOwn(editingRow?._valuesCache as object, input.name)) {
                // @ts-ignore
                (editingRow?._valuesCache as object)[input.name] = input.value
            }
        })

        onSaveRow?.({
            exitEditingMode: () => setEditingRow(null),
            row: editingRow ?? row,
            table,
            values: editingRow?._valuesCache ?? { ...row.original }
        });
    }

    function deleteRow() {
        onDeleteRow?.({ row, table })
    }

    function renderButtons() {
        if (isEditingRow) {
            return <>
                <IconButton onClick={() => saveRow()} color="primary"><Save /></IconButton>
                <IconButton onClick={() => cancelRowEdit()}><Cancel /></IconButton>
            </>
        }

        return <>
            <IconButton onClick={() => startRowEdit()} color="success"><Edit /></IconButton>
            <IconButton onClick={() => deleteRow()} color="error"><Delete /></IconButton>
        </>
    }

    return <Box onClick={(e) => e.stopPropagation()}>
        {renderButtons()}
    </Box>
}