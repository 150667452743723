import { ConsumptionMode } from "../../api/client";
import { ComponentApplyEventArgs } from "../../events/events/ComponentApplyEvent";
import EventManager from "../../events/EventManager";
import EventType from "../../events/EventType";
import { JunctionApply } from "../../views/forms/JunctionApply";
import ConsumptionModeValueUpdateTask from "./base/ConsumptionModeValueUpdateTask";
import { ValuesPair } from "./base/ValueUpdateTask";
import UpdateConsumptionModeTaskOptions from "./options/UpdateConsumptionModeTaskOptions";
import { IUpdateValueTaskOptions } from "./options/UpdateValueTaskOptions";

export default class JunctionUpdateModeTask extends ConsumptionModeValueUpdateTask<JunctionApply> {
  protected _type: string = 'JunctionUpdateModeTask';
  protected componentName: string = "ewa_component_type.Junction_one";
  protected fieldName: string = "model.components.junction.mode";

  public static fromPlainTaskOptions(plainOptions: IUpdateValueTaskOptions<ConsumptionMode>) {
    const options = new UpdateConsumptionModeTaskOptions(plainOptions);
    return new JunctionUpdateModeTask(options)
  }

  public activate() {
    super.activate();
    EventManager.getInstance().attachListener(EventType.JunctionApply, this.onValueUpdate);
  }

  public deactivate() {
    super.deactivate();
    EventManager.getInstance().detachListener(EventType.JunctionApply, this.onValueUpdate);
  }

  protected retrieveValues(args: ComponentApplyEventArgs<JunctionApply>): ValuesPair<ConsumptionMode> {
    return {
      newValue: args.apply.timed.mode,
      oldValue: args.oldApply.timed.mode
    }
  }
}
