import { IUpdateValueTaskOptions } from "./UpdateValueTaskOptions";
import UpdateValueTaskWithoutRelativeOptions from "./UpdateValueTaskWithoutRelativeOptions";

export default class UpdateLifetimeTaskOptions extends UpdateValueTaskWithoutRelativeOptions<number | number[]>
{
  public constructor(options: IUpdateValueTaskOptions<number | number[]> = {}) {
    super(options);
  }

  public static fromPlain(plainOptions: IUpdateValueTaskOptions<number | number[]>) {
    return new UpdateLifetimeTaskOptions(plainOptions);
  }
}
