import Challenge from "../../Challenge";
import ChallengeOptions from "../../ChallengeOptions";
import TaskGroup from "../../TaskGroup";
import TCVUpdateCommentTask from "../../tasks/TCVUpdateCommentTask";
import TCVUpdateDiameterTask from "../../tasks/TCVUpdateDiameterTask";
import TCVUpdateHeadlossCoefficientTask from "../../tasks/TCVUpdateHeadlossCoefficientTask";
import TCVUpdateLifetimeTask from "../../tasks/TCVUpdateLifetimeTask";
import TCVUpdateStatusTask from "../../tasks/TCVUpdateStatusTask";

export default function TCVUpdates(): Challenge {
  const taskGroups: TaskGroup[] = [
    new TaskGroup([
      new TCVUpdateCommentTask(),
      new TCVUpdateDiameterTask(),
      new TCVUpdateHeadlossCoefficientTask(),
      new TCVUpdateLifetimeTask(),
      new TCVUpdateStatusTask(),
    ])
  ];

  const options = new ChallengeOptions({
  });

  return new Challenge(taskGroups, options);
}
