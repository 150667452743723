import Task, { PlainTask } from "../tasks/base/Task";
import TaskParsers from "./TaskParsers";
import TaskTypeString from "./TaskTypeString";

export default function taskFromPlainTask(plainTask: PlainTask): Task {
  if (!(plainTask.type in TaskTypeString)) {
    throw Error(`Unsupported Task Type - ${plainTask.type}`)
  }

  return TaskParsers[plainTask.type as TaskTypeString](plainTask.options)
}
