import Challenge from "../../Challenge";
import ChallengeOptions from "../../ChallengeOptions";
import TaskGroup from "../../TaskGroup";
import SpringUpdateCommentTask from "../../tasks/SpringUpdateCommentTask";
import SpringUpdateElevationTask from "../../tasks/SpringUpdateElevationTask";
import SpringUpdateLifetimeTask from "../../tasks/SpringUpdateLifetimeTask";
import SpringUpdateInflowTask from "../../tasks/SpringUpdateInflowTask";
import UpdateValueTaskOptions from "../../tasks/options/UpdateValueTaskOptions";

export default function SpringUpdates(): Challenge {
  const taskGroups: TaskGroup[] = [
    new TaskGroup([
      new SpringUpdateCommentTask(),
      new SpringUpdateInflowTask(new UpdateValueTaskOptions<number>({ year: 2035, id: 'spring_1' })),
      new SpringUpdateElevationTask(),
      new SpringUpdateLifetimeTask()
    ])
  ];

  const options = new ChallengeOptions({
  });

  return new Challenge(taskGroups, options);
}
