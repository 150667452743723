import { EWAComponentType } from "../../../data/EWAComponentType";
import Challenge from "../../Challenge";
import ChallengeOptions from "../../ChallengeOptions";
import TaskGroup from "../../TaskGroup";
import TaskGroupOptions from "../../TaskGroupOptions";
import CreateComponentTask from "../../tasks/CreateComponentTask";
import ComponentTaskOptions from "../../tasks/options/ComponentTaskOptions";

export default function AllCompTypes(): Challenge {
  const taskGroups: TaskGroup[] = [
    new TaskGroup([
      new CreateComponentTask(new ComponentTaskOptions({
        componentType: EWAComponentType.junction
      })),
      new CreateComponentTask(new ComponentTaskOptions({
        componentType: EWAComponentType.reservoir
      })),
      new CreateComponentTask(new ComponentTaskOptions({
        componentType: EWAComponentType.tank
      }))
    ],
      new TaskGroupOptions(
        {
          name: { en: "1st Task Group" },
          description: [
            { en: `Descriptive text Group 1` }
          ],
          autoShowDescription: true
        })
    ),
    new TaskGroup([
      new CreateComponentTask(new ComponentTaskOptions({
        componentType: EWAComponentType.pipe
      })),
      new CreateComponentTask(new ComponentTaskOptions({
        componentType: EWAComponentType.pump
      })),
      new CreateComponentTask(new ComponentTaskOptions({
        componentType: EWAComponentType.valve
      }))
    ],
      new TaskGroupOptions(
        {
          name: { en: "2nd Task Group" },
          description: [
            { en: `Descriptive text Group 2, page 1` },
            {
              en: `Descriptive text Group 2, page 2

![Image](/static/media/logo.9f9bc4d3d786cebfa4e8.png)`
            }
          ]
        })
    )
  ];

  const options = new ChallengeOptions({
  });

  return new Challenge(taskGroups, options);
}
