import { EWAComponentType } from "../../../data/EWAComponentType";
import Challenge from "../../Challenge";
import ChallengeOptions from "../../ChallengeOptions";
import TaskGroup from "../../TaskGroup";
import TaskGroupOptions from "../../TaskGroupOptions";
import CreateComponentTask from "../../tasks/CreateComponentTask";
import DeleteComponentTask from "../../tasks/DeleteComponentTask";
import MoveComponentTask from "../../tasks/MoveComponentTask";
import ComponentTaskOptions from "../../tasks/options/ComponentTaskOptions";

export default function GeneralComponentEvents(): Challenge {
  const taskGroups: TaskGroup[] = [
    new TaskGroup([
      new CreateComponentTask(),
      new CreateComponentTask(new ComponentTaskOptions({
        componentType: EWAComponentType.junction,
        tooltip: { en: 'Füge eine Junction am Stadtrand ein' }
      })),
      new DeleteComponentTask(),
      new DeleteComponentTask(new ComponentTaskOptions({
        componentType: EWAComponentType.junction
      })),
      new MoveComponentTask(),
      new MoveComponentTask(new ComponentTaskOptions({
        componentType: EWAComponentType.junction
      }))
    ],
      new TaskGroupOptions(
        {
          name: { en: "Primary Task Group" },
          description: [
            { en: `Descriptive text Group 1, page 1` },
            {
              en: `*Descriptive text Group 1, page 2*

![Image](/static/media/logo.9f9bc4d3d786cebfa4e8.png)`
            }]
        })
    )
  ];

  const options = new ChallengeOptions({
    score: 100,
    targetTime: 15000
  });

  return new Challenge(taskGroups, options);
}
