import { Close } from "@mui/icons-material"
import { Box, Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material"
import { useTranslation } from "react-i18next"
import { EWASimulationDTO } from "../../../api/client"
import { TaskRunTable } from "./TaskRunTable"

export interface TaskRunDialogProps {
  selectedSimulation?: EWASimulationDTO
  onClose: () => void
}

export default function TaskRunDialog(props: TaskRunDialogProps) {
  const {
    selectedSimulation,
    onClose
  } = props

  const {t} = useTranslation()

  return <Dialog
    open={selectedSimulation !== undefined}
    maxWidth="md"
    fullWidth
    onClose={() => onClose()}
  >
    <DialogTitle sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <div>{t('simulation_view.task_runs')}</div>
      <IconButton aria-label="close" onClick={() => onClose()}>
        <Close />
      </IconButton>
    </DialogTitle>
    <DialogContent>
      {selectedSimulation !== undefined && <Box sx={{ height: 500 }}>
        <TaskRunTable simulationId={selectedSimulation.id} state={selectedSimulation.state} />
      </Box>}
    </DialogContent>
  </Dialog>
}
