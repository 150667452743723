import { IUpdateValueTaskOptions } from "./UpdateValueTaskOptions";
import UpdateValueTaskWithoutRelativeOptions from "./UpdateValueTaskWithoutRelativeOptions";

export default class UpdateStringTaskOptions extends UpdateValueTaskWithoutRelativeOptions<string>
{
  public constructor(options: IUpdateValueTaskOptions<string> = {}) {
    super(options);
  }

  public static fromPlain(plainOptions: IUpdateValueTaskOptions<string>) {
    return new UpdateStringTaskOptions(plainOptions);
  }
}
