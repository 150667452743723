import { ReactNode, useEffect, useMemo, useState } from "react";
import MaterialReactTable, { MRT_Row, type MRT_ColumnDef, MRT_TableInstance, MRT_Cell } from 'material-react-table';
import { useTranslation } from "react-i18next";
import { ComponentTableProps, filterAndMapComponents, TableFeature } from "./Table";
import { TankTimedProps, TankConditionProps } from "../../../api/client";
import { EWAComponentType } from "../../../data/EWAComponentType";
import parseFloatOrUndefined from "../../../utils/parseFloatOrUndefined";
import validNumber from "../../../utils/validNumber";
import { minYear } from "../../../utils/constants";
import { Tank } from "../../../data/Tank";
import { TankApply } from "../../forms/TankApply";
import { EWAModelWrapper } from "../EWAModelWrapper";
import RowActions from "./RowActions";

type TankTableFeature = TableFeature<TankTimedProps, TankConditionProps>

interface ValidationErrors {
  initlevel?: string
  diameter?: string
}

let isFirstYear = true
let currentDate = minYear
function validateRequiredNonNegative(value: string): boolean {
  if (isFirstYear || value !== '') {
    const parsed = parseFloatOrUndefined(value)
    if (!validNumber(parsed) || parsed! <= 0) {
      return false
    }
  }
  return true
}

export function TankTable(props: ComponentTableProps) {
  const {
    data,
    currentCondition,
    localization,
    setModelWrapper
  } = props;
  currentDate = props.currentDate
  const { t, i18n } = useTranslation();

  const numberFormatter = new Intl.NumberFormat(i18n.language, { maximumFractionDigits: 5 })
  isFirstYear = currentDate === minYear
  const [validationErrors, setValidationErrors] = useState<ValidationErrors>({})
  const [editingRow, setEditingRow] = useState<MRT_Row<TankTableFeature> | null>(null)

  useEffect(() => {
    setEditingRow(null)
  }, [currentDate])

  const columns = useMemo<MRT_ColumnDef<TankTableFeature>[]>(
    () => [
      {
        header: 'ID',
        accessorKey: 'id'
      },
      {
        header: `${t('model.components.tank.initlevel')} (m)`,
        accessorKey: 'timedProps.initlevel',
        Cell: ({ cell }) => renderNumber(cell.getValue<number | undefined>()),
        filterVariant: 'range',
        filterFn: 'betweenInclusive',
        muiTableBodyCellEditTextFieldProps: {
          error: !!validationErrors.initlevel,
          helperText: validationErrors.initlevel,
          type: 'number',
          onChange(event) {
            const value = event.target.value
            let error: string | undefined = undefined
            if (!validateRequiredNonNegative(value)) {
              error = t('validation.invalid_value') as string
            }
            setValidationErrors((prev) => ({ ...prev, initlevell: error }))
          }
        }
      },
      {
        header: `${t('model.components.tank.diameter')} (m)`,
        accessorKey: 'timedProps.diameter',
        accessorFn: (row) => row.timedProps.diameter ?? 0,
        Cell: ({ cell }) => renderNumber(cell.getValue<number | undefined>()),
        filterVariant: 'range',
        filterFn: 'betweenInclusive',
        muiTableBodyCellEditTextFieldProps: {
          error: !!validationErrors.diameter,
          helperText: validationErrors.diameter,
          type: 'number',
          onChange(event) {
            const value = event.target.value
            let error: string | undefined = undefined
            if (!validateRequiredNonNegative(value)) {
              error = t('validation.invalid_value') as string
            }
            setValidationErrors((prev) => ({ ...prev, diameter: error }))
          }
        }
      },
    ],
    [i18n.language, validationErrors]
  )

  const tableData = useMemo<TankTableFeature[]>(() => {
    const tableData = filterAndMapComponents<TankTimedProps, TankConditionProps>(
      data.features,
      EWAComponentType.tank,
      currentDate,
      { initlevel: 0 },
      currentCondition,
      {}
    )

    return tableData
  }, [data.features, currentDate, currentCondition, i18n.language, data.id]);

  function onDeleteRow (props: {
    row: MRT_Row<TankTableFeature>,
    table: MRT_TableInstance<TankTableFeature>,
  }) {
    const { row } = props

    const feature = row.original.feature

    feature.delete()
    setModelWrapper(new EWAModelWrapper(data))
  }

  function onSaveRow (props: {
    exitEditingMode: () => void,
    row: MRT_Row<TankTableFeature>,
    table: MRT_TableInstance<TankTableFeature>,
    values: Record<string, any>
  }) {
    const {values, row, exitEditingMode} = props
    // first validate values
    if (values['id'] === '' ||!validateRequiredNonNegative(values['timedProps.diameter']) ||
    !validateRequiredNonNegative(values['timedProps.initlevel'])) {
      return
    }

    // create the Apply object
    const tank = row.original.feature as Tank

    const timed: Partial<TankTimedProps> = {
    }

    const diameter = parseFloatOrUndefined(values['timedProps.diameter'])
    if (diameter !== undefined) {
      timed.diameter = diameter
    }

    const initlevel = parseFloatOrUndefined(values['timedProps.initlevel'])
    if (initlevel !== undefined) {
      timed.initlevel = initlevel
    }

    const apply: TankApply = {
      id: values['id'],
      comment: tank.comment,
      lifetime: tank.data.lifetime,
      longitude: tank._geometry.coordinates[0],
      latitude: tank._geometry.coordinates[1],
      elevation: tank._geometry.coordinates[2],
      strength: tank.data.properties.strength,
      conditional: {
      },
      timed
    }

    tank.apply(apply)
    setModelWrapper(new EWAModelWrapper(data))
    exitEditingMode()
    setValidationErrors({})
  }

  function renderNumber(value?: number) {
    return value !== undefined ? numberFormatter.format(value) : ''
  }

  return <MaterialReactTable
    columns={columns}
    data={tableData}
    initialState={{
      showColumnFilters: true
    }}
    localization={localization}
    enableEditing
    editingMode="row"
    state={{
      editingRow
    }}
    onEditingRowChange={(_: any) => setEditingRow(_)}
    renderRowActions={(props: {
      cell: MRT_Cell<TankTableFeature>;
      row: MRT_Row<TankTableFeature>;
      table: MRT_TableInstance<TankTableFeature>;
    }): ReactNode => <RowActions<TankTableFeature>
     {...props}
     onSaveRow={onSaveRow}
     onDeleteRow={onDeleteRow}
    ></RowActions>}
  />
}
