import PipeUpdateMaterialTask from "@/challenges/tasks/PipeUpdateMaterialTask";
import Challenge from "../../Challenge";
import ChallengeOptions from "../../ChallengeOptions";
import TaskGroup from "../../TaskGroup";
import PipeUpdateCommentTask from "../../tasks/PipeUpdateCommentTask";
import PipeUpdateDiameterTask from "../../tasks/PipeUpdateDiameterTask";
import PipeUpdateLengthTask from "../../tasks/PipeUpdateLengthTask";
import PipeUpdateLifetimeTask from "../../tasks/PipeUpdateLifetimeTask";
import PipeUpdateMinorlossTask from "../../tasks/PipeUpdateMinorlossTask";
import PipeUpdateRoughnessTask from "../../tasks/PipeUpdateRoughnessTask";
import PipeUpdateStatusTask from "../../tasks/PipeUpdateStatusTask";
import PipeUpdateConstructionYearTask from "@/challenges/tasks/PipeUpdateConstructionYearTask";
import { PipeMaterial } from "@/api/client";
import UpdatePipeMaterialTaskOptions from "@/challenges/tasks/options/UpdatePipeMaterialTaskOptions";
import UpdateTaskType from "@/challenges/misc/UpdateTaskType";

export default function PipeUpdates(): Challenge {
  const taskGroups: TaskGroup[] = [
    new TaskGroup([
      new PipeUpdateCommentTask(),
      new PipeUpdateMaterialTask(new UpdatePipeMaterialTaskOptions({
        targetValue: PipeMaterial.BET,
        updateTaskType: UpdateTaskType.ExactValue
      })),
      new PipeUpdateConstructionYearTask(),
      new PipeUpdateDiameterTask(),
      new PipeUpdateLengthTask(),
      new PipeUpdateLifetimeTask(),
      new PipeUpdateMinorlossTask(),
      new PipeUpdateRoughnessTask(),
      new PipeUpdateStatusTask()
    ])
  ];

  const options = new ChallengeOptions({
  });

  return new Challenge(taskGroups, options);
}
