import { GeoJsonEditMode, Geometry } from "@nebula.gl/edit-modes";
//Mostly stolen from  https://codesandbox.io/s/yow65
export class ConnectTheDotsMode extends GeoJsonEditMode {
  firstPick: any = null;

  handleClick({ mapCoords, picks }: { mapCoords: any, picks: any }, { onEdit, data }: { onEdit: any, data: any }) {
    if (picks.length > 0) {
      // find the first point picked
      const pick = picks.find((p: any) => p.object.geometry.type === "Point");
      if (!pick) {
        return
      }

      if (!this.firstPick) {
        this.firstPick = pick
        return
      }

      // start and end node cant be the same
      if (pick.object.id === this.firstPick.object.id) {
        return
      }

      const geometry = {
        type: "LineString",
        coordinates: [
          this.firstPick.object.geometry.coordinates,
          pick.object.geometry.coordinates
        ],
        properties: { //TODO Hacking this in here, should return a real feature, but I don't care for now
          "startNode": this.firstPick.object.id,
          "endNode": pick.object.id,
          "startNodeType": this.firstPick.object.componentType,
          "endNodeType": pick.object.componentType,
        }
      };
      this.firstPick = null;
      onEdit(this.getAddFeatureAction(geometry as Geometry, data));
    }
  }

  handlePointerMove({ picks }: { picks: any }, { onUpdateCursor }: { onUpdateCursor: any }) {
    if (picks.find((p: any) => p.object.geometry.type === "Point")) {
      onUpdateCursor("cell");
    } else {
      onUpdateCursor(null);
    }
  }

  getGuides({ lastPointerMoveEvent }: { lastPointerMoveEvent: any }): any {
    if (this.firstPick && lastPointerMoveEvent) {
      return {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            properties: {
              guideType: "tentative"
            },
            geometry: {
              type: "LineString",

              coordinates: [
                this.firstPick.object.geometry.coordinates,
                lastPointerMoveEvent.mapCoords
              ]
            }
          }
        ]
      };
    }
  }
}
