import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
// import Backend from 'i18next-http-backend'
// import LanguageDetector from 'i18next-browser-languagedetector'
import en from './locales/en/translation.json';
import enLanding from './locales/en/landing.json'
import de from './locales/de/translation.json';
import deLanding from './locales/de/landing.json'
import store from './store/store';

export const supportedLngs = ['en', 'de']
export const defaultNS = 'translation'
export const resources = {
    en: {
        translation: en,
        landing: enLanding
    },
    de: {
        translation: de,
        landing: deLanding
    }
}

const lng = store.getState().configuration.language

i18n
// .use(Backend)
// .use(LanguageDetector)
.use(initReactI18next)
.init({
    // lng: 'en',
    lng,
    fallbackLng: 'en',
    // debug: true,
    interpolation: {
        escapeValue: false // react escapes by default
    },
    supportedLngs: supportedLngs,
    defaultNS,
    resources
})

// i18n.on('languageChanged', (language: string) => {
//     store.dispatch(setLanguage(language))
// })

export default i18n;
