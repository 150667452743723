import { Checkbox, FormControl, FormControlLabel, FormHelperText, InputAdornment, TextField } from "@mui/material";
import { Well } from "../../data/Well";
import { WellApply } from "./WellApply";
import { useEffect, useState } from "react";
import { BaseMultiForm, BaseMultiFormApply } from "./BaseMultiForm";
import { findComputedTimedMulti, findTimedIfDefinedMulti, findTimedMulti } from "../../utils/findTimedMulti";
import { SourceConditionProps, SourceTimedProps } from "../../api/client";
import { EWAModelWrapper } from "../model/EWAModelWrapper";
import { findProp } from "../../utils/findProp";
import { useTranslation } from "react-i18next";
import parseFloatOrUndefined from "../../utils/parseFloatOrUndefined";
import validNumber from "../../utils/validNumber";
import { useFormContext } from "./FormContext";
import { multiFormComputeIfFirstYear } from "./util";
import { CheckBox, CheckBoxOutlineBlank, IndeterminateCheckBox } from "@mui/icons-material";

export interface MultiWellFormProps {
    wells: Well[]
}

export function MultiWellForm(props: MultiWellFormProps) {
    const { wells } = props;
    const context = useFormContext();

    const currentDate = context.currentDate as number

    const [isFirstYear, setIsFirstYear] = useState<boolean>(multiFormComputeIfFirstYear(wells, currentDate))

    const [inflow, setInflow] = useState<string>(findTimedMulti({
        items: wells,
        get: (props) => props.inflow
    }, currentDate, undefined)?.toString() ?? '')
    const [fallbackInflow, setFallbackInflow] = useState<number | undefined>(findComputedTimedMulti({
        items: wells,
        get: _ => _.inflow
    }, currentDate, undefined))

    const [scaleInflow, setScaleInflow] = useState<boolean | undefined>(findTimedMulti({
        items: wells,
        get: (props) => props.scale_inflow
    }, currentDate, undefined));
    const [fallbackScaleInflow, setFallbackScaleInflow] = useState<boolean | undefined>(findComputedTimedMulti({
        items: wells,
        get: _ => _.scale_inflow
    }, currentDate, undefined))

    const { t } = useTranslation();

    useEffect(() => {
        const currentDate = context.currentDate as number
        const newIsFirstYear = multiFormComputeIfFirstYear(wells, currentDate)
        setIsFirstYear(newIsFirstYear)

        setFallbackInflow(findComputedTimedMulti({
            items: wells,
            get: _ => _.inflow
        }, newIsFirstYear ? currentDate : currentDate - 10, undefined))
        setFallbackScaleInflow(findComputedTimedMulti({
            items: wells,
            get: _ => _.scale_inflow
        }, newIsFirstYear ? currentDate : currentDate - 10, undefined))

        setInflow(findTimedIfDefinedMulti({
            items: wells,
            get: (props) => props.inflow
        }, currentDate, undefined)?.toString() ?? '')
        setScaleInflow(findTimedIfDefinedMulti({
            items: wells,
            get: (props) => props.scale_inflow
        }, currentDate, undefined));


    }, [wells, context.currentDate, context.condition, context.modelWrapper?.id])

    function validate() {
        if (isFirstYear || inflow !== '') {
            const parsedInflow = parseFloatOrUndefined(inflow)
            if (!validNumber(parsedInflow) || parsedInflow! < 0) {
                return false
            }
        }

        return true
    }

    function fallbackScaleInflowIcon(fallbackScaleInflow?: boolean) {
        if (fallbackScaleInflow === true) {
            return <CheckBox />
        } else if (fallbackScaleInflow === false) {
            return <CheckBoxOutlineBlank />
        } else {
            return <IndeterminateCheckBox />
        }
    }

    function handleApply(a: BaseMultiFormApply) {
        const timed: Partial<SourceTimedProps> = {}

        const applyInflow = parseFloatOrUndefined(inflow)
        if (applyInflow !== undefined) {
            timed.inflow = applyInflow
        }
        if (scaleInflow !== undefined) {
            timed.scale_inflow = scaleInflow
        }

        for (const well of wells) {
            const geometry = well._geometry
            const apply = {
                id: well.id,
                comment: well.comment,
                lifetime: a.lifetime,
                longitude: geometry.coordinates[0],
                latitude: geometry.coordinates[1],
                elevation: geometry.coordinates[2],
                strength: findProp({
                    item: well,
                    get: (props) => props.strength
                }, undefined),
                conditional: {} as SourceConditionProps,
                timed: timed
            } as WellApply
            well.apply(apply);
        }
        context.setModelWrapper(new EWAModelWrapper(context.modelWrapper!.model));
    }

    return <BaseMultiForm handleApply={handleApply}
        features={wells}
        validate={validate}
        timedChildren={<>
            <TextField
                type="number"
                value={inflow}
                onChange={(event) => setInflow((event.target.value))}
                style={{ flexGrow: 1, marginBottom: "10px" }}
                label={t('model.components.well.inflow') as string}
                InputProps={{ endAdornment: <InputAdornment position="end">L/s</InputAdornment> }}
                helperText={isFirstYear ? undefined : fallbackInflow?.toString()}
            />
            <FormControl>
                <FormControlLabel
                    style={{ flexGrow: 1 }}
                    control={<Checkbox
                        checked={scaleInflow === true}
                        indeterminate={scaleInflow === undefined}
                        onChange={() => {
                            if (scaleInflow === undefined) setScaleInflow(true)
                            else if (scaleInflow) setScaleInflow(false)
                            else setScaleInflow(undefined)
                        }}
                    />}
                    label={t('model.components.well.scale_inflow') as string}
                />
                {!isFirstYear && <FormHelperText>{fallbackScaleInflowIcon(fallbackScaleInflow)}</FormHelperText>}
            </FormControl>
        </>
        }>
    </BaseMultiForm>
}
