import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.scss';
import CssBaseline from '@mui/material/CssBaseline';
import { SnackbarProvider } from "notistack";
import { makeStyles } from "@mui/styles";
import keycloakClient from "./keycloak";
import { OpenAPI } from "./api/client";
import Landing from './views/landing/Landing';
import EwaThemeProvider from './EwaThemeProvider';
import EwaTool from './views/EwaTool';

export const API_URL_BASE = (window as any)._env_.API_URL_BASE;

const useStyles = makeStyles((theme) => ({
    mainContainer: {
        height: "100vh",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch"
    },
    errorContent: {
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        paddingLeft: "50px"
    }
}));

const getToken = async () => {
    // Some code that requests a token...
    return keycloakClient.token;
}

OpenAPI.BASE = API_URL_BASE
OpenAPI.TOKEN = getToken

export function Main() {
    const classes = useStyles()

    function renderContent() {
        return <div className={classes.mainContainer}>
            <Routes>
                <Route index element={<Landing />} />
                <Route path="tool/*" element={<EwaTool />} />
            </Routes>
        </div>
    }

    return (
        <BrowserRouter>
            {renderContent()}
        </BrowserRouter>
    )
}

function App() {
    return (
        <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            preventDuplicate={true}
        >

            <EwaThemeProvider mode='dark'>
                <CssBaseline />
                <Main />
            </EwaThemeProvider>
        </SnackbarProvider >
    );
}

export default App;
