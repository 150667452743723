import { useEffect } from "react";
import { createContext, ReactNode, useContext, useState } from "react";
import { KeycloakInstance } from "keycloak-js";
import { Box, CircularProgress } from "@mui/material";

export interface AuthContextProps {
    currentUser?: ClaimsUser;
    keycloak?: KeycloakInstance
}

export const AuthContext = createContext<AuthContextProps>({
    currentUser: undefined,
    keycloak: undefined
});

export interface AuthProviderProps {
    children: ReactNode;
    keycloak: KeycloakInstance
    initialized: boolean
}


export interface IHttpClient {
    fetch(url: RequestInfo, init?: RequestInit): Promise<Response>;
}

export class KeycloakHttpClient implements IHttpClient {
    protected keycloak: KeycloakInstance;
    constructor(keycloak: KeycloakInstance) {
        this.keycloak = keycloak;
    }

    async fetch(url: RequestInfo, init?: RequestInit): Promise<Response> {
        const token = this.keycloak.token;
        if (init) {
            let headers = new Headers(init.headers);
            headers.append("Authorization", "Bearer " + token);
            init.headers = headers;
            return fetch(url, init);
        } else {
            throw new Error("Http request initialization failed!");
        }
    }
}

export interface ClaimsUser {
    sub?: string | undefined;
    email?: string | undefined;
    givenName?: string | undefined;
    familyName?: string | undefined;
}


export function AuthProvider(props: AuthProviderProps) {
    const { keycloak, initialized } = props;
    const [currentUser, setCurrentUser] = useState<ClaimsUser | undefined>();

    useEffect(() => {
        console.log(initialized, keycloak.authenticated, keycloak.idTokenParsed);
        setCurrentUser({
            sub: keycloak.idTokenParsed?.sub,
            email: keycloak.idTokenParsed?.email,
            givenName: keycloak.idTokenParsed?.given_name,
            familyName: keycloak.idTokenParsed?.family_name,
        } as ClaimsUser)
    }, [initialized, keycloak.authenticated]);

    let node: ReactNode;
    if (!initialized) {
        node = <Box display={'flex'} justifyContent={'center'}>
            <CircularProgress />
        </Box>
    } else {
        node = props.children;
    }

    return (
        <AuthContext.Provider value={{ currentUser, keycloak }}>{node}</AuthContext.Provider>
    );
}

/**
 * Returns the currently authenticated user.
 */
export function useCurrentUser(): ClaimsUser {
    const { currentUser } = useContext(AuthContext);
    if (currentUser === undefined) {
        throw new Error(
            "useCurrentUser can only be used in descendants of AuthProvider"
        );
    }
    return currentUser;
}

export function useKeycloak() {
    const { keycloak } = useContext<AuthContextProps>(AuthContext)

    if (keycloak === undefined) return undefined

    return {
        login: keycloak.login,
        logout: keycloak.logout,
        authenticated: keycloak.authenticated
    }
}
