import React from "react";
import EconomyBudgetChangedEvent from "../../events/events/EconomyBudgetChangedEvent";
import EconomyEnabledChangedEvent from "../../events/events/EconomyEnabledChangedEvent";
import EconomyManager from "../managers/EconomyManager";
import EventManager from "../../events/EventManager";
import EventType from "../../events/EventType";

interface Props {
}

interface State {
  enabled: boolean,
  budget: number
}

export default class EconomyPanel extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const economyManager = EconomyManager.getInstance();
    this.state = {
      enabled: economyManager.enabled,
      budget: economyManager.budget
    };

    this.onEconomyEnabledChanged = this.onEconomyEnabledChanged.bind(this);
    this.onEconomyBudgetChanged = this.onEconomyBudgetChanged.bind(this);
  }

  componentDidMount() {
    this.attachEventHandlers();
  }

  componentWillUnmount() {
    this.detachEventHandlers();
  }

  protected attachEventHandlers() {
    EventManager.getInstance().attachListener(EventType.EconomyEnabledChanged, this.onEconomyEnabledChanged);
    EventManager.getInstance().attachListener(EventType.EconomyBudgetChanged, this.onEconomyBudgetChanged);

  }

  protected detachEventHandlers() {
    EventManager.getInstance().detachListener(EventType.EconomyEnabledChanged, this.onEconomyEnabledChanged);
    EventManager.getInstance().detachListener(EventType.EconomyBudgetChanged, this.onEconomyBudgetChanged);
  }

  protected onEconomyEnabledChanged(e: EconomyEnabledChangedEvent) {
    this.setState({
      enabled: e.detail.newValue
    });
  }

  protected onEconomyBudgetChanged(e: EconomyBudgetChangedEvent) {
    this.setState({
      budget: e.detail.newValue
    });
  }

  render() {
    if (!this.state.enabled) {
      return '';
    }

    const style = {
      color: 'white'
    }
    if (this.state.budget < 0) {
      style.color = 'red';
    }

    return <div style={{ padding: "10px" }}>
      <div className="budget" style={style}>
        Budget: <span>{this.state.budget.toFixed(2)}€</span>
      </div>
    </div>
  }
}
