import addAlphaToHexColor from "@/utils/addAlphaToHexColor"
import { Paper } from "@mui/material"
import { useTheme } from "@mui/material"

export default function CurrentYearOverlay(props: { currentYear: number }) {
    const { currentYear } = props
    const theme = useTheme()
    const backgroundColor = addAlphaToHexColor(theme.palette.darkBlue.main, 0.75)

    return <Paper sx={{ position: "absolute", top: "10px", left: "68px", padding: '10px', zIndex: "1002", backgroundColor, pointerEvents: 'none' }}>
        {currentYear}
    </Paper>
}
