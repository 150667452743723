import {
  useEffect,
  useState
} from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import {
  Log,
  ModellingService,
  StateType
} from "@/api/client";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

export interface FlowLogFormProps {
  item: string | undefined
  state: StateType | undefined
}

export function FlowLogForm(props: FlowLogFormProps) {
  const { item } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [logs, setLogs] = useState<Array<Log> | []>([]);
  const {t} = useTranslation();

  const fetchLogs = async () => {
    if (item !== undefined) {
      ModellingService.modelLogsV1ModelsModelIdLogsGet(item).then(m => {
        setLogs(m);
      }).catch(e => {
        if (e.message.indexOf("Not Found") > -1) {
          setLogs([])
        } else
          enqueueSnackbar(e.message, {
            variant: 'error',
          });
      })
    } else {
      setLogs([]);
    }
  }
  useEffect(() => {
    fetchLogs();
    const comInterval = setInterval(fetchLogs, 2000); //This will refresh the data at regularIntervals of refreshTime
    return () => clearInterval(comInterval) //Clear interval on component unmount to avoid memory leak
  }, [item, setLogs]);

  return (<div style={{
    padding: "30px", width: "100%", height: "100%", backgroundColor: "#09253E",
    display: "flex", flexDirection: "column"
  }}>
    <AutoSizer>
      {({ height, width }: { height: number, width: number }) => (
        <TableContainer component={Paper} style={{ width: width, height: height }}>
          <Table stickyHeader sx={{ width: "100%" }} style={{ fontSize: "8px", height: "100%" }}
            aria-label={ t('home.flow_logs.title') as string}>
            <TableHead>
              <TableRow>
                <TableCell>{ t('general.level') }</TableCell>
                <TableCell align="right">{ t('general.date') }</TableCell>
                <TableCell align="right">{ t('general.message') }</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {logs !== undefined && logs.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell style={{ fontSize: "10px", backgroundColor: "#09243E" }}
                    align="left">{row.level}</TableCell>
                  <TableCell style={{ fontSize: "10px", backgroundColor: "#09243E" }}
                    align="left">{row.timestamp}</TableCell>
                  <TableCell style={{ fontSize: "10px", backgroundColor: "#09243E" }}
                    align="left">{row.message}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>)
      }
    </AutoSizer>
  </div>)
}
