import { EWAComponentType } from "@/data/EWAComponentType";
import { ValveComponentType } from "@/data/ValveComponentType";
import ValveImage from "@/assets/Valve_256.png";
import JunctionImage from "@/assets/Junction_256.png";
import SpringImage from "@/assets/Spring_256.png";
import WellImage from "@/assets/Well_256.png";
import TankImage from "@/assets/Tank_256.png";
import ReservoirImage from "@/assets/Reservoir_256.png";
import PumpImage from "@/assets/Pump_256.png";
import PipeImage from "@/assets/Pipe_256.png";

export default function getImageSource(componentType: EWAComponentType|ValveComponentType) {
    switch (componentType) {
        case EWAComponentType.junction:
            return JunctionImage
        case EWAComponentType.spring:
            return SpringImage
        case EWAComponentType.well:
            return WellImage
        case EWAComponentType.tank:
            return TankImage
        case EWAComponentType.reservoir:
            return ReservoirImage
        case EWAComponentType.pipe:
            return PipeImage
        case EWAComponentType.pump:
            return PumpImage
        case EWAComponentType.valve:
        case ValveComponentType.fcv:
        case ValveComponentType.gpv:
        case ValveComponentType.pbv:
        case ValveComponentType.prv:
        case ValveComponentType.psv:
        case ValveComponentType.tcv:
            return ValveImage
    }
}
