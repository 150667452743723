import ComparisonMode from "../../../utils/ComparisonMode";
import Challenge from "../../Challenge";
import TaskGroup from "../../TaskGroup";
import PerformanceIndicatorTaskOptions from "../../tasks/options/PerformanceIndicatorTaskOptions";
import PIUnsatisfiedNodesDetailValueTask from "../../tasks/PIUnsatisfiedNodesDetailValueTask";

export default function PIUnsatisfiedNodesDetails(): Challenge {
  return new Challenge([
    new TaskGroup([
      new PIUnsatisfiedNodesDetailValueTask(new PerformanceIndicatorTaskOptions({
        threshold: 0.5,
        comparisonMode: ComparisonMode.lt
      })),
      // junction 1 must not be unsatisfied
      new PIUnsatisfiedNodesDetailValueTask(new PerformanceIndicatorTaskOptions({
        comparisonMode: ComparisonMode.eq,
        id: 'junction_1'
      })),
    ])
  ])
}
