import {
    useMemo
} from 'react'
import {
    EWAModelDTO
} from "@/api/client";
import {
    Box,
    Card,
    CardContent,
    SxProps,
    Theme,
    Typography
} from "@mui/material";
import StateTypeLabel from "@/views/StateTypeLabel";
import CircularProgressWithLabel from "@/widgets/CircularProgressWithLabel";

export interface ModelCardProps {
    item: EWAModelDTO
    accessibleModels?: Set<string>
}

export function ModelCard(props: ModelCardProps) {
    const {
        item,
        accessibleModels
    } = props

    const isAccesible = useMemo<boolean>(() => {
        return (accessibleModels?.has(item.id) ?? true)
    },[accessibleModels])

    function cardSx(): SxProps<Theme>|undefined {
        if (!isAccesible) {
            return {
                color: 'grey'
            }
        }

        return undefined
    }

    function renderContent() {
        let challengeProgress = <></>

        if (item.challenge) {
            const completedTasks = item.challenge.completed_tasks ?? 0
            const totalTasks = item.challenge.total_tasks ?? 0

            const progress = (totalTasks > 0 ? completedTasks / totalTasks : 0) * 100

            challengeProgress = <CircularProgressWithLabel variant="determinate" value={progress} />
        }

        return <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
            {challengeProgress}
            <Typography variant="h6" component="div">
                {item.name}
            </Typography>
            <Box sx={{flexGrow: 1}}></Box>
            <StateTypeLabel state={item.state} />
        </Box>
    }

    return <Card sx={cardSx()}>
        <CardContent>
            {renderContent()}
        </CardContent>
    </Card>
}
