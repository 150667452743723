import { SimulationNewResultEventArgs } from "../../events/events/SimulationNewResultEvent";
import { EWAResultType } from "../../views/model/simulation/EWAResultType";
import { compare, comparisonModeToHumanString } from "../../utils/ComparisonMode";
import PerformanceIndicatorTask from "./base/PerformanceIndicatorTask"
import PerformanceIndicatorTaskOptions, { IPerformanceIndicatorTaskOptions } from "./options/PerformanceIndicatorTaskOptions";
import i18n from "../../i18n";

export default class PIUnsatisfiedNodesCountTask extends PerformanceIndicatorTask {
    protected _type: string = "PIUnsatisfiedNodesCountTask";
    protected performanceIndicatorName: string = EWAResultType.UnsatisfiedNodes;

    constructor(options?: PerformanceIndicatorTaskOptions) {
        if (options === undefined) {
            options = new PerformanceIndicatorTaskOptions()
        }

        if (options.threshold === undefined) {
            options.threshold = 0
        }

        super(options);
    }

    protected passesTest(e: SimulationNewResultEventArgs): boolean {
        const result = e.lastResult
        if (result === undefined) return false

        const options = this._options as PerformanceIndicatorTaskOptions;

        // can never pass if no threshold is set for this task - but will be set in constructor to 0 anyway
        if (options.threshold === undefined) return false

        const timedResults = result.timed_results
        if (timedResults === undefined) return false

        let availableYears = Object.keys(timedResults)
        if (options.year !== undefined) {
            availableYears = availableYears.filter(y => y === options.year!.toString())
        }

        let operatingConditionTypes: string[] = []
        if (options.operatingConditionType !== undefined) {
            if (e.model === undefined) return false // we need the model to get a list of operating conditions

            operatingConditionTypes = e.model.conditions.filter(condition => condition.id !== undefined && condition.type === options.operatingConditionType)
                .map(condition => condition.id!)
        }

        for (const year of availableYears) {
            let singleResults = timedResults[year].filter(item => item.name === this.performanceIndicatorName)

            if (singleResults.length === 0) continue

            // filter to relevant scenarios if option is set
            if (options.scenario !== undefined) {
                singleResults = singleResults.filter(item => item.scenario === options.scenario)
            }

            // filter to relevant operating condition
            if (options.operatingConditionType !== undefined) {
                singleResults = singleResults.filter(item => item.operating_condition !== undefined && operatingConditionTypes.includes(item.operating_condition))
            }

            // check every SingleResult
            for (const singleResult of singleResults) {
                if (singleResult.value === undefined) continue
                if (!compare<number>(options.comparisonMode, singleResult.value, options.threshold)) {
                    return false
                }
            }
        }

        return true
    }

    public static fromPlainTaskOptions(plainOptions: IPerformanceIndicatorTaskOptions) {
        const options = new PerformanceIndicatorTaskOptions(plainOptions);
        return new PIUnsatisfiedNodesCountTask(options)
    }

    public get description(): string {
        const options = this._options as PerformanceIndicatorTaskOptions;
        return i18n.t('challenge.task.pi_unsatisfied_nodes_count', {
            comparisonString: comparisonModeToHumanString(options.comparisonMode),
            threshold: options.threshold
        })
    }
}
