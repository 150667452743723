import { OperatingConditionType } from "../../../api/client";
import Challenge from "../../Challenge";
import ChallengeOptions from "../../ChallengeOptions";
import UpdateTaskType from "../../misc/UpdateTaskType";
import TaskGroup from "../../TaskGroup";
import ChangeOperatingConditionTask from "../../tasks/ChangeOperatingConditionTask";
import CreateOperatingConditionTask from "../../tasks/CreateOperatingConditionTask";
import DeleteOperatingConditionTask from "../../tasks/DeleteOperatingConditionTask";
import ChangeOperatingConditionTaskOptions from "../../tasks/options/ChangeOperatingConditionTaskOptions";
import CreateDeleteOperatingConditionTaskOptions from "../../tasks/options/CreateDeleteOperatingConditionTaskOptions";

export default function OperatingConditionTasks(): Challenge {
  const taskGroups: TaskGroup[] = [
    new TaskGroup([
      new CreateOperatingConditionTask(),
      new CreateOperatingConditionTask(new CreateDeleteOperatingConditionTaskOptions({
        type: OperatingConditionType.FAILURE
      })),
      new ChangeOperatingConditionTask(),
      new ChangeOperatingConditionTask(new ChangeOperatingConditionTaskOptions({
        updateTaskType: UpdateTaskType.ExactValue,
        type: OperatingConditionType.FIRE
      })),
      new DeleteOperatingConditionTask()
    ])
  ];

  const options = new ChallengeOptions();

  return new Challenge(taskGroups, options);
}
