import ComponentCreatedEvent from "./events/ComponentCreatedEvent";
import ComponentDeletedEvent from "./events/ComponentDeletedEvent";
import ComponentMovedEvent, { ComponentMovedEventArgs } from "./events/ComponentMovedEvent";
import EconomyBudgetChangedEvent from "./events/EconomyBudgetChangedEvent";
import EconomyEnabledChangedEvent from "./events/EconomyEnabledChangedEvent";
import EventType from "./EventType";
import { JunctionApply } from "../views/forms/JunctionApply";
import { PipeApply } from "../views/forms/PipeApply";
import { PumpApply } from "../views/forms/PumpApply";
import { ReservoirApply } from "../views/forms/ReservoirApply";
import { TankApply } from "../views/forms/TankApply";
import { ValveFCVApply } from "../views/forms/ValveFCVApply";
import { ValveGPVApply } from "../views/forms/ValveGPVApply";
import { ValvePBVApply } from "../views/forms/ValvePBVApply";
import { ValvePRVApply } from "../views/forms/ValvePRVApply";
import { ValvePSVApply } from "../views/forms/ValvePSVApply";
import { ValveTCVApply } from "../views/forms/ValveTCVApply";
import ComponentApplyEvent, { ComponentApplyEventArgs } from "./events/ComponentApplyEvent";
import YearChangedEvent, { YearChangedEventArgs } from "./events/YearChangedEvent";
import OperatingConditionChangedEvent, { OperatingConditionChangedEventArgs } from "./events/OperatingConditionChangedEvent";
import OperatingConditionCreatedEvent, { OperatingConditionCreatedEventArgs } from "./events/OperatingConditionCreatedEvent";
import OperatingConditionDeletedEvent, { OperatingConditionDeletedEventArgs } from "./events/OperatingConditionDeletedEvent";
import SimulationStartedEvent, { SimulationStartedEventArgs } from "./events/SimulationStartedEvent";
import ModelSavedEvent, { ModelSavedEventArgs } from "./events/ModelSavedEvent";
import TypedComponentEventArgs from "./args/TypedComponentEventArgsOptions";
import ResultsShownEvent, { ResultsShownEventArgs } from "./events/ResultsShownEvent";
import ChallengeCompletedEvent from "./events/ChallengeCompletedEvent";
import TabChangedEvent, { TabChangedEventArgs } from "./events/TabChangedEvent";
import SimulationNewResultEvent, { SimulationNewResultEventArgs } from "./events/SimulationNewResultEvent";
import EditDialogOpenedEvent, { EditDialogOpenedEventArgs } from "./events/EditDialogOpened";
import SimulationResultsDialogOpenedEvent, { SimulationResultsDialogOpenedEventArgs } from "./events/SimulationResultsDialogOpened";
import OrthoLayerChangedEvent, { OrthoLayerChangedEventArgs } from "./events/OrthoLayerChangedEvent";
import ComponentVisibilityChangedEvent, { ComponentVisibilityChangedEventArgs } from "./events/ComponentVisibilityChangedEvent";
import { SpringApply } from "../views/forms/SpringApply";
import { WellApply } from "../views/forms/WellApply";
import HomeChallengeUploadedEvent, { HomeChallengeUploadedEventArgs } from "./events/HomeChallengeUploadedEvent";
import HomeChallengeDeletedEvent, { HomeChallengeDeletedEventArgs } from "./events/HomeChallengeDeletedEvent";

class EventManager {

  private static instance: EventManager;
  public static getInstance(): EventManager {
    if (!EventManager.instance) {
      EventManager.instance = new EventManager();
    }

    return EventManager.instance;
  }

  private constructor() {
  }

  public attachListener(event: EventType, callback: (event: CustomEvent) => void) {
    document.addEventListener(event, callback);
  }

  public detachListener(event: EventType, callback: (event: CustomEvent) => void) {
    document.removeEventListener(event, callback);
  }

  public onEconomyEnabledChanged(oldValue: boolean, newValue: boolean) {
    document.dispatchEvent(new EconomyEnabledChangedEvent({ oldValue, newValue }));
  }

  public onEconomyBudgetChanged(oldValue: number, newValue: number) {
    document.dispatchEvent(new EconomyBudgetChangedEvent({ oldValue, newValue }));
  }

  public onChallengeCompleted() {
    document.dispatchEvent(new ChallengeCompletedEvent());
  }

  public onTabChanged(options: TabChangedEventArgs) {
    document.dispatchEvent(new TabChangedEvent(options));
  }

  public onEditDialogOpened(options: EditDialogOpenedEventArgs) {
    document.dispatchEvent(new EditDialogOpenedEvent(options))
  }

  public onYearChanged(options: YearChangedEventArgs) {
    document.dispatchEvent(new YearChangedEvent(options));
  }

  public onModelSaved(options: ModelSavedEventArgs) {
    document.dispatchEvent(new ModelSavedEvent(options));
  }

  public onResultsShown(options: ResultsShownEventArgs) {
    document.dispatchEvent(new ResultsShownEvent(options));
  }

  public onOrthoLayerChanged(options: OrthoLayerChangedEventArgs) {
    document.dispatchEvent(new OrthoLayerChangedEvent(options));
  }

  public onOperatingConditionChanged(options: OperatingConditionChangedEventArgs) {
    document.dispatchEvent(new OperatingConditionChangedEvent(options));
  }

  public onOperatingConditionCreated(options: OperatingConditionCreatedEventArgs) {
    document.dispatchEvent(new OperatingConditionCreatedEvent(options));
  }

  public onOperatingConditionDeleted(options: OperatingConditionDeletedEventArgs) {
    document.dispatchEvent(new OperatingConditionDeletedEvent(options));
  }

  public onSimulationStarted(options: SimulationStartedEventArgs) {
    document.dispatchEvent(new SimulationStartedEvent(options));
  }

  public onSimulationNewResult(options: SimulationNewResultEventArgs) {
    document.dispatchEvent(new SimulationNewResultEvent(options));
  }

  public onSimulationResultsDialogOpened(options: SimulationResultsDialogOpenedEventArgs) {
    document.dispatchEvent(new SimulationResultsDialogOpenedEvent(options))
  }

  public onComponentCreated(options: TypedComponentEventArgs) {
    document.dispatchEvent(new ComponentCreatedEvent(options));
  }

  public onComponentDeleted(options: TypedComponentEventArgs) {
    document.dispatchEvent(new ComponentDeletedEvent(options));
  }

  public onComponentMoved(options: ComponentMovedEventArgs) {
    document.dispatchEvent(new ComponentMovedEvent(options));
  }

  public onComponentVisibilityChanged(options: ComponentVisibilityChangedEventArgs) {
    document.dispatchEvent(new ComponentVisibilityChangedEvent(options))
  }

  public onJunctionApply(options: ComponentApplyEventArgs<JunctionApply>) {
    document.dispatchEvent(new ComponentApplyEvent<JunctionApply>(EventType.JunctionApply, options));
  }

  public onSpringApply(options: ComponentApplyEventArgs<SpringApply>) {
    document.dispatchEvent(new ComponentApplyEvent<SpringApply>(EventType.SpringApply, options))
  }

  public onWellApply(options: ComponentApplyEventArgs<WellApply>) {
    document.dispatchEvent(new ComponentApplyEvent<WellApply>(EventType.WellApply, options))
  }

  public onReservoirApply(options: ComponentApplyEventArgs<ReservoirApply>) {
    document.dispatchEvent(new ComponentApplyEvent<ReservoirApply>(EventType.ReservoirApply, options));
  }

  public onTankApply(options: ComponentApplyEventArgs<TankApply>) {
    document.dispatchEvent(new ComponentApplyEvent<TankApply>(EventType.TankApply, options));
  }

  public onPipeApply(options: ComponentApplyEventArgs<PipeApply>) {
    document.dispatchEvent(new ComponentApplyEvent<PipeApply>(EventType.PipeApply, options));
  }

  public onPumpApply(options: ComponentApplyEventArgs<PumpApply>) {
    document.dispatchEvent(new ComponentApplyEvent<PumpApply>(EventType.PumpApply, options));
  }

  public onValveFCVApply(options: ComponentApplyEventArgs<ValveFCVApply>) {
    document.dispatchEvent(new ComponentApplyEvent<ValveFCVApply>(EventType.ValveFCVApply, options));
  }

  public onValveGPVApply(options: ComponentApplyEventArgs<ValveGPVApply>) {
    document.dispatchEvent(new ComponentApplyEvent<ValveGPVApply>(EventType.ValveGPVApply, options));
  }

  public onValvePBVApply(options: ComponentApplyEventArgs<ValvePBVApply>) {
    document.dispatchEvent(new ComponentApplyEvent<ValvePBVApply>(EventType.ValvePBVApply, options));
  }

  public onValvePRVApply(options: ComponentApplyEventArgs<ValvePRVApply>) {
    document.dispatchEvent(new ComponentApplyEvent<ValvePRVApply>(EventType.ValvePRVApply, options));
  }

  public onValvePSVApply(options: ComponentApplyEventArgs<ValvePSVApply>) {
    document.dispatchEvent(new ComponentApplyEvent<ValvePSVApply>(EventType.ValvePSVApply, options));
  }

  public onValveTCVApply(options: ComponentApplyEventArgs<ValveTCVApply>) {
    document.dispatchEvent(new ComponentApplyEvent<ValveTCVApply>(EventType.ValveTCVApply, options));
  }

  public onHomeChallengeUploaded(options: HomeChallengeUploadedEventArgs) {
    document.dispatchEvent(new HomeChallengeUploadedEvent(options))
  }

  public onHomeChallengeDeleted(options: HomeChallengeDeletedEventArgs) {
    document.dispatchEvent(new HomeChallengeDeletedEvent(options))
  }
}

export default EventManager;
