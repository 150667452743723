
import {
    EWATaskRunDTO
} from "../../../api/client";
import {
    Box,
    Card,
    CardContent,
    Typography
} from "@mui/material";
import StateTypeLabel from "../../StateTypeLabel";
import { useTranslation } from "react-i18next";

export interface TaskCardProps {
    item: EWATaskRunDTO
}

export default function TaskCard(props: TaskCardProps) {
    const { item } = props
    const { t } = useTranslation()

    return <Card>
        <CardContent>
            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                <Box sx={{width: 80, textAlign: 'center'}}><StateTypeLabel state={item.state} /></Box>
                <Box sx={{ display: 'flex', gap: 2, flexDirection: 'column', flexGrow: 1 }}>
                    <Typography fontSize={"x-small"} variant="body1" component="div" marginLeft="10px">
                        {t('general.start_time')}: {item.start_time}
                    </Typography>
                    <Typography fontSize={"x-small"} variant="body1" component="div" marginLeft="10px">
                        {t('general.end_time')}: {item.end_time}
                    </Typography>
                </Box>
                <Typography variant="body2" fontWeight={"bold"} component="div">
                    {item.name}
                </Typography>
            </Box>
        </CardContent>
    </Card>
}
