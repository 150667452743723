import {
  EWASimulationDTO, StateType,
} from "../../../api/client";
import { Box, Button, Card, CardContent, Checkbox, Typography, useMediaQuery } from "@mui/material";
import StateTypeLabel from "../../StateTypeLabel";
import { useTranslation } from "react-i18next";
import { Delete, Details, Download, Info, QueryStats, TextSnippet } from "@mui/icons-material";

export interface SimulationCardProps {
  ewaSimulation: EWASimulationDTO
  debugMode: boolean
  onOpenTaskRunList: (_: EWASimulationDTO) => void
  onOpenSimulationResultsPlot: (_: EWASimulationDTO) => void
  onOpenSimulationResultsDetails: (_: EWASimulationDTO) => void
  onOpenSimulationLogs: (_: EWASimulationDTO) => void
  onOpenSimulationDelete: (_: EWASimulationDTO) => void
  isSelected: boolean
  selectSimulation: (ewaSimulation: EWASimulationDTO, isSelected: boolean) => void
  lastSimulation: EWASimulationDTO | undefined
  setLastSimulation: (_?: EWASimulationDTO) => void
  loadModelData: (modelId: string, versionId?: string) => Promise<void>
}

export function SimulationCard(props: SimulationCardProps) {
  const {
    ewaSimulation,
    debugMode,
    onOpenTaskRunList,
    onOpenSimulationDelete,
    onOpenSimulationResultsPlot,
    onOpenSimulationResultsDetails,
    onOpenSimulationLogs,
    isSelected,
    selectSimulation,
    lastSimulation,
    setLastSimulation,
    loadModelData
  } = props
  const { t } = useTranslation()

  const isCompleted = ewaSimulation.state === StateType.COMPLETED
  const smallLayout = useMediaQuery('(max-width:950px)')
  const isCurrent = lastSimulation?.id === ewaSimulation.id

  function renderCheckbox() {
    return <Checkbox
      checked={isSelected}
      onChange={() => selectSimulation(ewaSimulation, !isSelected)}
    />
  }

  function renderState() {
    const width = smallLayout ? undefined : 120
    return <Box sx={{ width, textAlign: 'center' }}>
      <StateTypeLabel state={ewaSimulation.state} />
    </Box>
  }

  function renderName() {
    const width = smallLayout ? undefined : 200
    return <Typography variant="h6" fontWeight={"bold"} component="div" sx={{ width, textAlign: 'center', wordWrap: 'break-word' }}>
      {ewaSimulation.name}
    </Typography>
  }

  async function loadModelAndSimulation() {
    const modelId = ewaSimulation.model_id
    const versionId = ewaSimulation.version_id

    await loadModelData(modelId!, versionId)

    setLastSimulation(ewaSimulation)
  }

  function renderActions() {
    return <Box sx={{ display: 'flex', gap: 1 }}>
      <Button
        disabled={!isCompleted}
        variant="contained"
        title={t('simulation_view.simulation_results_plot') as string}
        onClick={() => onOpenSimulationResultsPlot(ewaSimulation)}
      >
        <QueryStats />
      </Button>
      <Button
        disabled={!isCompleted}
        variant="contained"
        title={t('simulation_view.simulation_results_details') as string}
        onClick={() => onOpenSimulationResultsDetails(ewaSimulation)}
      >
        <Details />
      </Button>
      <Button variant="contained" title={t('simulation_view.task_runs') as string} onClick={() => onOpenTaskRunList(ewaSimulation)}><Info /></Button>
      {debugMode && <Button variant="contained" title={t('simulation_view.simulation_logs') as string} onClick={() => onOpenSimulationLogs(ewaSimulation)}><TextSnippet /></Button>}
      {isCompleted && <Button
        variant="contained"
        title={t('simulation_view.load') as string}
        onClick={() => loadModelAndSimulation()}
      >
        <Download />
      </Button>}
      <Button
        variant="contained"
        title={t('general.delete') as string}
        color="error"
        onClick={() => onOpenSimulationDelete(ewaSimulation)}
      >
        <Delete />
      </Button>
    </Box>
  }

  function renderInfo() {
    return <Box>
      <Typography fontSize={"x-small"} variant="body1" component="div">
        {t('model.version')}: {ewaSimulation.version_id}
      </Typography>
      <Typography fontSize={"x-small"} variant="body1" component="div">
        {t('general.start_time')}: {ewaSimulation.start_time}
      </Typography>
      <Typography fontSize={"x-small"} variant="body1" component="div">
        {t('general.end_time')}: {ewaSimulation.end_time}
      </Typography>
    </Box>
  }

  function renderContent() {
    if (smallLayout) {
      return <Box sx={{ display: 'flex', alignContent: 'center', gap: 2 }}>
        {renderCheckbox()}
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, flexGrow: 1 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center' }}>
            {renderState()}
            {renderName()}
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center' }}>
            {renderActions()}
            {renderInfo()}
          </Box>
        </Box>
      </Box>
    }

    return <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
      {renderCheckbox()}
      {renderState()}
      {renderName()}
      {renderActions()}
      {renderInfo()}
    </Box>
  }

  return <Card sx={[
    {
      border: 2,
      borderColor: 'background.paper'
    },
    isCurrent && {
      borderColor: 'primary.main'
    }
  ]}>
    <CardContent>
      {renderContent()}
    </CardContent>
  </Card>
}
