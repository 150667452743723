import { Close } from "@mui/icons-material"
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material"
import { useState } from "react"

export interface LightboxProps {
    src: string
    srcThumb?: string
    alt: string
    title?: string
}

export default function Lightbox(props: LightboxProps) {
    const {
        src,
        srcThumb,
        alt,
        title
    } = props

    const [open, setOpen] = useState<boolean>(false)
    const handleClose = () => setOpen(false)

    return <>
        <img src={srcThumb ?? src} alt={alt} title={title} onClick={() => setOpen(true)} className="lightbox responsive-img"/>
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="xl"
        >
            <DialogTitle>
                &nbsp;
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        top: 8,
                        right: 8
                    }}
                >
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <img src={src} alt={alt} title={title} className="responsive-img" />
            </DialogContent>
        </Dialog>
    </>
}
