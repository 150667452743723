import { ComponentApplyEventArgs } from "../../events/events/ComponentApplyEvent";
import EventManager from "../../events/EventManager";
import EventType from "../../events/EventType";
import { PumpApply } from "../../views/forms/PumpApply";
import NumberValueUpdateTask from "./base/NumberValueUpdateTask";
import { ValuesPair } from "./base/ValueUpdateTask";
import UpdateValueTaskOptions, { IUpdateValueTaskOptions } from "./options/UpdateValueTaskOptions";

export default class PumpUpdatePowerTask extends NumberValueUpdateTask<PumpApply> {
  protected _type: string = 'PumpUpdatePowerTask';
  protected componentName: string = "ewa_component_type.Pump_one";
  protected fieldName: string = "model.components.pump.power";

  public static fromPlainTaskOptions(plainOptions: IUpdateValueTaskOptions<number>) {
    const options = new UpdateValueTaskOptions<number>(plainOptions);
    return new PumpUpdatePowerTask(options);
  }

  public activate() {
    super.activate();
    EventManager.getInstance().attachListener(EventType.PumpApply, this.onValueUpdate);
  }

  public deactivate() {
    super.deactivate();
    EventManager.getInstance().detachListener(EventType.PumpApply, this.onValueUpdate);
  }

  protected retrieveValues(args: ComponentApplyEventArgs<PumpApply>): ValuesPair<number> {
    return {
      newValue: args.apply.timed.power,
      oldValue: args.oldApply.timed.power

    };
  }
}
