import { Box, Container } from '@mui/material'

export interface SectionProps {
    id: string
    color?: string
    backgroundColor?: string
    children: React.ReactNode
}

export default function Section(props: SectionProps)
{
    return <Box sx={{
        backgroundColor: props.backgroundColor,
        color: props.color,
        paddingTop: 10,
        paddingBottom: 10
    }}>
        <Container id={props.id}>
            {props.children}
        </Container>
    </Box>
}
