import i18n from "../../../i18n";
import ChallengeManager from "../../managers/ChallengeManager";
import TaskOptions, { ITaskOptions } from "../options/TaskOptions";

export interface PlainTask {
  type: string,
  options: ITaskOptions
}

export default abstract class Task {
  //#region members
  protected abstract _type: string;
  protected _options: TaskOptions;
  protected _completed: boolean = false;
  //#endregion

  //#region constructor
  public constructor(options?: TaskOptions) {
    this._options = options || new TaskOptions();
    this._completed = this.currentCount >= this.requiredCount;
  }
  //#endregion

  //#region getters/setters
  public get currentCount(): number {
    return this._options.currentCount;
  }

  public set currentCount(value: number) {
    this._options.currentCount = value;
  }

  public get requiredCount(): number {
    return this._options.requiredCount;
  }

  public set requiredCount(value: number) {
    this._options.requiredCount = value;
  }

  public get name(): string {
    return this._options.name[i18n.language] ?? (this._options.name.en ?? '');
  }

  public get optionYear(): string | undefined {
    return undefined;
  }

  public get optionType(): string | undefined {
    return undefined;
  }

  public get optionId(): string | undefined {
    return undefined;
  }

  public get optionOperatingConditionType(): string | undefined {
    return undefined;
  }

  public get optionScenario(): string | undefined {
    return undefined;
  }

  public get tooltip(): string {
    return this._options.tooltip[i18n.language] ?? (this._options.tooltip.en ?? '');
  }

  public abstract get description(): string;

  public get completed(): boolean {
    return this._completed;
  }
  //#endregion

  //#region methods
  /**
   * activate all tasks
   */
  public activate() {
  }

  /**
   * deactivate all tasks
   */
  public deactivate() {
  }

  /**
   * reset the task
   */
  public reset() {
    this._completed = false;
    this.currentCount = 0;
  }

  /**
   * mark the task completed
   */
  public markCompleted() {
    this._completed = true;
    ChallengeManager.getInstance().taskStateChanged();
  }

  /**
   * mark the task as not completed
   */
  public markNotCompleted() {
    this._completed = false;
    ChallengeManager.getInstance().taskStateChanged();
  }

  /**
   * check whether task has fullfilled the completion condition
   */
  protected checkCompletion() {
    if (this.currentCount >= this.requiredCount) {
      this.markCompleted();
    } else {
      this.markNotCompleted();
    }
  }

  // trigger a task view update
  protected triggerTaskViewUpdate() {
    // force update to rerender
    ChallengeManager.getInstance().forceTaskViewUpdate(this);
  }

  public toJSON(): PlainTask {
    return {
      type: this._type,
      options: this._options.toJSON()
    }
  }
  //#endregion
}
