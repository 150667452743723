import EconomyPanel from "../components/EconomyPanel";
import EventManager from "../../events/EventManager";
import { Pipe } from "../../data/Pipe";
import { EWAComponentType } from "../../data/EWAComponentType";
import EventType from "../../events/EventType";
import ComponentCreatedEvent from "../../events/events/ComponentCreatedEvent";
import ComponentDeletedEvent from "../../events/events/ComponentDeletedEvent";
import ChallengeManager from "./ChallengeManager";
import ComponentMovedEvent from "../../events/events/ComponentMovedEvent";
import { ValveComponentType } from "../../data/ValveComponentType";

export type BudgetKey = EWAComponentType | ValveComponentType;

export const defaultPricesList: [BudgetKey, number][] = [
  [EWAComponentType.junction, 20],
  [EWAComponentType.reservoir, 200],
  [EWAComponentType.tank, 100],
  [EWAComponentType.pipe, 0.025],
  [EWAComponentType.pump, 50],
  [EWAComponentType.valve, 60],
  [ValveComponentType.fcv, 61],
  [ValveComponentType.gpv, 62],
  [ValveComponentType.pbv, 63],
  [ValveComponentType.prv, 64],
  [ValveComponentType.psv, 65],
  [ValveComponentType.tcv, 66]
];

export const defaultPrices: Map<BudgetKey, number> = new Map(defaultPricesList);

export default class EconomyManager {
  //#region singleton stuff
  private static instance: EconomyManager;
  public static getInstance(): EconomyManager {
    if (!EconomyManager.instance) {
      EconomyManager.instance = new EconomyManager();
    }

    return EconomyManager.instance;
  }
  //#endregion

  //#region members
  protected _budget: number = 0;
  protected _enabled: boolean = false;
  protected _view: EconomyPanel | undefined;
  //TODO Add Valve ComponentType to pricing
  protected _priceMap: Map<EWAComponentType | ValveComponentType, number> = new Map();
  //#endregion

  //#region constructor
  private constructor() {
    for (const pair of defaultPrices) {
      this._priceMap.set(pair[0], pair[1]);
    }

    this.onComponentCreated = this.onComponentCreated.bind(this);
    this.onComponentDeleted = this.onComponentDeleted.bind(this);
    this.onComponentMoved = this.onComponentMoved.bind(this);
  }
  //#endregion

  //#region getters/setters
  public set view(view: EconomyPanel | undefined) {
    this._view = view;
  }

  public get budget(): number {
    return this._budget;
  }
  public set budget(budget: number) {
    const oldValue = this._budget;
    this._budget = budget;
    this.onBudgetChange(oldValue, budget);
  }

  public get enabled(): boolean {
    return this._enabled;
  }
  public set enabled(enabled: boolean) {
    const oldValue = this._enabled;
    this._enabled = enabled;
    this.onEnabledChange(oldValue, enabled);
  }
  //#endregion

  //#region  methods
  public updatePrices(priceMap: Map<EWAComponentType, number>) {
    for (const componentType of Object.values(EWAComponentType)) {
      const value = priceMap.get(componentType) ?? defaultPrices.get(componentType)
      if (value === undefined) {
        continue
      }
      this._priceMap.set(componentType, value)
    }
  }

  public price(componentType: EWAComponentType | ValveComponentType): number {
    switch (componentType) {
      case ValveComponentType.fcv:
      case ValveComponentType.gpv:
      case ValveComponentType.pbv:
      case ValveComponentType.prv:
      case ValveComponentType.psv:
      case ValveComponentType.tcv:
        return (this._priceMap.get(componentType) ?? (this._priceMap.get(EWAComponentType.valve) ?? 0));
      default:
        return this._priceMap.get(componentType) || 0;
    }
  }

  protected onEnabledChange(oldValue: boolean, newValue: boolean) {
    if (oldValue === newValue) return;

    EventManager.getInstance().onEconomyEnabledChanged(oldValue, newValue);

    if (newValue) {
      // retrieve prices from active challenge/reset prices to defaults
      this.updatePrices(ChallengeManager.getInstance().challenge?.options.priceMap ?? new Map())

      EventManager.getInstance().attachListener(EventType.ComponentCreated, this.onComponentCreated);
      EventManager.getInstance().attachListener(EventType.ComponentDeleted, this.onComponentDeleted);
      EventManager.getInstance().attachListener(EventType.ComponentMoved, this.onComponentMoved);
    } else {
      EventManager.getInstance().detachListener(EventType.ComponentCreated, this.onComponentCreated);
      EventManager.getInstance().detachListener(EventType.ComponentDeleted, this.onComponentDeleted);
      EventManager.getInstance().detachListener(EventType.ComponentMoved, this.onComponentMoved);
    }
  }

  protected onBudgetChange(oldValue: number, newValue: number) {
    EventManager.getInstance().onEconomyBudgetChanged(oldValue, newValue);
  }

  protected onComponentCreated(e: ComponentCreatedEvent) {
    let price = this.price(e.detail.type);

    switch (e.detail.type) {
      case EWAComponentType.pipe:
        const component = e.detail.model.findComponent(e.detail.id, e.detail.type) as Pipe;
        const length = component.data.properties.length;
        if (length === undefined) {
          price = 0;
        } else {
          price = Number((length * price).toFixed(2));
        }
        break;
    }
    this.budget = this._budget - price;
  }

  protected onComponentDeleted(e: ComponentDeletedEvent) {
    const price = this.price(e.detail.type);
    this.budget = this._budget + price;
  }

  protected onComponentMoved(e: ComponentMovedEvent) {
    const lengthDifferences = e.detail.lengthDifferences ?? [];
    let priceSum = 0;
    for (const diffTuple of lengthDifferences) {
      priceSum += diffTuple[1] * this.price(EWAComponentType.pipe);
    }

    this.budget = this._budget - priceSum;
  }
  //#endregion
}
