import { AppBar, Box, Button, Link, Toolbar, useMediaQuery } from "@mui/material";
import logo from "@/assets/logo.png";
import { Link as RouterLink, useHref} from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { PlayCircle } from "@mui/icons-material";
import { AppBarLinkButton } from "@/widgets/Buttons";
import LanguageSelect from "../LanguageSelect";

export interface LandingAppBarProps {
    portalContainerCallbackRef?: (node: any) => void
}

export default function LandingAppBar(props: LandingAppBarProps) {
    const {
        portalContainerCallbackRef
    } = props

    const { t } = useTranslation();
    const mediaQuery1k = useMediaQuery('(max-width:1000px)')
    const toolRoute = useHref('/tool')
    const homeButtonRoute = useHref('/')

    function renderLeft() {
        return <Box display='flex' alignItems={"center"}>
            <AppBarLinkButton to={homeButtonRoute} edge={"start"} label={"Home"} icon={<img src={logo} alt={"logo"} width={64} height={64}></img>}></AppBarLinkButton>
            <Link
                component={RouterLink}
                underline="none"
                variant="h5"
                to={homeButtonRoute}
                sx={{
                    marginLeft: "10px",
                    color: "white !important"
                }}
            >
                EWA
            </Link>
        </Box>
    }


    function renderRight() {
        return <>
            <LanguageSelect />
            <Button
                sx={{ color: 'white' }}
                href={toolRoute}
                startIcon={<PlayCircle />}
            >
                {t('general.play')}
            </Button>
        </>
    }

    function renderCenter() {
        return <div ref={portalContainerCallbackRef} style={{ flexGrow: 1 }}></div>
    }

    function renderContent() {
        if (mediaQuery1k) {
            return <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Box>{renderLeft()}</Box>
                    <Box>{renderRight()}</Box>
                </Box>
                <Box>
                    {renderCenter()}
                </Box>
            </Box>
        }

        return <>
            {renderLeft()}
            {renderCenter()}
            {renderRight()}
        </>
    }

    return <Box sx={{ marginTop: '64px' }}>
        <AppBar elevation={0} sx={{
            backgroundColor: "#09253E !important",
            flexDirection: "row",
        }}>
            <Toolbar sx={{ width: '100%' }}>
                {renderContent()}
            </Toolbar>
        </AppBar>
    </Box>;
}
