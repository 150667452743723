import { Close, Delete } from "@mui/icons-material"
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, LinearProgress } from "@mui/material"
import { useSnackbar } from "notistack"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { EWAModelDTO, ModellingService } from "../../../api/client"
import EventManager from "@/events/EventManager"

export interface ChallengeDeleteDialogProps {
    open: boolean
    onClose: () => void
    model: EWAModelDTO
    setModel: (_: EWAModelDTO) => void
}

export default function ChallengeDeleteDialog(props: ChallengeDeleteDialogProps) {
    const {
        open,
        onClose,
        model,
        setModel
    } = props

    const { t } = useTranslation()
    const [loading, setLoading] = useState<boolean>(false)
    const { enqueueSnackbar } = useSnackbar();

    async function onDelete() {
        setLoading(true)
        try {
            await ModellingService.modelDeleteChallengeV1ModelsModelIdChallengeDelete(model.id)

            const newModel:EWAModelDTO = {
                ...model,
                challenge: undefined
            }
            setModel(newModel)

            EventManager.getInstance().onHomeChallengeDeleted({
                modelId: model.id
            })

            enqueueSnackbar(t('challenge.home.delete_challenge.delete_succeeded'), {
                variant: 'success'
            })

            onClose()
        } catch (e: any) {
            console.error(e)
            enqueueSnackbar(t('challenge.home.delete_challenge.delete_failed'), {
                variant: 'error'
            })
        }
        setLoading(false)
    }

    return <>
        <Dialog
            open={open}
            fullWidth
            maxWidth="sm"
        >
            <DialogTitle sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <span>{t('challenge.home.delete_challenge.title')}</span>
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                >
                    <Close />
                </IconButton>
            </DialogTitle>
            {loading && <LinearProgress />}
            <DialogContent>
                {t('challenge.home.delete_challenge.prompt')}
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    startIcon={<Delete />}
                    color="error"
                    onClick={() => onDelete()}
                >{t('general.delete')}</Button>
            </DialogActions>
        </Dialog>
    </>
}
