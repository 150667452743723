import { OperatingConditionType } from "../../../api/client";
import ComparisonMode from "../../../utils/ComparisonMode";
import TaskOptions, { ITaskOptions } from "./TaskOptions";

export interface IPerformanceIndicatorTaskOptions extends ITaskOptions {
  id?: string
  year?: number
  scenario?: string
  operatingConditionType?: OperatingConditionType
  threshold?: number
  comparisonMode?: ComparisonMode
}

export default class PerformanceIndicatorTaskOptions extends TaskOptions {
  public id?: string;
  public year?: number;
  public scenario?: string;
  public operatingConditionType?: OperatingConditionType;
  public threshold?: number;
  public comparisonMode: ComparisonMode;

  public constructor(options: IPerformanceIndicatorTaskOptions = {}) {
    super(options);

    this.id = options.id;
    this.year = options.year;
    this.scenario = options.scenario;
    this.operatingConditionType = options.operatingConditionType;
    this.threshold = options.threshold;
    this.comparisonMode = options.comparisonMode ?? ComparisonMode.eq;
    // performance indicator tasks will always either pass or fail - no point in increasing requiredCount
    this.requiredCount = 1;
  }

  public static fromPlain(plainOptions?: IPerformanceIndicatorTaskOptions) {
    return new PerformanceIndicatorTaskOptions(plainOptions);
  }

  public toJSON(): IPerformanceIndicatorTaskOptions {
    return {
      ...super.toJSON(),
      id: this.id,
      year: this.year,
      scenario: this.scenario,
      operatingConditionType: this.operatingConditionType,
      threshold: this.threshold,
      comparisonMode: this.comparisonMode
    }
  }
}
