import { Button, Menu, MenuItem } from "@mui/material"
import { supportedLngs } from "../../../i18n"
import { useMemo, useRef, useState } from "react"
import { Language } from "@mui/icons-material"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../../store/store"
import { setLocale } from "../../../store/slices/challengeEditorSlice"

export interface LocaleMenuProps {
}

export default function LocaleMenu(props: LocaleMenuProps) {
  const anchorRef = useRef<any>()
  const { t, i18n } = useTranslation()
  const [open, setOpen] = useState<boolean>(false)
  const locale: string = useSelector((state: RootState) => state.challengeEditor.locale)
  const dispatch = useDispatch()

  const menuItems: JSX.Element[] = useMemo(() => {
    const items: JSX.Element[] = []
    for (const supportedLng of supportedLngs) {
      items.push(<MenuItem
        key={supportedLng}
        onClick={() => onLocale(supportedLng)}
        selected={locale === supportedLng}
      >{t(`locale.${supportedLng}`)}</MenuItem>)
    }
    return items
  }, [i18n.language, locale])

  function onLocale(locale: string) {
    dispatch(setLocale(locale))
    setOpen(false)
  }

  return <>
    <div ref={anchorRef}>
      <Button
        variant="contained"
        onClick={() => setOpen(true)}
        startIcon={<Language />}
      >
        {t('challenge.editor.current_locale')}: {t(`locale.${locale}`)}
      </Button>
    </div>
    <Menu
      anchorEl={anchorRef.current}
      open={open}
      onClose={() => setOpen(false)}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      {menuItems}
    </Menu>
  </>
}
