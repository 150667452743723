import { CardContent, List, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { RootState } from "../../store/store"
import Challenge from "../Challenge"
import TaskItem from "./TaskItem"

export interface ChallengePanelContentProps {
  minified: boolean
  challenge: Challenge
}

export default function ChallengePanelContent(props: ChallengePanelContentProps) {
  const {
    minified,
    challenge
  } = props

  const taskGroupIndex: number | undefined = useSelector((state: RootState) => state.challenge.taskGroupIndex);
  const { t } = useTranslation()

  function renderTasks() {
    if (taskGroupIndex === undefined || challenge.taskGroups[taskGroupIndex].totalTasks === 0) {
      return t('challenge.panel.no_tasks');
    }

    if (minified) {
      const currentTask = challenge.activeTaskGroup?.firstIncompleteTask
      if (currentTask === undefined) {
        return t('challenge.panel.no_tasks');
      }

      return <TaskItem task={currentTask} />
    }

    const tasks = [];
    for (let i = 0; i < challenge.taskGroups[taskGroupIndex].totalTasks; i++) {
      const task = challenge.taskGroups[taskGroupIndex].tasks[i];
      tasks.push(<TaskItem task={task} key={i}></TaskItem>)
    }

    return <List>{tasks}</List>;
  }

  function renderTaskGroupName() {
    if (minified) return

    const name = challenge.activeTaskGroup?.name
    if (name === undefined) return

    return <Typography>{name}</Typography>
  }

  return <CardContent>
    {renderTaskGroupName()}
    {renderTasks()}
  </CardContent>
}
