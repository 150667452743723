import { TextField, InputAdornment } from "@mui/material";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { PipeConditionProps, PipeMaterial, PipeTimedProps } from "../../api/client";
import { Pipe } from "../../data/Pipe";
import { EWAModelWrapper } from "../model/EWAModelWrapper";
import { BaseMultiForm, BaseMultiFormApply } from "./BaseMultiForm";
import { findComputedTimedMulti, findTimedIfDefinedMulti, findTimedMulti } from "../../utils/findTimedMulti";
import { findConditionMulti } from "../../utils/findConditionMulti";
import { PipeApply } from "./PipeApply";
import PipeStatusSelect from "./PipeStatusSelect";
import parseFloatOrUndefined from "../../utils/parseFloatOrUndefined";
import validNumber from "../../utils/validNumber";
import { useFormContext } from "./FormContext";
import { multiFormComputeIfFirstYear } from "./util";
import PipeMaterialSelect from "@/widgets/PipeMaterialSelect";

export interface MultiPipeFormProps {
    pipes: Pipe[]
}

export function MultiPipeForm(props: MultiPipeFormProps) {
    const { pipes } = props;

    const context = useFormContext();
    const currentDate = context.currentDate as number

    const [isFirstYear, setIsFirstYear] = useState<boolean>(multiFormComputeIfFirstYear(pipes, currentDate))
    const [roughness, setRoughness] = useState<string>(findTimedMulti({
        items: pipes,
        get: (props) => props.roughness
    }, currentDate, undefined)?.toString() ?? '')
    const [fallbackRoughness, setFallbackRoughness] = useState<number | undefined>(findComputedTimedMulti({
        items: pipes,
        get: _ => _.roughness
    }, currentDate, undefined))
    const [diameter, setDiameter] = useState<string>(findTimedMulti({
        items: pipes,
        get: (props) => props.diameter
    }, currentDate, undefined)?.toString() ?? '')
    const [fallbackDiameter, setFallbackDiameter] = useState<number | undefined>(findComputedTimedMulti({
        items: pipes,
        get: _ => _.diameter
    }, currentDate, undefined))
    const [status, setStatus] = useState<string>(findConditionMulti({
        items: pipes,
        get: _ => _.status
    }, context.condition, ''));
    const { t } = useTranslation();

    const [constructionYear, setConstructionYear] = useState<string>(findTimedMulti({
        items: pipes,
        get: _ => _.construction_year
    }, currentDate, undefined)?.toString() ?? '')
    const [fallbackConstructionYear, setFallbackConstructionYear] = useState<number | undefined>(findComputedTimedMulti({
        items: pipes,
        get: _ => _.construction_year
    }, currentDate, undefined))
    const [material, setMaterial] = useState<PipeMaterial | undefined>(findTimedMulti({
        items: pipes,
        get: _ => _.material
    }, currentDate, undefined))
    const [fallbackMaterial, setFallbackMaterial] = useState<PipeMaterial | undefined>(findComputedTimedMulti({
        items: pipes,
        get: _ => _.material
    }, currentDate, undefined))

    useEffect(() => {
        const currentDate = context.currentDate as number
        const newIsFirstYear = multiFormComputeIfFirstYear(pipes, currentDate)
        setIsFirstYear(newIsFirstYear)

        setDiameter(findTimedIfDefinedMulti({
            items: pipes,
            get: (props) => props.diameter
        }, currentDate, undefined)?.toString() ?? '')
        setFallbackDiameter(findComputedTimedMulti({
            items: pipes,
            get: _ => _.diameter
        }, newIsFirstYear ? currentDate : currentDate - 10, undefined))
        setRoughness(findTimedIfDefinedMulti({
            items: pipes,
            get: (props) => props.roughness
        }, currentDate, undefined)?.toString() ?? '');
        setFallbackRoughness(findComputedTimedMulti({
            items: pipes,
            get: _ => _.roughness
        }, newIsFirstYear ? currentDate : currentDate - 10, undefined))
        setStatus(findConditionMulti({
            items: pipes,
            get: (props) => props.status
        }, context.condition, undefined));

        setConstructionYear(findTimedIfDefinedMulti({
            items: pipes,
            get: _ => _.construction_year
        }, currentDate, undefined)?.toString() ?? '')
        setFallbackConstructionYear(findComputedTimedMulti({
            items: pipes,
            get: _ => _.construction_year
        }, newIsFirstYear ? currentDate : currentDate - 10, undefined))
        setMaterial(findTimedIfDefinedMulti({
            items: pipes,
            get: _ => _.material
        }, currentDate, undefined))
        setFallbackMaterial(findComputedTimedMulti({
            items: pipes,
            get: _ => _.material
        }, newIsFirstYear ? currentDate : currentDate - 10, undefined))
    }, [pipes, context.currentDate, context.condition, context.modelWrapper?.id])

    function handleApply(a: BaseMultiFormApply) {
        const timed: Partial<PipeTimedProps> = {
            material
        }

        const applyRoughness = parseFloatOrUndefined(roughness)
        if (applyRoughness !== undefined) {
            timed.roughness = applyRoughness
        }
        const applyDiameter = parseFloatOrUndefined(diameter)
        if (applyDiameter !== undefined) {
            timed.diameter = applyDiameter
        }
        const applyConstructionYear = parseFloatOrUndefined(constructionYear)
        if (applyConstructionYear !== undefined) {
            timed.construction_year = applyConstructionYear
        }

        for (const pipe of pipes) {
            const apply = {
                id: pipe.id,
                comment: pipe.comment,
                lifetime: a.lifetime,
                length: pipe.length,
                conditional: {
                    status: status
                } as PipeConditionProps,
                timed: timed
            } as PipeApply
            pipe.apply(apply);
        }
        context.setModelWrapper(new EWAModelWrapper(context.modelWrapper!.model));
    }

    function validate() {
        if (isFirstYear || diameter !== '') {
            const parsedDiameter = parseFloatOrUndefined(diameter)
            if (!validNumber(parsedDiameter) || parsedDiameter! <= 0) {
                return false
            }
        }

        if (isFirstYear || roughness !== '') {
            const parsedRoughness = parseFloatOrUndefined(roughness)
            if (!validNumber(parsedRoughness) || parsedRoughness! <= 0) {
                return false
            }
        }

        if (constructionYear !== '') {
            const parsedConstructionYear = parseFloatOrUndefined(constructionYear)
            if (!validNumber(parsedConstructionYear) || parsedConstructionYear! < 0) {
                return false
            }
        }

        return true
    }

    return <BaseMultiForm
        handleApply={handleApply}
        features={pipes}
        validate={validate}
        timedChildren={<>
            <TextField
                required
                type="number"
                value={diameter}
                onChange={(event) => setDiameter(event.target.value)}
                style={{ flexGrow: 1, marginBottom: "10px" }}
                label={t('model.components.pipe.diameter')}
                InputProps={{ endAdornment: <InputAdornment position="end">mm</InputAdornment> }}
                helperText={isFirstYear ? undefined : fallbackDiameter?.toString()}
            />
            <TextField
                required
                type="number"
                value={(roughness)}
                onChange={(event) => setRoughness((event.target.value))}
                style={{ flexGrow: 1, marginBottom: "10px" }}
                label={t('model.components.pipe.roughness')}
                InputProps={{ endAdornment: <InputAdornment position="end">mm</InputAdornment> }}
                helperText={isFirstYear ? undefined : fallbackRoughness?.toString()}
            />
            <TextField
                type="number"
                value={constructionYear}
                onChange={(event) => setConstructionYear(event.target.value)}
                style={{ flexGrow: 1, marginBottom: "10px" }}
                label={t('model.components.pipe.construction_year')}
                helperText={isFirstYear ? undefined : fallbackConstructionYear?.toString()}
            />
            <PipeMaterialSelect value={material} setValue={setMaterial} />
        </>}
        conditionChildren={<PipeStatusSelect status={status} setStatus={setStatus} />}
    ></BaseMultiForm>
}
