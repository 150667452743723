export enum ShowResults {
    None = 0,
    HeatMap = 1,
    Demand = 2
}

export function showResultsFromString(value: string): ShowResults {
    switch(value) {
      case 'None':
      case 'HeatMap':
      case 'Demand':
        return ShowResults[value];
      default:
        throw Error(`Unable to determine type for: ${value}`)
    }
  }

