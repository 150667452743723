import { EWASimulationDTO } from "../../api/client";
import { EWAModel } from "../../data/EWAModel";
import EventType from "../EventType";

export interface SimulationResultsDialogOpenedEventArgs {
  model: EWAModel
  simulations: EWASimulationDTO[]
}

export default class SimulationResultsDialogOpenedEvent extends CustomEvent<SimulationResultsDialogOpenedEventArgs> {
  public constructor( detail: SimulationResultsDialogOpenedEventArgs) {
    super(EventType.SimulationResultsDialogOpened, { detail })
  }
}
