import { Box, Theme } from "@mui/material"
import { makeStyles } from "@mui/styles"

const useStyles = makeStyles((theme: Theme) => ({
  selectedRow: {
    backgroundColor: theme.palette.primary.main,
    padding: "10px"
  },
  row: {
    padding: "10px"
  }
}))

export interface CardListProps {
  items: unknown[]
  rowKey: string
  rowRenderer: (_: unknown) => JSX.Element
  rowClick?: (_: unknown) => void
  rowIsSelected?: (_: unknown) => boolean
  height: number
}

export default function CardList(props: CardListProps) {
  const {
    items,
    rowKey,
    rowRenderer,
    rowClick,
    rowIsSelected,
    height
  } = props

  const rows: JSX.Element[] = []
  const styles = useStyles()

  function rowClassName (item: unknown) {
    if (rowIsSelected === undefined) return styles.row

    return rowIsSelected(item) ? styles.selectedRow : styles.row
  }

  function onRowClick(item: unknown) {
    if (rowClick !== undefined) {
      rowClick(item)
    }
  }

  for (const item of items) {
    rows.push(<Box
      key={(item as any)[rowKey]}
      onClick={() => onRowClick(item)}
      className={rowClassName(item)}
      sx={{cursor: 'pointer'}}
    >
      {rowRenderer(item)}
    </Box>)
  }

  return <Box sx={{ height, overflow: 'auto'}}>
    {rows}
  </Box>
}
