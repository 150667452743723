import { createContext, useContext } from "react"
import { EWAModel } from "../../data/EWAModel"
import { EWAModelWrapper } from "../model/EWAModelWrapper"

export interface FormContextProps {
    model?: EWAModel,
    minDate?: number,
    maxDate?: number
    setSelectedFeatureIndices: (_: number[]) => void
    modelWrapper: EWAModelWrapper | undefined
    setModelWrapper: (_: EWAModelWrapper) => void
    condition: string | undefined
    currentDate?: number
    selectedFeatureIndices?: number[]
    headerPortalContainerRef?: React.MutableRefObject<any>
    footerPortalContainerRef?: React.MutableRefObject<any>
    onDelete?: () => void
}

export const FormContext: React.Context<FormContextProps> = createContext<FormContextProps>({
    model: undefined,
    minDate: undefined,
    modelWrapper: undefined,
    setSelectedFeatureIndices: (_: number[]) => {},
    setModelWrapper: (_: EWAModelWrapper) => {},
    maxDate: undefined,
    condition: undefined,
    currentDate: undefined,
    selectedFeatureIndices: undefined,
    headerPortalContainerRef: undefined,
    footerPortalContainerRef: undefined,
    onDelete: undefined
});

export function useFormContext() {
    return useContext<FormContextProps>(FormContext);
}
