import { EWAComponentType } from "../../../data/EWAComponentType";
import Challenge from "../../Challenge";
import ChallengeOptions from "../../ChallengeOptions";
import TaskGroup from "../../TaskGroup";
import OpenEditDialogTask from "../../tasks/OpenEditDialogTask";
import OpenEditDialogTaskOptions from "../../tasks/options/OpenEditDialogTaskOptions";

export default function OpenEditDialogTasks(): Challenge {
    const taskGroups: TaskGroup[] = [
        new TaskGroup([
            new OpenEditDialogTask(),
            new OpenEditDialogTask(new OpenEditDialogTaskOptions({
                year: 2035
            })),
            new OpenEditDialogTask(new OpenEditDialogTaskOptions({
                id: 'junction_1'
            })),
            new OpenEditDialogTask(new OpenEditDialogTaskOptions({
                componentType: EWAComponentType.pump
            })),
            new OpenEditDialogTask(new OpenEditDialogTaskOptions({
                id: 'tank_1',
                year: 2045,
                componentType: EWAComponentType.tank
            }))
        ])
    ]

    return new Challenge(taskGroups, new ChallengeOptions({
        name: { en: 'OpenEditDialogTasks' }
    }))
}
