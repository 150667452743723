import OptionsExtrasType from "./OptionsExtrasType";
import TaskTypeString from "./TaskTypeString";

const TaskTypeToOptionsExtrasType: Map<TaskTypeString, OptionsExtrasType> = new Map([
  // misc tasks
  [TaskTypeString.ChangeYearTask, OptionsExtrasType.ChangeYear],
  [TaskTypeString.ShowResultsTask, OptionsExtrasType.ShowResults],
  [TaskTypeString.ChangeTabTask, OptionsExtrasType.ChangeTab],
  [TaskTypeString.OpenEditDialogTask, OptionsExtrasType.OpenEditDialog],
  [TaskTypeString.ChangeOrthoLayerTask, OptionsExtrasType.ChangeOrthoLayer],
  // simulation tasks
  [TaskTypeString.PIAveragePipeAgeTask, OptionsExtrasType.PerformanceIndicator],
  [TaskTypeString.PIResilienceIndexTask, OptionsExtrasType.PerformanceIndicator],
  [TaskTypeString.PIInflowDemandRatioTask, OptionsExtrasType.PerformanceIndicator],
  [TaskTypeString.PIUnsatisfiedNodesCountTask, OptionsExtrasType.PerformanceIndicator],
  [TaskTypeString.PIUnsatisfiedNodesDetailValueTask, OptionsExtrasType.PerformanceIndicator],
  [TaskTypeString.PIUnsatisfiedNodesRelativeCountTask, OptionsExtrasType.PerformanceIndicator],
  // general component tasks
  [TaskTypeString.CreateComponentTask, OptionsExtrasType.Component],
  [TaskTypeString.DeleteComponentTask, OptionsExtrasType.Component],
  [TaskTypeString.MoveComponentTask, OptionsExtrasType.Component],
  [TaskTypeString.ChangeComponentVisibilityTask, OptionsExtrasType.ChangeComponentVisibility],
  // junction tasks
  [TaskTypeString.JunctionUpdateCommentTask, OptionsExtrasType.UpdateString],
  [TaskTypeString.JunctionUpdateDemandTask, OptionsExtrasType.UpdateNumber],
  [TaskTypeString.JunctionUpdateElevationTask, OptionsExtrasType.UpdateNumber],
  [TaskTypeString.JunctionUpdateFireflowTask, OptionsExtrasType.UpdateNumber],
  [TaskTypeString.JunctionUpdateLifetimeTask, OptionsExtrasType.UpdateLifetime],
  [TaskTypeString.JunctionUpdateModeTask, OptionsExtrasType.UpdateConsumptionMode],
  [TaskTypeString.JunctionUpdateScaleDemandTask, OptionsExtrasType.UpdateBoolean],
  // well tasks
  [TaskTypeString.WellUpdateCommentTask, OptionsExtrasType.UpdateString],
  [TaskTypeString.WellUpdateElevationTask, OptionsExtrasType.UpdateNumber],
  [TaskTypeString.WellUpdateLifetimeTask, OptionsExtrasType.UpdateLifetime],
  [TaskTypeString.WellUpdateInflowTask, OptionsExtrasType.UpdateNumber],
  // spring tasks
  [TaskTypeString.SpringUpdateCommentTask, OptionsExtrasType.UpdateString],
  [TaskTypeString.SpringUpdateElevationTask, OptionsExtrasType.UpdateNumber],
  [TaskTypeString.SpringUpdateLifetimeTask, OptionsExtrasType.UpdateLifetime],
  [TaskTypeString.SpringUpdateInflowTask, OptionsExtrasType.UpdateNumber],
  // pipe tasks
  [TaskTypeString.PipeUpdateCommentTask, OptionsExtrasType.UpdateString],
  [TaskTypeString.PipeUpdateDiameterTask, OptionsExtrasType.UpdateNumber],
  [TaskTypeString.PipeUpdateLengthTask, OptionsExtrasType.UpdateNumber],
  [TaskTypeString.PipeUpdateLifetimeTask, OptionsExtrasType.UpdateLifetime],
  [TaskTypeString.PipeUpdateMinorlossTask, OptionsExtrasType.UpdateNumber],
  [TaskTypeString.PipeUpdateRoughnessTask, OptionsExtrasType.UpdateNumber],
  [TaskTypeString.PipeUpdateStatusTask, OptionsExtrasType.UpdatePipeStatus],
  [TaskTypeString.PipeUpdateMaterialTask, OptionsExtrasType.UpdatePipeMaterial],
  [TaskTypeString.PipeUpdateConstructionYearTask, OptionsExtrasType.UpdateNumber],
  // pump tasks
  [TaskTypeString.PumpUpdateCommentTask, OptionsExtrasType.UpdateString],
  [TaskTypeString.PumpUpdateHeadCurveTask, OptionsExtrasType.UpdateString],
  [TaskTypeString.PumpUpdateLifetimeTask, OptionsExtrasType.UpdateLifetime],
  [TaskTypeString.PumpUpdatePowerTask, OptionsExtrasType.UpdateNumber],
  [TaskTypeString.PumpUpdateStatusTask, OptionsExtrasType.UpdatePipeStatus],
  // reservoir tasks
  [TaskTypeString.ReservoirUpdateCommentTask, OptionsExtrasType.UpdateString],
  [TaskTypeString.ReservoirUpdateElevationTask, OptionsExtrasType.UpdateNumber],
  [TaskTypeString.ReservoirUpdateHeadTask, OptionsExtrasType.UpdateNumber],
  [TaskTypeString.ReservoirUpdateLifetimeTask, OptionsExtrasType.UpdateLifetime],
  // tank tasks
  [TaskTypeString.TankUpdateCommentTask, OptionsExtrasType.UpdateString],
  [TaskTypeString.TankUpdateDiameterTask, OptionsExtrasType.UpdateNumber],
  [TaskTypeString.TankUpdateElevationTask, OptionsExtrasType.UpdateNumber],
  [TaskTypeString.TankUpdateInitlevelTask, OptionsExtrasType.UpdateNumber],
  [TaskTypeString.TankUpdateLifetimeTask, OptionsExtrasType.UpdateLifetime],
  // valve tasks
  // FCV
  [TaskTypeString.FCVUpdateCommentTask, OptionsExtrasType.UpdateString],
  [TaskTypeString.FCVUpdateDiameterTask, OptionsExtrasType.UpdateNumber],
  [TaskTypeString.FCVUpdateLifetimeTask, OptionsExtrasType.UpdateLifetime],
  [TaskTypeString.FCVUpdateMaximumFlowTask, OptionsExtrasType.UpdateNumber],
  [TaskTypeString.FCVUpdateStatusTask, OptionsExtrasType.UpdatePipeStatus],
  // GPV
  [TaskTypeString.GPVUpdateCommentTask, OptionsExtrasType.UpdateString],
  [TaskTypeString.GPVUpdateDiameterTask, OptionsExtrasType.UpdateNumber],
  [TaskTypeString.GPVUpdateHeadCurveTask, OptionsExtrasType.UpdateString],
  [TaskTypeString.GPVUpdateLifetimeTask, OptionsExtrasType.UpdateLifetime],
  [TaskTypeString.GPVUpdateStatusTask, OptionsExtrasType.UpdatePipeStatus],
  // PBV
  [TaskTypeString.PBVUpdateCommentTask, OptionsExtrasType.UpdateString],
  [TaskTypeString.PBVUpdateDiameterTask, OptionsExtrasType.UpdateNumber],
  [TaskTypeString.PBVUpdateLifetimeTask, OptionsExtrasType.UpdateLifetime],
  [TaskTypeString.PBVUpdatePressureDropTask, OptionsExtrasType.UpdateNumber],
  [TaskTypeString.PBVUpdateStatusTask, OptionsExtrasType.UpdatePipeStatus],
  // PRV
  [TaskTypeString.PRVUpdateCommentTask, OptionsExtrasType.UpdateString],
  [TaskTypeString.PRVUpdateDiameterTask, OptionsExtrasType.UpdateNumber],
  [TaskTypeString.PRVUpdateLifetimeTask, OptionsExtrasType.UpdateLifetime],
  [TaskTypeString.PRVUpdateMaximumPressureTask, OptionsExtrasType.UpdateNumber],
  [TaskTypeString.PRVUpdateStatusTask, OptionsExtrasType.UpdatePipeStatus],
  // PSV
  [TaskTypeString.PSVUpdateCommentTask, OptionsExtrasType.UpdateString],
  [TaskTypeString.PSVUpdateDiameterTask, OptionsExtrasType.UpdateNumber],
  [TaskTypeString.PSVUpdateLifetimeTask, OptionsExtrasType.UpdateLifetime],
  [TaskTypeString.PSVUpdatePressureLimitTask, OptionsExtrasType.UpdateNumber],
  [TaskTypeString.PSVUpdateStatusTask, OptionsExtrasType.UpdatePipeStatus],
  // TCV
  [TaskTypeString.TCVUpdateCommentTask, OptionsExtrasType.UpdateString],
  [TaskTypeString.TCVUpdateDiameterTask, OptionsExtrasType.UpdateNumber],
  [TaskTypeString.TCVUpdateHeadlossCoefficientTask, OptionsExtrasType.UpdateNumber],
  [TaskTypeString.TCVUpdateLifetimeTask, OptionsExtrasType.UpdateLifetime],
  [TaskTypeString.TCVUpdateStatusTask, OptionsExtrasType.UpdatePipeStatus],
  // operating condition
  [TaskTypeString.ChangeOperatingConditionTask, OptionsExtrasType.ChangeOperatingCondition],
  [TaskTypeString.CreateOperatingConditionTask, OptionsExtrasType.CreateDeleteOperatingCondition],
  [TaskTypeString.DeleteOperatingConditionTask, OptionsExtrasType.CreateDeleteOperatingCondition],
]);

export default TaskTypeToOptionsExtrasType;
