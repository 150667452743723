import { ComponentApplyEventArgs } from "../../../events/events/ComponentApplyEvent";
import i18n from "../../../i18n";
import { compare, comparisonModeToSymbol } from "../../../utils/ComparisonMode";
import UpdateTaskType from "../../misc/UpdateTaskType";
import UpdateStringTaskOptions from "../options/UpdateStringTaskOptions";
import ValueUpdateTask from "./ValueUpdateTask";


export default abstract class StringValueUpdateTask<A> extends ValueUpdateTask<A, string> {
  public constructor(options?: UpdateStringTaskOptions) {
    if (!options) {
      options = new UpdateStringTaskOptions();
    }
    super(options);
  }

  public get description(): string {
    const options = this._options as UpdateStringTaskOptions;
    let description = ''

    switch (options.updateTaskType) {
      case UpdateTaskType.ExactValue:
        description = i18n.t('challenge.task.value_update.exact_value', {
          component: i18n.t(this.componentName),
          field: i18n.t(this.fieldName),
          value: this.optionTargetValue,
          comparator: comparisonModeToSymbol(options.comparisonMode)
        })
        break
      case UpdateTaskType.Any:
      default:
        description = i18n.t('challenge.task.value_update.any', {
          component: i18n.t(this.componentName),
          field: i18n.t(this.fieldName)
        })
    }

    return description;
  }


  protected determineUpdate(e: ComponentApplyEventArgs<A>): boolean {
    const values = this.retrieveValues(e);

    if (values.newValue === values.oldValue) {
      return false;
    }

    let shouldUpdate = false;
    const options = this._options as UpdateStringTaskOptions;

    if (options.id !== undefined && e.id !== options.id) return false;
    if (options.year !== undefined && e.year !== options.year) return false;

    switch (options.updateTaskType) {
      case UpdateTaskType.ExactValue:
        shouldUpdate = compare(options.comparisonMode, values.newValue, options.targetValue);
        break;
      case UpdateTaskType.Any:
      default:
        shouldUpdate = true;
        break;
    }
    return shouldUpdate;
  }
}
