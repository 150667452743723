import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import { useTranslation } from "react-i18next"
import { EWAResultType } from "./EWAResultType"

export interface PerformanceIndicatorSelectProps {
    value: EWAResultType
    setValue: (_: EWAResultType) => void
}

const ewaResultTypes = [
    EWAResultType.UnsatisfiedNodes,
    EWAResultType.ResilienceIndex,
    EWAResultType.InflowDemandRatio,
    EWAResultType.AveragePipeAge
]

export default function PerformanceIndicatorSelect(props: PerformanceIndicatorSelectProps) {
    const {
        value,
        setValue
    } = props

    const { t } = useTranslation()

    function renderOptions () {
        const options: JSX.Element[] = []

        for (const ewaResultType of ewaResultTypes) {
            options.push(<MenuItem
                value={ewaResultType}
                key={ewaResultType}
            >{t(`ewa_result_type.${ewaResultType}`)}</MenuItem>)
        }

        return options
    }
    return <FormControl margin="normal" sx={{minWidth: '200px'}}>
        <InputLabel>{t('ewa_result_type.label')}</InputLabel>
        <Select
            value={value}
            onChange={(event) => setValue(event.target.value as EWAResultType)}
            label={t('ewa_result_type.label')}
        >
            {renderOptions()}
        </Select>
    </FormControl>
}
