import Keycloak, {KeycloakConfig} from "keycloak-js";
export const KEYCLOAK_URL = (window as any)._env_.KEYCLOAK_URL;
export const KEYCLOAK_REALM = (window as any)._env_.KEYCLOAK_REALM;
export const KEYCLOAK_CLIENT_ID = (window as any)._env_.KEYCLOAK_CLIENT_ID;
const keycloak = new Keycloak({
    url: KEYCLOAK_URL,
    realm: KEYCLOAK_REALM,
    clientId: KEYCLOAK_CLIENT_ID,
    
} as KeycloakConfig) as any;

export default keycloak;
