import EventType from "../EventType";
import { ChallengeMetaDataModel } from "@/api/client"

export interface HomeChallengeUploadedEventArgs {
    modelId: string
    challengeMetaData: ChallengeMetaDataModel
}

export default class HomeChallengeUploadedEvent extends CustomEvent<HomeChallengeUploadedEventArgs> {
    constructor(detail: HomeChallengeUploadedEventArgs) {
        super(EventType.HomeChallengeUploaded, { detail })
    }
}
