import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, TextField, FormControl, InputLabel, Select, MenuItem, Button, IconButton } from "@mui/material"
import { useTranslation } from "react-i18next"
import { OperatingCondition, OperatingConditionType } from "../../api/client"
import { v4 as uuidv4 } from "uuid"
import { EWAModelWrapper } from "./EWAModelWrapper"
import EventManager from "../../events/EventManager"
import { useState } from "react"
import { Close } from "@mui/icons-material"

export interface OperatingConditionDialogProps {
    open: boolean
    setOpen: (value: boolean) => void
    setCurrentCondition: (_?: string) => void
    modelWrapper: EWAModelWrapper
    setModelWrapper: (_: EWAModelWrapper) => void
}

export default function OperatingConditionDialog(props: OperatingConditionDialogProps) {
    const {
        open,
        setOpen,
        setCurrentCondition,
        modelWrapper,
        setModelWrapper
    } = props
    const { t } = useTranslation();
    const [name, setName] = useState<string>("")
    const [description, setDescription] = useState<string>("");
    const [type, setType] = useState<OperatingConditionType>(OperatingConditionType.FIRE);

    function create() {
        const newOperatingConditionId = uuidv4()
        modelWrapper.model.conditions.push({
            id: newOperatingConditionId,
            name: name,
            description: description,
            type: type
        } as OperatingCondition)
        setModelWrapper(new EWAModelWrapper(modelWrapper.model));
        setCurrentCondition(newOperatingConditionId);
        setOpen(false);
        setName("")
        setDescription("")

        EventManager.getInstance().onOperatingConditionCreated({
            id: newOperatingConditionId,
            type: type,
            model: modelWrapper.model
        })
    }

    function onClose() {
        setName("")
        setDescription("")
        setOpen(false)
        setType(OperatingConditionType.FIRE)
    }

    return <Dialog open={open} onClose={onClose}>
        <DialogTitle sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <span>{t('model.operating_condition.header_add')}</span>
            <IconButton aria-label="close" onClick={onClose}>
                <Close />
            </IconButton>
        </DialogTitle>
        <DialogContent>
            <DialogContentText>{t('model.operating_condition.hint')}</DialogContentText>
            <TextField
                autoFocus
                value={name}
                onChange={(e) => setName(e.target.value)}
                margin="dense"
                required
                id="name"
                label={t('general.name')}
                fullWidth
                variant="standard"
            />
            <TextField
                margin="dense"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                id="description"
                label={t('general.description')}
                fullWidth
                variant="standard"
            />
            <FormControl fullWidth style={{ marginTop: "20px" }}>
                <InputLabel id="operating-condition-type-label">{t('model.operating_condition.type')}</InputLabel>
                <Select
                    labelId="operating-condition-type-label"
                    id="operating-condition-type"
                    value={type}
                    label={t('model.operating_condition.type') as string}
                    onChange={(event, newValue) => setType(event.target.value as OperatingConditionType)}
                >
                    <MenuItem value={OperatingConditionType.FIRE}>{t('operating_condition_type.fire')}</MenuItem>
                    <MenuItem value={OperatingConditionType.FAILURE}>{t('operating_condition_type.failure')}</MenuItem>
                </Select>
            </FormControl>
        </DialogContent>
        <DialogActions>
            <Button variant="contained" onClick={create}>{t('general.ok')}</Button>
        </DialogActions>
    </Dialog>
}
