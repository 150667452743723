import { OperatingConditionType } from "../../../api/client";
import Challenge from "../../Challenge";
import TaskGroup from "../../TaskGroup";
import PerformanceIndicatorTaskOptions from "../../tasks/options/PerformanceIndicatorTaskOptions";
import PIUnsatisfiedNodesCountTask from "../../tasks/PIUnsatisfiedNodesCountTask";

export default function PIUnsatisfiedNodesCounts(): Challenge {
  return new Challenge([
    new TaskGroup([
      new PIUnsatisfiedNodesCountTask(),
      new PIUnsatisfiedNodesCountTask(new PerformanceIndicatorTaskOptions({
        scenario: 'favorable',
        operatingConditionType: OperatingConditionType.FIRE
      })),
      new PIUnsatisfiedNodesCountTask(new PerformanceIndicatorTaskOptions({
        year: 2045
      }))
    ])
  ])
}
