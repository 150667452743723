import Challenge from "../../Challenge";
import ChallengeOptions from "../../ChallengeOptions";
import TaskGroup from "../../TaskGroup";
import PSVUpdateCommentTask from "../../tasks/PSVUpdateCommentTask";
import PSVUpdateDiameterTask from "../../tasks/PSVUpdateDiameterTask";
import PSVUpdateLifetimeTask from "../../tasks/PSVUpdateLifetimeTask";
import PSVUpdatePressureLimitTask from "../../tasks/PSVUpdatePressureLimitTask";
import PSVUpdateStatusTask from "../../tasks/PSVUpdateStatusTask";

export default function PSVUpdates(): Challenge {
  const taskGroups: TaskGroup[] = [
    new TaskGroup([
      new PSVUpdateCommentTask(),
      new PSVUpdateDiameterTask(),
      new PSVUpdateLifetimeTask(),
      new PSVUpdatePressureLimitTask(),
      new PSVUpdateStatusTask()
    ])
  ];

  const options = new ChallengeOptions({
  });

  return new Challenge(taskGroups, options);
}
