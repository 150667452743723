import { TankConditionProps, TankModel, TankTimedProps } from "../api/client";
import EventManager from "../events/EventManager";
import { TankApply } from "../views/forms/TankApply";
import { BasePointFeature } from "./BasePointFeature";
import { EWAComponentType } from "./EWAComponentType";

export class Tank extends BasePointFeature<TankConditionProps, Partial<TankTimedProps>> {
    public get componentType(): EWAComponentType {
        return EWAComponentType.tank;
    }

    //Must return number here for GPU filtering
    public get visible() {
        return this.collection.showComponents.includes(EWAComponentType.tank) ? 1 : 0;
    }

    public apply(apply: TankApply): void {
        const currentDate = this.collection.currentDate;
        const geometry = this._geometry
        const oldApply: TankApply = {
            id: this.id,
            comment: this.data.properties.comment,
            lifetime: this.data.lifetime,
            longitude: geometry.coordinates[0],
            latitude: geometry.coordinates[1],
            elevation: geometry.coordinates[2],
            strength: this.data.properties.strength,
            conditional: { ...this.data.properties.conditional[this.collection.currentCondition!] } as TankConditionProps,
            timed: { ...this._timedProperties.getValue(currentDate) } as Partial<TankTimedProps>
        }

        super.apply(apply);

        this.updateCoordinates3d([apply.longitude, apply.latitude, apply.elevation!])
        EventManager.getInstance().onTankApply({
            id: this.id,
            apply,
            oldApply,
            year: currentDate,
            model: this.collection
        });
    }

    public delete(): void {
        super.delete();
        EventManager.getInstance().onComponentDeleted({
            id: this.id,
            type: EWAComponentType.tank,
            year: this.collection.currentDate,
            model: this.collection
        });
    }

    public toComponentModel() {
        const model = {
            id: this.id,
            type: "Feature",
            bbox: this.bbox,
            geometry: this._geometry,
            properties: {
                comment: this.comment,
                timed: this._timedProperties.items(),
                conditional: this.apiConditional
            },
            component_type: EWAComponentType.tank,
            lifetime: [this.visibleFrom, this.visibleTo]
        } as TankModel

        return model
    }
}
