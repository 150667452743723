import { Button, Menu, MenuItem } from "@mui/material";
import { useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { OrthoLayer, orthoLayerImage, orthoLayerText } from "../data/OrthoLayer";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store/store";
import { setDefaultOrthoLayer } from "../store/slices/configurationSlice";

const orthoLayerOrder = [
    OrthoLayer.BasemapGrau,
    OrthoLayer.OSM,
    OrthoLayer.BasemapOrtho
]

export interface DefaultOrthoLayerMenuProps {

}

export default function DefaultOrthoLayerMenu(props: DefaultOrthoLayerMenuProps) {
    const anchorRef = useRef<any>()
    const {i18n} = useTranslation()
    const defaultOrthoLayer: OrthoLayer = useSelector(
        (state: RootState) => state.configuration.defaultOrthoLayer
    )
    const [open, setOpen] = useState<boolean>(false)
    const dispatch = useDispatch()

    const menuItems = useMemo(() => {
        const items: JSX.Element[] = []

        for (const orthoLayer of orthoLayerOrder) {
            const title = orthoLayerText(orthoLayer)
            items.push(<MenuItem
                key={orthoLayer}
                title={title}
                onClick={() => setValue(orthoLayer)}
                selected={orthoLayer === defaultOrthoLayer}
            >
                <img src={orthoLayerImage(orthoLayer)} height={24} width={24} alt={title} />
            </MenuItem>)
        }
        return items
    }, [i18n.language, defaultOrthoLayer])

    function setValue(orthoLayer: OrthoLayer) {
        dispatch(setDefaultOrthoLayer(orthoLayer))
        setOpen(false)
    }

    return <>
        <div ref={anchorRef}>
            <Button
                variant="contained"
                onClick={() => setOpen(true)}
            >
                <img src={orthoLayerImage(defaultOrthoLayer)} height={24} width={24} alt={orthoLayerText(defaultOrthoLayer)} />
            </Button>
        </div>
        <Menu
            anchorEl={anchorRef.current}
            open={open}
            onClose={() => setOpen(false)}
        >{menuItems}</Menu>
    </>
}