import i18n from "../i18n";
import TaskGroupOptions, { ITaskGroupOptions } from "./TaskGroupOptions";
import Task, { PlainTask } from "./tasks/base/Task";
import taskFromPlainTask from "./util/TaskFromPlainTask";

export interface PlainTaskGroup {
  tasks: PlainTask[]
  options: ITaskGroupOptions
}

export default class TaskGroup {
  protected _tasks: Task[] = [];
  public get tasks(): Task[] {
    return this._tasks;
  }
  protected set tasks(tasks: Task[]) {
    this._tasks = tasks;
  }

  protected _options: TaskGroupOptions;
  public get options() {
    return this._options;
  }
  public set options(options: TaskGroupOptions) {
    this._options = options;
  }

  public get hasDescription(): boolean {
    return this._options.description.length > 0
  }

  public constructor(tasks: Task[], options?: TaskGroupOptions) {
    this._tasks = tasks;
    this._options = options ?? new TaskGroupOptions();
  }

  /**
   * return length of tasks array
   */
  public get totalTasks(): number {
    return this.tasks.length;
  }

  /**
   * compute amount of completed tasks
   */
  public get completedTasks(): number {
    let completed = 0;
    for (const task of this.tasks) {
      if (task.completed) {
        completed++;
      }
    }

    return completed;
  }

  public get name(): string {
    const names = this._options.name

    return names[i18n.language] ?? (names.en ?? '')
  }

  public descriptionPage(index: number): string {
    const locale = i18n.language
    const descriptions = this._options.description
    const page = descriptions[index] ?? {}

    return page[locale] ?? (page.en ?? '')
  }

  /**
   * retrieve first incomplete task for minified view - or the last task
   */
  public get firstIncompleteTask(): Task {
    for (const task of this.tasks) {
      if (!task.completed) {
        return task
      }
    }

    return this.tasks[this.tasks.length - 1]
  }

  /**
   * compute progress in a range of 0-100
   */
  public get progress(): number {
    return Math.round((this.completedTasks / this.totalTasks) * 100)
  }

  /**
   * activate all tasks
   */
  public activate() {
    for (const task of this.tasks) {
      task.activate();
    }
  }

  /**
   * deactivate all tasks
   */
  public deactivate() {
    for (const task of this.tasks) {
      task.deactivate();
    }
  }

  /**
   * reset all tasks
   */
  public reset() {
    for (const task of this.tasks) {
      task.reset();
    }
  }

  public toJSON(): PlainTaskGroup{
    const tasks = [];
    for (const task of this.tasks) {
      tasks.push(task.toJSON())
    }

    return {
      tasks: tasks,
      options: this._options.toJSON()
    }
  }

  public static deserialize(plainTaskGroup: PlainTaskGroup) {
    const tasks = [];
    for (const task of plainTaskGroup.tasks) {
      tasks.push(taskFromPlainTask(task))
    }

    return new TaskGroup(tasks, new TaskGroupOptions(plainTaskGroup.options));
  }
}
