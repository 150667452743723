import { FCVConditionProps, FCVModel, FCVTimedProps } from "../api/client";
import EventManager from "../events/EventManager";
import { ValveFCVApply } from "../views/forms/ValveFCVApply";
import { BaseLinestringFeature } from "./BaseLinestringFeature";
import { EWAComponentType } from "./EWAComponentType";
import { ValveComponentType } from "./ValveComponentType";

export class ValveFCV extends BaseLinestringFeature<FCVConditionProps, Partial<FCVTimedProps>> {

    public get componentType(): EWAComponentType {
        return EWAComponentType.valve;
    }

    public get valveType(): ValveComponentType {
        return ValveComponentType.fcv;
    }
    //Must return number here for GPU filtering
    public get visible() {
        return this.collection.showComponents.includes(EWAComponentType.valve) ? 1 : 0;
    }

    public apply(apply: ValveFCVApply): void {
        const currentDate = this.collection.currentDate;
        const oldApply: ValveFCVApply = {
            id: this.id,
            comment: this.comment,
            lifetime: this.data.lifetime,
            conditional: { ...this.data.properties.conditional[this.collection.currentCondition!] } as FCVConditionProps,
            timed: { ...this._timedProperties.getValue(currentDate) } as Partial<FCVTimedProps>
        }

        super.apply(apply);
        EventManager.getInstance().onValveFCVApply({
            id: this.id,
            apply,
            oldApply,
            year: currentDate,
            model: this.collection
        });
    }

    public delete(): void {
        super.delete();
        EventManager.getInstance().onComponentDeleted({
            id: this.id,
            type: EWAComponentType.valve,
            valveType: ValveComponentType.fcv,
            year: this.collection.currentDate,
            model: this.collection
        });
    }

    public toComponentModel() {
        const model = {
            id: this.id,
            startnode: this.startnode,
            endnode: this.endnode,
            type: "Feature",
            bbox: this.bbox,
            geometry: this._geometry,
            properties: {
                comment: this.comment,
                timed: this._timedProperties.items(),
                conditional: this.apiConditional
            },
            component_type: ValveComponentType.fcv,
            lifetime: [this.visibleFrom, this.visibleTo]
        } as FCVModel

        return model
    }
}
