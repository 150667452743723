import { ComponentApplyEventArgs } from "../../../events/events/ComponentApplyEvent";
import i18n from "../../../i18n";
import { compare, comparisonModeToSymbol } from "../../../utils/ComparisonMode";
import UpdateTaskType from "../../misc/UpdateTaskType";
import UpdateValueTaskOptions from "../options/UpdateValueTaskOptions";
import ValueUpdateTask from "./ValueUpdateTask";

export default abstract class NumberValueUpdateTask<A> extends ValueUpdateTask<A, number>
{
  public constructor(options?: UpdateValueTaskOptions<number>) {
    super(options);
  }

  public get description(): string {
    const options = this._options as UpdateValueTaskOptions<number>;

    let description = ''

    const comparator = comparisonModeToSymbol(options.comparisonMode)
    switch (options.updateTaskType) {
      case UpdateTaskType.ExactValue:
        description = i18n.t('challenge.task.value_update.exact_value', {
          component: i18n.t(this.componentName),
          field: i18n.t(this.fieldName),
          value: options.targetValue,
          comparator
        })
        break;
      case UpdateTaskType.Relative:
        description = i18n.t('challenge.task.value_update.relative', {
          component: i18n.t(this.componentName),
          field: i18n.t(this.fieldName),
          value: options.targetValue,
          comparator
        })
        break;
      case UpdateTaskType.RelativeFactor:
        description = i18n.t('challenge.task.value_update.relative_factor', {
          component: i18n.t(this.componentName),
          field: i18n.t(this.fieldName),
          value: options.targetValue,
          comparator
        })
        break;
      case UpdateTaskType.Any:
      default:
        description = i18n.t('challenge.task.value_update.any', {
          component: i18n.t(this.componentName),
          field: i18n.t(this.fieldName)
        })
        break;
    }

    return description;
  }

  protected determineUpdate(e: ComponentApplyEventArgs<A>): boolean {
    const values = this.retrieveValues(e);

    if (values.newValue === values.oldValue) {
      return false;
    }

    let shouldUpdate = false;
    const options = this._options as UpdateValueTaskOptions<number>;

    if (options.id !== undefined && e.id !== options.id) return false;
    if (options.year !== undefined && e.year !== options.year) return false;

    switch (options.updateTaskType) {
      case UpdateTaskType.ExactValue:
        shouldUpdate = compare(options.comparisonMode, values.newValue, options.targetValue)
        break;
      case UpdateTaskType.Relative:
        if (values.newValue !== undefined && values.oldValue !== undefined && options.targetValue !== undefined) {
          const diff = values.newValue - values.oldValue
          shouldUpdate = compare(options.comparisonMode, diff, options.targetValue)
        }
        break;
      case UpdateTaskType.RelativeFactor:
        if (values.newValue !== undefined && values.oldValue !== undefined && options.targetValue !== undefined) {
          const expectedOutcome = options.targetValue * values.oldValue
          shouldUpdate = compare(options.comparisonMode, values.newValue, expectedOutcome)
        }
        break;
      case UpdateTaskType.Any:
      default:
        shouldUpdate = true;
        break;
    }
    return shouldUpdate;
  }
}
