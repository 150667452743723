import { Pipe } from "@/data/Pipe"
import { EWAModelWrapper } from "../EWAModelWrapper"
import { EWAComponentType } from "@/data/EWAComponentType"
import { PipeTimedProps } from "@/api/client"
import { ComponentMetaData, ComponentMetaDataCollection } from "./ComponentMetaData"
import parseFloatOrUndefined from "@/utils/parseFloatOrUndefined"
import parseIntOrUndefined from "@/utils/parseIntOrUndefined"
import { pipeMaterialFromString } from "@/utils/pipeMaterialUtils"

export interface PipeMetaData extends ComponentMetaData {
    material: string
    age: string
    diameter: string
}

export const pipeMetaFields = [
    'id',
    'material',
    'age',
    'diameter'
]

export function exportPipeMetaData(ewaModelWrapper: EWAModelWrapper): ComponentMetaDataCollection<PipeMetaData> {
    const model = ewaModelWrapper.model
    const features = model.getVisibleFeaturesOfType(EWAComponentType.pipe) as Pipe[]

    const data = features.map((pipe) => {
        const timedProps = pipe._timedProperties.getFirstValue() as PipeTimedProps
        return {
            id: pipe.id,
            material: timedProps.material?.toString(),
            age: timedProps.construction_year?.toString(),
            diameter: timedProps.diameter?.toString()
        } as PipeMetaData
    })

    return {
        fields: pipeMetaFields,
        data
    }
}

export function importPipeMetaData(ewaModelWrapper: EWAModelWrapper, data: PipeMetaData[]) {
    // get a map of id => meta data of incoming data
    const mappedData = new Map<string, PipeMetaData>()
    for (const item of data) {
        mappedData.set(item.id, item)
    }

    // retrieve all pipes from the model
    const model = ewaModelWrapper.model
    const modelDate = model.modelDate
    const pipes = model.getVisibleFeaturesOfType(EWAComponentType.pipe) as Pipe[]

    // update the pipes' attributes (if applicable)
    for (const pipe of pipes) {
        const incomingData = mappedData.get(pipe.id)
        if (incomingData === undefined) continue

        // update timed props
        const newTimedProps = {
            ...pipe._timedProperties.getFirstValue() as PipeTimedProps
        }

        const age = parseIntOrUndefined(incomingData.age)
        if (age !== undefined) {
            newTimedProps.construction_year = age
        }
        const diameter = parseFloatOrUndefined(incomingData.diameter)
        if (diameter !== undefined) {
            newTimedProps.diameter = diameter
        }

        if (incomingData.material !== '') {
            newTimedProps.material = pipeMaterialFromString(incomingData.material)
        }
        pipe._timedProperties.setItem(modelDate, newTimedProps);
    }
}

