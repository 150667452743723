import { EWAModel } from "../../../data/EWAModel";
import Economy from "./Economy";
import ImportExport from "./ImportExport";
import LoadSaveChallenge from "./LoadSaveChallenge";
import Simulations from "./Simulations";

interface Props {
  model?: EWAModel
}

export default function DebugPanel(props: Props) {
  const { model } = props;

  return (<>
    <LoadSaveChallenge />
    <ImportExport />
    <Economy />
    <Simulations model={model} />
  </>)
}
