import { Box } from "@mui/material";

export function ariaTabProps(index: number, prefix: string = '') {
    return {
        id: `${prefix}tab-${index}`,
        'aria-controls': `${prefix}tabpanel-${index}`
    }
}

export interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
    idPrefix?: string;
}

export default function TabPanel(props: TabPanelProps) {
    const { children, value, index, idPrefix, ...other } = props;

    const prefix = (idPrefix ?? '')

    return (
        <div
          role="tabpanel"
          hidden={value !== index}
          id={`${prefix}tabpanel-${index}`}
          aria-labelledby={`${prefix}tab-${index}`}
          {...other}
        >
          {value === index && (
            <Box sx={{ p: 3 }}>
              {children}
            </Box>
          )}
        </div>
      );
}
