import { Button, Dialog, DialogContent, DialogTitle, IconButton, TextField } from "@mui/material"
import { Close } from "@mui/icons-material"
import { useTranslation } from "react-i18next"
import { useEffect, useState } from "react"
import { commentMaxLength } from '../../utils/constants'

export interface RunSimulationDialogProps {
    open: boolean
    onClose: () => void
    onSave: (simulationName?: string, comment?: string) => void
}

export default function RunSimulationDialog(props: RunSimulationDialogProps) {
    const {
        open,
        onClose,
        onSave
    } = props

    const { t } = useTranslation()
    const [simulationName, setSimulationName] = useState<string>('')
    const [simulationNameInvalid, setSimulationNameInvalid] = useState<boolean>(false)
    const [comment, setComment] = useState<string>('')
    const [commentInvalid, setCommentInvalid] = useState<boolean>(false)


    useEffect(() => {
        setComment('')
        setSimulationName('')
        setCommentInvalid(false)
        setSimulationNameInvalid(false)
    }, [open])

    function submit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault()

        if (commentInvalid || simulationNameInvalid) return

        onSave(simulationName, comment)
        onClose()
    }

    function onCommentChange(value: string) {
        setCommentInvalid(value.length > commentMaxLength)
        setComment(value)
    }

    function onSimulationNameChange(value: string) {
        setSimulationNameInvalid(value.length > 20)
        setSimulationName(value)
    }

    return <Dialog
        open={open}
        maxWidth="sm"
        fullWidth
        onClose={() => onClose()}
    >
        <DialogTitle sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            {t('model.controls.run_simulation')}
            <IconButton aria-label="close" onClick={() => onClose()}>
                <Close />
            </IconButton>
        </DialogTitle>
        <DialogContent>
            <form onSubmit={(e) => submit(e)}>
                <TextField
                    margin="normal"
                    label={t('general.name')}
                    fullWidth
                    inputProps={{ maxLength: 20 }}
                    onChange={(e) => onSimulationNameChange(e.target.value)}
                    helperText={`${simulationName.length}/20`}
                    error={simulationNameInvalid}
                />
                <TextField
                    margin="normal"
                    label={t('general.comment')}
                    fullWidth
                    multiline
                    rows={5}
                    inputProps={{ maxLength: commentMaxLength }}
                    onChange={(e) => onCommentChange(e.target.value)}
                    helperText={`${comment.length}/${commentMaxLength}`}
                    error={commentInvalid}
                />
                <Button type="submit" disabled={commentInvalid} variant="contained" color="primary">{t('general.save')}</Button>
            </form>
        </DialogContent>
    </Dialog>
}
