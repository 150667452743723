import {
  EWASimulationDTO
} from "../../../api/client";
import AutoSizer from "react-virtualized-auto-sizer";
import PlotlyPlotWrapper from "./plot/PlotlyPlotWrapper";
import { EWAResultType } from "./EWAResultType";
import { Box } from "@mui/material";
import { useState, useEffect } from "react"
import { EWAModelWrapper } from "../EWAModelWrapper";
import PerformanceIndicatorSelect from "./PerformanceIndicatorSelect";
import OperatingConditionSelect from "./OperatingConditionSelect";
import ScenarioSelect from "./ScenarioSelect";
import { Scenario } from "./Scenario";

export interface SimulationResultsPlotProps {
  ewaSimulations: EWASimulationDTO[]
  operatingCondition: string
  modelWrapper: EWAModelWrapper
}

const initialEnabledScenarios: Scenario[] = [Scenario.favorable, Scenario.unfavorable]

export function SimulationResultsPlot(props: SimulationResultsPlotProps) {
  const {
    ewaSimulations,
    operatingCondition,
    modelWrapper
  } = props;

  const [shownCondition, setShownCondition] = useState<string>(operatingCondition)
  const [ewaResultType, setEwaResultType] = useState<EWAResultType>(EWAResultType.UnsatisfiedNodes)
  const [enabledScenarios, setEnabledScenarios] = useState<Scenario[]>([...initialEnabledScenarios])

  useEffect(() => {
    setShownCondition(operatingCondition)
  }, [operatingCondition])

  function renderControls() {
    return <Box sx={{ display: 'flex' }}>
      <OperatingConditionSelect
        modelWrapper={modelWrapper}
        value={shownCondition}
        setValue={setShownCondition}
      />
      <PerformanceIndicatorSelect
        value={ewaResultType}
        setValue={setEwaResultType}
      />
      {/* <ScenarioSelect
        value={enabledScenarios}
        setValue={setEnabledScenarios}
      /> */}
    </Box>
  }

  return <>
    {renderControls()}
    <Box sx={{ width: '100%', height: 400 }}>
      <AutoSizer>
        {({ height, width }: { height: number, width: number }) => (
          <PlotlyPlotWrapper
            height={height}
            width={width}
            ewaSimulations={ewaSimulations}
            operatingCondition={shownCondition}
            ewaResultType={ewaResultType}
            enabledScenarios={enabledScenarios}
          />
        )}
      </AutoSizer>
    </Box>
  </>
}
