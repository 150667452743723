import { EWAComponentType } from "../../../data/EWAComponentType";
import { ValveComponentType } from "../../../data/ValveComponentType";
import TaskOptions, { ITaskOptions } from "./TaskOptions";

export interface IComponentTaskOptions extends ITaskOptions {
  componentType?: EWAComponentType
  valveType?: ValveComponentType
  year?: number
}

export default class ComponentTaskOptions extends TaskOptions {
  public componentType?: EWAComponentType;
  public valveType?: ValveComponentType;
  public year: number | undefined;

  public constructor(options: IComponentTaskOptions = {}) {
    super(options);

    this.componentType = options.componentType;
    this.year = options.year;
    this.valveType = options.valveType;
  }

  public static fromPlain(plainOptions?: IComponentTaskOptions) {
    return new ComponentTaskOptions(plainOptions);
  }

  public toJSON(): IComponentTaskOptions {
    return {
      ...super.toJSON(),
      componentType: this.componentType,
      valveType: this.valveType,
      year: this.year
    }
  }
}
