import { Grid, TextField } from "@mui/material"
import React from "react"
import { withTranslation, WithTranslation } from "react-i18next"
import { LocalizableText } from "../../../LocalizableText"
import { ITaskOptions } from "../../../tasks/options/TaskOptions"

interface Props extends WithTranslation {
  ref: React.RefObject<any>
  options: ITaskOptions
  locale: string
  setIsDirty: (_: boolean) => void
  taskIndex: number
}

interface SaveData {
  name: LocalizableText
  tooltip: LocalizableText
  requiredCount: number
}

interface LocalizableState {
  name: string
  tooltip: string
}

interface State extends LocalizableState {
  requiredCount: number
}

class CommonTaskOptionsForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = this.stateFromProps(props);

    this.onNameChange = this.onNameChange.bind(this);
    this.onTooltipChange = this.onTooltipChange.bind(this);
    this.onRequiredCountChange = this.onRequiredCountChange.bind(this);
    this.getSaveData = this.getSaveData.bind(this);
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.taskIndex !== this.props.taskIndex || prevProps.options !== this.props.options) {
      this.setState(this.stateFromProps(this.props));
    }

    if (prevProps.locale !== this.props.locale) {
      this.setState(this.localizedStateFromProps(this.props))
    }
  }

  localizedStateFromProps(props: Props): LocalizableState {
    const locale = props.locale

    const result: LocalizableState = {
      name: (props.options?.name ?? {})[locale] ?? '',
      tooltip: (props.options?.tooltip ?? {})[locale] ?? '',
    }

    return result
  }

  stateFromProps(props: Props) {
    return {
      ...this.localizedStateFromProps(props),
      requiredCount: props.options.requiredCount ?? 1
    };
  }

  getSaveData(): SaveData {
    const { locale, options } = this.props

    const name: LocalizableText = { ...options.name ?? {} }
    if (this.state.name === '') {
      delete name[locale]
    } else {
      name[locale] = this.state.name
    }

    const tooltip: LocalizableText = { ...options.tooltip ?? {} }
    if (this.state.tooltip === '') {
      delete tooltip[locale]
    } else {
      tooltip[locale] = this.state.tooltip
    }

    return {
      name,
      tooltip,
      requiredCount: this.state.requiredCount
    }
  }

  onNameChange(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    this.setState({
      name: e.target.value
    });

    this.props.setIsDirty(true)
  }

  onTooltipChange(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    this.setState({
      tooltip: e.target.value
    });

    this.props.setIsDirty(true)
  }

  onRequiredCountChange(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    const value = Number.parseFloat(e.target.value);
    this.setState({
      requiredCount: isNaN(value) ? 0 : value
    })

    this.props.setIsDirty(true)
  }

  render() {
    const { t } = this.props

    return <Grid container columnSpacing={2} rowSpacing={0}>
      <Grid item sm={6} xs={12}>
        <TextField
          margin="normal"
          variant="outlined"
          fullWidth
          onChange={this.onNameChange}
          value={this.state.name}
          label={t('general.name')}
          helperText={t('challenge.editor.tasks.name_hint')}
        />
      </Grid>
      <Grid item sm={6} xs={12}>
        <TextField
          margin="normal"
          variant="outlined"
          fullWidth
          onChange={this.onRequiredCountChange}
          type="number"
          value={this.state.requiredCount}
          label={t('challenge.editor.tasks.required_count')}
          helperText={t('challenge.editor.tasks.required_count_hint')}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          margin="normal"
          variant="outlined"
          fullWidth
          onChange={this.onTooltipChange}
          value={this.state.tooltip}
          label={t('challenge.editor.tasks.tooltip')}
          helperText={t('challenge.editor.tasks.tooltip_hint')}
          multiline
          rows={5}
        />
      </Grid>
    </Grid>
  }
}

export default withTranslation('translation', { withRef: true })(CommonTaskOptionsForm)
