import { Chip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { StateType } from "../api/client";

export function getStateColor(state: StateType) : PaletteColorType {
  let color: PaletteColorType = "default"
  switch (state) {
    case StateType.COMPLETED:
      color = "success"
      break
    case StateType.FAILED:
    case StateType.CANCELLED:
      color = "error"
      break
    case StateType.PENDING:
      color = "secondary"
      break
    case StateType.SCHEDULED:
      color = "warning"
      break
    case StateType.RUNNING:
      color = "primary"
      break
  }

  return color
}

export interface StateTypeLabelProps {
  state: StateType
}

type PaletteColorType = "primary" | "secondary" | "error" | "info" | "warning" | "success" | "default"

export default function StateTypeLabel(props: StateTypeLabelProps) {
  const { state } = props
  const { t } = useTranslation()

  const color = getStateColor(state)
  const text = t(`state_type.${state}`)

  return <Chip color={color} label={text}/>
}
