import { TextField } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { setTaskOptionsDirty } from "../../../../../store/slices/challengeEditorSlice"

export interface IdInputProps {
    value?: string
    onChange: (_?: string) => void
}

export default function IdInput(props: IdInputProps) {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    function onChange(value: string) {
        props.onChange(value === '' ? undefined : value)

        dispatch(setTaskOptionsDirty(true))
    }

    return <TextField
        margin="normal"
        variant="outlined"
        fullWidth
        label="ID"
        helperText={t('challenge.editor.tasks.id_hint')}
        onChange={(e) => onChange(e.target.value)}
        value={props.value ?? ''}
    />
}
