import AutoSizer from "react-virtualized-auto-sizer";
import { useRef, useState, useEffect } from "react"
import { EWAComponentType } from "../../../data/EWAComponentType";
import { EWAModelWrapper } from "../EWAModelWrapper";
import ChallengePanel from "../../../challenges/components/ChallengePanel";
import { Table } from "./Table";
import FeatureEditDialog from "../FeatureEditDialog";
import { Box, useTheme } from "@mui/material";
import addAlphaToHexColor from "../../../utils/addAlphaToHexColor";

export interface TableViewProps {
    modelWrapper: EWAModelWrapper,
    setModelWrapper: (_: EWAModelWrapper) => void,
    currentDate: number
    setCurrentDate: (_: number) => void
    minDate: number
    maxDate: number
    currentCondition: string | undefined
    debugMode: boolean
}

export default function TableView(props: TableViewProps) {
    const {
        modelWrapper,
        setModelWrapper,
        currentCondition,
        minDate,
        maxDate,
        currentDate,
        setCurrentDate
    } = props

    const theme = useTheme()
    const mainContainerRef = useRef<any>();

    const [tableComponentType, setTableComponentType] = useState(EWAComponentType.junction);
    const [selectedFeatureIndices, setSelectedFeatureIndices] = useState<number[]>([])
    const [editOpen, setEditOpen] = useState<boolean>(false)

    // unselect all components that are no longe visible
    useEffect(() => {
        if (selectedFeatureIndices.length > 0) {
            const newSelectedFeatureIndices: number[] = []
            for (const selectedFeatureIndex of selectedFeatureIndices) {
                const visibleFrom = modelWrapper.model.features[selectedFeatureIndex].visibleFrom;
                const visibleTo = modelWrapper.model.features[selectedFeatureIndex].visibleTo;
                if (!(visibleFrom > (currentDate as number) || visibleTo < (currentDate as number))) {
                    newSelectedFeatureIndices.push(selectedFeatureIndex)
                }
            }
            setSelectedFeatureIndices(newSelectedFeatureIndices)
        }
    }, [currentDate]);

    function closeEditDialog() {
        setEditOpen(false)
        setSelectedFeatureIndices([])
    }

    function renderMainContent(height: number, width: number) {
        return <div ref={mainContainerRef}>
            <Table
                selectedFeatureIndices={selectedFeatureIndices}
                setSelectedFeatureIndices={setSelectedFeatureIndices}
                tableComponentType={tableComponentType}
                setTableComponentType={setTableComponentType}
                data={modelWrapper.model!}
                currentDate={currentDate}
                currentCondition={currentCondition}
                openEditDialog={() => setEditOpen(true)}
                width={width}
                height={height}
                setModelWrapper={setModelWrapper}
            />
        </div>
    }

    function renderChallengePanelOverlay() {
        const backgroundColor = addAlphaToHexColor(theme.palette.darkBlue.main, 0.75)
        return <Box sx={{ position: "absolute", bottom: 10, right: 10, maxHeight: 'calc(100% - 20px)', overflow: 'auto', zIndex: 200 }}>
            <ChallengePanel backgroundColor={backgroundColor} minifiable width={500} />
        </Box>
    }

    return <>
        <AutoSizer>
            {({ height, width }) => renderMainContent(height, width)}
        </AutoSizer>
        {editOpen && <FeatureEditDialog
            onClose={closeEditDialog}
            selectedFeatureIndices={selectedFeatureIndices}
            setSelectedFeatureIndices={setSelectedFeatureIndices}
            model={modelWrapper.model}
            modelWrapper={modelWrapper}
            setModelWrapper={setModelWrapper}
            minDate={minDate}
            maxDate={maxDate}
            condition={currentCondition}
            currentDate={currentDate}
            setCurrentDate={setCurrentDate}
        />}
        {renderChallengePanelOverlay()}
    </>
}
