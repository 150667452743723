import { Box } from "@mui/material"
import LandingContent from "./LandingContent"
import LandingFooter from "./LandingFooter"
import { useState, useCallback } from 'react'
import LandingAppBar from "./LandingAppBar"

export interface LandingProps {
    portalContainer?: Element
}

export default function Landing(props: LandingProps) {
    const [portalContainer, setPortalContainer] = useState<Element | undefined>(undefined)
    const portalContainerCallbackRef = useCallback((node: any) => {
        setPortalContainer(node ?? undefined)
    }, [])

    return <Box>
        <LandingAppBar portalContainerCallbackRef={portalContainerCallbackRef} />
        <Box
            display="flex"
            flexDirection="column"
            minHeight="calc(100vh - 64px)"
        >
            <LandingContent portalContainer={portalContainer} />
            <LandingFooter />
        </Box>
    </Box>
}
