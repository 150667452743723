import ChallengeManager from '../managers/ChallengeManager'
import ChallengePanelHeader from './ChallengePanelHeader'
import { Card, CardContent, Divider } from '@mui/material'
import { useSelector } from 'react-redux'
import { RootState } from '../../store/store'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import ChallengePanelContent from './ChallengePanelContent'
import ChallengePanelSubHeader from './ChallengePanelSubHeader'

export interface ChallengePanelProps {
  backgroundColor?: string
  minifiable?: boolean
  width?: number
}

export default function ChallengePanel(props: ChallengePanelProps) {
  const {
    backgroundColor,
    minifiable,
    width
  } = props

  // get challengeId just to force rerenders whenever challenge is changed via debug dialog
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const challengeId: string | undefined = useSelector((state: RootState) => state.challenge.challengeId)
  const showChallengePanel: boolean = useSelector((state: RootState) => state.challenge.showChallengePanel);
  const [minified, setMinified] = useState<boolean>(false)

  const { t } = useTranslation()

  function renderChallenge() {
    if (!showChallengePanel) return <></>

    const challenge = ChallengeManager.getInstance().challenge;

    if (challenge === undefined) {
      return <Card sx={{ backgroundColor, width }}>
        <CardContent>{t('challenge.panel.no_challenge')}</CardContent>
      </Card>
    }

    if (minified) {
      return <Card sx={{ backgroundColor, width }}>
        <ChallengePanelHeader minifiable={minifiable as boolean} minified={minified} setMinified={setMinified} challenge={challenge} />
      </Card>
    }

    return <Card sx={{ backgroundColor, width }}>
      <ChallengePanelHeader minifiable={minifiable as boolean} minified={minified} setMinified={setMinified} challenge={challenge} />
      <ChallengePanelSubHeader minified={minified} challenge={challenge} />
      <Divider />
      <ChallengePanelContent minified={minified} challenge={challenge} />
    </Card>
  }

  return <>
    {renderChallenge()}
  </>
}
