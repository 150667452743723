import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useToolboxStyles = makeStyles((theme: Theme) => {
    const backgroundColor = `${theme.palette.primary.main} !important`
    return {
        selected: {
            "&&": {
                backgroundColor
            }
        }
    }
});

export default useToolboxStyles
