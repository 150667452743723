import { EWAModel } from "../../data/EWAModel";
import EventType from "../EventType";

export interface EditDialogOpenedEventArgs {
  model: EWAModel
  selectedFeatureIndices: number[]
  year: number
}

export default class EditDialogOpenedEvent extends CustomEvent<EditDialogOpenedEventArgs> {
  public constructor( detail: EditDialogOpenedEventArgs) {
    super(EventType.EditDialogOpened, { detail })
  }
}
