import { useEffect, useState } from "react";
import { PumpConditionProps, PumpTimedProps } from "../../api/client";
import { Pump } from "../../data/Pump";
import { EWAModelWrapper } from "../model/EWAModelWrapper";
import { PumpApply } from "./PumpApply";
import { TextField, FormControl, InputAdornment, InputLabel, Select, MenuItem, FormHelperText } from "@mui/material";
import { BaseSingleLinestringForm, BaseSingleLinestringFormApply } from "./BaseSingleLineStringForm";
import { findCondition } from "../../utils/findCondition";
import { findTimed, findTimedIfDefined, findComputedTimed } from "../../utils/findTimed";
import { useTranslation } from "react-i18next";
import PipeStatusSelect from "./PipeStatusSelect";
import parseFloatOrUndefined from "../../utils/parseFloatOrUndefined";
import { useFormContext } from "./FormContext";
import validNumber from "../../utils/validNumber";

export interface SinglePumpFormProps {
    pump: Pump
}

export function SinglePumpForm(props: SinglePumpFormProps) {
    const { pump } = props;
    const context = useFormContext();

    const currentDate = context.currentDate as number
    const lifetime = pump.data.lifetime as number | number[]
    const isFirstYear = Array.isArray(lifetime) ? context.currentDate === lifetime[0] : context.currentDate === lifetime

    const currentTimed = findTimed({
        item: pump,
        get: _ => _
    }, currentDate, {}) as Partial<PumpTimedProps>
    const fallbackTimed = findComputedTimed({
        item: pump,
        get: _ => _
    }, isFirstYear ? currentDate : currentDate - 10, {}) as Partial<PumpTimedProps>

    const [power, setPower] = useState<string>(currentTimed.power?.toString() ?? '');
    const [headCurve, setHeadCurve] = useState<string | undefined>(currentTimed.head_curve);
    const [status, setStatus] = useState<string>(findCondition({
        item: pump,
        get: _ => _.status
    }, context.condition, ''));

    const { t } = useTranslation();

    useEffect(() => {
        const timed = findTimedIfDefined({
            item: pump,
            get: _ => _
        }, currentDate, {}) as Partial<PumpTimedProps>

        setPower(timed.power?.toString() ?? '')
        setHeadCurve(timed.head_curve)
        setStatus(findCondition({
            item: pump,
            get: _ => _.status
        }, context.condition, ''));
    }, [pump, context.currentDate, context.condition, context.modelWrapper?.id])

    function validate() {
        if (isFirstYear || power !== '') {
            return validNumber(parseFloatOrUndefined(power))
        }

        return true
    }

    function handleApply(a: BaseSingleLinestringFormApply) {
        const timed: Partial<PumpTimedProps> = {
            speed: currentTimed.speed,
            setting: currentTimed.setting
        }

        const applyPower = parseFloatOrUndefined(power)
        if (applyPower !== undefined) {
            timed.power = applyPower
        }

        if (headCurve !== undefined) {
            timed.head_curve = headCurve
        }

        let apply = {
            id: a.id,
            comment: a.comment,
            lifetime: a.lifetime,
            conditional: {
                status: status
            } as PumpConditionProps,
            timed: timed
        } as PumpApply
        pump.apply(apply);
        context.setModelWrapper(new EWAModelWrapper(context.modelWrapper!.model));
    }

    return (<BaseSingleLinestringForm validate={validate} handleApply={handleApply} feature={pump} timedChildren={<>
        <TextField
            type="number"
            value={power}
            onChange={(event) => setPower(event.target.value)}
            style={{ flexGrow: 1, marginBottom: "10px" }}
            label={t('model.components.pump.power')}
            InputProps={{ endAdornment: <InputAdornment position="end">kW</InputAdornment> }}
            helperText={isFirstYear ? undefined : fallbackTimed.power?.toString()}
        />
        <FormControl fullWidth>
            <InputLabel id="head-curve">{t('model.components.pump.head_curve')}</InputLabel>
            <Select
                labelId="head-curve-label"
                id="head-curve"
                value={headCurve ?? ''}
                label={t('model.components.pump.head_curve')}
                onChange={(event, newValue) => setHeadCurve(event.target.value)}
            >
                {context.modelWrapper?.model?.curves.map((curve, j) => <MenuItem
                    value={curve.id}>{curve.id}</MenuItem>)}
            </Select>
            {isFirstYear && <FormHelperText>{fallbackTimed.head_curve}</FormHelperText>}
        </FormControl>
    </>
    } conditionChildren={<PipeStatusSelect status={status} setStatus={setStatus} />}>
    </BaseSingleLinestringForm>)
}
