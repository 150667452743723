import { EWAModelDTO, StateType } from "@/api/client"
import CardList from "@/widgets/CardList"
import { Box, LinearProgress, useTheme } from "@mui/material"
import AutoSizer from "react-virtualized-auto-sizer";
import { ModelCard } from "./common/ModelCard";
import { ModelForm } from "./common/ModelForm";
import { ReactNode } from "react";

export interface ModelListProps {
    isLoading: boolean
    models: EWAModelDTO[]
    selection?: string
    setSelection: (_?: string) => void
    state?: StateType
    setState: (_?: StateType) => void
    accessibleModels: Set<string>
    challengeToModelName: Map<string, string>
    fetchModels: () => void
    actions: ReactNode
}

export default function ModelList(props: ModelListProps) {
    const {
        isLoading,
        selection,
        setSelection,
        setState,
        state,
        accessibleModels,
        challengeToModelName,
        fetchModels,
        models,
        actions
    } = props

    const theme = useTheme()

    function rowClick(item: EWAModelDTO) {
        setSelection(item.id)
        setState(item.state)
    }

    function rowIsSelected(item: EWAModelDTO) {
        return selection === item.id
    }

    return <Box display="flex" gap={2} sx={{ flexGrow: 1, marginLeft: "50px", marginRight: "50px", marginBottom: "25px" }}>
        <Box sx={{ backgroundColor: theme.palette.darkBlue.main, width: '100%', height: '100%', display: "flex", flexDirection: "column", padding: '5px' }}>
            <Box sx={{ width: '100%', minHeight: 44, alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                {actions}
            </Box>
            <div style={{ width: '100%', minHeight: 4 }}>
                {isLoading && <LinearProgress />}
            </div>
            <div style={{ width: '100%', flexGrow: 1 }}>
                <AutoSizer>
                    {({ height, width }: { height: number, width: number }) => (
                        <div style={{ width }}>
                            <CardList
                                height={height}
                                items={models}
                                rowKey="id"
                                rowRenderer={item => <ModelCard
                                    item={item as EWAModelDTO}
                                    accessibleModels={props.accessibleModels}
                                />}
                                rowClick={item => rowClick(item as EWAModelDTO)}
                                rowIsSelected={item => rowIsSelected(item as EWAModelDTO)}
                            />
                        </div>
                    )}
                </AutoSizer>
            </div>
        </Box>
        <ModelForm
            item={selection}
            state={state}
            fetchModels={fetchModels}
            setSelection={setSelection}
            accessibleModels={accessibleModels}
            challengeToModelName={challengeToModelName}
        />
    </Box>
}
