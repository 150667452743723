import { useState } from "react";
import { ValvePBV } from "../../data/ValvePBV";
import { TextField } from "@mui/material";
import { PBVTimedProps } from "../../api/client";
import { useTranslation } from "react-i18next";
import parseFloatOrUndefined from "../../utils/parseFloatOrUndefined";
import validNumber from "../../utils/validNumber";
import { useFormContext } from "./FormContext";
import { findComputedTimedMulti, findTimedIfDefinedMulti, findTimedMulti } from "../../utils/findTimedMulti";
import { multiFormComputeIfFirstYear } from "./util";
import { MultiValveForm } from "./MultiValveForm";

export interface MultiValvePBVFormProps {
    valves: ValvePBV[]
}

export function MultiValvePBVForm(props: MultiValvePBVFormProps) {
    const { valves } = props;
    const context = useFormContext();
    const currentDate = context.currentDate as number

    const [isFirstYear, setIsFirstYear] = useState<boolean>(multiFormComputeIfFirstYear(valves, currentDate))
    const [pressureDrop, setPressureDrop] = useState<string>(findTimedMulti({
        items: valves,
        get: _ => _.pressure_drop
    }, context.currentDate, undefined)?.toString() ?? '');
    const [fallbackPressureDrop, setFallbackPressureDrop] = useState<number | undefined>(findComputedTimedMulti({
        items: valves,
        get: _ => _.pressure_drop
    }, currentDate, undefined))
    const { t } = useTranslation();

    function updateTimedState() {
        setPressureDrop(findTimedIfDefinedMulti({
            items: valves,
            get: _ => _.pressure_drop
        }, currentDate, undefined)?.toString() ?? '')
        setFallbackPressureDrop(findComputedTimedMulti({
            items: valves,
            get: _ => _.pressure_drop
        }, currentDate, undefined))
    }

    function validate() {
        if (isFirstYear || pressureDrop !== '') {
            const parsedPressureDrop = parseFloatOrUndefined(pressureDrop)

            if (!validNumber(parsedPressureDrop) || parsedPressureDrop! < 0) {
                return false
            }
        }

        return true
    }

    function computeTimedApply(): Partial<PBVTimedProps> {
        const timed: Partial<PBVTimedProps> = {}

        const applyPressureDrop = parseFloatOrUndefined(pressureDrop)
        if (applyPressureDrop !== undefined) {
            timed.pressure_drop = applyPressureDrop
        }

        return timed
    }

    return <MultiValveForm
        valves={valves}
        isFirstYear={isFirstYear}
        setIsFirstYear={setIsFirstYear}
        validate={validate}
        computeTimedApply={computeTimedApply}
        updateTimedState={updateTimedState}
        timedChildren={<>
            <TextField
                required
                type="number"
                value={(pressureDrop)}
                onChange={(event) => setPressureDrop((event.target.value))}
                style={{ flexGrow: 1, marginBottom: "10px" }}
                label={t('model.components.valve.pbv.pressure_drop')}
                helperText={isFirstYear ? undefined : fallbackPressureDrop?.toString()}
            />
        </>}></MultiValveForm>
}
