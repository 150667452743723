import { ListItem, ListItemIcon, ListItemText, Tooltip, Typography } from '@mui/material'
import React from 'react'
import ChallengeManager from '../managers/ChallengeManager'
import Task from '../tasks/base/Task'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import InfoIcon from '@mui/icons-material/Info';
import TaskItemOptionalFied from './TaskItemOptionalField';
import TaskItemDescription from './TaskItemDescription';

interface Props {
  task: Task
  hideDivider?: boolean
}

interface State {
}

class TaskItem extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
    }
  }

  componentDidMount() {
    // register reference in challenge manager
    ChallengeManager.getInstance().setTaskViewReference(this.props.task, this);
  }

  componentDidUpdate(prevProps: Props) {
    // component remains, but different task as prop
    if (prevProps.task !== this.props.task) {
      ChallengeManager.getInstance().unsetTaskViewReference(prevProps.task)
      ChallengeManager.getInstance().setTaskViewReference(this.props.task, this);
    }
  }

  componentWillUnmount() {
    ChallengeManager.getInstance().unsetTaskViewReference(this.props.task);
  }

  renderOptional(text: string | undefined, prefix: string, translatePrefix: boolean = true, valueTranslationPrefix?: string) {
    if (text === undefined) return

    return <TaskItemOptionalFied text={text} prefix={prefix} translatePrefix={translatePrefix} valueTranslationPrefix={valueTranslationPrefix} />
  }

  render() {
    const task = this.props.task;

    const icon = task.completed ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />
    const hasTooltip = task.tooltip !== '';
    const requiredCount = task.requiredCount
    const currentCount = task.currentCount
    const content = <ListItem divider={!this.props.hideDivider}>
      <ListItemText>
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          {hasTooltip && <InfoIcon />}
          <div style={{ flexGrow: 1, flexBasis: '100%' }}>
            {task.name && <Typography variant='subtitle2'>{task.name}</Typography>}
            <TaskItemDescription task={task} />
            {this.renderOptional(task.optionId, 'ID', false)}
            {this.renderOptional(task.optionYear, 'general.year')}
            {this.renderOptional(task.optionType, 'general.type')}
            {this.renderOptional(task.optionScenario, 'general.scenario')}
            {this.renderOptional(task.optionOperatingConditionType, 'operating_condition_type.label', true, 'operating_condition_type')}
          </div>
          {requiredCount > 1 && <div>
            {currentCount}/{requiredCount}
          </div>}
        </div>
      </ListItemText>
      <ListItemIcon sx={{justifyContent: 'end'}}>{icon}</ListItemIcon>
    </ListItem>

    if (hasTooltip) {
      return <Tooltip arrow title={task.tooltip} placement="left">{content}</Tooltip>
    }

    return content
  }
}

export default TaskItem
