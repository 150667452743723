import { ComponentApplyEventArgs } from "../../events/events/ComponentApplyEvent";
import EventManager from "../../events/EventManager";
import EventType from "../../events/EventType";
import { TankApply } from "../../views/forms/TankApply";
import NumberValueUpdateTask from "./base/NumberValueUpdateTask";
import { ValuesPair } from "./base/ValueUpdateTask";
import UpdateValueTaskOptions, { IUpdateValueTaskOptions } from "./options/UpdateValueTaskOptions";

export default class TankUpdateDiameterTask extends NumberValueUpdateTask<TankApply> {
  protected _type: string = 'TankUpdateDiameterTask';
  protected componentName: string = "ewa_component_type.Tank_one";
  protected fieldName: string = "model.components.tank.diameter";

  public static fromPlainTaskOptions(plainOptions: IUpdateValueTaskOptions<number>) {
    const options = new UpdateValueTaskOptions<number>(plainOptions);
    return new TankUpdateDiameterTask(options)
  }

  public activate() {
    super.activate();
    EventManager.getInstance().attachListener(EventType.TankApply, this.onValueUpdate);
  }

  public deactivate() {
    super.deactivate();
    EventManager.getInstance().detachListener(EventType.TankApply, this.onValueUpdate);
  }

  protected retrieveValues(args: ComponentApplyEventArgs<TankApply>): ValuesPair<number> {
    return {
      newValue: args.apply.timed.diameter,
      oldValue: args.oldApply.timed.diameter
    };
  }
}
