import EventManager from "../../events/EventManager";
import EventType from "../../events/EventType";
import Task from "./base/Task";
import ModelSavedEvent from "../../events/events/ModelSavedEvent";
import TaskOptions, { ITaskOptions } from "./options/TaskOptions";
import i18n from "../../i18n";

export default class SaveModelTask extends Task {
  protected _type: string = 'SaveModelTask';
  public constructor(options?: TaskOptions) {
    super(options);

    this.onModelSaved = this.onModelSaved.bind(this);
  }

  public static fromPlainTaskOptions(plainOptions: ITaskOptions) {
    const options = new TaskOptions(plainOptions);
    return new SaveModelTask(options);
  }

  protected onModelSaved(e: ModelSavedEvent) {
    this.currentCount++;
    this.checkCompletion();
    this.triggerTaskViewUpdate();
  }

  public get description(): string {
    return i18n.t('challenge.task.save_model');
  }

  public activate() {
    super.activate();
    EventManager.getInstance().attachListener(EventType.ModelSaved, this.onModelSaved);
  }

  public deactivate() {
    super.deactivate();
    EventManager.getInstance().detachListener(EventType.ModelSaved, this.onModelSaved);
  }

}
