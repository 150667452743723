import { ComponentApplyEventArgs } from "../../events/events/ComponentApplyEvent";
import EventManager from "../../events/EventManager";
import EventType from "../../events/EventType";
import { PumpApply } from "../../views/forms/PumpApply";
import LifetimeValueUpdateTask from "./base/LifetimeValueUpdateTask";
import { ValuesPair } from "./base/ValueUpdateTask";
import UpdateLifetimeTaskOptions from "./options/UpdateLifetimeTaskOptions";
import { IUpdateValueTaskOptions } from "./options/UpdateValueTaskOptions";

export default class PumpUpdateLifetimeTask extends LifetimeValueUpdateTask<PumpApply> {
  protected _type: string = 'PumpUpdateLifetimeTask';
  protected componentName: string = "ewa_component_type.Pump_one";
  protected fieldName: string = "model.components.lifetime";

  public static fromPlainTaskOptions(plainOptions: IUpdateValueTaskOptions<number|number[]>) {
    const options = new UpdateLifetimeTaskOptions(plainOptions);
    return new PumpUpdateLifetimeTask(options)
  }

  public activate() {
    super.activate();
    EventManager.getInstance().attachListener(EventType.PumpApply, this.onValueUpdate);
  }

  public deactivate() {
    super.deactivate();
    EventManager.getInstance().detachListener(EventType.PumpApply, this.onValueUpdate);
  }

  protected retrieveValues(args: ComponentApplyEventArgs<PumpApply>): ValuesPair<number|number[]> {
    return {
      newValue: args.apply.lifetime,
      oldValue: args.oldApply.lifetime
    }
  }
}
