import { EWAModel } from "../../data/EWAModel";
import EventType from "../EventType";

export interface OperatingConditionChangedEventArgs {
  model: EWAModel
  oldValue?: string
  newValue?: string
}

export default class OperatingConditionChangedEvent extends CustomEvent<OperatingConditionChangedEventArgs> {
  public constructor(detail: OperatingConditionChangedEventArgs) {
    super(EventType.OperatingConditionChanged, { detail })
  }
}
