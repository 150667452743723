import { Box, Button, ButtonGroup } from "@mui/material"
import ModelList from "./ModelList"
import { FilteredModelsProps } from "./Models"
import { EWAUploadDialog } from "./common/EWAUploadDialog"
import { ModelUploadDialog } from "./common/ModelUploadDialog"
import { NewModelDialog } from "./common/NewModelDialog"
import { CloudUpload, FileUpload, NoteAdd } from "@mui/icons-material"
import { useTranslation } from "react-i18next"
import { useState } from 'react'
import { EWAModelType } from "@/api/client"

export default function UserModels(props: FilteredModelsProps) {
    const {
        isLoading,
        models,
        selection,
        setSelection,
        state,
        setState,
        accessibleModels,
        challengeToModelName,
        fetchModels
    } = props
    const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
    const [uploadEWADialogOpen, setUploadEWADialogOpen] = useState(false);
    const [newDialogOpen, setNewDialogOpen] = useState(false);
    const { t } = useTranslation();

    return <>
        <ModelUploadDialog
            open={uploadDialogOpen}
            setOpen={setUploadDialogOpen}
            setSelection={setSelection}
            fetchModels={fetchModels}
            type={EWAModelType.USER}
        />
        <NewModelDialog
            open={newDialogOpen}
            setOpen={setNewDialogOpen}
            setSelection={setSelection}
            fetchModels={fetchModels}
            type={EWAModelType.USER}
        />
        <EWAUploadDialog
            open={uploadEWADialogOpen}
            setOpen={setUploadEWADialogOpen}
            setSelection={setSelection}
            fetchModels={fetchModels}
            type={EWAModelType.USER}
        />
        <ModelList
            isLoading={isLoading}
            models={models}
            selection={selection}
            setSelection={setSelection}
            state={state}
            setState={setState}
            accessibleModels={accessibleModels}
            challengeToModelName={challengeToModelName}
            fetchModels={fetchModels}
            actions={<Box sx={{ width: '100%', minHeight: 44, alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                <ButtonGroup>
                    <Button
                        title={t('home.model_chooser.btns.create') as string}
                        onClick={() => setNewDialogOpen(true)}
                        variant="contained"
                    >
                        <NoteAdd />
                    </Button>
                    <Button
                        title={t('home.model_chooser.btns.upload_ewa') as string}
                        onClick={() => setUploadEWADialogOpen(true)}
                        variant="contained"
                    >
                        <FileUpload />
                    </Button>
                    <Button
                        title={t('home.model_chooser.btns.upload_epanet') as string}
                        onClick={() => setUploadDialogOpen(true)}
                        variant="contained"
                    >
                        <CloudUpload />
                    </Button>
                </ButtonGroup>
            </Box>}
        />
    </>
}
