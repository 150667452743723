import { useEffect, useState } from "react";
import {
    Log,
    ModellingService,
    EWASimulationDTO
} from "../../../api/client";
import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    LinearProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { Close } from "@mui/icons-material";

export interface SimulationLogsDialogProps {
    ewaSimulation: EWASimulationDTO
    onClose: () => void
}

export default function SimulationLogsDialog(props: SimulationLogsDialogProps) {
    const {
        ewaSimulation,
        onClose
    } = props;
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState<boolean>(false)
    const [logs, setLogs] = useState<Log[]>([]);

    useEffect(() => {
        loadLogs()
    }, [ewaSimulation.id])


    async function loadLogs() {
        setLoading(true)

        try {
            const logs = await ModellingService.simulationLogsV1SimulationsIdLogsGet(ewaSimulation.id)
            setLogs(logs)
        } catch (e: any) {
            if (e.message.indexOf("Not found") === -1) {
                enqueueSnackbar(e.message, {
                    variant: 'error'
                })
            }
            setLogs([])
        }

        setLoading(false)
    }

    return <Dialog
        open
        fullWidth
        maxWidth="lg"
        onClose={() => onClose()}
    >
        <DialogTitle sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <div>{t('simulation_view.simulation_logs')}</div>
            <IconButton aria-label="close" onClick={() => onClose()}>
                <Close />
            </IconButton>
        </DialogTitle>
        <DialogContent>
            <Box sx={{ height: 4 }}>
                {loading && <LinearProgress></LinearProgress>}
            </Box>
            <TableContainer>
                <Table stickyHeader sx={{ width: "100%", height: "100%" }}
                    aria-label={t('simulation_view.simulation_logs') as string}>
                    <TableHead>
                        <TableRow>
                            <TableCell>{t('general.level')}</TableCell>
                            <TableCell>{t('general.date')}</TableCell>
                            <TableCell>{t('general.message')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {logs !== undefined && logs.map((row) => (
                            <TableRow
                                key={row.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell align="left">{row.level}</TableCell>
                                <TableCell align="left">{row.timestamp}</TableCell>
                                <TableCell align="left">{row.message}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </DialogContent>
    </Dialog>
}
