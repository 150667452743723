import EventType from "../EventType";
import ComponentEventArgs from "../args/ComponentEventArgs";

export interface ComponentApplyEventArgs<T> extends ComponentEventArgs {
  apply: T
  oldApply: T
}

export default class ComponentApplyEvent<T> extends CustomEvent<ComponentApplyEventArgs<T>> {
  public constructor(eventType: EventType, detail: ComponentApplyEventArgs<T>) {
    super(eventType, { detail })
  }
}
