import { useState, useRef, useMemo } from "react";
import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton, Tab } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { ITaskGroupOptions } from "../../../TaskGroupOptions";
import TaskGroupDescriptionEdtor, { TaskGroupDescriptionEdtorState } from "./TaskGroupDescriptionEditor";
import { useTranslation } from "react-i18next";
import { LocalizableText } from "../../../LocalizableText";
import LocaleMenu from "../LocaleMenu";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import ConfirmationDialog from "../../../../widgets/ConfirmationDialog";

interface TaskGroupDescriptionDialogProps {
  taskGroupOptions: ITaskGroupOptions
  onClose: () => void
  onOptionsChange: (options: ITaskGroupOptions) => void
}

export default function TaskGroupDescriptionDialog(props: TaskGroupDescriptionDialogProps) {
  const {
    taskGroupOptions,
    onClose,
    onOptionsChange
  } = props

  const editorRef = useRef<any>()
  const locale = useSelector((state: RootState) => state.challengeEditor.locale)
  const { t } = useTranslation()

  const [descriptions, setDescriptions] = useState<LocalizableText[]>(taskGroupOptions.description ?? [])
  const [descriptionIndex, setDescriptionIndex] = useState<number>(0)
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState<boolean>(false)

  const activeDescription = useMemo(() => {
    const page = descriptions[descriptionIndex]
    return page?.[locale] ?? ''
  }, [descriptions, descriptionIndex, locale])

  function handleClose() {
    onClose()
  }

  function onPageTabChange(descriptionIndex: number) {
    setDescriptionIndex(descriptionIndex)
  }

  function computeNewDescriptions(description: string) {
    const newDescriptions = [...descriptions]
    newDescriptions[descriptionIndex] = {
      ...newDescriptions[descriptionIndex],
      [locale]: description
    }

    return newDescriptions
  }

  function saveDescription(description: string) {
    setDescriptions(computeNewDescriptions(description))
  }

  function handleSave() {
    // check editor state to save current page as well
    const editorState = editorRef.current.state as TaskGroupDescriptionEdtorState
    const description = editorState.changed ? computeNewDescriptions(editorState.description) : descriptions

    const newTaskGroupOptions = {
      ...taskGroupOptions,
      description
    }

    onOptionsChange(newTaskGroupOptions)
    handleClose()
  }

  function addPage() {
    let oldDescriptions = descriptions
    if (editorRef.current) {
      const editorState = editorRef.current.state as TaskGroupDescriptionEdtorState
      oldDescriptions = computeNewDescriptions(editorState.description)
    }

    const newDescriptions = [
      // make sure to save any pending changes
      ...oldDescriptions,
      { [locale]: "" }
    ]

    setDescriptions(newDescriptions)
    setDescriptionIndex(newDescriptions.length - 1)
  }

  function deletePage() {
    const newDescriptions = [...descriptions]

    newDescriptions.splice(descriptionIndex, 1)

    const newDescriptionIndex = descriptionIndex === 0 ? 0 : descriptionIndex -1

    setDescriptions([...newDescriptions])
    setDescriptionIndex(newDescriptionIndex)
  }

  const descriptionsCount = descriptions.length;
  const pageTabs: JSX.Element[] = [];

  if (descriptionsCount > 0) {
    for (let i = 0; i < descriptionsCount; i++) {
      pageTabs.push(<Tab key={i} label={i + 1} value={i} />)
    }
  }

  return <Dialog
    open={true}
    fullScreen
  >
    <DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          top: 8,
          right: 8
        }}
      >
        <CloseIcon />
      </IconButton>
    </DialogTitle>
    <DialogContent>
      <Box sx={{ display: 'flex', gap: 1 }}>
        <Button variant="contained" startIcon={<AddIcon />} onClick={addPage}>{t('challenge.editor.task_group_options.add_page')}</Button>
        <Button disabled={descriptionsCount === 0} variant="contained" startIcon={<DeleteIcon />} onClick={() => setConfirmDeleteOpen(true)} color="error">{t('challenge.editor.task_group_options.delete_page')}</Button>
        <Button variant="contained" startIcon={<SaveIcon />} onClick={handleSave} color="success">{t('challenge.editor.task_group_options.save_all')}</Button>
        <LocaleMenu />
      </Box>
      {confirmDeleteOpen && <ConfirmationDialog
        onClose={() => setConfirmDeleteOpen(false)}
        onConfirm={() => deletePage()}
        text={t('challenge.editor.task_group_options.confirm_delete_page')}
      ></ConfirmationDialog>}
      {descriptionsCount > 0 && <>
        <TaskGroupDescriptionEdtor
          ref={editorRef}
          locale={locale}
          saveDescription={saveDescription}
          description={activeDescription}
          descriptionIndex={descriptionIndex}
          tabs={pageTabs}
          tabIndex={descriptionIndex}
          onTabChange={onPageTabChange}
        />
      </>}
    </DialogContent>
  </Dialog>
}
