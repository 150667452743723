import Challenge from "../Challenge";
import GeneralComponentEvents from "./debug_challenges/GeneralComponentEvents";
import PIUnsatisfiedNodesRelativeCounts from "./debug_challenges/PIUnsatisfiedNodesRelativeCounts";
import PIUnsatisfiedNodesCounts from "./debug_challenges/PIUnsatisfiedNodesCounts";
import PIUnsatisfiedNodesDetails from "./debug_challenges/PIUnsatisfiedNodesDetails";
import PIResilienceIndex from "./debug_challenges/PIResilienceIndex";
import PIAveragePipeAge from "./debug_challenges/PIAveragePipeAge";
import ChangeTabTasks from "./debug_challenges/ChangeTabTasks";
import RunSimulationSaveModelTasks from "./debug_challenges/RunSimulationSaveModelTasks";
import ShowResultsTasks from "./debug_challenges/ShowResultsTasks";
import CompleteChallengeTest from "./debug_challenges/CompleteChallengeTest";
import OperatingConditionTasks from "./debug_challenges/OperatingConditionTasks";
import AllCompTypes from "./debug_challenges/AllCompTypes";
import JunctionUpdates from "./debug_challenges/JunctionUpdates";
import TankUpdates from "./debug_challenges/TankUpdates";
import ReservoirUpdates from "./debug_challenges/ReservoirUpdates";
import PipeUpdates from "./debug_challenges/PipeUpdates";
import PumpUpdates from "./debug_challenges/PumpUpdates";
import WithEconomy from "./debug_challenges/WithEconomy";
import UiTest from "./debug_challenges/UiTest";
import ChangeYear from "./debug_challenges/ChangeYear";
import AllValveTypes from "./debug_challenges/AllValveTypes";
import FCVUpdates from "./debug_challenges/FCVUpdates";
import GPVUpdates from "./debug_challenges/GPVUpdates";
import PBVUpdates from "./debug_challenges/PBVUpdates";
import PRVUpdates from "./debug_challenges/PRVUpdates";
import PSVUpdates from "./debug_challenges/PSVUpdates";
import TCVUpdates from "./debug_challenges/TCVUpdates";
import OpenEditDialogTasks from "./debug_challenges/OpenEditDialogTasks";
import OpenSimulationResultsDialogTasks from "./debug_challenges/OpenSimulationResultsDialogTasks";
import ChangeOrthoLayerTasks from "./debug_challenges/ChangeOrthoLayerTasks";
import ChangeComponentVisibility from "./debug_challenges/ChangeComponentVisibility";
import SpringUpdates from "./debug_challenges/SpringUpdates";
import WellUpdates from "./debug_challenges/WellUpdates";
import ComparisonModeNumberUpdateTasks from "./debug_challenges/ComparisonModeNumberUpdateTasks";

export type ChallengeFactory = {
  fn: () => Challenge,
  text: string
}

export default class DebugChallengeManager {
  //#region singleton stuff
  private static instance: DebugChallengeManager;
  public static getInstance(): DebugChallengeManager {
    if (!DebugChallengeManager.instance) {
      DebugChallengeManager.instance = new DebugChallengeManager();
    }

    return DebugChallengeManager.instance;
  }
  //#endregion

  public challenges: ChallengeFactory[] = [
    { fn: GeneralComponentEvents.bind(this), text: 'General Component Events' },
    { fn: AllCompTypes.bind(this), text: 'All Component Types' },
    { fn: JunctionUpdates.bind(this), text: 'Junction Updates' },
    { fn: SpringUpdates.bind(this), text: 'Spring Updates'},
    { fn: WellUpdates.bind(this), text: 'Well Updates'},
    { fn: TankUpdates.bind(this), text: 'Tank Updates' },
    { fn: ReservoirUpdates.bind(this), text: 'Reservoir Updates' },
    { fn: PipeUpdates.bind(this), text: 'Pipe Updates' },
    { fn: PumpUpdates.bind(this), text: 'Pump Updates' },
    { fn: WithEconomy.bind(this), text: 'With Economy' },
    { fn: UiTest.bind(this), text: 'UI Test' },
    { fn: ChangeYear.bind(this), text: 'Change Year' },
    { fn: AllValveTypes.bind(this), text: 'Create/Delete all valve types' },
    { fn: FCVUpdates.bind(this), text: 'FCV Update' },
    { fn: GPVUpdates.bind(this), text: 'GPV Update' },
    { fn: PBVUpdates.bind(this), text: 'PBV Update' },
    { fn: PRVUpdates.bind(this), text: 'PRV Update' },
    { fn: PSVUpdates.bind(this), text: 'PSV Update' },
    { fn: TCVUpdates.bind(this), text: 'TCV Update' },
    { fn: OperatingConditionTasks.bind(this), text: 'Operating Condition Events' },
    { fn: CompleteChallengeTest.bind(this), text: 'Complete Challenge Test' },
    { fn: ShowResultsTasks.bind(this), text: 'Show Results tasks' },
    { fn: RunSimulationSaveModelTasks.bind(this), text: 'Run sim; save model' },
    { fn: ChangeTabTasks.bind(this), text: 'change tab tasks' },
    { fn: ChangeOrthoLayerTasks.bind(this), text: 'change ortho layer tasks' },
    { fn: PIUnsatisfiedNodesCounts.bind(this), text: 'unsatisfied nodes: count tasks' },
    { fn: PIUnsatisfiedNodesRelativeCounts.bind(this), text: 'unsatisfied nodes: relative count tasks' },
    { fn: PIUnsatisfiedNodesDetails.bind(this), text: 'unsatisfied nodes: detail tasks' },
    { fn: PIResilienceIndex.bind(this), text: 'resilience index tasks' },
    { fn: PIAveragePipeAge.bind(this), text: 'average pipe age tasks'},
    { fn: OpenEditDialogTasks.bind(this), text: 'open edit dialog tasks' },
    { fn: OpenSimulationResultsDialogTasks.bind(this), text: 'open simulation results dialog tasks' },
    { fn: ChangeComponentVisibility.bind(this), text: 'change component visibility' },
    { fn: ComparisonModeNumberUpdateTasks.bind(this), text: 'Comparison mode update tasks: number' }
  ];

  public get challengeCount(): number {
    return this.challenges.length;
  }



















}
