import { FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { withTranslation } from "react-i18next";
import UpdateTaskType from "../../../misc/UpdateTaskType";
import { tabToText } from "../../../tasks/ChangeTabTask";
import { IChangeTabTaskOptions } from "../../../tasks/options/ChangeTabTaskOptions";
import UpdateTaskTypeSelect from "./common_inputs/UpdateTaskTypeSelect";
import TaskOptionsExtrasForm, { TaskSubformPropsAlias } from "./TaskOptionsExtrasForm";

interface State {
  tab: number
  updateTaskType: UpdateTaskType
}

class ChangeTabTaskOptionsForm extends TaskOptionsExtrasForm<State, IChangeTabTaskOptions> {
  constructor(props: TaskSubformPropsAlias<IChangeTabTaskOptions>) {
    super(props);

    this.onTabChange = this.onTabChange.bind(this);

    this.renderTabField = this.renderTabField.bind(this);
    this.renderUpdateTypeField = this.renderUpdateTypeField.bind(this);
  }


  stateFromProps(props: TaskSubformPropsAlias<IChangeTabTaskOptions>): State {
    return {
      tab: props.options.tab ?? 0,
      updateTaskType: props.options.updateTaskType ?? UpdateTaskType.Any
    };
  }

  public extractOptions() {
    return {
      tab: this.state.tab ?? 0,
      updateTaskType: this.state.updateTaskType
    }
  }

  renderUpdateTypeField() {
    return <UpdateTaskTypeSelect
      value={this.state.updateTaskType}
      onValueChange={(_: UpdateTaskType) => this.setState({ updateTaskType: _ })}
      noRelative
    />
  }

  onTabChange(e: SelectChangeEvent<string>) {
    let tab = Number.parseInt(e.target.value)
    if (isNaN(tab)) tab = 0
    this.setState({
      tab
    });

    this.props.setIsDirty(true)
  }

  renderTabField() {
    const items: any[] = [];
    const currentValue = this.state.tab === undefined ? '' : this.state.tab.toString();

    for (let i = 0; i < 3; i++) {
      const text = tabToText(i);
      items.push(<MenuItem key={i} value={i}>{text}</MenuItem>);
    }

    return <FormControl fullWidth margin="normal" variant="outlined">
      <InputLabel htmlFor="tab">{this.props.t('challenge.editor.tasks.change_tab.tab')}</InputLabel>
      <Select id="tab" onChange={this.onTabChange} value={currentValue} label="Tab">
        {items}
      </Select>
      <FormHelperText>{this.props.t('challenge.editor.tasks.change_tab.tab_hint')}</FormHelperText>
    </FormControl>;
  }


  render() {
    return <Grid container columnSpacing={2} rowSpacing={0}>
      <Grid item sm={6} xs={12}>{this.renderUpdateTypeField()}</Grid>
      <Grid item sm={6} xs={12}>{this.renderTabField()}</Grid>
    </Grid>;
  }
}

export default withTranslation('translation', { withRef: true })(ChangeTabTaskOptionsForm)
