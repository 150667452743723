
import { BaseFeature } from "../../data/BaseFeature"
import { EWAComponentType } from "../../data/EWAComponentType";
import TankImage from "../../assets/Tank_256.png";
import ReservoirImage from "../../assets/Reservoir_256.png";
import PipeImage from "../../assets/Pipe_256.png";
import PumpImage from "../../assets/Pump_256.png";
import ValveImage from "../../assets/Valve_256.png";
import JunctionImage from "../../assets/Junction_256.png";
import SpringImage from "../../assets/Spring_256.png";
import WellImage from "../../assets/Well_256.png";

export const componentTypeToImage: Record<EWAComponentType, string> = {
    Junction: JunctionImage,
    Spring: SpringImage,
    Well: WellImage,
    Reservoir: ReservoirImage,
    Tank: TankImage,
    Pump: PumpImage,
    Pipe: PipeImage,
    Valve: ValveImage
}

export function singleFormComputeIfFirstYear (item: BaseFeature<any,any,any>, currentDate: number) {
  const lifetime = item.data.lifetime as number | number[]
  return Array.isArray(lifetime) ? currentDate === lifetime[0] : currentDate === lifetime
}

export function multiFormComputeIfFirstYear(items: BaseFeature<any,any,any>[], currentDate: number): boolean {
    for (const item of items) {
        const lifetime = item.data.lifetime as number | number[]
        const isFirstYear = Array.isArray(lifetime) ? currentDate === lifetime[0] : currentDate === lifetime

        if (!isFirstYear) {
            return false
        }
    }

    return true
}
