import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton, LinearProgress } from "@mui/material"
import { EWAModelWrapper } from "../EWAModelWrapper"
import { Close, Download, FileUpload } from "@mui/icons-material"
import { useTranslation } from "react-i18next"
import { useState, createRef } from "react"
import { EWAComponentType } from "@/data/EWAComponentType"
import ComponentTypeSelect from "@/widgets/ComponentTypeSelect"
import { exportComponentMetaData, importComponentMetaData } from "./convertComponentMetaData"
import { saveAs } from 'file-saver';

const supportedComponents = [
    EWAComponentType.pipe
]

export interface MetaDataFormProps {
    loading: boolean
    setLoading: (_: boolean) => void
    modelWrapper: EWAModelWrapper
}

export interface ModelMetaDataDialogProps {
    onClose: () => void
    modelWrapper: EWAModelWrapper
}

export default function ModelMetaDataDialog(props: ModelMetaDataDialogProps) {
    const {
        onClose,
        modelWrapper
    } = props

    const { t } = useTranslation()
    const [loading, setLoading] = useState<boolean>(false)
    const [componentType, setComponentType] = useState<EWAComponentType>(EWAComponentType.pipe)

    const fileInputRef = createRef<HTMLInputElement>()

    function onFileChange(e: React.ChangeEvent<HTMLInputElement>) {
        if (!e.target.files?.length) return;

        const reader = new FileReader();
        reader.onload = (event) => {
            setLoading(true)

            const content: string | ArrayBuffer = event.target?.result ?? '';

            let data = ''
            if (content instanceof ArrayBuffer) {
                const decoder = new TextDecoder('utf-8');
                data = decoder.decode(content)
            } else {
                data = content
            }

            importComponentMetaData(modelWrapper, componentType, data, () => {
                setLoading(false)
            })
        }

        reader.readAsText(e.target.files[0])
    }

    function onDownload() {
        setLoading(true)
        const csv = exportComponentMetaData(modelWrapper, componentType)

        const blob = new Blob([csv], { type: 'text/plain;charset=utf-8' })
        saveAs(blob, `${modelWrapper.model.meta?.id}_${componentType}_meta.csv`)
        setLoading(false)
    }

    return <Dialog open maxWidth="md" fullWidth onClose={() => onClose()}>
        <DialogTitle sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            {t('model.meta_data_dialog.title')}
            <IconButton aria-label="close" onClick={() => onClose()}>
                <Close />
            </IconButton>
        </DialogTitle>
        <DialogContent>
            {loading && <LinearProgress variant="indeterminate" />}
            <p>{t('model.meta_data_dialog.hint')}</p>
            <ComponentTypeSelect
                componentType={componentType}
                onChangeComponentType={_ => setComponentType(_ ?? EWAComponentType.pipe)}
                availableComponents={supportedComponents}
                disabled={true}
            />
            <Box display="flex" sx={{ gap: 1 }}>
                <Button startIcon={<Download />} variant="contained" disabled={loading} onClick={() => onDownload()}>{t('general.download')}</Button>
                <Button variant="contained" component="label" startIcon={<FileUpload />}>
                    {t('general.upload')} <input
                        ref={fileInputRef}
                        hidden
                        accept=".csv,.meta"
                        type="file"
                        onChange={onFileChange}
                    />
                </Button>
            </Box>
        </DialogContent>
    </Dialog>
}
