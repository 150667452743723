import { EWASimulationDTO } from "../../../api/client";
import ChallengePanel from "../../../challenges/components/ChallengePanel";
import { SimulationTable } from "../simulation/SimulationTable";
import { useLocation } from "react-router";
import { EWAModelWrapper } from "../EWAModelWrapper";
import { Box, useTheme } from "@mui/material";
import addAlphaToHexColor from "../../../utils/addAlphaToHexColor";

export interface SimulationViewProps {
    debugMode: boolean
    simulating: boolean
    modelWrapper: EWAModelWrapper
    lastSimulation: EWASimulationDTO|undefined
    setLastSimulation: (_?: EWASimulationDTO) => void
    loadModelData: (modelId: string, versionId?: string) => Promise<void>
}

export default function SimulationView(props: SimulationViewProps) {
    const {
        debugMode,
        simulating,
        modelWrapper,
        lastSimulation,
        setLastSimulation,
        loadModelData
    } = props

    const theme = useTheme()
    const location = useLocation();

    function renderMainContent() {
        return <div style={{ display: "flex", height: '100%', width: '100%' }}>
            <SimulationTable
                modelId={location.pathname.replace("/tool/model/", "")}
                simulating={simulating}
                debugMode={debugMode}
                modelWrapper={modelWrapper}
                lastSimulation={lastSimulation}
                setLastSimulation={setLastSimulation}
                loadModelData={loadModelData}
            />
        </div>
    }

    function renderChallengePanelOverlay() {
        const backgroundColor = addAlphaToHexColor(theme.palette.darkBlue.main, 0.75)
        return <Box sx={{ position: "absolute", bottom: 10, right: 10, maxHeight: 'calc(100% - 20px)', overflow: 'auto' }}>
            <ChallengePanel backgroundColor={backgroundColor} minifiable width={500} />
        </Box>
    }

    return <>
        {renderMainContent()}
        {renderChallengePanelOverlay()}
    </>
}
