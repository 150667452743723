import { useTranslation } from "react-i18next";
import { ClaimsUser } from "../../api/auth";
import { useStyles } from "./Home";

export interface WelcomeTextProps {
  user: ClaimsUser
}

export function WelcomeText(props: WelcomeTextProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { user } = props;
  return (<div className={classes.mainContent}>
      <h2>{ t('home.greeting', {name: user.givenName}) }</h2>
      {/* <p>{ t('home.prompt_1') }</p>
      <div style={{ textAlign: "center" }}>
          <p style={{ whiteSpace: 'pre-line'}}>{ t('home.prompt_2') }</p>
      </div> */}
  </div>);
}
