import { Autocomplete, Button, TextField } from "@mui/material";
import React, { useState } from "react";
import { PerformanceIndicatorFormProps } from "../SingleResultForm";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import { SingleResult } from "../../../../../api/client";
import { EWAComponentType } from "../../../../../data/EWAComponentType";

type JunctionOption = {
    id: string
    label: string
}

export function UnsatisfiedNodesForm(props: PerformanceIndicatorFormProps) {
    const junctions = (props.model?.features.filter(element => element.componentType === EWAComponentType.junction) ?? []).map(item => {
        return {
            id: item.data.id,
            label: item.data.id
        }
    });

    const unsatisfiedJunctionIds = Object.keys((props.singleResult?.details) ?? {})
    const [junction, setJunction] = useState<JunctionOption>(junctions[0])
    const [details, setDetails] = useState<Record<string, string>>(props.singleResult.details ?? {})
    const [value, setValue] = useState<string>(unsatisfiedJunctionIds.length.toString())

    function onSave() {
        const keys = Object.keys(details ?? {})
        const savedDetails: Record<string, number> = {}
        for (const key of keys) {
            let value = Number.parseFloat(details[key])
            if (Number.isNaN(value)) {
                value = 0
            }
            savedDetails[key] = value
        }

        let savedValue = Number.parseFloat(value)
        if (Number.isNaN(savedValue)) {
            savedValue = 0
        }
        const newSingleResult: SingleResult = {
            ...props.singleResult,
            value: savedValue,
            details: savedDetails
        }

        props.handleSave(newSingleResult)
    }

    function onValueChange(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        setValue(e.target.value)
    }

    function onJunctionValueChange(junctionId: string, e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        const newDetails = { ...details }
        newDetails[junctionId] = e.target.value
        setDetails(newDetails)
    }

    function addJunction() {
        if (junction === undefined) return

        // node already in details
        if (details[junction.id] !== undefined) return

        const newDetails = { ...details }
        newDetails[junction.id] = '0'
        setDetails(newDetails)

        // determine new value based on junction count
        const newValue = Object.keys(newDetails).length
        setValue(newValue.toString())
    }

    function removeJunction(junctionId: string) {
        if (details[junctionId] === undefined) return

        const newDetails = { ...details }
        delete newDetails[junctionId]
        setDetails(newDetails)

        // determine new value based on junction count
        const newValue = Object.keys(newDetails).length
        setValue(newValue.toString())
    }

    function renderJunctionInputs() {
        const unsatisfiedNodes = Object.keys(details ?? {})
        const rows = []

        for (const unsatisfiedNode of unsatisfiedNodes) {
            const label = `${unsatisfiedNode}: value`
            const value = details[unsatisfiedNode]
            rows.push(<div key={unsatisfiedNode} style={{ display: 'flex' }}>
                <Button variant="outlined" onClick={() => removeJunction(unsatisfiedNode)}><DeleteIcon /></Button>
                <TextField label={label} value={value} onChange={(e) => onJunctionValueChange(unsatisfiedNode, e)} sx={{ grow: 1, flexBasis: '100%' }} />
            </div>)
        }

        if (rows.length === 0) {
            return <p><i>No unsatisfied nodes</i></p>
        }

        return <div style={{ margin: '1rem 0' }}>
            {rows}
        </div>
    }

    return <>
        <TextField fullWidth label={'override junction count ("value")'} value={value} onChange={onValueChange} sx={{ marginBottom: '1rem' }} />
        <div style={{ display: 'flex' }}>
            <Button variant="outlined" onClick={addJunction}><AddIcon /></Button>
            <Autocomplete
                disablePortal
                options={junctions}
                renderInput={(params) => <TextField {...params} label="Junction" />}
                value={junction}
                onChange={(e, value) => setJunction(value ?? undefined)}
                isOptionEqualToValue={(option: JunctionOption, value: JunctionOption) => option.id === value.id}
                disableClearable
                sx={{ grow: 1, flexBasis: '100%' }}
            />
        </div>
        {renderJunctionInputs()}
        <Button variant="outlined" onClick={onSave}><SaveIcon></SaveIcon>Save</Button>
    </>
}
