import TabPanel from "@/widgets/TabPanel"
import { Box } from "@mui/material"
import { useTranslation } from "react-i18next"
import { FeatureProps } from "./Features"
import { EWAResultType } from "@/views/model/simulation/EWAResultType"
import imgSimResults from '@/assets/landing/features/simulation_results.png'
import Lightbox from "@/widgets/Lightbox"

export default function FeatureSimulations(props: FeatureProps) {
    const { index, value } = props
    const { t } = useTranslation('landing')

    return <TabPanel value={value} index={index} idPrefix='features-'>
        <Box textAlign="justify">
            <Box component="p" whiteSpace="pre-line">{t('sections.features.simulations.intro')}</Box>
            <Lightbox
                src={imgSimResults}
                alt={t('sections.features.simulations.img_alt')}
            />
            <p>{t('sections.features.simulations.performance_indicators.label')}</p>
            <ul>
                <li>{t('sections.features.simulations.performance_indicators.' + EWAResultType.UnsatisfiedNodes)}</li>
                <li><a href="https://doi.org/10.1061/(ASCE)WR.1943-5452.0000656" rel="noreferrer" target="_blank">{t('sections.features.simulations.performance_indicators.' + EWAResultType.ResilienceIndex)}</a></li>
                <li>{t('sections.features.simulations.performance_indicators.' + EWAResultType.InflowDemandRatio)}</li>
                <li>{t('sections.features.simulations.performance_indicators.' + EWAResultType.AveragePipeAge)}</li>
            </ul>
        </Box>
    </TabPanel>
}
