import { EWAComponentType } from "../../../data/EWAComponentType";
import { ValveComponentType } from "../../../data/ValveComponentType";
import Challenge from "../../Challenge";
import ChallengeOptions from "../../ChallengeOptions";
import TaskGroup from "../../TaskGroup";
import CreateComponentTask from "../../tasks/CreateComponentTask";
import DeleteComponentTask from "../../tasks/DeleteComponentTask";
import ComponentTaskOptions from "../../tasks/options/ComponentTaskOptions";

export default function AllValveTypes(): Challenge {
  const taskGroups = [
    new TaskGroup([
      new CreateComponentTask(new ComponentTaskOptions({
        requiredCount: 6,
        componentType: EWAComponentType.junction
      })),
      new CreateComponentTask(new ComponentTaskOptions({
        requiredCount: 6,
        componentType: EWAComponentType.valve
      })),
      new CreateComponentTask(new ComponentTaskOptions({
        componentType: EWAComponentType.valve,
        valveType: ValveComponentType.fcv
      })),
      new CreateComponentTask(new ComponentTaskOptions({
        componentType: EWAComponentType.valve,
        valveType: ValveComponentType.gpv
      })),
      new CreateComponentTask(new ComponentTaskOptions({
        componentType: EWAComponentType.valve,
        valveType: ValveComponentType.pbv
      })),
      new CreateComponentTask(new ComponentTaskOptions({
        componentType: EWAComponentType.valve,
        valveType: ValveComponentType.prv
      })),
      new CreateComponentTask(new ComponentTaskOptions({
        componentType: EWAComponentType.valve,
        valveType: ValveComponentType.psv
      })),
      new CreateComponentTask(new ComponentTaskOptions({
        componentType: EWAComponentType.valve,
        valveType: ValveComponentType.tcv
      }))
    ]),
    new TaskGroup([
      new DeleteComponentTask(new ComponentTaskOptions({
        requiredCount: 6,
        componentType: EWAComponentType.valve
      })),
      new DeleteComponentTask(new ComponentTaskOptions({
        componentType: EWAComponentType.valve,
        valveType: ValveComponentType.fcv
      })),
      new DeleteComponentTask(new ComponentTaskOptions({
        componentType: EWAComponentType.valve,
        valveType: ValveComponentType.gpv
      })),
      new DeleteComponentTask(new ComponentTaskOptions({
        componentType: EWAComponentType.valve,
        valveType: ValveComponentType.pbv
      })),
      new DeleteComponentTask(new ComponentTaskOptions({
        componentType: EWAComponentType.valve,
        valveType: ValveComponentType.prv
      })),
      new DeleteComponentTask(new ComponentTaskOptions({
        componentType: EWAComponentType.valve,
        valveType: ValveComponentType.psv
      })),
      new DeleteComponentTask(new ComponentTaskOptions({
        componentType: EWAComponentType.valve,
        valveType: ValveComponentType.tcv
      }))
    ])
  ];

  const options = new ChallengeOptions({
  });

  return new Challenge(taskGroups, options);
}
