import Challenge from "../../Challenge"
import UpdateTaskType from "../../misc/UpdateTaskType"
import TaskGroup from "../../TaskGroup"
import ChangeTabTask from "../../tasks/ChangeTabTask"
import ChangeTabTaskOptions from "../../tasks/options/ChangeTabTaskOptions"

export default function ChangeTabTasks(): Challenge {
  const taskGroups = [
    new TaskGroup([
      new ChangeTabTask(),
      new ChangeTabTask(new ChangeTabTaskOptions({
        tab: 0,
        updateTaskType: UpdateTaskType.ExactValue
      })),
      new ChangeTabTask(new ChangeTabTaskOptions({
        tab: 1,
        updateTaskType: UpdateTaskType.ExactValue
      })),
      new ChangeTabTask(new ChangeTabTaskOptions({
        tab: 2,
        updateTaskType: UpdateTaskType.ExactValue
      }))
    ])
  ]

  return new Challenge(taskGroups)
}
