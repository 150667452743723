import { ComponentApplyEventArgs } from "../../events/events/ComponentApplyEvent";
import EventManager from "../../events/EventManager";
import EventType from "../../events/EventType";
import { PumpApply } from "../../views/forms/PumpApply";
import StringValueUpdateTask from "./base/StringValueUpdateTask";
import { ValuesPair } from "./base/ValueUpdateTask";
import UpdateStringTaskOptions from "./options/UpdateStringTaskOptions";
import { IUpdateValueTaskOptions } from "./options/UpdateValueTaskOptions";
import i18n from "@/i18n";

export default class PumpUpdateStatusTask extends StringValueUpdateTask<PumpApply> {
  protected _type: string = 'PumpUpdateStatusTask';
  protected componentName: string = "ewa_component_type.Pump_one";
  protected fieldName: string = "model.components.pipe.status.label";

  public static fromPlainTaskOptions(plainOptions: IUpdateValueTaskOptions<string>) {
    const options = new UpdateStringTaskOptions(plainOptions);
    return new PumpUpdateStatusTask(options)
  }

  public activate() {
    super.activate();
    EventManager.getInstance().attachListener(EventType.PumpApply, this.onValueUpdate);
  }

  public deactivate() {
    super.deactivate();
    EventManager.getInstance().detachListener(EventType.PumpApply, this.onValueUpdate);
  }

  protected retrieveValues(args: ComponentApplyEventArgs<PumpApply>): ValuesPair<string> {
    return {
      newValue: args.apply.conditional.status,
      oldValue: args.oldApply.conditional.status
    };
  }

  public get optionTargetValue() {
    const targetValue = (this._options as UpdateStringTaskOptions).targetValue

    if (targetValue === undefined) return undefined

    return i18n.t(`model.components.pipe.status.${targetValue}`)
  }
}
