import { useEffect, useState } from "react";
import {
    StateType,
    ModellingService,
    EWATaskRunDTO
} from "../../../api/client";
import AutoSizer from "react-virtualized-auto-sizer";
import { useSnackbar } from "notistack";
import TaskCard from "./TaskCard";
import CardList from "../../../widgets/CardList";
import { Box, LinearProgress } from "@mui/material";
import { isStateUnfinished } from "../../../utils/stateTypeUtils";

export interface TaskRunTableProps {
    simulationId: string;
    state: StateType
}

export function TaskRunTable(props: TaskRunTableProps) {
    const { simulationId, state } = props;
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setIsLoading] = useState(true);
    const [execFetchTaskRuns, setExecFetchTaskRuns] = useState<boolean>(false)
    const [taskRuns, setTaskRuns] = useState<Array<EWATaskRunDTO>>([])
    const [fetchInterval, setFetchInterval] = useState<number | undefined>(undefined)

    useEffect(() => {
        if (execFetchTaskRuns) {
            initFetchInterval()
        } else {
            clearFetchInterval()
            fetchTaskRuns()
        }
    }, [execFetchTaskRuns])

    useEffect(() => {
        setExecFetchTaskRuns(isStateUnfinished(state))
    }, [state])

    function initFetchInterval() {
        if (fetchInterval !== undefined) return

        setFetchInterval(window.setInterval(fetchTaskRuns, 5000))
    }

    function clearFetchInterval() {
        if (fetchInterval !== undefined) {
            window.clearInterval(fetchInterval)
            setFetchInterval(undefined)
        }
    }

    async function fetchTaskRuns() {
        if (simulationId === undefined) {
            setTaskRuns([])
            return
        }

        setIsLoading(true)
        try {
            const taskRuns = await ModellingService.taskRunsV1SimulationsIdTaskRunsGet(simulationId)
            setTaskRuns(taskRuns)
        } catch (e: any) {
            if (e.message.indexOf("Not Found") > -1) {
                setTaskRuns([])
            } else {
                enqueueSnackbar(e.message, {
                    variant: 'error',
                });
            }
        }
        setIsLoading(false)
    }

    return (
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%"
        }}>
            <Box sx={{ height: 4 }}>
                {isLoading && <LinearProgress />}
            </Box>
            <Box sx={{ flexGrow: 1 }}>
                <AutoSizer>
                    {({ height, width }: { height: number, width: number }) => (
                        <div style={{ width }}>
                            <CardList
                                rowKey="flow_run_id"
                                items={taskRuns}
                                height={height}
                                rowRenderer={item => <TaskCard item={item as EWATaskRunDTO} />}
                            />
                        </div>
                    )}
                </AutoSizer>
            </Box>
        </Box>)
}
