import UpdateTaskType from "../../misc/UpdateTaskType";
import TaskOptions, { ITaskOptions } from "./TaskOptions";

export interface IChangeTabTaskOptions extends ITaskOptions {
  updateTaskType?: UpdateTaskType
  tab?: number
}

export default class ChangeTabTaskOptions extends TaskOptions {
  public updateTaskType: UpdateTaskType = UpdateTaskType.Any;
  public tab: number = 0;

  public constructor(options: IChangeTabTaskOptions = {}) {
    super(options);

    this.updateTaskType = options.updateTaskType ?? UpdateTaskType.Any;
    this.tab = options.tab ?? 0;

    this.constrainUpdateTaskType();
  }

  protected constrainUpdateTaskType() {
    if (this.updateTaskType === UpdateTaskType.Relative || this.updateTaskType === UpdateTaskType.RelativeFactor) {
      throw new Error("Relative/Relative Factor task types not allowed");
    }
  }

  public static fromPlain(plainOptions?: IChangeTabTaskOptions) {
    return new ChangeTabTaskOptions(plainOptions);
  }

  public toJSON(): IChangeTabTaskOptions {
    return {
      ...super.toJSON(),
      updateTaskType: this.updateTaskType,
      tab: this.tab
    }
  }
}
