import { useTranslation } from "react-i18next";
import UpdateTaskType from "../../../../misc/UpdateTaskType";
import { FormControl, FormHelperText, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { setTaskOptionsDirty } from "../../../../../store/slices/challengeEditorSlice";

export interface UpdateTaskTypeSelectProps {
    value: UpdateTaskType
    onValueChange: (_: UpdateTaskType) => void
    noRelative?: boolean
}

export default function UpdateTaskTypeSelect(props: UpdateTaskTypeSelectProps) {
    const {
        value,
        onValueChange,
        noRelative
    } = props

    const { t, i18n } = useTranslation()

    const dispatch = useDispatch()

    const items = useMemo(() => {
        const keys = [UpdateTaskType.Any, UpdateTaskType.ExactValue]
        if (!noRelative) {
            keys.push(UpdateTaskType.Relative, UpdateTaskType.RelativeFactor)
        }

        const items: JSX.Element[] = []
        for (const key of keys) {
            items.push(<MenuItem
                key={key}
                value={key}
            >
                {t(`challenge.editor.tasks.update_type.${key}`)}
            </MenuItem>)
        }

        return items
    }, [noRelative, i18n.language])

    function onChange(e: SelectChangeEvent<string>) {
        onValueChange(e.target.value as UpdateTaskType)
        dispatch(setTaskOptionsDirty(true))
    }

    return <FormControl fullWidth margin="normal" variant="outlined">
        <InputLabel htmlFor="updateType">{t('challenge.editor.tasks.update_type.label')}</InputLabel>
        <Select
            id="updateType"
            value={value}
            onChange={onChange}
            label={t('challenge.editor.tasks.update_type.label')}
        >
            {items}
        </Select>
        <FormHelperText>{t('challenge.editor.tasks.update_type_hint')}</FormHelperText>
    </FormControl>
}
