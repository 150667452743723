import Challenge from "../../Challenge";
import ChallengeOptions from "../../ChallengeOptions";
import UpdateTaskType from "../../misc/UpdateTaskType";
import TaskGroup from "../../TaskGroup";
import ChangeYearTask from "../../tasks/ChangeYearTask";
import ChangeYearTaskOptions from "../../tasks/options/ChangeYearTaskOptions";

export default function ChangeYear(): Challenge {
  const taskGroups = [
    new TaskGroup([
      new ChangeYearTask(),
      new ChangeYearTask(new ChangeYearTaskOptions({
        updateTaskType: UpdateTaskType.ExactValue,
        year: 2045
      }))
    ])
  ];
  const options = new ChallengeOptions({
    targetTime: 5000
  });

  return new Challenge(taskGroups, options);
}
