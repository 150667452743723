import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { withTranslation } from "react-i18next";
import { ShowResults, showResultsFromString } from "../../../../data/ShowResults";
import { IShowResultsTaskOptions } from "../../../tasks/options/ShowResultsTaskOptions";
import TaskOptionsExtrasForm, { TaskSubformPropsAlias } from "./TaskOptionsExtrasForm";

interface State {
  showResults?: ShowResults
}

class ShowResultsTaskOptionsForm extends TaskOptionsExtrasForm<State, IShowResultsTaskOptions> {
  constructor(props: TaskSubformPropsAlias<IShowResultsTaskOptions>) {
    super(props);

    this.onSelectChange = this.onSelectChange.bind(this);
    this.renderSelect = this.renderSelect.bind(this);
  }

  stateFromProps(props: TaskSubformPropsAlias<IShowResultsTaskOptions>): State {
    return {
      showResults: props.options.showResults
    };
  }

  public extractOptions() {
    return {
      showResults: this.state.showResults
    }
  }

  onSelectChange(e: SelectChangeEvent<string>) {
    const showResults = e.target.value === '' ? undefined : showResultsFromString(e.target.value)
    this.setState({
      showResults
    });

    this.props.setIsDirty(true)
  }

  renderSelect() {
    const { t } = this.props
    const items: any[] = [
      <MenuItem key="any" value=""><em>{t('general.any')}</em></MenuItem>
    ];
    const currentValue = this.state.showResults === undefined ? '' : ShowResults[this.state.showResults].toString();

    const enumValues = Object.values(ShowResults);
    const offset = enumValues.length / 2;
    for (let i = 0; i < offset; i++) {
      const key = enumValues[i];
      const text = t(`challenge.editor.tasks.show_results.${key}`)
      items.push(<MenuItem key={key} value={key}>{text}</MenuItem>);
    }

    return <FormControl fullWidth margin="normal" variant="outlined">
      <InputLabel htmlFor="showResults">{t('challenge.editor.tasks.show_results.label')}</InputLabel>
      <Select id="showResults" onChange={this.onSelectChange} value={currentValue} label={t('challenge.editor.tasks.show_results.label')}>
        {items}
      </Select>
    </FormControl>;
  }


  render() {
    return <>
      {this.renderSelect()}
    </>;
  }
}

export default withTranslation('translation', { withRef: true })(ShowResultsTaskOptionsForm)
