import Challenge from "../../Challenge";
import ChallengeOptions from "../../ChallengeOptions";
import TaskGroup from "../../TaskGroup";
import TankUpdateCommentTask from "../../tasks/TankUpdateCommentTask";
import TankUpdateDiameterTask from "../../tasks/TankUpdateDiameterTask";
import TankUpdateElevationTask from "../../tasks/TankUpdateElevationTask";
import TankUpdateInitlevelTask from "../../tasks/TankUpdateInitlevelTask";
import TankUpdateLifetimeTask from "../../tasks/TankUpdateLifetimeTask";

export default function TankUpdates(): Challenge {
  const taskGroups: TaskGroup[] = [
    new TaskGroup([
      new TankUpdateCommentTask(),
      new TankUpdateDiameterTask(),
      new TankUpdateElevationTask(),
      new TankUpdateInitlevelTask(),
      new TankUpdateLifetimeTask()
    ])
  ];

  const options = new ChallengeOptions({
  });

  return new Challenge(taskGroups, options);
}
