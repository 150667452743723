import {
    useCallback,
    useEffect,
    useState
} from "react";
import {
    EWAModelDTO,
    ModellingService,
} from "@/api/client";
import {
    Button,
    TextField
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Delete } from "@mui/icons-material";

export interface FlowModelFormProps {
    model: EWAModelDTO
    setModel: (_: EWAModelDTO) => void;
}

export function FlowModelForm(props: FlowModelFormProps) {
    const { model } = props;
    const [name, setName] = useState<string>(model.name);
    const [description, setDescription] = useState<string>(model.description);
    const { t } = useTranslation();

    useEffect(() => {
        setName(model.name);
        setDescription(model.description);
    }, [model, model.id, setName, setDescription]);

    const handleDelete = useCallback(async () => {
        await ModellingService.deleteModelV1ModelsModelIdDelete(model.id);
    }, [model.id]);

    return (
        <div style={{
            padding: "30px", width: "100%", height: "100%",
            display: "flex", flexDirection: "column"
        }}>
            <div style={{ flexGrow: 1 }}>
                <TextField
                    InputProps={{
                        readOnly: true,
                    }}
                    value={name}
                    fullWidth
                    label={t('general.name')}
                />
                <TextField
                    InputProps={{
                        readOnly: true,
                    }}
                    rows={6}
                    multiline={true}
                    margin="normal"
                    value={description}
                    fullWidth
                    label={t('general.description')}
                />
            </div>
            <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                <div style={{ flexGrow: 1 }}>
                    <Button
                    variant="contained"
                    color="error"
                    type="submit"
                    onClick={handleDelete}
                        startIcon={<Delete />}
                    >
                        {t('general.delete')}
                    </Button>
                </div>
                <div style={{ flexGrow: 0 }}>
                </div>
            </div>
        </div>)
}
