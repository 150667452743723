import EventManager from "../../events/EventManager";
import EventType from "../../events/EventType";
import TabChangedEvent from "../../events/events/TabChangedEvent";
import UpdateTaskType from "../misc/UpdateTaskType";
import Task from "./base/Task";
import ChangeTabTaskOptions, { IChangeTabTaskOptions } from "./options/ChangeTabTaskOptions";
import i18n from "../../i18n";

export function tabToText(tab: number): string {
  switch (tab) {
    case 0:
      return i18n.t('model.controls.tabs.map')
    case 1:
      return i18n.t('model.controls.tabs.table')
    case 2:
      return i18n.t('model.controls.tabs.simulation_results')
    default:
      return tab.toString()
  }
};

export default class ChangeTabTask extends Task {
  protected _type: string = 'ChangeTabTask';

  public constructor(options?: ChangeTabTaskOptions) {
    if (!options) {
      options = new ChangeTabTaskOptions();
    }

    super(options);

    this.onTabChanged = this.onTabChanged.bind(this);
  }

  public static fromPlainTaskOptions(plainOptions: IChangeTabTaskOptions) {
    const options = new ChangeTabTaskOptions(plainOptions);
    return new ChangeTabTask(options);
  }

  protected onTabChanged(e: TabChangedEvent) {
    let shouldUpdate = false;
    const options = this._options as ChangeTabTaskOptions;

    switch (options.updateTaskType) {
      case UpdateTaskType.ExactValue:
        if (e.detail.tab === options.tab) {
          shouldUpdate = true;
        }
        break;
      case UpdateTaskType.Any:
      default:
        shouldUpdate = true;
        break;
    }

    if (!shouldUpdate) return;

    this.currentCount++;
    this.checkCompletion();
    this.triggerTaskViewUpdate();
  }

  public get description(): string {
    const options = this._options as ChangeTabTaskOptions;

    let description = '';
    switch (options.updateTaskType) {
      case UpdateTaskType.ExactValue:
        // TODO: better handling
        description = i18n.t('challenge.task.change_tab.exact_value', { tab: tabToText(options.tab ?? 0) })
        break;
      case UpdateTaskType.Any:
      default:
        description = i18n.t('challenge.task.change_tab.any')
        break;
    }

    return description;
  }

  public activate() {
    super.activate();
    EventManager.getInstance().attachListener(EventType.TabChanged, this.onTabChanged);
  }

  public deactivate() {
    super.deactivate();
    EventManager.getInstance().detachListener(EventType.TabChanged, this.onTabChanged);
  }

}
