import { PipeMaterial } from "@/api/client";
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export interface PipeMaterialSelectProps {
    value?: PipeMaterial,
    setValue: (_?: PipeMaterial) => void
}

export default function PipeMaterialSelect(props: PipeMaterialSelectProps) {
    const { value, setValue } = props

    const { t, i18n } = useTranslation()

    const items = useMemo(() => {
        const items: JSX.Element[] = []

        for (const value of Object.values(PipeMaterial)) {
            items.push(<MenuItem key={value} value={value}>
                {t(`pipe_material.${value}`)}
            </MenuItem>)
        }
        return items
    }, [i18n.language])

    function onChange(event: SelectChangeEvent<PipeMaterial>) {
        const value = event.target.value
        console.log(value)
        if (value === '') {
            setValue(undefined)
        } else {
            setValue(value as PipeMaterial)
        }
    }

    return <FormControl fullWidth>
        <InputLabel id="pipe-material-label">{ t('model.components.pipe.material')}</InputLabel>
        <Select
            labelId="pipe-material-label"
            id="pipe-material"
            value={value ?? ''}
            label={t('model.components.pipe.material')}
            onChange={onChange}
        >
           {items}
        </Select>
    </FormControl>
}
