import EventManager from "../../../events/EventManager";
import SimulationNewResultEvent, { SimulationNewResultEventArgs } from "../../../events/events/SimulationNewResultEvent";
import EventType from "../../../events/EventType";
import PerformanceIndicatorTaskOptions from "../options/PerformanceIndicatorTaskOptions";
import Task from "./Task";

export default abstract class PerformanceIndicatorTask extends Task {
    protected performanceIndicatorName: string = "";

    public constructor(options?: PerformanceIndicatorTaskOptions) {
        if (options === undefined) {
            options = new PerformanceIndicatorTaskOptions();
        }
        super(options);

        this.onSimulationNewResult = this.onSimulationNewResult.bind(this);
    }

    public get optionYear(): string | undefined {
        const year = (this._options as PerformanceIndicatorTaskOptions).year;

        return year !== undefined ? year.toString() : year;
    }

    public get optionOperatingConditionType(): string | undefined {
        const operationConditionType = (this._options as PerformanceIndicatorTaskOptions).operatingConditionType

        return operationConditionType !== undefined ? operationConditionType.toString() : operationConditionType;
    }

    public get optionScenario(): string | undefined {
        return (this._options as PerformanceIndicatorTaskOptions).scenario
    }

    public get optionId(): string | undefined {
        return (this._options as PerformanceIndicatorTaskOptions).id;
    }

    protected abstract passesTest(e: SimulationNewResultEventArgs): boolean;

    public activate() {
        super.activate();
        EventManager.getInstance().attachListener(EventType.SimulationNewResult, this.onSimulationNewResult);
    }

    public deactivate() {
        super.deactivate();
        EventManager.getInstance().detachListener(EventType.SimulationNewResult, this.onSimulationNewResult);
    }

    protected onSimulationNewResult(e: SimulationNewResultEvent) {
        const oldCurrentCount = this.currentCount;

        const passesTest = this.passesTest(e.detail)

        this.currentCount = passesTest ? 1 : 0

        if (oldCurrentCount !== this.currentCount) {
            this.checkCompletion();
            this.triggerTaskViewUpdate();
        }
    }
}
