import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { EWAModel } from "../../../../data/EWAModel";
import CloseIcon from '@mui/icons-material/Close';
import React from "react";
import DebugSimulations from "../../../util/DebugSimulations";
import { EWAResult, OperatingCondition, SingleResult } from "../../../../api/client";
import { SingleResultForm } from "./SingleResultForm";
import EventManager from "../../../../events/EventManager";
import SendTimeExtensionIcon from '@mui/icons-material/SendTimeExtension';

interface Props {
    onClose: () => void
    model?: EWAModel
}

interface State {
    selectedSimulation: number
    result?: EWAResult
    yearIndex: number
    scenarioIndex: number
    conditionIndex: number
    performanceIndicatorIndex: number
}

export default class SimulationResultFaker extends React.Component<Props, State> {
    protected years = DebugSimulations.getInstance().years;
    protected performanceIndicators = DebugSimulations.getInstance().performanceIndicators;
    protected scenarios = DebugSimulations.getInstance().scenarios;
    protected simulations = DebugSimulations.getInstance().simulations;
    protected conditions: OperatingCondition[] = [];
    protected get simulationCount() {
        return DebugSimulations.getInstance().simulationCount;
    }

    protected currentResult?: EWAResult;

    constructor(props: Props) {
        super(props);
        this.state = {
            selectedSimulation: 0,
            yearIndex: 0,
            scenarioIndex: 0,
            conditionIndex: 0,
            performanceIndicatorIndex: 0,
            result: undefined
        }

        this.handleClose = this.handleClose.bind(this);
        this.createSelectedSimulation = this.createSelectedSimulation.bind(this);
        this.renderSimulationLoaders = this.renderSimulationLoaders.bind(this);
        this.renderYearSelection = this.renderYearSelection.bind(this);
        this.renderScenarioSelection = this.renderScenarioSelection.bind(this);
        this.renderConditionSelection = this.renderConditionSelection.bind(this);
        this.renderPerformanceIndicatorSelection = this.renderPerformanceIndicatorSelection.bind(this);
        this.setResult = this.setResult.bind(this);
        this.saveSingleResult = this.saveSingleResult.bind(this);
        this.sendEvent = this.sendEvent.bind(this);

        this.conditions = props.model?.conditions.filter(item => item.id !== undefined) ?? []
    }

    handleClose() {
        this.setState({
            selectedSimulation: 0
        })

        this.props.onClose();
    }

    parseSelectValue(e: SelectChangeEvent<number>) {
        let value = e.target.value

        if (typeof value === 'string') {
            value = parseInt(value)
        }

        return value
    }

    setResult(result?: EWAResult) {
        this.setState({
            result,
            yearIndex: 0,
            scenarioIndex: 0,
            conditionIndex: 0,
            performanceIndicatorIndex: 0
        })
    }

    saveSingleResult(newSingleResult: SingleResult) {
        const result = this.state.result!
        const year = this.years[this.state.yearIndex]
        const scenario = this.scenarios[this.state.scenarioIndex]
        const condition = this.conditions[this.state.conditionIndex]
        const performanceIndicator = this.performanceIndicators[this.state.performanceIndicatorIndex]

        console.info("SimulationResultFaker@saveSingleResult", { year, scenario, condition, performanceIndicator }, newSingleResult)
        const timedResults = result.timed_results ?? {}
        const singleResults = timedResults[year] ?? []

        const index = singleResults.findIndex(item => {
            return item.scenario === scenario &&
                item.operating_condition === condition.id &&
                item.name === performanceIndicator.toString()
        })

        if (index === -1) {
            singleResults.push(newSingleResult)
        } else {
            singleResults[index] = newSingleResult
        }

        timedResults[year] = [
            ...singleResults
        ]

        this.setState({
            result: {
                ...result,
                timed_results: {
                    ...timedResults
                }
            }
        })
    }

    sendEvent() {
        console.info("SimulationResultFaker@sendEvent: sending result", this.state.result)
        EventManager.getInstance().onSimulationNewResult({
            model: this.props.model!,
            lastResult: this.state.result
        })
    }

    createSelectedSimulation() {
        const { model } = this.props;

        const options = {
            model
        }

        const result = DebugSimulations.getInstance().run(this.state.selectedSimulation, options)

        this.setResult(result)
    }

    renderSimulationLoaders() {
        const simulationSelectOptions = [];
        for (let i = 0; i < this.simulationCount; i++) {
            simulationSelectOptions.push(<MenuItem value={i} key={i}>{this.simulations[i].text}</MenuItem>);
        }
        return <>
            <div style={{ display: 'flex', marginBottom: '1rem' }}>
                <Button onClick={this.createSelectedSimulation} variant="outlined">Load</Button>
                <Select
                    onChange={(e) => this.setState({ selectedSimulation: this.parseSelectValue(e) })}
                    value={this.state.selectedSimulation}
                    size="small"
                    sx={{ flexBasis: '100%', grow: 1 }}
                >
                    {simulationSelectOptions}
                </Select>
            </div>
        </>
    }

    renderYearSelection() {
        const options = [];

        for (let i = 0; i < this.years.length; i++) {
            options.push(<MenuItem value={i} key={i}>{this.years[i]}</MenuItem>)
        }

        return <Select
            onChange={(e) => this.setState({ yearIndex: this.parseSelectValue(e) })}
            value={this.state.yearIndex}
            size="small"
        >{options}</Select>
    }

    renderScenarioSelection() {
        const options = [];

        for (let i = 0; i < this.scenarios.length; i++) {
            options.push(<MenuItem value={i} key={i}>{this.scenarios[i]}</MenuItem>)
        }

        return <Select
            onChange={(e) => this.setState({ scenarioIndex: this.parseSelectValue(e) })}
            value={this.state.scenarioIndex}
            size="small"
        >{options}</Select>
    }

    renderConditionSelection() {
        const options = [];

        for (let i = 0; i < this.conditions.length; i++) {
            const condition = this.conditions[i]
            options.push(<MenuItem value={i} key={condition.id}>{`[${condition.type ?? ''}] ${condition.name}`}</MenuItem>)
        }

        return <Select
            onChange={(e) => this.setState({ conditionIndex: this.parseSelectValue(e) })}
            value={this.state.conditionIndex}
            size="small"
        >{options}</Select>
    }

    renderPerformanceIndicatorSelection() {
        const options = [];

        for (let i = 0; i < this.performanceIndicators.length; i++) {
            options.push(<MenuItem value={i} key={i}>{this.performanceIndicators[i]}</MenuItem>)
        }

        return <Select
            onChange={(e) => this.setState({ performanceIndicatorIndex: this.parseSelectValue(e) })}
            value={this.state.performanceIndicatorIndex}
            size="small"
        >{options}</Select>
    }

    render() {
        const year = this.years[this.state.yearIndex]
        const scenario = this.scenarios[this.state.scenarioIndex]
        const operatingCondition = this.conditions[this.state.conditionIndex].id ?? ''
        const performanceIndicator = this.performanceIndicators[this.state.performanceIndicatorIndex]
        const key = `${year}_${scenario}_${operatingCondition}_${performanceIndicator}`
        return <Dialog
            open
            maxWidth="lg"
            fullWidth
        >
            <DialogTitle sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <span>Simulation Result Faker</span>
                <IconButton
                    aria-label="close"
                    onClick={this.handleClose}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <p>Create a fake "NewSimulationResultEvent" with various configurable parameters</p>
                <div>{this.renderSimulationLoaders()}</div>
                {this.state.result !== undefined && <>
                    <Divider style={{ margin: '1rem 0' }} />
                    <div>
                        {this.renderYearSelection()}
                        {this.renderScenarioSelection()}
                        {this.renderConditionSelection()}
                        {this.renderPerformanceIndicatorSelection()}
                    </div>
                    <div style={{ margin: '1rem 0' }}>
                        <SingleResultForm
                            year={year}
                            scenario={scenario}
                            operatingCondition={operatingCondition}
                            performanceIndicator={performanceIndicator}
                            result={this.state.result}
                            handleSave={this.saveSingleResult}
                            model={this.props.model}
                            key={key}
                        />
                    </div>
                </>}
            </DialogContent>
            <DialogActions>
                <Button variant="contained" startIcon={<SendTimeExtensionIcon />} onClick={this.sendEvent}>Send Event</Button>
                <Button variant="contained" startIcon={<CloseIcon />} onClick={this.handleClose}>Close</Button>
            </DialogActions>
        </Dialog>
    }
}
