import { useState } from "react";
import { ValvePRV } from "../../data/ValvePRV";
import { TextField } from "@mui/material";
import { PRVTimedProps } from "../../api/client";
import { useTranslation } from "react-i18next";
import parseFloatOrUndefined from "../../utils/parseFloatOrUndefined";
import validNumber from "../../utils/validNumber";
import { useFormContext } from "./FormContext";
import { findComputedTimedMulti, findTimedIfDefinedMulti, findTimedMulti } from "../../utils/findTimedMulti";
import { multiFormComputeIfFirstYear } from "./util";
import { MultiValveForm } from "./MultiValveForm";

export interface MultiValvePRVFormProps {
    valves: ValvePRV[]
}

export function MultiValvePRVForm(props: MultiValvePRVFormProps) {
    const { valves } = props;
    const context = useFormContext();
    const currentDate = context.currentDate as number

    const [isFirstYear, setIsFirstYear] = useState<boolean>(multiFormComputeIfFirstYear(valves, currentDate))
    const [maximumPressure, setMaximumPressure] = useState<string>(findTimedMulti({
        items: valves,
        get: _ => _.maximum_pressure
    }, context.currentDate, undefined)?.toString() ?? '');
    const [fallbackMaximumPressure, setFallbackMaximumPressure] = useState<number | undefined>(findTimedMulti({
        items: valves,
        get: _ => _.maximum_pressure
    }, context.currentDate, undefined));

    const { t } = useTranslation();

    function updateTimedState() {
        setMaximumPressure(findTimedIfDefinedMulti({
            items: valves,
            get: _ => _.maximum_pressure
        }, currentDate, undefined)?.toString() ?? '')
        setFallbackMaximumPressure(findComputedTimedMulti({
            items: valves,
            get: _ => _.maximum_pressure
        }, currentDate, undefined))
    }

    function validate() {
        if (isFirstYear || maximumPressure !== '') {
            const parsedMaximumPressure = parseFloatOrUndefined(maximumPressure)

            if (!validNumber(parsedMaximumPressure) || parsedMaximumPressure! < 0) {
                return false
            }
        }

        return true
    }

    function computeTimedApply(): Partial<PRVTimedProps> {
        const timed: Partial<PRVTimedProps> = {}

        const applyMaximumPressure = parseFloatOrUndefined(maximumPressure)
        if (applyMaximumPressure !== undefined) {
            timed.maximum_pressure = applyMaximumPressure
        }
        return timed
    }

    return <MultiValveForm
        valves={valves}
        isFirstYear={isFirstYear}
        setIsFirstYear={setIsFirstYear}
        validate={validate}
        computeTimedApply={computeTimedApply}
        updateTimedState={updateTimedState}
        timedChildren={<>
            <TextField
                type="number"
                required
                value={(maximumPressure)}
                onChange={(event) => setMaximumPressure((event.target.value))}
                style={{ flexGrow: 1, marginBottom: "10px" }}
                label={t('model.components.valve.prv.maximum_pressure')}
                helperText={isFirstYear ? undefined : fallbackMaximumPressure?.toString()}
            />
        </>}></MultiValveForm>
}
