import { Button } from "@mui/material"
import { EWAComponentType } from "../../../data/EWAComponentType"
import { ValveComponentType } from "../../../data/ValveComponentType";
import i18n from "../../../i18n";
import { Add } from "@mui/icons-material";
import EconomyManager from "../../../challenges/managers/EconomyManager";
import { useTranslation } from "react-i18next";

export interface ComponentAddButtonProps {
  componentType: EWAComponentType | ValveComponentType
  setComponentAddMode: (_: EWAComponentType | ValveComponentType) => void
  economyEnabled: boolean
}

export function createComponentButtonTitle(componentType: EWAComponentType | ValveComponentType): string {
  const key = componentType.toLowerCase()
  return i18n.t(`model.controls.edit_create_mode.${key}`) as string
}

export default function ComponentAddButton(props: ComponentAddButtonProps) {
  const {
    setComponentAddMode,
    componentType,
    economyEnabled
  } = props
  const { t } = useTranslation()
  const title = t(`model.controls.edit_create_mode.${componentType.toLowerCase()}`) as string
  const price = economyEnabled ? EconomyManager.getInstance().price(componentType) : 0

  return <Button
    value={componentType}
    onClick={() => setComponentAddMode(componentType)}
    title={title}
    style={{ padding: '11px' }}
  >
    <span>
      <Add htmlColor="white" />
      {economyEnabled && <span style={{ position: 'absolute', right: '0', bottom: '0', fontSize: '8px' }}>{price}</span>}
    </span>
  </Button>
}
