import { SimulationNewResultEventArgs } from "../../events/events/SimulationNewResultEvent";
import { EWAResultType } from "../../views/model/simulation/EWAResultType";
import ComparisonMode, { compare, comparisonModeToHumanString } from "../../utils/ComparisonMode";
import PerformanceIndicatorTask from "./base/PerformanceIndicatorTask"
import PerformanceIndicatorTaskOptions, { IPerformanceIndicatorTaskOptions } from "./options/PerformanceIndicatorTaskOptions";
import i18n from "../../i18n";

export default class PIUnsatisfiedNodesDetailValueTask extends PerformanceIndicatorTask {
    protected _type: string = "PIUnsatisfiedNodesDetailValueTask";
    protected performanceIndicatorName: string = EWAResultType.UnsatisfiedNodes;

    constructor(options?: PerformanceIndicatorTaskOptions) {
        if (options === undefined) {
            options = new PerformanceIndicatorTaskOptions()
        }

        // threshold of undefined only makes sense for equal/not equal comparisons. otherwise default to a threshold of 0
        if (options.threshold === undefined && !([ComparisonMode.eq, ComparisonMode.neq].includes(options.comparisonMode))) {
            options.threshold = 0
        }

        super(options);

    }

    protected passesTest(e: SimulationNewResultEventArgs): boolean {
        const result = e.lastResult
        if (result === undefined) return false

        const options = this._options as PerformanceIndicatorTaskOptions;
        const timedResults = result.timed_results
        if (timedResults === undefined) return false

        let availableYears = Object.keys(timedResults)
        if (options.year !== undefined) {
            availableYears = availableYears.filter(y => y === options.year!.toString())
        }

        let operatingConditionTypes: string[] = []
        if (options.operatingConditionType !== undefined) {
            if (e.model === undefined) return false // we need the model to get a list of operating conditions

            operatingConditionTypes = e.model.conditions.filter(condition => condition.id !== undefined && condition.type === options.operatingConditionType)
                .map(condition => condition.id!)
        }

        for (const year of availableYears) {
            let singleResults = timedResults[year].filter(item => item.name === this.performanceIndicatorName)

            if (singleResults.length === 0) continue

            // filter to relevant scenarios if option is set
            if (options.scenario !== undefined) {
                singleResults = singleResults.filter(item => item.scenario === options.scenario)
            }

            // filter to relevant operating condition
            if (options.operatingConditionType !== undefined) {
                singleResults = singleResults.filter(item => item.operating_condition !== undefined && operatingConditionTypes.includes(item.operating_condition))
            }


            // check every SingleResult
            for (const singleResult of singleResults) {
                if (singleResult.value === undefined || singleResult.value === 0) continue
                if (singleResult.details === undefined) continue

                // check every detail value
                const details = singleResult.details as Record<string, number>
                const recordedNodeIds = Object.keys(details)

                for (const recordedNodeId of recordedNodeIds) {
                    // only look for specific node if specified
                    if (options.id !== undefined && recordedNodeId !== options.id) continue

                    // special case for undefined threshold
                    if (options.threshold === undefined) {
                        let comparison = true
                        if (ComparisonMode.eq) {
                            comparison = (details[recordedNodeId] === undefined)
                        } else {
                            comparison = (details[recordedNodeId] !== undefined)
                        }

                        if (!comparison) {
                            return false
                        }
                    }

                    const compareResult = compare<number>(options.comparisonMode, details[recordedNodeId], options.threshold!)

                    if (!compareResult) {
                        return false
                    }
                }
            }
        }

        return true
    }

    public static fromPlainTaskOptions(plainOptions: IPerformanceIndicatorTaskOptions) {
        const options = new PerformanceIndicatorTaskOptions(plainOptions);
        return new PIUnsatisfiedNodesDetailValueTask(options)
    }

    public get description(): string {
        const options = this._options as PerformanceIndicatorTaskOptions;

        let description = ''
        if (options.threshold === undefined) { // special case - ensure either there are no unsatisfied nodes, or that (certain) nodes are unsatisfied
            description = (options.comparisonMode === ComparisonMode.eq) ?
                i18n.t('challenge.task.pi_unsatisfied_nodes_detail_value.no_threshold_satisfied') :
                i18n.t('challenge.task.pi_unsatisfied_nodes_detail_value.no_threshold_not_satisfied')
        } else {
            description = i18n.t('challenge.task.pi_unsatisfied_nodes_detail_value.threshold', {
                comparisonString: comparisonModeToHumanString(options.comparisonMode),
                threshold: options.threshold
            })
        }
        return description
    }
}
