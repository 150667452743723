import i18n from "../i18n";
import OsmImage from "../assets/layer_osm.png"
import BasemapGrauImage from "../assets/layer_basemap_grau.png"
import BasemapOrthoImage from "../assets/layer_basemap_ortho.jpg"

export enum OrthoLayer {
  OSM = 'OSM',
  BasemapGrau = 'BasemapGrau',
  BasemapOrtho = 'BasemapOrtho'
}

export function orthoLayerFromString(value: string): OrthoLayer {
  switch(value) {
    case 'OSM':
    case 'BasemapOrtho':
    case 'BasemapGrau':
      return value as OrthoLayer;
    default:
      throw Error(`Unable to determine type for: ${value}`)
  }
}

export function orthoLayerText(value: OrthoLayer): string {
  return i18n.t(`model.controls.ortho_layer.${value}`)
}

export function orthoLayerImage(value: OrthoLayer): string {
  switch (value) {
    case OrthoLayer.BasemapGrau:
      return BasemapGrauImage
    case OrthoLayer.BasemapOrtho:
      return BasemapOrthoImage
    case OrthoLayer.OSM:
      return OsmImage
  }
}
