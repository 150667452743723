import { useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import {
    EWAModel
} from "../../data/EWAModel";
import { ModelViewMode } from "../../data/ModelViewMode";
import { EWAComponentType } from "../../data/EWAComponentType";
import {
    useLocation
} from "react-router";
import {
    ChallengeModel,
    EWAModelDTO,
    EWAModelSaveDTO,
    EWAResult,
    EWASimulationDTO,
    ModellingService
} from "../../api/client";
import EventManager from "../../events/EventManager";
import ChallengeManager from "../../challenges/managers/ChallengeManager";
import { useSnackbar } from "notistack";
import { cloneDeep } from "lodash";
import { useSearchParams } from "react-router-dom";
import ModelToolbar from "./ModelToolbar";
import ModelFooter from "./ModelFooter";
import usePrevious from "../../utils/usePrevious";
import { EWAModelWrapper } from "./EWAModelWrapper";
import MapView from "./map/MapView";
import TableView from "./table/TableView";
import SimulationView from "./simulation/SimulationView";
import DeckGL from "@deck.gl/react";
import { ContextProviderValue } from "@deck.gl/core/lib/deck";
import { useStyles } from "./util/useStyles";
import { LinearProgress, Portal } from "@mui/material";
import LastSimulationDialog from "./LastSimulationDialog";
import DebugPanelDialog from "./DebugPanelDialog";
import TaskGroupDialog from "../../challenges/components/TaskGroupDialog";
import ChallengeCompleted from "../../challenges/components/ChallengeCompleted";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import SaveModelDialog from "./SaveModelDialog";
import { maxYear, minYear } from "../../utils/constants";
import RunSimulationDialog from "./RunSimulationDialog";
import { useTranslation } from "react-i18next";

const minDate = minYear;
const maxDate = maxYear;
const modelDate = minYear;

export interface ModelProps {
    portalContainer?: Element
}

export function Model(props: ModelProps) {
    const {
        portalContainer
    } = props
    const location = useLocation();
    const classes = useStyles();
    const { t } = useTranslation()

    const { enqueueSnackbar } = useSnackbar();
    const [debugMode, setDebugMode] = useState<boolean>(false)
    const [searchParams] = useSearchParams();
    const [lastResult, setLastResult] = useState<EWAResult | undefined>(undefined)
    const [currentDate, setCurrentDate] = useState<number>(modelDate);
    const [lastSimulation, setLastSimulation] = useState<EWASimulationDTO | undefined>(undefined);
    const lastSimulationId = lastSimulation?.id;
    const prevLastSimulationId = usePrevious(lastSimulationId);
    const [simulationResultLoading, setSimulationResultLoading] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [saving, setSaving] = useState<boolean>(false);
    const [simulating, setSimulating] = useState<boolean>(false);
    const [currentCondition, setCurrentCondition] = useState<string | undefined>(undefined);
    const [currentTab, setCurrentTab] = useState(0);
    const [showComponents, setShowComponents] = useState<EWAComponentType[]>([EWAComponentType.junction, EWAComponentType.spring, EWAComponentType.well, EWAComponentType.reservoir, EWAComponentType.tank, EWAComponentType.pipe, EWAComponentType.pump, EWAComponentType.valve]);
    const [viewMode, setViewMode] = useState<ModelViewMode>(ModelViewMode.TwoD);
    const [modelWrapper, setModelWrapper] = useState<EWAModelWrapper>({
        id: uuidv4(),
        model: new EWAModel({
            features: [],
        }, viewMode, showComponents, minDate, maxDate, modelDate, currentDate as number, currentCondition)
    });
    const [execFetchPendingSimulations, setExecFetchPendingSimulations] = useState(false)
    const [fetchInterval, setFetchInterval] = useState<number | undefined>(undefined)
    const [modelLoaded, setModelLoaded] = useState<boolean>(false)
    const [simulationDialogOpen, setSimulationDialogOpen] = useState<boolean>(false)
    const [debugPanelDialogOpen, setDebugPanelDialogOpen] = useState<boolean>(false)
    const [saveModelDialogOpen, setSaveModelDialogOpen] = useState<boolean>(false)
    const [runSimulationDialogOpen, setRunSimulationDialogOpen] = useState<boolean>(false)

    const challengeId: string | undefined = useSelector(
        (state: RootState) => state.challenge.challengeId
    )
    const challengeCompleted: boolean = useSelector((state: RootState) => state.challenge.challengeCompleted)

    const deckRef = useRef<DeckGL<ContextProviderValue>>(null);
    const mainContainerRef = useRef<any>();

    async function fetchAndHandleSimResults() {
        let newLastResult: EWAResult | undefined = undefined

        if (lastSimulation !== undefined) {
            setSimulationResultLoading(true)
            try {
                newLastResult = await ModellingService.simulationResultsV1SimulationsIdResultsGet(lastSimulation.id)
            } catch (e: any) {
                if (e.message.indexOf("Not found") === -1) {
                    enqueueSnackbar(e.message, {
                        variant: 'error'
                    })
                }
            }
            setSimulationResultLoading(false)
        }

        setLastResult(newLastResult)

        EventManager.getInstance().onSimulationNewResult({
            model: modelWrapper.model,
            lastResult: newLastResult
        })
    }

    useEffect(() => {
        // make sure to only fetch results when lastSimulationId changes
        if (lastSimulationId === prevLastSimulationId) return

        // set both lastResult and selectedResult when lastSimulation changes
        fetchAndHandleSimResults()
    }, [lastSimulationId]);

    async function fetchLastSimulation() {
        if (modelWrapper.model.meta?.id === undefined) {
            setLastSimulation(undefined)
            return
        }

        try {
            const simulationFlows = await ModellingService.lastSimulationV1SimulationsModelIdLastGet(modelWrapper.model.meta?.id)
            if (simulationFlows.length > 0) {
                setLastSimulation(simulationFlows[0]);
            }
        } catch (e: any) {
            if (e.message.indexOf("Not Found") > -1) {
                setLastSimulation(undefined)
            } else {
                enqueueSnackbar(e.message, {
                    variant: 'error',
                });
            }
        }
    }

    async function fetchPendingSimulations() {
        if (modelWrapper.model.meta?.id === undefined) {
            setExecFetchPendingSimulations(false)
            return
        }

        try {
            const pendingSimulations = await ModellingService.pendingSimulationsV1SimulationsModelIdPendingGet(modelWrapper.model.meta?.id)
            const newExecFetchPendingSimulations = pendingSimulations.length > 0

            setSimulating(newExecFetchPendingSimulations)
            setExecFetchPendingSimulations(newExecFetchPendingSimulations)
        } catch (e: any) {
            enqueueSnackbar(e.message, {
                variant: 'error'
            })
        }
    }

    function initFetchInterval() {
        if (fetchInterval !== undefined) return

        setFetchInterval(window.setInterval(fetchPendingSimulations, 5000))
    }

    function clearFetchInterval() {
        if (fetchInterval === undefined) return

        window.clearInterval(fetchInterval)
        setFetchInterval(undefined)
    }

    useEffect(() => {
        if (execFetchPendingSimulations) {
            initFetchInterval()
        } else {
            clearFetchInterval()
            fetchLastSimulation()
        }
    }, [execFetchPendingSimulations])

    function applySearchParams() {
        // toggle debug mode
        const newDebugMode = searchParams.get("debug") === "true"
        if (debugMode !== newDebugMode) {
            setDebugMode(newDebugMode)
        }
    }

    async function initModel() {
        const modelId = location.pathname.replace("/tool/model/", "")
        const version = searchParams.get('version') ?? undefined

        await loadModelData(modelId, version)
        setExecFetchPendingSimulations(true)
    }

    async function loadModelData(modelId: string, version?: string) {
        setLoading(true)
        try {
            const model = await ModellingService.modelV1ModelsModelIdGet(modelId, version, true)
            const currentCondition = model.network!.conditions![0].id;

            setCurrentCondition(currentCondition);

            const ewaModel = new EWAModel(model, viewMode, showComponents, minDate, maxDate, modelDate, currentDate as number, currentCondition);

            setModelWrapper(new EWAModelWrapper(ewaModel));

            setModelLoaded(true)
            ChallengeManager.getInstance().challengeFromEWAModel(model);
        } catch (e: any) {
            enqueueSnackbar(e.message, {
                variant: 'error',
            });
        }
        setLoading(false)
    };

    useEffect(() => {
        initModel()

        return () => {
            // clear the active challenge
            ChallengeManager.getInstance().challenge = undefined

            clearFetchInterval()
        }
    }, []);

    useEffect(() => {
        const oldCurrentCondition = modelWrapper.model.currentCondition
        modelWrapper.model.currentCondition = currentCondition
        EventManager.getInstance().onOperatingConditionChanged({
            model: modelWrapper.model,
            oldValue: oldCurrentCondition,
            newValue: currentCondition
        })
    }, [currentCondition])

    useEffect(() => {
        applySearchParams()
    }, [searchParams])

    useEffect(() => {
        const ewaModel = modelWrapper.model;
        ewaModel.viewMode = viewMode;
        ewaModel.showComponents = showComponents;
        ewaModel.currentDate = currentDate as number;

        setModelWrapper(new EWAModelWrapper(ewaModel));
    }, [viewMode, showComponents, currentDate]);

    useEffect(() => {
        EventManager.getInstance().onYearChanged({
            year: currentDate,
            model: modelWrapper.model
        });
    }, [currentDate])

    function getSavableModel(): EWAModelSaveDTO {
        const challenge = ChallengeManager.getInstance().challenge;
        if (challenge !== undefined && challenge !== undefined && challenge?.taskGroups !== undefined) {
            challenge.save();
            modelWrapper.model.challenges = challenge.toJSON() as ChallengeModel;
        } else {
            modelWrapper.model.challenges = undefined
        }

        let saveableModel = modelWrapper.model.save();
        let meta = cloneDeep(modelWrapper.model.meta) as EWAModelDTO
        meta.network = saveableModel
        return meta
    }

    function verifyTankReservoirExistence() {
        const model = modelWrapper.model

        for (let i = model.modelDate; i <= maxDate; i+=10) {
            if (model.featureCounts[i][EWAComponentType.reservoir] === 0 && model.featureCounts[i][EWAComponentType.tank] === 0) {
                enqueueSnackbar(t('model.missing_tank_reservoir', {year: i}), {
                    variant: 'warning'
                })
            }
        }
    }

    useEffect(() => {
        // save model if challenge completed
        if (challengeCompleted) {
            saveModel('autosave')
        }
    }, [challengeCompleted])

    async function saveModel(comment?: string) {
        if (saving) return

        const meta = getSavableModel();

        if (comment !== undefined) {
            meta.comment = comment
        }

        // check if there are any tanks or reservoirs
        verifyTankReservoirExistence()

        setSaving(true)
        try {
            const savedModel = await ModellingService.updateModelV1ModelsModelIdPut(meta.id, meta)

            const version = savedModel.version ?? meta.version
            if (version !== undefined) {
                modelWrapper.model.updateVersion(version)
            }

            EventManager.getInstance().onModelSaved({
                model: modelWrapper.model
            })
        } catch (e: any) {
            enqueueSnackbar(e.message, {
                variant: 'error',
            });
        }
        setSaving(false)
    }

    async function runSimulation(simulationName?: string, comment?: string) {
        if (saving) return

        await saveModel(comment)

        try {
            await ModellingService.simulateModelV1ModelsModelIdPost(modelWrapper.model.meta!.id!, simulationName !== '' ? simulationName : undefined)
            setSimulating(true)
            EventManager.getInstance().onSimulationStarted({
                model: modelWrapper.model
            });
            setExecFetchPendingSimulations(true)
        } catch (e: any) {
            enqueueSnackbar(e.message, {
                variant: 'error'
            })
            setSimulating(false)
        }
    }


    function renderMainContent() {
        const mapIsActive = currentTab === 0
        return <>
            {loading && <LinearProgress variant="indeterminate" sx={{ zIndex: 1 }} />}
            <div hidden={!mapIsActive} style={{ display: (mapIsActive ? "block" : "none"), width: "100%", height: "100%" }}>
                <MapView
                    deckRef={deckRef}
                    modelWrapper={modelWrapper}
                    setModelWrapper={setModelWrapper}
                    currentCondition={currentCondition}
                    minDate={minDate}
                    maxDate={maxDate}
                    currentDate={currentDate}
                    setCurrentDate={setCurrentDate}
                    lastResult={lastResult}
                    showComponents={showComponents}
                    setShowComponents={setShowComponents}
                    viewMode={viewMode}
                    setViewMode={setViewMode}
                    active={mapIsActive}
                    modelLoaded={modelLoaded}
                />
            </div>
            {currentTab === 1 && <div style={{ display: "block", width: "100%", height: "100%" }}>
                <TableView
                    modelWrapper={modelWrapper}
                    setModelWrapper={setModelWrapper}
                    currentCondition={currentCondition}
                    minDate={minDate}
                    maxDate={maxDate}
                    currentDate={currentDate}
                    setCurrentDate={setCurrentDate}
                    debugMode={debugMode}
                />
            </div>}
            {currentTab === 2 && <div style={{ display: "block", width: "100%", height: "100%" }}>
                <SimulationView
                    debugMode={debugMode}
                    simulating={simulating}
                    modelWrapper={modelWrapper}
                    lastSimulation={lastSimulation}
                    setLastSimulation={setLastSimulation}
                    loadModelData={loadModelData}
                />
            </div>}
        </>
    }

    return (<>
        <Portal container={portalContainer}>
            <ModelToolbar
                loading={loading}
                saving={saving}
                simulationResultLoading={simulationResultLoading}
                simulating={simulating}
                openSaveModel={() => setSaveModelDialogOpen(true)}
                openRunSimulation={() => setRunSimulationDialogOpen(true)}
                currentTab={currentTab}
                setCurrentTab={setCurrentTab}
                currentCondition={currentCondition}
                setCurrentCondition={setCurrentCondition}
                conditions={modelWrapper.model.conditions}
                modelWrapper={modelWrapper}
                setModelWrapper={setModelWrapper}
                lastSimulation={lastSimulation}
                showLastSimulation={() => setSimulationDialogOpen(true)}
                saveModel={saveModel}
                runSimulation={runSimulation}
            />
        </Portal>
        <div className={classes.mainContainer} ref={mainContainerRef}>
            <div className={classes.mainContent}>
                {renderMainContent()}
            </div>
        </div>
        <ModelFooter
            minDate={minDate}
            maxDate={maxDate}
            currentDate={currentDate}
            setCurrentDate={setCurrentDate}
            debugMode={debugMode}
            showDebugPanel={() => setDebugPanelDialogOpen(true)}
            modelWrapper={modelWrapper}
            loadModelData={loadModelData}
        />
        <LastSimulationDialog
            open={simulationDialogOpen}
            onClose={() => setSimulationDialogOpen(false)}
            operatingCondition={currentCondition}
            ewaSimulation={lastSimulation}
            ewaResult={lastResult}
            modelWrapper={modelWrapper}
        />
        <DebugPanelDialog
            open={debugPanelDialogOpen}
            onClose={() => setDebugPanelDialogOpen(false)}
            modelWrapper={modelWrapper}
        />
        <SaveModelDialog
            open={saveModelDialogOpen}
            onClose={() => setSaveModelDialogOpen(false)}
            onSave={saveModel}
        />
        <RunSimulationDialog
            open={runSimulationDialogOpen}
            onClose={() => setRunSimulationDialogOpen(false)}
            onSave={runSimulation}
        />
        {challengeId !== undefined && <>
            <TaskGroupDialog />
            <ChallengeCompleted />
        </>}
    </>
    );
}
