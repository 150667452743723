import { EWAModel } from "../../data/EWAModel";
import EventType from "../EventType";

export interface YearChangedEventArgs {
  model: EWAModel
  year: number
}

export default class YearChangedEvent extends CustomEvent<YearChangedEventArgs> {
  public constructor(detail: YearChangedEventArgs) {
    super(EventType.YearChanged, { detail })
  }
}
