import { LocalizableText } from "./LocalizableText";

export interface ITaskGroupOptions {
  name?: LocalizableText
  description?: LocalizableText[]
  autoShowDescription?: boolean
}

export default class TaskGroupOptions {
  public name: LocalizableText;
  public autoShowDescription: boolean;
  public description: LocalizableText[];

  public constructor(options: ITaskGroupOptions = {}) {
    this.name = options.name ?? {en: ''};
    this.description = options.description ?? [];
    this.autoShowDescription = options.autoShowDescription ?? false;
  }

  public toJSON(): ITaskGroupOptions {
    return {
      name: this.name,
      description: this.description,
      autoShowDescription: this.autoShowDescription
    }
  }
}
