import React from "react";
import DeckGL from "@deck.gl/react";
import { ContextProviderValue } from "@deck.gl/core/lib/deck";
import { EWAComponentType } from "../../data/EWAComponentType";
import { EWAModelWrapper } from "./EWAModelWrapper";
import { ValveComponentType } from "../../data/ValveComponentType";

type Point = {
  x: number
  y: number
}

export type BoundingBox = {
  p1: Point
  p2: Point
}

type SelectionBoxOffsets = {
  left: number
  bottom: number
  right: number
  top: number
}

interface Props {
  mapContainerRef: React.MutableRefObject<any>
  deckRef: React.RefObject<DeckGL<ContextProviderValue>>
  setSelectedFeatureIndices: (_: number[]) => void
  editLassoMode: EWAComponentType | ValveComponentType | null
  modelWrapper: EWAModelWrapper
}

interface State {
  selecting: boolean
  startPoint: Point | undefined
  endPoint: Point | undefined
}

export default class DeckSelectionOverlay extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      selecting: false,
      startPoint: undefined,
      endPoint: undefined
    }

    this.onMouseDown = this.onMouseDown.bind(this)
    this.onMouseMove = this.onMouseMove.bind(this)
    this.onMouseUp = this.onMouseUp.bind(this)
    this.setSelecting = this.setSelecting.bind(this)
    this.setEndPoint = this.setEndPoint.bind(this)
    this.setStartPoint = this.setStartPoint.bind(this)
    this.computeSelectedFeatures = this.computeSelectedFeatures.bind(this)
    this.computeSelectionBoundingBox = this.computeSelectionBoundingBox.bind(this)
    this.computeSelectionBoxOffsets = this.computeSelectionBoxOffsets.bind(this)
    this.performSelection = this.performSelection.bind(this)
  }

  componentDidMount(): void {
    this.props.mapContainerRef.current.addEventListener('mousedown', this.onMouseDown)
    this.props.mapContainerRef.current.addEventListener('mouseup', this.onMouseUp)
  }

  componentWillUnmount(): void {
    if (this.state.selecting) {
      this.props.mapContainerRef.current.removeEventListener('mousemove', this.onMouseMove)
    }
    this.props.mapContainerRef.current.removeEventListener('mousedown', this.onMouseDown)
    this.props.mapContainerRef.current.removeEventListener('mouseup', this.onMouseUp)
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
    if (prevState.selecting !== this.state.selecting) {
      if (this.state.selecting) {
        this.props.mapContainerRef.current.addEventListener('mousemove', this.onMouseMove)
      } else {
        this.props.mapContainerRef.current.removeEventListener('mousemove', this.onMouseMove)
      }
    }
  }

  setSelecting(selecting: boolean) {
    this.setState({
      selecting
    })
  }

  setStartPoint(startPoint?: Point) {
    this.setState({
      startPoint
    })
  }

  setEndPoint(endPoint?: Point) {
    this.setState({
      endPoint
    })
  }

  onMouseDown(e: MouseEvent) {
    this.setSelecting(true)
    this.setStartPoint({
      x: e.clientX,
      y: e.clientY
    })
  }

  onMouseMove(e: MouseEvent) {
    this.setEndPoint({
      x: e.clientX,
      y: e.clientY
    })
  }

  onMouseUp(e: MouseEvent) {
    this.performSelection()
  }

  computeSelectionBoundingBox(): BoundingBox | undefined {
    const { startPoint, endPoint } = this.state
    if (startPoint === undefined || endPoint === undefined) return undefined

    const bbox: BoundingBox = {
      p1: {
        x: Math.min(startPoint.x, endPoint.x),
        y: Math.min(startPoint.y, endPoint.y),
      },
      p2: {
        x: Math.max(startPoint.x, endPoint.x),
        y: Math.max(startPoint.y, endPoint.y)
      }
    }

    return bbox
  }

  computeSelectionBoxOffsets(): SelectionBoxOffsets | undefined {
    const bbox = this.computeSelectionBoundingBox()
    if (bbox === undefined) return undefined

    const clientWidth = document.body.clientWidth
    const clientHeight = document.body.clientHeight
    const offsets: SelectionBoxOffsets = {
      left: bbox.p1.x,
      top: bbox.p1.y,
      right: clientWidth - bbox.p2.x,
      bottom: clientHeight - bbox.p2.y
    }

    return offsets
  }

  computeSelectedFeatures(x: number, y: number, width: number, height: number) {
    const selectedFeatures = this.props.deckRef.current!.pickObjects({
      x,
      y,
      width,
      height,
      layerIds: ['geojson-layer']
    })

    const selectedIndices = new Array<number>();
    for (const selectedFeature of selectedFeatures) {
      let addFeature = false
      switch (this.props.editLassoMode) {
        case ValveComponentType.fcv:
        case ValveComponentType.gpv:
        case ValveComponentType.pbv:
        case ValveComponentType.prv:
        case ValveComponentType.psv:
        case ValveComponentType.tcv:
          addFeature = (this.props.modelWrapper.model.features[selectedFeature.index].valveType === this.props.editLassoMode)
          break
        case EWAComponentType.junction:
        case EWAComponentType.tank:
        case EWAComponentType.reservoir:
        case EWAComponentType.pump:
        case EWAComponentType.pipe:
        default:
          addFeature = (this.props.modelWrapper.model.features[selectedFeature.index].componentType === this.props.editLassoMode)
      }

      if (addFeature) {
        selectedIndices.push(selectedFeature.index);
      }
    }

    this.props.setSelectedFeatureIndices(selectedIndices);
  }

  performSelection() {
    const bbox = this.computeSelectionBoundingBox()
    if (bbox !== undefined) {
      const boundingClientRect: DOMRect = this.props.mapContainerRef.current.getBoundingClientRect()

      this.computeSelectedFeatures(bbox.p1.x - boundingClientRect.left, bbox.p1.y - boundingClientRect.top, bbox.p2.x - bbox.p1.x, bbox.p2.y - bbox.p1.y)
    }

    this.setSelecting(false)
    this.setStartPoint(undefined)
    this.setEndPoint(undefined)
  }

  render() {
    const offsets = this.computeSelectionBoxOffsets()
    let selectionBoxStyle: React.CSSProperties | undefined = undefined
    if (offsets !== undefined) {
      selectionBoxStyle = {
        position: 'fixed',
        backgroundColor: 'rgb(9, 37, 62, 0.25)',
        ...offsets
      }
    }

    return <>
      {offsets !== undefined && <div style={selectionBoxStyle}></div>}
    </>
  }
}
