import turfBearing from '@turf/bearing';
import turfDistance from '@turf/distance';
import turfTransformTranslate from '@turf/transform-translate';
import { point } from '@turf/helpers';
import { cloneDeep } from "lodash";
import WebMercatorViewport from 'viewport-mercator-project';
import {
    AnyCoordinates,
    DraggingEvent,
    FeatureCollection,
    GeoJsonEditMode, ImmutableFeatureCollection,
    ModeProps,
    PointerMoveEvent, Position,
    StartDraggingEvent, StopDraggingEvent
} from "@nebula.gl/edit-modes";
import { GeoJsonEditAction } from "@nebula.gl/edit-modes/dist-types/lib/geojson-edit-mode";
import { BaseLinestringFeature } from "../data/BaseLinestringFeature";
import { BasePointFeature } from "../data/BasePointFeature";

export function mapCoords(
    coords: AnyCoordinates,
    callback: (coords: Position) => Position
): AnyCoordinates {
    if (typeof coords[0] === 'number') {
        if (!isNaN(coords[0]) && isFinite(coords[0])) {
            return callback(coords as Position);
        }
        return coords;
    }

    return (coords as Position[])
        .map((coord) => {
            return mapCoords(coord, callback) as Position;
        })
        .filter(Boolean);
}

export class HackedTranslateMode extends GeoJsonEditMode {
    _geometryBeforeTranslate: FeatureCollection | null | undefined;
    _isTranslatable: boolean = false;
    _translating: boolean = false;
    handleDragging(event: DraggingEvent, props: ModeProps<FeatureCollection>) {
        if (!this._isTranslatable) {
            // Nothing to do
            return;
        }

        if (this._geometryBeforeTranslate) {
            // Translate the geometry
            const editAction = this.getTranslateAction(
                event.pointerDownMapCoords,
                event.mapCoords,
                'translating',
                props
            );

            if (editAction) {
                props.onEdit(editAction);
            }
        }

        // cancel map panning
        event.cancelPan();
    }

    handlePointerMove(event: PointerMoveEvent, props: ModeProps<FeatureCollection>) {
        this._isTranslatable = this.isSelectionPicked(event.pointerDownPicks || event.picks, props);

        this.updateCursor(props);
    }

    handleStartDragging(event: StartDraggingEvent, props: ModeProps<FeatureCollection>) {
        if (!this._isTranslatable) {
            return;
        }
        this._translating = true;
        console.log("Start dragging");
        this._geometryBeforeTranslate = this.getSelectedFeaturesAsFeatureCollection(props);
    }

    handleStopDragging(event: StopDraggingEvent, props: ModeProps<FeatureCollection>) {
        console.log("Stop dragging");
        this._translating = false;
        if (this._geometryBeforeTranslate) {
            console.log("Translated");
            // Translate the geometry
            const editAction = this.getTranslateAction(
                event.pointerDownMapCoords,
                event.mapCoords,
                'translated',
                props
            );

            if (editAction) {
                props.onEdit(editAction);
            }

            this._geometryBeforeTranslate = null;
        }
    }

    updateCursor(props: ModeProps<FeatureCollection>) {
        if (this._isTranslatable) {
            props.onUpdateCursor('move');
        } else {
            props.onUpdateCursor(null);
        }
    }

    getTranslateAction(
        startDragPoint: Position,
        currentPoint: Position,
        editType: string,
        props: ModeProps<FeatureCollection>
    ): GeoJsonEditAction | null | undefined {
        if (!this._geometryBeforeTranslate) {
            return null;
        }

        let updatedData = new ImmutableFeatureCollection(props.data);
        const selectedIndexes = props.selectedIndexes;

        const { viewport: viewportDesc, screenSpace } = props.modeConfig || {};

        if (viewportDesc && screenSpace) {
            const viewport = viewportDesc.project ? viewportDesc : new WebMercatorViewport(viewportDesc);

            const from = viewport.project(startDragPoint);
            const to = viewport.project(currentPoint);
            const dx = to[0] - from[0];
            const dy = to[1] - from[1];

            for (let i = 0; i < selectedIndexes.length; i++) {
                const selectedIndex = selectedIndexes[i];
                const feature = this._geometryBeforeTranslate.features[i];

                let coordinates = feature.geometry.coordinates;
                if (coordinates) {
                    coordinates = mapCoords(coordinates, (coord: any) => {
                        const pixels = viewport.project(coord);
                        if (pixels) {
                            pixels[0] += dx;
                            pixels[1] += dy;
                            return viewport.unproject(pixels);
                        }
                        return null;
                    });

                    // @ts-ignore
                    updatedData = updatedData.replaceGeometry(selectedIndex, {
                        type: feature.geometry.type,
                        coordinates,
                    });
                }
            }
        } else {
            const p1 = point(startDragPoint);
            const p2 = point(currentPoint);

            const distanceMoved = turfDistance(p1, p2);
            const direction = turfBearing(p1, p2);

            const movedFeatures = turfTransformTranslate(
                // @ts-ignore
                this._geometryBeforeTranslate,
                distanceMoved,
                direction
            );

            for (let i = 0; i < selectedIndexes.length; i++) {
                const selectedIndex = selectedIndexes[i];
                const movedFeature = (movedFeatures as any).features[i];
                updatedData = updatedData.replaceGeometry(selectedIndex, movedFeature.geometry);
            }
        }

        return {
            updatedData: updatedData.getObject(),
            editType,
            editContext: {
                featureIndexes: selectedIndexes,
            },
        };
    }

    getGuides(props: ModeProps<FeatureCollection>): any {
        if (props.selectedIndexes && props.selectedIndexes.length > 0) {
            let feature = (props.data as any).features[props.selectedIndexes[0]] as BasePointFeature<any, any>;
            let connections = (props.data as any).findConnections(feature.id);
            let linestrings = []
            for (let i = 0; i < connections.length; i++) {
                let connection = connections[i] as BaseLinestringFeature<any, any>;
                if (connection.visibleFrom <= feature.collection.currentDate) {
                    let coordinates = cloneDeep((connection as any).geometry.coordinates);
                    if ((connection as any).startnode === feature.id) {
                        coordinates[0] = props.lastPointerMoveEvent.mapCoords
                    } else {
                        coordinates[coordinates.length - 1] = props.lastPointerMoveEvent.mapCoords
                    }
                    let linestring = {
                        type: "Feature",
                        componentType: (connection as any).componentType,
                        properties: {
                            guideType: "tentative"
                        },
                        geometry: {
                            type: "LineString",
                            coordinates: coordinates
                        }
                    }
                    linestrings.push(linestring)
                }
            }
            if (this._translating && props.lastPointerMoveEvent) {
                return {
                    type: "FeatureCollection",
                    features: [
                        {
                            type: "Feature",
                            componentType: feature.componentType,
                            properties: {
                                guideType: "tentative"
                            },
                            geometry: {
                                type: "Point",

                                coordinates:
                                    props.lastPointerMoveEvent.mapCoords
                            }
                        }
                        , ...linestrings
                    ]
                };
            }
        }
    }
}
