import { ComponentApplyEventArgs } from "../../events/events/ComponentApplyEvent";
import EventManager from "../../events/EventManager";
import EventType from "../../events/EventType";
import { ReservoirApply } from "../../views/forms/ReservoirApply";
import NumberValueUpdateTask from "./base/NumberValueUpdateTask";
import { ValuesPair } from "./base/ValueUpdateTask";
import UpdateValueTaskOptions, { IUpdateValueTaskOptions } from "./options/UpdateValueTaskOptions";

export default class ReservoirUpdateHeadTask extends NumberValueUpdateTask<ReservoirApply> {
  protected _type: string = 'ReservoirUpdateHeadTask';
  protected componentName: string = "ewa_component_type.Reservoir_one";
  protected fieldName: string = "model.components.reservoir.head";

  public static fromPlainTaskOptions(plainOptions: IUpdateValueTaskOptions<number>) {
    const options = new UpdateValueTaskOptions<number>(plainOptions);
    return new ReservoirUpdateHeadTask(options)
  }

  public activate() {
    super.activate();
    EventManager.getInstance().attachListener(EventType.ReservoirApply, this.onValueUpdate);
  }

  public deactivate() {
    super.deactivate();
    EventManager.getInstance().detachListener(EventType.ReservoirApply, this.onValueUpdate);
  }

  protected retrieveValues(args: ComponentApplyEventArgs<ReservoirApply>): ValuesPair<number> {
    return {
      newValue: args.apply.timed.head,
      oldValue: args.oldApply.timed.head
    };
  }
}
