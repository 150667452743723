import { PaletteMode, ThemeOptions } from "@mui/material";
import { deDE, enUS } from "@mui/material/locale";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import React from 'react';
import { useTranslation } from "react-i18next";

function getThemeOptions(mode: PaletteMode = "dark"): ThemeOptions {
    return {
        typography: {
            fontSize: 14,
        },
        palette: {
            mode,
            primary: {
                main: "#33ccff",
                light: "#8ae2ff",
                dark: "#00a4db",
                contrastText: "#09253E"
            },
            darkBlue: {
                main: "#09253E",
                variant: "#2177b1",
                contrastText: "#FFF"
            }
        }
    }
}

export interface EwaThemeProviderProps {
    mode?: PaletteMode
    children: React.ReactNode
}

export default function EwaThemeProvider(props: EwaThemeProviderProps) {
    const { i18n } = useTranslation()

    const theme = React.useMemo(
        () =>
            createTheme(getThemeOptions(props.mode), muiLocale()),
        [props.mode, i18n.language],
    );

    function muiLocale() {
        if (i18n.language === 'de') {
            return deDE
        } else {
            return enUS
        }
    }

    return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
}
