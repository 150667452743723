import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@mui/material"
import { isNumber } from "lodash";
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux";
import { setTaskOptionsDirty } from "../../../../../store/slices/challengeEditorSlice";
import { maxYear, minYear } from "../../../../../utils/constants";

export interface YearSelectInterface {
    year?: number
    onYearChange: (_?: number) => void
}

export default function YearSelect(props: YearSelectInterface) {
    const {
        year,
        onYearChange
    } = props

    const { t } = useTranslation()
    const dispatch = useDispatch()

    function onChange(value: string | number) {
        if (isNumber(value)) {
            onYearChange(value)
        } else {
            onYearChange(undefined)
        }

        dispatch(setTaskOptionsDirty(true))
    }

    const items = [<MenuItem key="" value=""><i>{t('general.any')}</i></MenuItem>]
    for (let i = minYear; i <= maxYear; i += 10) {
        items.push(<MenuItem key={i} value={i}>{i}</MenuItem>)
    }

    return <FormControl fullWidth margin="normal" variant="outlined">
        <InputLabel htmlFor="year" id="yearLabel">{t('general.year')}</InputLabel>
        <Select
            id="year"
            labelId="yearLabel"
            onChange={(event) => onChange(event.target.value)}
            value={year ?? ""}
            label={t('general.year')}
        >
            {items}
        </Select>
        <FormHelperText>{t('challenge.editor.tasks.year_hint')}</FormHelperText>
    </FormControl>
}
