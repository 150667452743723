import { FormHelperText, Slider } from "@mui/material";
import { ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BaseFeature } from "../../data/BaseFeature";
import { EWAModelWrapper } from "../model/EWAModelWrapper";
import { BaseForm } from "./BaseForm";
import { useFormContext } from "./FormContext";

export interface BaseMultiFormApply {
    lifetime: number | number[]
}

export interface BaseMultiFormProps {
    features: BaseFeature<any, any, any>[]
    children?: ReactNode;
    timedChildren?: ReactNode;
    conditionChildren?: ReactNode;
    handleApply: (apply: BaseMultiFormApply) => void;
    validate: () => boolean;
}

export function BaseMultiForm(props: BaseMultiFormProps) {
    const { features, handleApply, validate } = props;
    const formContext = useFormContext();
    const { t } = useTranslation();

    function findVisibleFrom(feature: BaseFeature<any, any, any>[]): number {
        return 1
    }

    function findVisibleTo(feature: BaseFeature<any, any, any>[]): number {
        return 1
    }

    const [lifeTime, setLifeTime] = useState<number | number[]>([findVisibleFrom(features), findVisibleTo(features)]);

    function handleDelete() {
        for (const feature of features) {
            feature.delete()
        }

        formContext.setModelWrapper(new EWAModelWrapper(formContext.modelWrapper!.model));
        formContext.setSelectedFeatureIndices([])
    };

    function doApply() {
        handleApply({
            "lifetime": lifeTime
        } as BaseMultiFormApply)
    }

    useEffect(() => {
        setLifeTime([findVisibleFrom(features), findVisibleTo(features)]);
    }, [features])

    return (<BaseForm handleApply={doApply} features={features} validate={validate} handleDelete={handleDelete}>
        {props.children !== undefined && <div style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "20px",
            marginLeft: "20px",
            marginRight: "20px"
        }}>
            <div style={{ flexGrow: 1, display: "flex", flexDirection: "column", marginBottom: "10px" }}>
                {props.children}
            </div>
        </div>}
        {props.conditionChildren !== undefined && <>
            <FormHelperText>{t('model.components.operating_condition_props')}</FormHelperText>
            <div style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "10px",
                marginBottom: "5px",
                marginLeft: "20px",
                marginRight: "20px"
            }}>{props.conditionChildren}</div>
        </>}
        {props.timedChildren !== undefined && <>
            <FormHelperText>{t('model.components.time_props')}</FormHelperText>
            <div style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "10px",
                marginBottom: "5px",
                marginLeft: "20px",
                marginRight: "20px"
            }}>{props.timedChildren}</div>
        </>}
        <div style={{ marginLeft: "40px", marginRight: "40px", marginBottom: "10px", display: "none" }}>
            <Slider style={{ display: "none" }}
                getAriaLabel={() => t('model.components.lifetime')}
                value={lifeTime}
                onChange={(event, newValue) => setLifeTime(newValue)}
                valueLabelDisplay="on"
                min={formContext.minDate}
                max={formContext.maxDate}
                marks={[
                    {
                        value: formContext.minDate!,
                        label: formContext.minDate!,
                    },
                    {
                        value: formContext.maxDate!,
                        label: formContext.maxDate!,
                    },
                ]}
            />
        </div>
    </BaseForm>
    )
}
