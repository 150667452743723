import { EWAModel } from "../../data/EWAModel";
import EventType from "../EventType";

export interface SimulationStartedEventArgs {
  model: EWAModel
}

export default class SimulationStartedEvent extends CustomEvent<SimulationStartedEventArgs> {
  public constructor(detail: SimulationStartedEventArgs) {
    super(EventType.SimulationStarted, { detail })
  }
}
