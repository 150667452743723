import { ElevationService, JunctionModel, Point, ReservoirModel, SpringModel, TankModel, WellModel } from "../api/client";
import { ewaComponentTypeFromString } from "./EWAComponentType"
import EventManager from "../events/EventManager";
import { PointApply } from "../views/forms/PointApply";
import { BaseFeature } from "./BaseFeature";
import { BaseLinestringFeature } from "./BaseLinestringFeature";
import { BasePointGeometry } from "./BasePointGeometry";
import { EWAComponentType } from "./EWAComponentType";
import { EWAModel } from "./EWAModel";
import { ModelViewMode } from "./ModelViewMode";
import * as turf from "@turf/turf"

export type NodeModel = JunctionModel|TankModel|ReservoirModel|WellModel|SpringModel

export abstract class BasePointFeature<T, U> extends BaseFeature<BasePointGeometry, T, U>{
    constructor(collection: EWAModel, data: NodeModel, visibleTo: number, currentDate: number) {
        super(collection, data, visibleTo, currentDate);

        this._geometry = {
            type: 'Point',
            coordinates: [
                data.geometry.coordinates[0],
                data.geometry.coordinates[1],
                data.geometry.coordinates[2]
            ]
        } as BasePointGeometry
    }

    public apply(apply: PointApply<T, U>) {
        super.apply(apply);

        this.data.properties.strength = apply.strength

    }

    public delete() {
        let connections = this.collection.findConnections(this.id);
        for (let i = 0; i < connections.length; i++) {
            this.collection.decreaseFeatureCounterForDeletedComponent(connections[i])
            let index = this.collection.findAnyComponentIndex(connections[i].id);
            this.collection.features.splice(index, 1);
        }
        let index = this.collection.findAnyComponentIndex(this.id);
        this.collection.features.splice(index, 1);
        this.collection.decreaseFeatureCounterForDeletedComponent(this)

    }
    public get geometry() {
        let value = this._geometry
        let coordinates = value.coordinates
        if (this.collection?.viewMode === ModelViewMode.TwoD) {
            coordinates = [coordinates[0], coordinates[1]]
        }
        return {
            "type": "Point",
            "coordinates": coordinates,
            "realCoordinates": value.coordinates
        }
    }

    // public setTimeFrame() {
    //     this._timedCoordinates.setItem(this.collection.currentDate, this._timedCoordinates.getValue(this.collection.currentDate));
    // }

    public updateConnections(id: string, coordinates: number[]) {
        const lengthDifferences: [string, number][] = [];
        let connections = this.collection.findConnections(this.id);
        if (connections !== undefined) {
            for (let i = 0; i < connections.length; i++) {
                let connection = connections[i] as BaseLinestringFeature<any, any>;
                const oldCoordinates = (connection.componentType === EWAComponentType.pipe ? connection.geometry.coordinates : undefined);
                const oldLength = oldCoordinates !== undefined ? turf.length(turf.lineString(oldCoordinates), { units: 'meters' }) : undefined;
                if (connection.startnode === this.id) {
                    connection.updateStartNode(coordinates)
                } else if (connection.endnode === this.id) {
                    connection.updateEndNode(coordinates)
                }
                connection.updateLength();
                const newCoordinates = (connection.componentType === EWAComponentType.pipe ? connection.geometry.coordinates : undefined);
                const newLength = newCoordinates !== undefined ? turf.length(turf.lineString(newCoordinates), { units: 'meters' }) : undefined;

                if (oldLength !== undefined && newLength !== undefined) {
                    lengthDifferences.push([connection.id, newLength - oldLength]);
                }
            }
        }

        return lengthDifferences;
    }
    public updateCoordinates3d(coordinates: number[]) {
        // let value = this._timedCoordinates.getValue(this.collection.currentDate);
        this._geometry = {
            coordinates: [coordinates[0], coordinates[1], coordinates[2]],
            type: 'Point'
        } as BasePointGeometry;

        const lengthDifferences = this.updateConnections(this.id, coordinates);
        EventManager.getInstance().onComponentMoved({
            id: this.id,
            type: ewaComponentTypeFromString(this.constructor.name),
            year: this.collection.currentDate,
            model: this.collection,
            lengthDifferences
        });
    }

    public async updateCoordinates(coordinates: number[]) {
        const coordinates3d = await ElevationService.getElevationV1GetElevationsPost({
            coordinates: [coordinates[0], coordinates[1]],
            type: "Point"
        } as Point)
        const elevation = coordinates3d.coordinates[2]

        this._geometry = {
            coordinates: [coordinates[0], coordinates[1], elevation],
            type: "Point"
        } as BasePointGeometry;

        const lengthDifferences = this.updateConnections(this.id, [coordinates[0], coordinates[1], elevation]);
        EventManager.getInstance().onComponentMoved({
            id: this.id,
            type: ewaComponentTypeFromString(this.constructor.name),
            year: this.collection.currentDate,
            model: this.collection,
            lengthDifferences
        });
    }
}
