import { Button, IconButton, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { ModellingService } from "@/api/client";
import AddIcon from "@mui/icons-material/Add";
import { ModelUploadDialogProps } from "./ModelUploadDialog";
import { Close } from "@mui/icons-material";
import { minYear } from "@/utils/constants";

export function NewModelDialog(props: ModelUploadDialogProps) {
    const {
        open,
        setOpen,
        setSelection,
        fetchModels,
        type
    } = props;
    const { enqueueSnackbar } = useSnackbar();
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const { t } = useTranslation();

    async function handleCreate() {
        if (!name) return

        try {
            const m = await ModellingService.uploadV1ModelsPost(name, description, minYear, undefined, type, undefined)
            setSelection(m.id)
            fetchModels()
        } catch (e: any) {
            enqueueSnackbar(e.message, {
                variant: 'error',
            })
        }

        setName("")
        setDescription("")
        setOpen(false)
    }

    function handleClose() {
        setName("");
        setDescription("");
        setOpen(false)
    }

    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle>
                {t('home.new_model.title')}
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        top: 8,
                        right: 8
                    }}
                >
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <TextField
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    label={t('general.name')}
                    required
                    fullWidth
                    margin="normal"
                    autoFocus
                />
                <TextField
                    multiline
                    margin="normal"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    label={t('general.description')}
                    fullWidth
                />
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleCreate}
                    variant="contained"
                    aria-label={t('general.create') as string}
                    disabled={!name || !handleCreate}
                    startIcon={<AddIcon />}
                    color="success"
                >
                    {t('general.create')}
                </Button>
            </DialogActions>
        </Dialog>)
}
