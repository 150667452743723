import React, {
    useEffect,
    useState,
    useMemo,
    useCallback
} from "react";
import {
    ChallengeMetaDataModel,
    EWAModelDTO,
    EWAModelType,
    ModellingService,
    StateType
} from "@/api/client";
import {
    Box,
    Tab,
    Tabs
} from "@mui/material";
import { useSnackbar } from "notistack";
import { DesignServices, School, Timer } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import ChallengeModels from "./ChallengeModels";
import UserModels from "./UserModels";
import TutorialModels from "./TutorialModels";
import HomeChallengeUploadedEvent from "@/events/events/HomeChallengeUploadedEvent";
import EventManager from "@/events/EventManager";
import EventType from "@/events/EventType";
import HomeChallengeDeletedEvent from "@/events/events/HomeChallengeDeletedEvent";

export interface FilteredModelsProps {
    isLoading: boolean
    models: EWAModelDTO[]
    selection?: string
    setSelection: (_?: string) => void
    state?: StateType
    setState: (_?: StateType) => void
    fetchModels: () => void
    accessibleModels: Set<string>
    challengeToModelName: Map<string, string>
}

export default function Models() {
    const [isLoading, setIsLoading] = useState(true);
    const [execFetchModels, setExecFetchModels] = useState<boolean>(true)
    const [models, setModels] = useState<EWAModelDTO[]>([])
    const [fetchInterval, setFetchInterval] = useState<number | undefined>(undefined)
    const [selection, setSelection] = useState<string | undefined>(undefined);
    const [state, setState] = useState<StateType | undefined>(undefined);
    const [ewaModelType, setEwaModelType] = useState<EWAModelType>(EWAModelType.TUTORIAL)
    const [accessibleModels, setAccessibleModels] = useState<Set<string>>(new Set())
    const [challengeToModelName, setChallengeToModelName] = useState<Map<string, string>>(new Map())

    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    const modelChallengeMap = useMemo<Map<string, ChallengeMetaDataModel>>(() => {
        const map = new Map<string, ChallengeMetaDataModel>()
        for (const model of models) {
            if (!model.challenge) {
                continue
            }

            map.set(model.challenge.id, model.challenge)
        }

        return map
    }, [models])

    useEffect(() => {
        const newAccessibleModels = new Set<string>()
        const newChallengeToModelName = new Map<string, string>()

        for (const model of models) {
            let accessible = true
            if (model.challenge) {
                newChallengeToModelName.set(model.challenge.id, model.name)

                if (model.challenge.required_challenges?.length) {
                    for (const challengeId of model.challenge.required_challenges) {
                        const challengeMetaData = modelChallengeMap.get(challengeId)

                        if (!challengeMetaData?.completed) {
                            accessible = false
                            break
                        }
                    }
                }
            }

            if (accessible) {
                newAccessibleModels.add(model.id)
            }
        }

        setAccessibleModels(newAccessibleModels)
        setChallengeToModelName(newChallengeToModelName)
    }, [models, modelChallengeMap])

    const filteredModels = useMemo(() => {
        return models.filter(model => model.type === ewaModelType)
    }, [models, ewaModelType])

    useEffect(() => {
        setSelection(undefined)
        setState(undefined)
    }, [ewaModelType])

    async function fetchModels() {
        try {
            const models = await ModellingService.modelsV1ModelsGet()
            setModels(models)

            let newExecFetchModels = false
            for (const model of models) {
                if (model.id === selection) {
                    setState(model.state)
                }

                if ([StateType.PENDING, StateType.RUNNING, StateType.SCHEDULED].includes(model.state)) {
                    newExecFetchModels = true
                }
            }

            setExecFetchModels(newExecFetchModels)
        } catch (e: any) {
            enqueueSnackbar(e.message, {
                variant: 'error',
            });
        }
    }

    function initFetchInterval() {
        if (fetchInterval !== undefined) return

        setFetchInterval(window.setInterval(fetchModels, 2000))
    }

    function clearFetchInterval() {
        if (fetchInterval === undefined) return

        window.clearInterval(fetchInterval)
        setFetchInterval(undefined)
    }

    const onHomeChallengeUploaded = useCallback((event: HomeChallengeUploadedEvent) => {
        console.log("onHomeChallengeUploaded", event.detail)
        const challengeMetaData = event.detail.challengeMetaData

        const index = models.findIndex(_ => _.id === event.detail.modelId)
        if (index !== -1) {
            const newModels = [
                ...models
            ]

            newModels[index] = {
                ...models[index],
                challenge: challengeMetaData
            }

            setModels(newModels)
        }
    }, [])

    const onHomeChallengedDeleted = useCallback((event: HomeChallengeDeletedEvent) => {
        console.log("onHomeChallengedDeleted", event.detail)
        const index = models.findIndex(_ => _.id === event.detail.modelId)
        if (index !== -1) {
            const newModels = [
                ...models
            ]

            newModels[index] = {
                ...models[index],
                challenge: undefined
            }

            setModels(newModels)
        }
    },[])

    useEffect(() => {
        fetchModels()

        EventManager.getInstance().attachListener(EventType.HomeChallengeUploaded, onHomeChallengeUploaded)
        EventManager.getInstance().attachListener(EventType.HomeChallengeDeleted, onHomeChallengedDeleted)

        return () => {
            clearFetchInterval()
            EventManager.getInstance().detachListener(EventType.HomeChallengeUploaded, onHomeChallengeUploaded)
            EventManager.getInstance().detachListener(EventType.HomeChallengeDeleted, onHomeChallengedDeleted)
        }
    }, [])

    useEffect(() => {
        if (execFetchModels) {
            initFetchInterval()
        } else {
            clearFetchInterval()
        }
        setIsLoading(execFetchModels)
    }, [execFetchModels])

    function renderModelList() {
        const props: FilteredModelsProps = {
            isLoading,
            setSelection,
            selection,
            state,
            setState,
            models: filteredModels,
            fetchModels,
            accessibleModels,
            challengeToModelName
        }

        switch (ewaModelType) {
            case EWAModelType.CHALLENGE:
                return <ChallengeModels {...props} />
            case EWAModelType.USER:
                return <UserModels {...props} />
            case EWAModelType.TUTORIAL:
                return <TutorialModels {...props} />
        }
    }

    function renderTypeSwitcher() {
        return <Box display="flex" justifyContent="center" marginBottom={2}>
            <Tabs value={ewaModelType} onChange={(e, v) => setEwaModelType(v)}>
                <Tab value={EWAModelType.TUTORIAL} icon={<School />} title={t('home.type_selector.tutorial') as string} />
                <Tab value={EWAModelType.CHALLENGE} icon={<Timer />} title={t('home.type_selector.challenge') as string} />
                <Tab value={EWAModelType.USER} icon={<DesignServices />} title={t('home.type_selector.user') as string} />
            </Tabs>
        </Box>
    }

    return <>
        {renderTypeSwitcher()}
        {renderModelList()}
    </>
}
