import { Button, Dialog, DialogActions, FormControl, DialogContent, DialogTitle, IconButton, InputLabel, MenuItem, Select, Slider, TextField, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { ChangeEvent, useCallback, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Body_upload_v1_models_post, EWAModelType, ModellingService } from "@/api/client";
import UploadIcon from "@mui/icons-material/Upload";
import { Close, FileUpload } from "@mui/icons-material";
import { maxYear, minYear } from "@/utils/constants";

export interface ModelUploadDialogProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  setSelection: (value?: string) => void
  fetchModels: () => void
  type?: EWAModelType
}

export function ModelUploadDialog(props: ModelUploadDialogProps) {
  const { open, setOpen, setSelection, fetchModels, type } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [selectedFile, setSelectedFile] = useState<File>();
  const [modelYear, setModelYear] = useState<number>(2025);
  const [coordinateSystem, setCoordinateSystem] = useState<string>("EPSG:4326");
  const handleFileSelect = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files != null && files.length > 0) {
      setSelectedFile(files[0]);
      if (name === "") {
        setName(files[0].name.replace(".inp", ""));
      }
    }
  }, [name, setSelectedFile, setName]);
  const { t } = useTranslation();

  const handleUpload = useCallback(async () => {
    if (!name || !selectedFile) return;
    ModellingService.uploadV1ModelsPost(name, description, modelYear as number, coordinateSystem.toLowerCase(), type, {
      upload_file: selectedFile
    } as Body_upload_v1_models_post).then(m => {
      setSelection(m.id);
      setSelectedFile(undefined);
      setCoordinateSystem("EPSG:4326");
      setName("");
      setDescription("");
      setModelYear(minYear);
      setOpen(false)
      fetchModels()
    }).catch(e => {
      setSelectedFile(undefined);
      setCoordinateSystem("EPSG:4326");
      setName("");
      setDescription("");
      setOpen(false)
      setModelYear(minYear);
      enqueueSnackbar(e.message, {
        variant: 'error',
      });
    })
  }, [setOpen, coordinateSystem, setSelectedFile, setCoordinateSystem, setName, setDescription, name, selectedFile, description]);

  function handleClose() {
    setSelectedFile(undefined);
    setCoordinateSystem("EPSG:4326");
    setName("");
    setModelYear(minYear);
    setDescription("");
    setOpen(false)
  }

  const marks = useMemo(() => {
    const marks = []
    for (let i = minYear; i <= maxYear; i += 10) {
        marks.push({
            value: i,
            label: i
        })
    }
    return marks
}, [minYear, maxYear])

  return (
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth="sm" fullWidth>
      <DialogTitle>
        {t('home.upload_model.title')}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            top: 8,
            right: 8
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Button
          variant="outlined"
          component="label"
          startIcon={<FileUpload />}
          sx={{ marginBottom: 2}}
        >
          {t('home.upload_model.select_btn_epanet')}
          <input
          accept=".inp"
          hidden
          id="pset-file-input"
          type="file"
          onChange={handleFileSelect}
        />
        </Button>
        {selectedFile !== undefined && <Typography style={{ marginBottom: "12px" }} variant="body1">{selectedFile?.name}</Typography>}
        <TextField
          value={name}
          onChange={(e) => setName(e.target.value)}
          label={t('general.name')}
          required
          fullWidth
        />
        <FormControl fullWidth margin="normal" variant="outlined">
          <InputLabel id="coordinate-system-label">{t('home.upload_model.coordinate_system')}</InputLabel>
          <Select
            labelId="coordinate-system-label"
            id="coordinate-system"
            value={coordinateSystem}
            label={t('home.upload_model.coordinate_system')}
            onChange={(event, newValue) => setCoordinateSystem(event.target.value)}
          >
            <MenuItem value="EPSG:31258">EPSG:31258</MenuItem>
            <MenuItem value="EPSG:31257">EPSG:31257</MenuItem>
            <MenuItem value="EPSG:31259">EPSG:31259</MenuItem>
            <MenuItem value="EPSG:31255">EPSG:31255</MenuItem>
            <MenuItem value="EPSG:31254">EPSG:31254</MenuItem>
            <MenuItem value="EPSG:31256">EPSG:31256</MenuItem>
            <MenuItem value="EPSG:32633">EPSG:32633</MenuItem>
            <MenuItem value="EPSG:3857">EPSG:3857</MenuItem>
            <MenuItem value="EPSG:4326">EPSG:4326</MenuItem>
          </Select>
        </FormControl>
        <div>
          <InputLabel id="model-year-label" sx={{marginBottom: 4}}>{t('home.upload_model.model_year')}</InputLabel>
          <div style={{margin: '0 16px'}}>
            <Slider
              getAriaLabel={() => t('home.upload_model.model_year')}
              value={modelYear}
              onChange={(event, newValue) => setModelYear(newValue as number)}
              valueLabelDisplay="on"
              step={10}
              min={minYear}
              max={maxYear}
              marks={marks}
            />
          </div>
        </div>
        <TextField
          multiline
          rows={4}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          label={t('general.description')}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleUpload}
          variant="contained"
          color="success"
          aria-label={t('general.upload') as string}
          disabled={!name || !selectedFile}
          startIcon={<UploadIcon />}
        >
          {t('general.upload')}
        </Button>
      </DialogActions>
    </Dialog>)
}
