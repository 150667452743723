import { useState, useEffect, ReactNode } from "react";
import { EWAModelWrapper } from "../model/EWAModelWrapper";
import { TextField, InputAdornment } from "@mui/material";
import { BaseMultiForm, BaseMultiFormApply } from "./BaseMultiForm";
import { useTranslation } from "react-i18next";
import PipeStatusSelect from "./PipeStatusSelect";
import parseFloatOrUndefined from "../../utils/parseFloatOrUndefined";
import validNumber from "../../utils/validNumber";
import { useFormContext } from "./FormContext";
import { findComputedTimedMulti, findTimedIfDefinedMulti, findTimedMulti } from "../../utils/findTimedMulti";
import { findConditionMulti } from "../../utils/findConditionMulti";
import { multiFormComputeIfFirstYear } from "./util";
import Valve from "../../data/Valve";
import ValveTimedProps from "../../data/ValveTimedProps";
import ValveApply from "./ValveApply";
import ValveConditionProps from "../../data/ValveConditionProps";

export interface MultiValveFormProps {
    valves: Valve[]
    timedChildren?: ReactNode
    isFirstYear: boolean
    setIsFirstYear: (_: boolean) => void
    validate: () => boolean
    computeTimedApply: () => Partial<ValveTimedProps>
    updateTimedState: () => void
}

export function MultiValveForm(props: MultiValveFormProps) {
    const {
        valves,
        isFirstYear,
        setIsFirstYear
    } = props;
    const context = useFormContext();
    const currentDate = context.currentDate as number

    const [diameter, setDiameter] = useState<string>(findTimedMulti({
        items: valves,
        get: (props) => props.diameter
    }, currentDate, undefined)?.toString() ?? '')
    const [fallbackDiameter, setFallbackDiameter] = useState<number | undefined>(findComputedTimedMulti({
        items: valves,
        get: _ => _.diameter
    }, currentDate, undefined))
    const [minorloss, setMinorloss] = useState<string>(findTimedMulti({
        items: valves,
        get: _ => _.minorloss
    }, currentDate, undefined)?.toString() ?? '');
    const [fallbackMinorloss, setFallbackMinorloss] = useState<number | undefined>(findComputedTimedMulti({
        items: valves,
        get: _ => _.minorloss
    }, currentDate, undefined))
    const [status, setStatus] = useState<string>(findConditionMulti({
        items: valves,
        get: _ => _.status
    }, context.condition, ''));

    const { t } = useTranslation();

    useEffect(() => {
        const currentDate = context.currentDate as number
        const newIsFirstYear = multiFormComputeIfFirstYear(valves, currentDate)
        setIsFirstYear(newIsFirstYear)

        setDiameter(findTimedIfDefinedMulti({
            items: valves,
            get: (props) => props.diameter
        }, currentDate, undefined)?.toString() ?? '')
        setFallbackDiameter(findComputedTimedMulti({
            items: valves,
            get: _ => _.diameter
        }, props.isFirstYear ? currentDate : currentDate - 10, undefined))
        setMinorloss(findTimedIfDefinedMulti({
            items: valves,
            get: (props) => props.minorloss
        }, currentDate, undefined)?.toString() ?? '');
        setFallbackMinorloss(findComputedTimedMulti({
            items: valves,
            get: _ => _.minorloss
        }, newIsFirstYear ? currentDate : currentDate - 10, undefined))
        setStatus(findConditionMulti({
            items: valves,
            get: (props) => props.status
        }, context.condition, undefined));

        props.updateTimedState()
    }, [valves, context.currentDate, context.condition, context.modelWrapper?.id])

    function validate() {
        if (isFirstYear || diameter !== '') {
            const parsedDiameter = parseFloatOrUndefined(diameter)
            if (!validNumber(parsedDiameter) || parsedDiameter! <= 0) {
                return false
            }
        }

        if (isFirstYear || minorloss !== '') {
            const parsedMinorloss = parseFloatOrUndefined(minorloss)
            if (!validNumber(parsedMinorloss) || parsedMinorloss! < 0) {
                return false
            }
        }

        return props.validate()
    }

    function handleApply(a: BaseMultiFormApply) {
        const timed: Partial<ValveTimedProps> = {
            ...props.computeTimedApply()
        }

        const applyDiameter = parseFloatOrUndefined(diameter)
        if (applyDiameter !== undefined) {
            timed.diameter = applyDiameter
        }

        const applyMinorloss = parseFloatOrUndefined(minorloss)
        if (applyMinorloss !== undefined) {
            timed.minorloss = applyMinorloss
        }

        for (const valve of valves) {
            const apply = {
                id: valve.id,
                comment: valve.comment,
                lifetime: a.lifetime,
                conditional: {
                    status: status
                } as ValveConditionProps,
                timed: timed
            } as ValveApply
            valve.apply(apply);
        }
        context.setModelWrapper(new EWAModelWrapper(context.modelWrapper!.model));
    }

    return <BaseMultiForm
        handleApply={handleApply}
        features={valves}
        validate={validate}
        timedChildren={<>
            <TextField
                required
                type="number"
                value={(diameter)}
                onChange={(event) => setDiameter((event.target.value))}
                style={{ flexGrow: 1, marginBottom: "10px" }}
                label={t('model.components.valve.diameter')}
                InputProps={{ endAdornment: <InputAdornment position="end">mm</InputAdornment> }}
                helperText={isFirstYear ? undefined : fallbackDiameter?.toString()}
            />
            <TextField
                type="number"
                value={(minorloss)}
                onChange={(event) => setMinorloss((event.target.value))}
                style={{ flexGrow: 1, marginBottom: "10px" }}
                label={t('model.components.valve.minorloss')}
                helperText={isFirstYear ? undefined : fallbackMinorloss?.toString()}
            />
            {props.timedChildren}
        </>}
        conditionChildren={<PipeStatusSelect status={status} setStatus={setStatus} />}
    ></BaseMultiForm>
}
