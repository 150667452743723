import { useState } from "react";
import { ValveGPV } from "../../data/ValveGPV";
import { FormControl, InputLabel, Select, MenuItem, FormHelperText } from "@mui/material";
import { GPVTimedProps } from "../../api/client";
import { findComputedTimed, findTimed } from "../../utils/findTimed";
import { useTranslation } from "react-i18next";
import { useFormContext } from "./FormContext";
import { singleFormComputeIfFirstYear } from "./util";
import { SingleValveForm } from "./SingleValveForm";

export interface SingleValveGPVFormProps {
    valve: ValveGPV
}

export function SingleValveGPVForm(props: SingleValveGPVFormProps) {
    const { valve } = props;
    const context = useFormContext();

    const currentDate = context.currentDate as number
    const [isFirstYear, setIsFirstYear] = useState<boolean>(singleFormComputeIfFirstYear(valve, currentDate))
    const currentTimed = findTimed({
        item: valve,
        get: _ => _
    }, currentDate, {}) as Partial<GPVTimedProps>
    const fallbackTimed = findComputedTimed({
        item: valve,
        get: _ => _
    }, isFirstYear ? currentDate : currentDate - 10, {}) as Partial<GPVTimedProps>

    const [headlossCurve, setHeadlossCurve] = useState<string | undefined>(currentTimed.headloss_curve);

    const { t } = useTranslation();

    function updateTimedState(timed: Partial<GPVTimedProps>) {
        setHeadlossCurve(timed.headloss_curve)
    }

    function validate() {
        return true
    }

    function computeTimedApply(): Partial<GPVTimedProps> {
        const timed: Partial<GPVTimedProps> = {}

        const applyHeadlossCurve = headlossCurve
        if (applyHeadlossCurve !== undefined) {
            timed.headloss_curve = applyHeadlossCurve
        }

        return timed
    }

    return (<SingleValveForm
        isFirstYear={isFirstYear}
        setIsFirstYear={setIsFirstYear}
        fallbackTimed={fallbackTimed}
        currentTimed={currentTimed}
        validate={validate}
        valve={valve}
        updateTimedState={updateTimedState}
        computeTimedApply={computeTimedApply}
        timedChildren={<>
            <FormControl fullWidth sx={{ marginBottom: "10px" }}>
                <InputLabel id="head-curve">{t('model.components.valve.gpv.head_curve')}</InputLabel>
                <Select
                    labelId="head-curve-label"
                    id="head-curve"
                    value={headlossCurve !== undefined ? headlossCurve : ''}
                    label={t('model.components.valve.gpv.head_curve')}
                    onChange={(event, newValue) => setHeadlossCurve(event.target.value)}
                >
                    {context.modelWrapper?.model?.curves.map((curve, j) => <MenuItem
                        value={curve.id}>{curve.id}</MenuItem>)}
                </Select>
                {isFirstYear && <FormHelperText>{fallbackTimed.headloss_curve}</FormHelperText>}
            </FormControl>
        </>
        }></SingleValveForm>)
}
