import { EWAComponentType } from "../../../data/EWAComponentType"
import { ValveComponentType } from "../../../data/ValveComponentType"
import LassoImage from "../../../assets/lasso_white.png";
import PipeSelectLassoImage from "../../../assets/Pipe_select_lasso.png";
import PumpSelectLassoImage from "../../../assets/Pump_select_lasso.png";
import TankSelectLassoImage from "../../../assets/Tank_select_lasso.png";
import JunctionSelectLassoImage from "../../../assets/Junction_select_lasso.png";
import SpringSelectLassoImage from "../../../assets/Spring_select_lasso.png"
import WellSelectLassoImage from "../../../assets/Well_select_lasso.png"
import ValveSelectLassoImage from "../../../assets/Valve_select_lasso.png";
import ReservoirSelectLassoImage from "../../../assets/Reservoir_select_lasso.png";
import componentIsValve from "./componentIsValve";
import { EWAModelWrapper } from "../EWAModelWrapper";

export interface ComponentLassoImageProps {
    alt: string
    componentType: EWAComponentType | ValveComponentType | null
    modelWrapper: EWAModelWrapper
}

export default function ComponentLassoImage(props: ComponentLassoImageProps) {
    const {
        alt,
        componentType,
        modelWrapper
    } = props

    function imageSrc(): string {
        switch (componentType) {
            case EWAComponentType.junction:
                return JunctionSelectLassoImage
            case EWAComponentType.spring:
                return SpringSelectLassoImage
            case EWAComponentType.well:
                return WellSelectLassoImage
            case EWAComponentType.tank:
                return TankSelectLassoImage
            case EWAComponentType.reservoir:
                return ReservoirSelectLassoImage
            case EWAComponentType.pipe:
                return PipeSelectLassoImage
            case EWAComponentType.pump:
                return PumpSelectLassoImage
            case EWAComponentType.valve:
            case ValveComponentType.fcv:
            case ValveComponentType.gpv:
            case ValveComponentType.pbv:
            case ValveComponentType.prv:
            case ValveComponentType.psv:
            case ValveComponentType.tcv:
                return ValveSelectLassoImage
            default:
                return LassoImage
        }
    }

    const image = imageSrc()
    const componentTypeIsSet = componentType !== null
    const isValve = componentTypeIsSet && componentIsValve(componentType)
    const count = componentTypeIsSet && modelWrapper.model.getFeatureCount(componentType)

    return <span>
        <img src={image} height={24} width={24} alt={alt} />
        {isValve && <span style={{ position: 'absolute', left: '0', bottom: '0', fontSize: '8px' }}>{componentType}</span>}
        {componentTypeIsSet && <span style={{ position: 'absolute', top: '0', right: '0', fontSize: '8px'}}>{count}</span>}
    </span>
}
