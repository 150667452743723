import { makeStyles } from "@mui/styles";
import { IconButton, Tooltip } from "@mui/material";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    xAppBarButton: {
        borderRadius: "10px",
        width: "64px",
        height: "64px"
    },
}));

export interface AppBarBaseButtonProps {
    edge: "start" | "end" | false;
    label: string;
    icon: React.ReactNode;
}

export interface AppBarLinkButtonProps extends AppBarBaseButtonProps {
    to: string;
}

export function AppBarLinkButton(props: AppBarLinkButtonProps) {
    const classes = useStyles();
    return (
        <Tooltip title={props.label} aria-label={props.label}>
            <IconButton
                edge={props.edge}
                component={Link}
                className={classes.xAppBarButton}
                color="inherit"
                aria-label={props.label}
                to={props.to}
            >
                {props.icon}
            </IconButton>
        </Tooltip>
    );
}
