import { Box, Button, Card, CardContent, CardHeader, Tab, Tabs } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import OptionsExtrasType from "../../../util/OptionsExtrasType";
import { ITaskOptions } from "../../../tasks/options/TaskOptions";
import CommonTaskOptionsForm from "./CommonTaskOptionsForm";
import ComponentTaskOptionsForm from "./ComponentTaskOptionsForm";
import UpdateNumberValueTaskOptionsForm from "./UpdateNumberValueTaskOptionsForm";
import UpdateStringValueTaskOptionsForm from "./UpdateStringValueTaskOptionsForm";
import UpdateBooleanValueTaskOptionsForm from "./UpdateBooleanValueTaskOptionsForm";
import TaskTypeString from "../../../util/TaskTypeString";
import TaskTypeToOptionsExtrasType from "../../../util/TaskTypeToOptionsExtrasType";
import UpdateLifetimeValueTaskOptionsForm from "./UpdateLifetimeValueTaskOptionsForm";
import UpdateConsumptionModeValueTaskOptionsForm from "./UpdateConsumptionModeValueTaskOptionsForm";
import ChangeYearTaskOptionsForm from "./ChangeYearTaskOptionsForm";
import CreateDeleteOperatingConditionTaskOptionsForm from "./CreateDeleteOperatingConditionTaskOptionsForm";
import ChangeOperatingConditionTaskOptionsForm from "./ChangeOperatingConditionTaskOptionsForm";
import ShowResultsTaskOptionsForm from "./ShowResultsTaskOptionsForm";
import ChangeTabTaskOptionsForm from "./ChangeTabTaskOptionsForm";
import PerformanceIndicatorTaskOptionsForm from "./PerformanceIndicatorTaskOptionsForm";
import TaskTypeSelect from "./TaskTypeSelect";
import OpenEditDialogTaskOptionsForm from "./OpenEditDialogTaskOptionsForm";
import ChangeOrthoLayerTaskOptionsForm from "./ChangeOrthoLayerTaskOptionsForm";
import ChangeComponentVisibilityTaskOptionsForm from "./ChangeComponentVisibilityTaskOptionsForm";
import UpdatePipeStatusValueTaskOptionsForm from "./UpdatePipeStatusValueTaskOptionsForm";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import { useTranslation } from "react-i18next";
import { Add } from "@mui/icons-material";
import { addTask, changeActiveTask, removeTask, setTaskOptionsDirty, updateTask } from "../../../../store/slices/challengeEditorSlice";
import { PlainTask } from "../../../tasks/base/Task";
import ConfirmationDialog from "../../../../widgets/ConfirmationDialog";
import { ITaskOptionExtrasForm } from "./TaskOptionsExtrasForm";
import UpdatePipeMaterialValueTaskOptionsForm from "./UpdatePipeMaterialValueTaskOptionsForm";

interface TaskFormProps {
}

export default function TaskForm(props: TaskFormProps) {
  const commonOptionsRef = useRef<any>()
  const extraOptionsRef = useRef<ITaskOptionExtrasForm | null>(null)

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const locale = useSelector((state: RootState) => state.challengeEditor.locale)
  const taskGroups = useSelector((state: RootState) => state.challengeEditor.taskGroups)
  const taskGroupIndex = useSelector((state: RootState) => state.challengeEditor.taskGroupIndex)
  const taskIndex = useSelector((state: RootState) => state.challengeEditor.taskIndex)

  const taskIsDirty = useSelector((state: RootState) => state.challengeEditor.taskOptionsDirty)
  const setIsDirty = (isDirty: boolean) => dispatch(setTaskOptionsDirty(isDirty))

  const [tabChangeConfirmationCallback, setTabChangeConfirmationCallback] = useState<(() => void) | undefined>(undefined)
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState<boolean>(false)

  const taskGroup = useMemo(() => {
    return taskGroups[taskGroupIndex]
  }, [taskGroupIndex, taskGroups])

  const tasks = useMemo(() => {
    return taskGroup.tasks
  }, [taskGroup]);

  const task = useMemo<PlainTask | undefined>(() => {
    return tasks[taskIndex]
  }, [tasks, taskIndex])

  const [taskType, setTypeSelectOption] = useState<string | undefined>(task?.type ?? TaskTypeString.CreateComponentTask)
  const [options, setOptions] = useState<ITaskOptions>(task?.options ?? {})

  const tabs = useMemo(() => {
    const tabs: JSX.Element[] = []
    for (let i = 0; i < tasks.length; i++) {
      tabs.push(<Tab key={i} label={`${i + 1}`} value={i} />)
    }
    return tabs
  }, [tasks])

  useEffect(() => {
    setOptions(task?.options ?? {})
    setTypeSelectOption(task?.type ?? TaskTypeString.CreateComponentTask)
  }, [task])

  function onTaskTypeChange(type?: string) {
    setTypeSelectOption(type)
    setOptions({})
    setIsDirty(true)
  }

  function handleSave() {
    if (commonOptionsRef.current === null) return

    // handle common options first
    const newOptions = {
      ...options,
      ...commonOptionsRef.current.getSaveData()
    };

    // handle extras
    if (extraOptionsRef.current !== null) {
      const extras = extraOptionsRef.current.extractOptions();
      Object.assign(newOptions, extras)
    }

    dispatch(updateTask({
      type: taskType ?? TaskTypeString.CreateComponentTask,
      options: newOptions
    }))
  }

  function renderTypeOptionsExtras() {
    if (taskType === undefined) return

    const optionsExtrasType = TaskTypeToOptionsExtrasType.get(taskType as TaskTypeString)

    if (optionsExtrasType === undefined) return

    const subFormParams = {
      setIsDirty: setIsDirty,
      ref: extraOptionsRef,
      options: options,
      taskIndex: taskIndex
    }

    switch (optionsExtrasType) {
      case OptionsExtrasType.Component:
        return <ComponentTaskOptionsForm {...subFormParams} />
      case OptionsExtrasType.UpdateNumber:
        return <UpdateNumberValueTaskOptionsForm {...subFormParams} />
      case OptionsExtrasType.UpdateString:
        return <UpdateStringValueTaskOptionsForm {...subFormParams} />
      case OptionsExtrasType.UpdateBoolean:
        return <UpdateBooleanValueTaskOptionsForm {...subFormParams} />
      case OptionsExtrasType.UpdateLifetime:
        return <UpdateLifetimeValueTaskOptionsForm {...subFormParams} />
      case OptionsExtrasType.UpdateConsumptionMode:
        return <UpdateConsumptionModeValueTaskOptionsForm {...subFormParams} />
      case OptionsExtrasType.UpdatePipeStatus:
        return <UpdatePipeStatusValueTaskOptionsForm {...subFormParams} />
        case OptionsExtrasType.UpdatePipeMaterial:
        return <UpdatePipeMaterialValueTaskOptionsForm {...subFormParams} />
      case OptionsExtrasType.ChangeYear:
        return <ChangeYearTaskOptionsForm {...subFormParams} />
      case OptionsExtrasType.CreateDeleteOperatingCondition:
        return <CreateDeleteOperatingConditionTaskOptionsForm {...subFormParams} />
      case OptionsExtrasType.ChangeOperatingCondition:
        return <ChangeOperatingConditionTaskOptionsForm {...subFormParams} />
      case OptionsExtrasType.ShowResults:
        return <ShowResultsTaskOptionsForm {...subFormParams} />
      case OptionsExtrasType.ChangeTab:
        return <ChangeTabTaskOptionsForm {...subFormParams} />
      case OptionsExtrasType.PerformanceIndicator:
        return <PerformanceIndicatorTaskOptionsForm {...subFormParams} />
      case OptionsExtrasType.OpenEditDialog:
        return <OpenEditDialogTaskOptionsForm {...subFormParams} />
      case OptionsExtrasType.ChangeOrthoLayer:
        return <ChangeOrthoLayerTaskOptionsForm {...subFormParams} />
      case OptionsExtrasType.ChangeComponentVisibility:
        return <ChangeComponentVisibilityTaskOptionsForm {...subFormParams} />
      default:
      // nothing
    }

    return;
  }

  function renderHeaderAction() {
    return <Box sx={{ gap: 1, display: 'flex'}}>
      <Button variant="contained" disabled={!taskIsDirty} onClick={handleSave} color="success"><SaveIcon /></Button>
      <Button variant="contained" onClick={() => setConfirmDeleteOpen(true)} color="error"><DeleteIcon /></Button>
    </Box>
  }

  function onTabChange(tab: number) {
    if (taskIsDirty) {
      const cb = () => { dispatch(changeActiveTask(tab)) }
      setTabChangeConfirmationCallback(_ => cb)
    } else {
      dispatch(changeActiveTask(tab))
    }
  }

  return <Card variant="outlined" sx={{ borderWidth: '4px' }}>
    <CardHeader
      title={t('challenge.editor.tasks.title') as string}
      action={<Button
        variant="contained"
        onClick={() => dispatch(addTask())}
      >
        <Add />
      </Button>}
    />
    {tasks.length > 0 && <CardContent>
      <Tabs
        value={taskIndex}
        onChange={(e, value) => onTabChange(value)}
        variant="scrollable"
        scrollButtons="auto"
      >
        {tabs}
      </Tabs>
      {tabChangeConfirmationCallback !== undefined && <ConfirmationDialog
        onClose={() => {
          setTabChangeConfirmationCallback(undefined)
        }}
        onConfirm={tabChangeConfirmationCallback}
        text={t('challenge.editor.confirm_task_tab_change') as string}
      />}
      {confirmDeleteOpen && <ConfirmationDialog
        onClose={() => setConfirmDeleteOpen(false)}
        onConfirm={() => dispatch(removeTask())}
        text={t('challenge.editor.confirm_delete_task') as string}
      />}
      <Card variant="outlined">
        <CardHeader action={renderHeaderAction()} />
        <CardContent>
          <TaskTypeSelect
            value={taskType}
            onValueChange={onTaskTypeChange}
          />
          <CommonTaskOptionsForm
            setIsDirty={setIsDirty}
            locale={locale}
            options={options}
            ref={commonOptionsRef}
            taskIndex={taskIndex}
          />
          {renderTypeOptionsExtras()}
        </CardContent>
      </Card>
    </CardContent>}
  </Card>
}
