import { Comment, CommentsDisabled, History, Map, PlayCircleFilled, QueryStats, Save, TableView, Task } from "@mui/icons-material";
import { CircularProgress, IconButton, Tab, Tabs, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { EWASimulationDTO, OperatingCondition } from "../../api/client";
import EventManager from "../../events/EventManager";
import { setShowChallengePanel } from "../../store/slices/challengeSlice";
import { RootState } from "../../store/store";
import { EWAModelWrapper } from "./EWAModelWrapper";
import { useStyles } from "./util/useStyles";
import OperatingConditionToolbarItem from "./OperatingConditionToolbarItem";
import { setSaveWithComment } from "../../store/slices/configurationSlice";

export interface ModelToolbarProps {
    loading: boolean
    simulationResultLoading: boolean
    saving: boolean
    simulating: boolean
    openSaveModel: () => void
    openRunSimulation: () => void
    currentTab: number
    setCurrentTab: (value: number) => void
    currentCondition: string | undefined
    setCurrentCondition: (value?: string) => void
    conditions: OperatingCondition[]
    modelWrapper: EWAModelWrapper
    setModelWrapper: (_: EWAModelWrapper) => void
    lastSimulation?: EWASimulationDTO
    showLastSimulation: () => void
    saveModel: (comment?: string) => void
    runSimulation: (simulationName?: string, comment?: string) => void
}

export default function ModelToolbar(props: ModelToolbarProps) {
    const {
        loading,
        simulationResultLoading,
        saving,
        simulating,
        openSaveModel,
        openRunSimulation,
        currentTab,
        setCurrentTab,
        currentCondition,
        setCurrentCondition,
        conditions,
        modelWrapper,
        setModelWrapper,
        lastSimulation,
        showLastSimulation,
        saveModel,
        runSimulation
    } = props;
    const classes = useStyles();
    const { t } = useTranslation();
    const challengeId: string | undefined = useSelector((state: RootState) => state.challenge.challengeId);
    const showChallengePanel: boolean = useSelector((state: RootState) => state.challenge.showChallengePanel);
    const dispatch = useDispatch();
    const saveDisabled = loading || saving
    const saveWithComment = useSelector<RootState, boolean>((state: RootState) => state.configuration.saveWithComment)

    const loadingSpinnerSx = { position: 'absolute', left: 4, top: 4}

    function toggleShowChallengePanel() {
        dispatch(setShowChallengePanel(!showChallengePanel))
    }

    function handleTabChange(event: React.ChangeEvent<{}>, newValue: number) {
        setCurrentTab(newValue);

        EventManager.getInstance().onTabChanged({
            tab: newValue,
            model: modelWrapper.model
        });
    }

    function onSaveModel() {
        if (saveWithComment) {
            openSaveModel()
        } else {
            saveModel()
        }
    }

    function renderSaveModelButton() {
        return <div style={{ position: 'relative' }}>
            <IconButton
                disabled={saveDisabled}
                size="large"
                color={saveDisabled ? "secondary" : "primary"}
                onClick={() => { onSaveModel() }}
                title={t('model.controls.save_model') as string}
                sx={{
                    zIndex: 1
                }}
            >
                <Save />
            </IconButton>
            {saving && <CircularProgress sx={loadingSpinnerSx} />}
        </div>
    }

    function onRunSimulation() {
        if (saveWithComment) {
            openRunSimulation()
        } else {
            runSimulation()
        }
    }

    function renderRunSimulationButton() {
        return <div style={{ position: 'relative' }}>
            <IconButton
                disabled={saveDisabled}
                size="large"
                color={saveDisabled ? "secondary" : "primary"}
                onClick={() => { onRunSimulation() }}
                title={t('model.controls.run_simulation') as string}
                sx={{
                    zIndex: 1
                }}
            >
                <PlayCircleFilled />
            </IconButton>
            {simulating && <CircularProgress sx={loadingSpinnerSx}/>}
        </div>
    }

    function renderSaveWithCommentButton() {
        return <IconButton
            color="primary"
            size="large"
            onClick={() => dispatch(setSaveWithComment(!saveWithComment))}
            title={t(`model.controls.save_${(saveWithComment ? 'with' : 'without')}_comment`) as string}
        >
            {saveWithComment ? <Comment /> : <CommentsDisabled />}
        </IconButton>
    }

    function renderOpenSimulationResultButton() {
        if (lastSimulation === undefined) return

        return <div style={{ position: 'relative' }}>
            <IconButton
                size="large"
                color="primary"
                title={t('model.controls.show_simulation_dialog') as string}
                onClick={() => showLastSimulation()}
                sx={{ zIndex: 1 }}
            >
                <QueryStats />
            </IconButton>
            {simulationResultLoading && <CircularProgress sx={loadingSpinnerSx} />}
        </div>
    }

    function renderChallengePanelToggler() {
        if (challengeId === undefined) return

        return <IconButton
            title={t('model.controls.toggle_challenge_panel') as string}
            color={showChallengePanel ? 'primary' : 'error'}
            onClick={() => toggleShowChallengePanel()}
        >
            <Task />
        </IconButton>
    }

    return <div className={classes.toolBarContent}>
        <div style={{ display: 'flex' }}>
            {renderSaveWithCommentButton()}
            {renderSaveModelButton()}
            {renderRunSimulationButton()}
        </div>
        <div style={{ flexGrow: '1' }} />
        <Tabs value={currentTab} onChange={handleTabChange}>
            <Tooltip title={t('model.controls.tabs.map') as string}>
                <Tab iconPosition="start" icon={<Map />} />
            </Tooltip>
            <Tooltip title={t('model.controls.tabs.table') as string}>
                <Tab iconPosition="start" icon={<TableView />} />
            </Tooltip>
            <Tooltip title={t('model.controls.tabs.simulation_results') as string}>
                <Tab iconPosition="start" icon={<History />} />
            </Tooltip>
        </Tabs>
        <div style={{ flexGrow: 1 }}></div>
        {renderOpenSimulationResultButton()}
        {renderChallengePanelToggler()}
        <OperatingConditionToolbarItem
            currentCondition={currentCondition}
            setCurrentCondition={setCurrentCondition}
            conditions={conditions}
            modelWrapper={modelWrapper}
            setModelWrapper={setModelWrapper}
        />
    </div>
}
