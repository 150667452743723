import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@mui/material";
import { withTranslation } from "react-i18next";
import UpdateValueTaskOptionsForm from "./UpdateValueTaskOptionsForm";

class UpdatePipeStatusValueTaskOptionsForm extends UpdateValueTaskOptionsForm<string> {
  targetValueOrDefault(targetValue?: string) {
    return targetValue ?? '';
  }

  renderTargetValueField() {
    return <FormControl fullWidth margin="normal" variant="outlined">
      <InputLabel htmlFor="type">{this.props.t('challenge.editor.tasks.target_value')}</InputLabel>
      <Select
        id="type"
        onChange={(e) => this.onTargetValueChange(e.target.value)}
        value={this.state.targetValue ?? ''}
        label={this.props.t('challenge.editor.tasks.target_value')}
      >
        <MenuItem value="" key=""></MenuItem>
        <MenuItem value="OPEN" key="OPEN">{this.props.t('model.components.pipe.status.OPEN')}</MenuItem>
        <MenuItem value="CLOSED" key="CLOSED">{this.props.t('model.components.pipe.status.CLOSED')}</MenuItem>
      </Select>
      <FormHelperText>{this.props.t('challenge.editor.tasks.target_value_hint')}</FormHelperText>
    </FormControl>
  }
}

export default withTranslation('translation', { withRef: true })(UpdatePipeStatusValueTaskOptionsForm)
