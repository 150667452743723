import { Box, Checkbox, FormControl, FormControlLabel, Grid } from "@mui/material";
import { withTranslation } from "react-i18next";
import UpdateTaskType from "../../../misc/UpdateTaskType";
import { EWAComponentType } from "../../../../data/EWAComponentType";
import { IChangeComponentVisibilityTaskOptions } from "../../../tasks/options/ChangeComponentVisibilityTaskOptions";
import UpdateTaskTypeSelect from "./common_inputs/UpdateTaskTypeSelect";
import ComponentTypeSelect from "./common_inputs/ComponentTypeSelect";
import TaskOptionsExtrasForm, { TaskSubformPropsAlias } from "./TaskOptionsExtrasForm";

interface State {
  updateTaskType: UpdateTaskType
  componentType?: EWAComponentType
  visible: boolean
}

class ChangeComponentVisibilityTaskOptionsForm extends TaskOptionsExtrasForm<State, IChangeComponentVisibilityTaskOptions> {
  constructor(props: TaskSubformPropsAlias<IChangeComponentVisibilityTaskOptions>) {
    super(props);

    this.onVisibleValueChange = this.onVisibleValueChange.bind(this)

    this.renderComponentTypeField = this.renderComponentTypeField.bind(this);
    this.renderUpdateTypeField = this.renderUpdateTypeField.bind(this);
    this.renderVisibleField = this.renderVisibleField.bind(this)
  }

  stateFromProps(props: TaskSubformPropsAlias<IChangeComponentVisibilityTaskOptions>): State {
    return {
      componentType: props.options.componentType,
      updateTaskType: props.options.updateTaskType ?? UpdateTaskType.Any,
      visible: props.options.visible ?? true
    };
  }

  public extractOptions() {
    return {
      updateTaskType: this.state.updateTaskType,
      componentType: this.state.componentType,
      visible: this.state.visible
    }
  }

  renderUpdateTypeField() {
    return <UpdateTaskTypeSelect
      value={this.state.updateTaskType}
      onValueChange={(_: UpdateTaskType) => this.setState({ updateTaskType: _ })}
      noRelative
    />
  }

  renderComponentTypeField() {
    return <ComponentTypeSelect
      componentType={this.state.componentType}
      onChangeComponentType={(_?: EWAComponentType) => this.setState({ componentType: _ })}
      noAny
    />
  }

  onVisibleValueChange(visible: boolean) {
    this.setState({
      visible
    })

    this.props.setIsDirty(true)
  }

  renderVisibleField() {
    return <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
      <FormControl fullWidth>
        <FormControlLabel
          control={<Checkbox
            checked={this.state.visible}
            onChange={(e) => this.onVisibleValueChange(e.target.checked)}
          />}
          label={this.props.t('challenge.editor.tasks.visible')}
        />
      </FormControl>
    </Box>
  }

  render() {
    return <Grid container columnSpacing={2} rowSpacing={0}>
      <Grid item sm={6} xs={12}>{this.renderUpdateTypeField()}</Grid>
      <Grid item sm={6} xs={12}>{this.renderComponentTypeField()}</Grid>
      <Grid item sm={12}>{this.renderVisibleField()}</Grid>
    </Grid>;
  }
}

export default withTranslation('translation', { withRef: true })(ChangeComponentVisibilityTaskOptionsForm)
