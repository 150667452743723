import Task from "../tasks/base/Task";
import CreateComponentTask from "../tasks/CreateComponentTask";
import DeleteComponentTask from "../tasks/DeleteComponentTask";
import JunctionUpdateDemandTask from "../tasks/JunctionUpdateDemandTask";
import JunctionUpdateElevationTask from "../tasks/JunctionUpdateElevationTask";
import MoveComponentTask from "../tasks/MoveComponentTask";
import PipeUpdateDiameterTask from "../tasks/PipeUpdateDiameterTask";
import PipeUpdateLengthTask from "../tasks/PipeUpdateLengthTask";
import PipeUpdateStatusTask from "../tasks/PipeUpdateStatusTask";
import PumpUpdateStatusTask from "../tasks/PumpUpdateStatusTask";
import ReservoirUpdateHeadTask from "../tasks/ReservoirUpdateHeadTask";
import TankUpdateElevationTask from "../tasks/TankUpdateElevationTask";
import TankUpdateDiameterTask from "../tasks/TankUpdateDiameterTask";
import JunctionUpdateCommentTask from "../tasks/JunctionUpdateCommentTask";
import JunctionUpdateFireflowTask from "../tasks/JunctionUpdateFireflowTask";
import JunctionUpdateLifetimeTask from "../tasks/JunctionUpdateLifetimeTask";
import JunctionUpdateModeTask from "../tasks/JunctionUpdateModeTask";
import JunctionUpdateScaleDemandTask from "../tasks/JunctionUpdateScaleDemandTask";
import TankUpdateCommentTask from "../tasks/TankUpdateCommentTask";
import TankUpdateInitlevelTask from "../tasks/TankUpdateInitlevelTask";
import TankUpdateLifetimeTask from "../tasks/TankUpdateLifetimeTask";
import ReservoirUpdateCommentTask from "../tasks/ReservoirUpdateCommentTask";
import ReservoirUpdateElevationTask from "../tasks/ReservoirUpdateElevationTask";
import ReservoirUpdateLifetimeTask from "../tasks/ReservoirUpdateLifetimeTask";
import PipeUpdateCommentTask from "../tasks/PipeUpdateCommentTask";
import PipeUpdateLifetimeTask from "../tasks/PipeUpdateLifetimeTask";
import PipeUpdateMinorlossTask from "../tasks/PipeUpdateMinorlossTask";
import PipeUpdateRoughnessTask from "../tasks/PipeUpdateRoughnessTask";
import PumpUpdateCommentTask from "../tasks/PumpUpdateCommentTask";
import PumpUpdateHeadCurveTask from "../tasks/PumpUpdateHeadCurveTask";
import PumpUpdateLifetimeTask from "../tasks/PumpUpdateLifetimeTask";
import PumpUpdatePowerTask from "../tasks/PumpUpdatePowerTask";
import TaskTypeString from "./TaskTypeString";
import ChangeYearTask from "../tasks/ChangeYearTask";
import FCVUpdateCommentTask from "../tasks/FCVUpdateCommentTask";
import FCVUpdateDiameterTask from "../tasks/FCVUpdateDiameterTask";
import FCVUpdateLifetimeTask from "../tasks/FCVUpdateLifetimeTask";
import FCVUpdateMaximumFlowTask from "../tasks/FCVUpdateMaximumFlowTask";
import FCVUpdateStatusTask from "../tasks/FCVUpdateStatusTask";
import GPVUpdateCommentTask from "../tasks/GPVUpdateCommentTask";
import GPVUpdateDiameterTask from "../tasks/GPVUpdateDiameterTask";
import GPVUpdateLifetimeTask from "../tasks/GPVUpdateLifetimeTask";
import GPVUpdateHeadCurveTask from "../tasks/GPVUpdateHeadCurveTask";
import GPVUpdateStatusTask from "../tasks/GPVUpdateStatusTask";
import PBVUpdateCommentTask from "../tasks/PBVUpdateCommentTask";
import PBVUpdateDiameterTask from "../tasks/PBVUpdateDiameterTask";
import PBVUpdateLifetimeTask from "../tasks/PBVUpdateLifetimeTask";
import PBVUpdatePressureDropTask from "../tasks/PBVUpdatePressureDropTask";
import PBVUpdateStatusTask from "../tasks/PBVUpdateStatusTask";
import PRVUpdateCommentTask from "../tasks/PRVUpdateCommentTask";
import PRVUpdateDiameterTask from "../tasks/PRVUpdateDiameterTask";
import PRVUpdateLifetimeTask from "../tasks/PRVUpdateLifetimeTask";
import PRVUpdateMaximumPressureTask from "../tasks/PRVUpdateMaximumPressureTask";
import PRVUpdateStatusTask from "../tasks/PRVUpdateStatusTask";
import PSVUpdateCommentTask from "../tasks/PSVUpdateCommentTask";
import PSVUpdateDiameterTask from "../tasks/PSVUpdateDiameterTask";
import PSVUpdateLifetimeTask from "../tasks/PSVUpdateLifetimeTask";
import PSVUpdatePressureLimitTask from "../tasks/PSVUpdatePressureLimitTask";
import PSVUpdateStatusTask from "../tasks/PSVUpdateStatusTask";
import TCVUpdateCommentTask from "../tasks/TCVUpdateCommentTask";
import TCVUpdateDiameterTask from "../tasks/TCVUpdateDiameterTask";
import TCVUpdateLifetimeTask from "../tasks/TCVUpdateLifetimeTask";
import TCVUpdateHeadlossCoefficientTask from "../tasks/TCVUpdateHeadlossCoefficientTask";
import TCVUpdateStatusTask from "../tasks/TCVUpdateStatusTask";
import ChangeOperatingConditionTask from "../tasks/ChangeOperatingConditionTask";
import CreateOperatingConditionTask from "../tasks/CreateOperatingConditionTask";
import DeleteOperatingConditionTask from "../tasks/DeleteOperatingConditionTask";
import StartSimulationTask from "../tasks/StartSimulationTask";
import WaitForSimulationTask from '../tasks/WaitForSimulationTask'
import SaveModelTask from "../tasks/SaveModelTask";
import ShowResultsTask from "../tasks/ShowResultsTask";
import ChangeTabTask from "../tasks/ChangeTabTask";
import PIResilienceIndexTask from "../tasks/PIResilienceIndexTask";
import PIAveragePipeAgeTask from "../tasks/PIAveragePipeAgeTask";
import PIUnsatisfiedNodesCountTask from "../tasks/PIUnsatisfiedNodesCountTask";
import PIUnsatisfiedNodesDetailValueTask from "../tasks/PIUnsatisfiedNodesDetailValueTask";
import PIUnsatisfiedNodesRelativeCountTask from "../tasks/PIUnsatisfiedNodesRelativeCountTask";
import OpenEditDialogTask from "../tasks/OpenEditDialogTask";
import OpenSimulationResultsDialogTask from "../tasks/OpenSimulationResultsDialog";
import { ITaskOptions } from "../tasks/options/TaskOptions";
import StayWithinBudgetTask from "../tasks/StayWithinBudgetTask";
import ChangeOrthoLayerTask from "../tasks/ChangeOrthoLayerTask";
import ChangeComponentVisibilityTask from "../tasks/ChangeComponentVisibilityTask";
import WellUpdateCommentTask from "../tasks/WellUpdateCommentTask";
import WellUpdateElevationTask from "../tasks/WellUpdateElevationTask";
import WellUpdateLifetimeTask from "../tasks/WellUpdateLifetimeTask";
import WellUpdateInflowTask from "../tasks/WellUpdateInflowTask";
import SpringUpdateCommentTask from "../tasks/SpringUpdateCommentTask";
import SpringUpdateElevationTask from "../tasks/SpringUpdateElevationTask";
import SpringUpdateLifetimeTask from "../tasks/SpringUpdateLifetimeTask";
import SpringUpdateInflowTask from "../tasks/SpringUpdateInflowTask";
import PIInflowDemandRatioTask from "../tasks/PIInflowDemandRatioTask";
import PipeUpdateConstructionYearTask from "../tasks/PipeUpdateConstructionYearTask";
import PipeUpdateMaterialTask from "../tasks/PipeUpdateMaterialTask";

type TaskParser = (_: ITaskOptions) => Task

const TaskParsers: Record<TaskTypeString, TaskParser> = {
  /* misc tasks */
  [TaskTypeString.ChangeTabTask]: ChangeTabTask.fromPlainTaskOptions,
  [TaskTypeString.ChangeYearTask]: ChangeYearTask.fromPlainTaskOptions,
  [TaskTypeString.ChangeOrthoLayerTask]: ChangeOrthoLayerTask.fromPlainTaskOptions,
  [TaskTypeString.SaveModelTask]: SaveModelTask.fromPlainTaskOptions,
  [TaskTypeString.ShowResultsTask]: ShowResultsTask.fromPlainTaskOptions,
  [TaskTypeString.OpenEditDialogTask]: OpenEditDialogTask.fromPlainTaskOptions,
  [TaskTypeString.OpenSimulationResultsDialogTask]: OpenSimulationResultsDialogTask.fromPlainTaskOptions,
  [TaskTypeString.StayWithinBudgetTask]: StayWithinBudgetTask.fromPlainTaskOptions,
  /* simulation related tasks */
  [TaskTypeString.StartSimulationTask]: StartSimulationTask.fromPlainTaskOptions,
  [TaskTypeString.WaitForSimulationTask]: WaitForSimulationTask.fromPlainTaskOptions,
  [TaskTypeString.PIResilienceIndexTask]: PIResilienceIndexTask.fromPlainTaskOptions,
  [TaskTypeString.PIAveragePipeAgeTask]: PIAveragePipeAgeTask.fromPlainTaskOptions,
  [TaskTypeString.PIInflowDemandRatioTask]: PIInflowDemandRatioTask.fromPlainTaskOptions,
  [TaskTypeString.PIUnsatisfiedNodesCountTask]: PIUnsatisfiedNodesCountTask.fromPlainTaskOptions,
  [TaskTypeString.PIUnsatisfiedNodesDetailValueTask]: PIUnsatisfiedNodesDetailValueTask.fromPlainTaskOptions,
  [TaskTypeString.PIUnsatisfiedNodesRelativeCountTask]: PIUnsatisfiedNodesRelativeCountTask.fromPlainTaskOptions,
  /* operating condition */
  [TaskTypeString.ChangeOperatingConditionTask]: ChangeOperatingConditionTask.fromPlainTaskOptions,
  [TaskTypeString.CreateOperatingConditionTask]: CreateOperatingConditionTask.fromPlainTaskOptions,
  [TaskTypeString.DeleteOperatingConditionTask]: DeleteOperatingConditionTask.fromPlainTaskOptions,
  [TaskTypeString.ChangeComponentVisibilityTask]: ChangeComponentVisibilityTask.fromPlainTaskOptions,
  /* general component tasks */
  [TaskTypeString.CreateComponentTask]: CreateComponentTask.fromPlainTaskOptions,
  [TaskTypeString.DeleteComponentTask]: DeleteComponentTask.fromPlainTaskOptions,
  [TaskTypeString.MoveComponentTask]: MoveComponentTask.fromPlainTaskOptions,
  /* junction tasks */
  [TaskTypeString.JunctionUpdateCommentTask]: JunctionUpdateCommentTask.fromPlainTaskOptions,
  [TaskTypeString.JunctionUpdateDemandTask]: JunctionUpdateDemandTask.fromPlainTaskOptions,
  [TaskTypeString.JunctionUpdateElevationTask]: JunctionUpdateElevationTask.fromPlainTaskOptions,
  [TaskTypeString.JunctionUpdateFireflowTask]: JunctionUpdateFireflowTask.fromPlainTaskOptions,
  [TaskTypeString.JunctionUpdateLifetimeTask]: JunctionUpdateLifetimeTask.fromPlainTaskOptions,
  [TaskTypeString.JunctionUpdateModeTask]: JunctionUpdateModeTask.fromPlainTaskOptions,
  [TaskTypeString.JunctionUpdateScaleDemandTask]: JunctionUpdateScaleDemandTask.fromPlainTaskOptions,
  /* well tasks */
  [TaskTypeString.WellUpdateCommentTask]: WellUpdateCommentTask.fromPlainTaskOptions,
  [TaskTypeString.WellUpdateElevationTask]: WellUpdateElevationTask.fromPlainTaskOptions,
  [TaskTypeString.WellUpdateLifetimeTask]: WellUpdateLifetimeTask.fromPlainTaskOptions,
  [TaskTypeString.WellUpdateInflowTask]: WellUpdateInflowTask.fromPlainTaskOptions,
  /* spring tasks */
  [TaskTypeString.SpringUpdateCommentTask]: SpringUpdateCommentTask.fromPlainTaskOptions,
  [TaskTypeString.SpringUpdateElevationTask]: SpringUpdateElevationTask.fromPlainTaskOptions,
  [TaskTypeString.SpringUpdateLifetimeTask]: SpringUpdateLifetimeTask.fromPlainTaskOptions,
  [TaskTypeString.SpringUpdateInflowTask]: SpringUpdateInflowTask.fromPlainTaskOptions,
  /* tank tasks */
  [TaskTypeString.TankUpdateCommentTask]: TankUpdateCommentTask.fromPlainTaskOptions,
  [TaskTypeString.TankUpdateDiameterTask]: TankUpdateDiameterTask.fromPlainTaskOptions,
  [TaskTypeString.TankUpdateElevationTask]: TankUpdateElevationTask.fromPlainTaskOptions,
  [TaskTypeString.TankUpdateInitlevelTask]: TankUpdateInitlevelTask.fromPlainTaskOptions,
  [TaskTypeString.TankUpdateLifetimeTask]: TankUpdateLifetimeTask.fromPlainTaskOptions,
  /* reservoir tasks */
  [TaskTypeString.ReservoirUpdateCommentTask]: ReservoirUpdateCommentTask.fromPlainTaskOptions,
  [TaskTypeString.ReservoirUpdateElevationTask]: ReservoirUpdateElevationTask.fromPlainTaskOptions,
  [TaskTypeString.ReservoirUpdateHeadTask]: ReservoirUpdateHeadTask.fromPlainTaskOptions,
  [TaskTypeString.ReservoirUpdateLifetimeTask]: ReservoirUpdateLifetimeTask.fromPlainTaskOptions,
  /* pipe tasks */
  [TaskTypeString.PipeUpdateCommentTask]: PipeUpdateCommentTask.fromPlainTaskOptions,
  [TaskTypeString.PipeUpdateDiameterTask]: PipeUpdateDiameterTask.fromPlainTaskOptions,
  [TaskTypeString.PipeUpdateLengthTask]: PipeUpdateLengthTask.fromPlainTaskOptions,
  [TaskTypeString.PipeUpdateLifetimeTask]: PipeUpdateLifetimeTask.fromPlainTaskOptions,
  [TaskTypeString.PipeUpdateMinorlossTask]: PipeUpdateMinorlossTask.fromPlainTaskOptions,
  [TaskTypeString.PipeUpdateRoughnessTask]: PipeUpdateRoughnessTask.fromPlainTaskOptions,
  [TaskTypeString.PipeUpdateStatusTask]: PipeUpdateStatusTask.fromPlainTaskOptions,
  [TaskTypeString.PipeUpdateConstructionYearTask]: PipeUpdateConstructionYearTask.fromPlainTaskOptions,
  [TaskTypeString.PipeUpdateMaterialTask]: PipeUpdateMaterialTask.fromPlainTaskOptions,
  /* pump tasks */
  [TaskTypeString.PumpUpdateCommentTask]: PumpUpdateCommentTask.fromPlainTaskOptions,
  [TaskTypeString.PumpUpdateHeadCurveTask]: PumpUpdateHeadCurveTask.fromPlainTaskOptions,
  [TaskTypeString.PumpUpdateLifetimeTask]: PumpUpdateLifetimeTask.fromPlainTaskOptions,
  [TaskTypeString.PumpUpdatePowerTask]: PumpUpdatePowerTask.fromPlainTaskOptions,
  [TaskTypeString.PumpUpdateStatusTask]: PumpUpdateStatusTask.fromPlainTaskOptions,
  /* valve tasks */
  /* FCV */
  [TaskTypeString.FCVUpdateCommentTask]: FCVUpdateCommentTask.fromPlainTaskOptions,
  [TaskTypeString.FCVUpdateDiameterTask]: FCVUpdateDiameterTask.fromPlainTaskOptions,
  [TaskTypeString.FCVUpdateLifetimeTask]: FCVUpdateLifetimeTask.fromPlainTaskOptions,
  [TaskTypeString.FCVUpdateMaximumFlowTask]: FCVUpdateMaximumFlowTask.fromPlainTaskOptions,
  [TaskTypeString.FCVUpdateStatusTask]: FCVUpdateStatusTask.fromPlainTaskOptions,
  /* GPV */
  [TaskTypeString.GPVUpdateCommentTask]: GPVUpdateCommentTask.fromPlainTaskOptions,
  [TaskTypeString.GPVUpdateDiameterTask]: GPVUpdateDiameterTask.fromPlainTaskOptions,
  [TaskTypeString.GPVUpdateLifetimeTask]: GPVUpdateLifetimeTask.fromPlainTaskOptions,
  [TaskTypeString.GPVUpdateHeadCurveTask]: GPVUpdateHeadCurveTask.fromPlainTaskOptions,
  [TaskTypeString.GPVUpdateStatusTask]: GPVUpdateStatusTask.fromPlainTaskOptions,
  /* PBV */
  [TaskTypeString.PBVUpdateCommentTask]: PBVUpdateCommentTask.fromPlainTaskOptions,
  [TaskTypeString.PBVUpdateDiameterTask]: PBVUpdateDiameterTask.fromPlainTaskOptions,
  [TaskTypeString.PBVUpdateLifetimeTask]: PBVUpdateLifetimeTask.fromPlainTaskOptions,
  [TaskTypeString.PBVUpdatePressureDropTask]: PBVUpdatePressureDropTask.fromPlainTaskOptions,
  [TaskTypeString.PBVUpdateStatusTask]: PBVUpdateStatusTask.fromPlainTaskOptions,
  /* PRV */
  [TaskTypeString.PRVUpdateCommentTask]: PRVUpdateCommentTask.fromPlainTaskOptions,
  [TaskTypeString.PRVUpdateDiameterTask]: PRVUpdateDiameterTask.fromPlainTaskOptions,
  [TaskTypeString.PRVUpdateLifetimeTask]: PRVUpdateLifetimeTask.fromPlainTaskOptions,
  [TaskTypeString.PRVUpdateMaximumPressureTask]: PRVUpdateMaximumPressureTask.fromPlainTaskOptions,
  [TaskTypeString.PRVUpdateStatusTask]: PRVUpdateStatusTask.fromPlainTaskOptions,
  /* PSV */
  [TaskTypeString.PSVUpdateCommentTask]: PSVUpdateCommentTask.fromPlainTaskOptions,
  [TaskTypeString.PSVUpdateDiameterTask]: PSVUpdateDiameterTask.fromPlainTaskOptions,
  [TaskTypeString.PSVUpdateLifetimeTask]: PSVUpdateLifetimeTask.fromPlainTaskOptions,
  [TaskTypeString.PSVUpdatePressureLimitTask]: PSVUpdatePressureLimitTask.fromPlainTaskOptions,
  [TaskTypeString.PSVUpdateStatusTask]: PSVUpdateStatusTask.fromPlainTaskOptions,
  /* TCV */
  [TaskTypeString.TCVUpdateCommentTask]: TCVUpdateCommentTask.fromPlainTaskOptions,
  [TaskTypeString.TCVUpdateDiameterTask]: TCVUpdateDiameterTask.fromPlainTaskOptions,
  [TaskTypeString.TCVUpdateLifetimeTask]: TCVUpdateLifetimeTask.fromPlainTaskOptions,
  [TaskTypeString.TCVUpdateHeadlossCoefficientTask]: TCVUpdateHeadlossCoefficientTask.fromPlainTaskOptions,
  [TaskTypeString.TCVUpdateStatusTask]: TCVUpdateStatusTask.fromPlainTaskOptions
}

export default TaskParsers
