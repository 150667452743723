import { Grid } from "@mui/material";
import { IUpdateValueTaskOptions } from "../../../tasks/options/UpdateValueTaskOptions"
import UpdateTaskType from "../../../misc/UpdateTaskType";
import YearSelect from "./common_inputs/YearSelect";
import UpdateTaskTypeSelect from "./common_inputs/UpdateTaskTypeSelect";
import IdInput from "./common_inputs/IdInput";
import ComparisonModeSelect from "./common_inputs/ComparisonModeSelect";
import ComparisonMode from "../../../../utils/ComparisonMode";
import TaskOptionsExtrasForm, { TaskSubformPropsAlias } from "./TaskOptionsExtrasForm";

interface State<T> {
  id?: string
  year?: number
  targetValue?: T
  updateTaskType: UpdateTaskType
  comparisonMode: ComparisonMode
}

export default abstract class UpdateValueTaskOptionsForm<T> extends TaskOptionsExtrasForm<State<T>, IUpdateValueTaskOptions<T>> {
  protected noRelativeUpdateTypes: boolean = true
  protected onlyEqualityComparison: boolean = true

  constructor(props: TaskSubformPropsAlias<IUpdateValueTaskOptions<T>>) {
    super(props);

    this.onTargetValueChange = this.onTargetValueChange.bind(this);

    this.renderUpdateTypeField = this.renderUpdateTypeField.bind(this);
    this.renderComparisonModeField = this.renderComparisonModeField.bind(this)
    this.renderTargetValueField = this.renderTargetValueField.bind(this);
    this.renderIdField = this.renderIdField.bind(this);
    this.renderYearField = this.renderYearField.bind(this);
  }

  stateFromProps(props: TaskSubformPropsAlias<IUpdateValueTaskOptions<T>>) {
    return {
      id: props.options.id,
      year: props.options.year,
      updateTaskType: props.options.updateTaskType ?? UpdateTaskType.Any,
      comparisonMode: props.options.comparisonMode ?? ComparisonMode.eq,
      targetValue: this.targetValueOrDefault(props.options.targetValue)
    };
  }

  onTargetValueChange(targetValue: T): void {
    this.setState({
      targetValue
    })

    this.props.setIsDirty(true)
  }

  abstract targetValueOrDefault(targetValue?: T): T;
  abstract renderTargetValueField(): JSX.Element;

  public extractOptions() {
    return {
      id: this.state.id,
      year: this.state.year,
      targetValue: this.state.targetValue,
      updateTaskType: this.state.updateTaskType,
      comparisonMode: this.state.comparisonMode
    }
  }

  renderUpdateTypeField() {
    return <UpdateTaskTypeSelect
      value={this.state.updateTaskType}
      onValueChange={(_: UpdateTaskType) => this.setState({ updateTaskType: _ })}
      noRelative={this.noRelativeUpdateTypes}
    />
  }

  renderComparisonModeField() {
    return <ComparisonModeSelect
      value={this.state.comparisonMode}
      onValueChange={(_: ComparisonMode) => this.setState({ comparisonMode: _})}
      onlyEquality={this.onlyEqualityComparison}
    />
  }

  renderIdField() {
    return <IdInput value={this.state.id} onChange={(_) => this.setState({ id: _ })} />
  }

  renderYearField() {
    return <YearSelect year={this.state.year} onYearChange={(_?: number) => this.setState({ year: _ })} />
  }

  render() {
    return <Grid container columnSpacing={2} rowSpacing={0}>
      <Grid item xs={12} sm={6}>{this.renderIdField()}</Grid>
      <Grid item xs={12} sm={6}>{this.renderYearField()}</Grid>
      <Grid item xs={12} sm={6}>{this.renderUpdateTypeField()}</Grid>
      <Grid item xs={12} sm={6}>{this.renderComparisonModeField()}</Grid>
      <Grid item xs={12}>{this.renderTargetValueField()}</Grid>
    </Grid>
  }
}
