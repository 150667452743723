import { FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { withTranslation } from "react-i18next";
import UpdateTaskType from "../../../misc/UpdateTaskType";
import { OrthoLayer, orthoLayerFromString, orthoLayerText } from "../../../../data/OrthoLayer";
import { IChangeOrthoLayerTaskOptions } from "../../../tasks/options/ChangeOrthoLayerTaskOptions";
import UpdateTaskTypeSelect from "./common_inputs/UpdateTaskTypeSelect";
import TaskOptionsExtrasForm, { TaskSubformPropsAlias } from "./TaskOptionsExtrasForm";


interface State {
  orthoLayer: OrthoLayer
  updateTaskType: UpdateTaskType
}

class ChangeOrthoLayerTaskOptionsForm extends TaskOptionsExtrasForm<State, IChangeOrthoLayerTaskOptions> {
  constructor(props: TaskSubformPropsAlias<IChangeOrthoLayerTaskOptions>) {
    super(props);

    this.onOrthoLayerChange = this.onOrthoLayerChange.bind(this);

    this.renderOrthoLayerField = this.renderOrthoLayerField.bind(this);
    this.renderUpdateTypeField = this.renderUpdateTypeField.bind(this);
  }

  stateFromProps(props: TaskSubformPropsAlias<IChangeOrthoLayerTaskOptions>): State {
    return {
      orthoLayer: props.options.orthoLayer ?? OrthoLayer.OSM,
      updateTaskType: props.options.updateTaskType ?? UpdateTaskType.Any
    };
  }

  public extractOptions() {
    return {
      orthoLayer: this.state.orthoLayer,
      updateTaskType: this.state.updateTaskType
    }
  }

  renderUpdateTypeField() {
    return <UpdateTaskTypeSelect
      value={this.state.updateTaskType}
      onValueChange={(_: UpdateTaskType) => this.setState({ updateTaskType: _ })}
      noRelative
    />
  }

  onOrthoLayerChange(e: SelectChangeEvent<string>) {
    this.setState({
      orthoLayer: orthoLayerFromString(e.target.value)
    });

    this.props.setIsDirty(true)
  }

  renderOrthoLayerField() {
    const items: any[] = [];
    const currentValue = this.state.orthoLayer

    for (const orthoLayer of Object.values(OrthoLayer)) {
      const text = orthoLayerText(orthoLayer)
      items.push(<MenuItem key={orthoLayer} value={orthoLayer}>{text}</MenuItem>);
    }

    return <FormControl fullWidth margin="normal" variant="outlined">
      <InputLabel htmlFor="orthoLayer">{this.props.t('model.controls.ortho_layer.label')}</InputLabel>
      <Select id="orthoLayer" onChange={this.onOrthoLayerChange} value={currentValue} label={this.props.t('model.controls.ortho_layer.label')}>
        {items}
      </Select>
    </FormControl>;
  }


  render() {
    return <Grid container columnSpacing={2} rowSpacing={0}>
      <Grid item sm={6} xs={12}>{this.renderUpdateTypeField()}</Grid>
      <Grid item sm={6} xs={12}>{this.renderOrthoLayerField()}</Grid>
    </Grid>;
  }
}

export default withTranslation('translation', { withRef: true })(ChangeOrthoLayerTaskOptionsForm)
