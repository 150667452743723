import { ComponentApplyEventArgs } from "../../events/events/ComponentApplyEvent";
import EventManager from "../../events/EventManager";
import EventType from "../../events/EventType";
import { ValvePSVApply } from "../../views/forms/ValvePSVApply";
import NumberValueUpdateTask from "./base/NumberValueUpdateTask";
import { ValuesPair } from "./base/ValueUpdateTask";
import UpdateValueTaskOptions, { IUpdateValueTaskOptions } from "./options/UpdateValueTaskOptions";

export default class PSVUpdatePressureLimitTask extends NumberValueUpdateTask<ValvePSVApply> {
  protected _type: string = 'PSVUpdatePressureLimitTask';
  protected componentName: string = "valve_component_type.PSV";
  protected fieldName: string = "model.components.valve.psv.pressure_limit";

  public static fromPlainTaskOptions(plainOptions: IUpdateValueTaskOptions<number>) {
    const options = new UpdateValueTaskOptions<number>(plainOptions);
    return new PSVUpdatePressureLimitTask(options)
  }

  public activate() {
    super.activate();
    EventManager.getInstance().attachListener(EventType.ValvePSVApply, this.onValueUpdate);
  }

  public deactivate() {
    super.deactivate();
    EventManager.getInstance().detachListener(EventType.ValvePSVApply, this.onValueUpdate);
  }

  protected retrieveValues(args: ComponentApplyEventArgs<ValvePSVApply>): ValuesPair<number> {
    return {
      newValue: args.apply.timed.pressure_limit,
      oldValue: args.oldApply.timed.pressure_limit
    };
  }
}
