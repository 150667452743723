import { ValveComponentType } from "../../../../../data/ValveComponentType"
import { FormControl, FormHelperText, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { setTaskOptionsDirty } from "../../../../../store/slices/challengeEditorSlice";

export interface ValveTypeSelectProps {
    valveType?: ValveComponentType
    onValveTypeChange: (_?: ValveComponentType) => void
}

export default function ValveTypeSelect(props: ValveTypeSelectProps) {
    const {
        valveType,
        onValveTypeChange
    } = props

    const { t, i18n } = useTranslation()

    const dispatch = useDispatch()

    const items = useMemo(() => {
        const items: JSX.Element[] = [
            <MenuItem key="" value=""><i>{t('general.any')}</i></MenuItem>
        ]

        for (const value of Object.values(ValveComponentType)) {
            items.push(<MenuItem key={value} value={value}>{t(`valve_component_type.${value}`)}</MenuItem>);
        }

        return items
    }, [i18n.language])

    function onChange(e: SelectChangeEvent<string>) {
        const value = e.target.value
        onValveTypeChange(value === '' ? undefined : value as ValveComponentType)

        dispatch(setTaskOptionsDirty(true))
    }

    return <FormControl fullWidth margin="normal" variant="outlined">
        <InputLabel htmlFor="valveType" id="valveTypeLabel">{t('valve_component_type.label')}</InputLabel>
        <Select
            id="valveType"
            labelId="valveTypeLabel"
            onChange={onChange}
            value={valveType ?? ''}
            label={t('valve_component_type.label')}
        >
            {items}
        </Select>
        <FormHelperText>{t('challenge.editor.tasks.valve_type_hint')}</FormHelperText>
    </FormControl>
}
