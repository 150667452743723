import ComparisonMode from "../../../utils/ComparisonMode";
import Challenge from "../../Challenge";
import TaskGroup from "../../TaskGroup";
import PerformanceIndicatorTaskOptions from "../../tasks/options/PerformanceIndicatorTaskOptions";
import PIAveragePipeAgeTask from "../../tasks/PIAveragePipeAgeTask";

export default function PIAveragePipeAge(): Challenge {
  return new Challenge([
    new TaskGroup([
      new PIAveragePipeAgeTask(new PerformanceIndicatorTaskOptions({
        threshold: 10,
        scenario: 'unfavorable',
        year: 2025,
        comparisonMode: ComparisonMode.lte
      }))
    ])
  ])
}
