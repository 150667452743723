import { InputAdornment, TextField } from "@mui/material";
import { ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BasePointFeature } from "../../data/BasePointFeature";
import { BaseSingleForm, BaseSingleFormApply } from "./BaseSingleForm";
import { useFormContext } from "./FormContext";
import parseFloatOrUndefined from "../../utils/parseFloatOrUndefined";
import validNumber from "../../utils/validNumber";
import textFromNumber from "../../utils/textFromNumber";

export interface BaseSinglePointFormApply extends BaseSingleFormApply {
    latitude: number
    longitude: number
    elevation: number | undefined
}

export interface BaseSinglePointFormProps<T, U> {
    feature: BasePointFeature<T, U>
    children?: ReactNode;
    timedChildren?: ReactNode;
    conditionChildren?: ReactNode;
    handleApply: (apply: BaseSinglePointFormApply) => void;
    validate: () => boolean
}

export function BaseSinglePointForm<T, U>(props: BaseSinglePointFormProps<T, U>) {
    const { feature, conditionChildren, timedChildren, handleApply, validate } = props;
    const context = useFormContext();
    const [latitude, setLatitude] = useState<number | undefined>(feature.geometry.realCoordinates[1]);
    const [longitude, setLongitude] = useState<number | undefined>(feature.geometry.realCoordinates[0]);
    const [elevation, setElevation] = useState<number | undefined>(feature.geometry.realCoordinates[2]);
    const { t } = useTranslation();

    useEffect(() => {
        setLatitude(feature.geometry.realCoordinates[1]);
        setLongitude(feature.geometry.realCoordinates[0]);
        setElevation(feature.geometry.realCoordinates[2]);
    }, [feature, context.currentDate, context.condition, context.modelWrapper?.id])

    function pointValidate() {
        if (validNumber(latitude) && validNumber(longitude) && validNumber(elevation)) return validate();
        return false
    }
    function doApply(apply: BaseSingleFormApply) {

        handleApply({
            "id": apply.id,
            "comment": apply.comment,
            "lifetime": apply.lifetime,
            "latitude": latitude,
            "longitude": longitude,
            "elevation": elevation
        } as BaseSinglePointFormApply);
    }

    return <BaseSingleForm validate={pointValidate} handleApply={doApply} feature={feature} conditionChildren={conditionChildren} timedChildren={
        <>
            {timedChildren}
            <div style={{ marginTop: "10px", display: "flex" }}>
                <TextField required type="number" value={textFromNumber(latitude)}
                    onChange={(event) => setLatitude(parseFloatOrUndefined(event.target.value))} style={{ flexGrow: 1 }}
                    label={t('general.latitude') as string} />
                <TextField required type="number" value={textFromNumber(longitude)}
                    onChange={(event) => setLongitude(parseFloatOrUndefined(event.target.value))} style={{ flexGrow: 1 }}
                    label={t('general.longitude') as string} />
                <TextField required type="number" value={textFromNumber(elevation)}
                    onChange={(event) => setElevation(parseFloatOrUndefined(event.target.value))} style={{ flexGrow: 1 }}
                    label={t('general.elevation') as string} InputProps={{ endAdornment: <InputAdornment position="end">m</InputAdornment> }}/>
            </div>
        </>}>
        {props.children}
    </BaseSingleForm>
}
