import UpdateTaskType from "../../misc/UpdateTaskType";
import TaskOptions, { ITaskOptions } from "./TaskOptions";

export interface IChangeYearTaskOptions extends ITaskOptions {
  updateTaskType?: UpdateTaskType
  year?: number
}

export default class ChangeYearTaskOptions extends TaskOptions {
  public updateTaskType: UpdateTaskType = UpdateTaskType.Any;
  public year: number | undefined;

  public constructor(options: IChangeYearTaskOptions = {}) {
    super(options);

    this.updateTaskType = options.updateTaskType ?? UpdateTaskType.Any;
    this.year = options.year;

    this.constrainUpdateTaskType();
  }

  protected constrainUpdateTaskType() {
    if (this.updateTaskType === UpdateTaskType.Relative || this.updateTaskType === UpdateTaskType.RelativeFactor) {
      throw new Error("Relative/Relative Factor task types not allowed");
    }
  }

  public static fromPlain(plainOptions?: IChangeYearTaskOptions) {
    return new ChangeYearTaskOptions(plainOptions);
  }

  public toJSON(): IChangeYearTaskOptions {
    return {
      ...super.toJSON(),
      updateTaskType: this.updateTaskType,
      year: this.year
    }
  }
}
