import { OrthoLayer } from "../../../data/OrthoLayer";
import UpdateTaskType from "../../misc/UpdateTaskType";
import TaskOptions, { ITaskOptions } from "./TaskOptions";

export interface IChangeOrthoLayerTaskOptions extends ITaskOptions {
  updateTaskType?: UpdateTaskType
  orthoLayer?: OrthoLayer
}

export default class ChangeOrthoLayerTaskOptions extends TaskOptions {
  public updateTaskType: UpdateTaskType = UpdateTaskType.Any;
  public orthoLayer: OrthoLayer;

  public constructor(options: IChangeOrthoLayerTaskOptions = {}) {
    super(options);

    this.updateTaskType = options.updateTaskType ?? UpdateTaskType.Any;
    this.orthoLayer = options.orthoLayer ?? OrthoLayer.OSM;

    this.constrainUpdateTaskType();
  }

  protected constrainUpdateTaskType() {
    if (this.updateTaskType === UpdateTaskType.Relative || this.updateTaskType === UpdateTaskType.RelativeFactor) {
      throw new Error("Relative/Relative Factor task types not allowed");
    }
  }

  public static fromPlain(plainOptions?: IChangeOrthoLayerTaskOptions) {
    return new ChangeOrthoLayerTaskOptions(plainOptions);
  }

  public toJSON(): IChangeOrthoLayerTaskOptions {
    return {
      ...super.toJSON(),
      updateTaskType: this.updateTaskType,
      orthoLayer: this.orthoLayer
    }
  }
}
