import { Check, Close } from "@mui/icons-material"
import { Button, Dialog, DialogActions, DialogContent } from "@mui/material"

export interface ConfirmationDialogProps {
    text: string
    onConfirm: () => void
    onCancel?: () => void
    onClose: () => void
}

export default function ConfirmationDialog(props: ConfirmationDialogProps) {
    const {
        text,
        onConfirm,
        onCancel,
        onClose
    } = props

    function onCancelClick () {
        if (onCancel !== undefined) {
            onCancel()
        }

        onClose()
    }

    function onConfirmClick () {
        onConfirm()
        onClose()
    }

    return <Dialog open>
        <DialogContent>{text}</DialogContent>
        <DialogActions>
            <Button variant="contained" onClick={() => onConfirmClick()}><Check /></Button>
            <Button variant="contained" onClick={() => onCancelClick()}><Close /></Button>
        </DialogActions>
    </Dialog>
}
