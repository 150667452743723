import Challenge from "../../Challenge";
import ChallengeOptions from "../../ChallengeOptions";
import UpdateTaskType from "../../misc/UpdateTaskType";
import TaskGroup from "../../TaskGroup";
import JunctionUpdateCommentTask from "../../tasks/JunctionUpdateCommentTask";
import JunctionUpdateDemandTask from "../../tasks/JunctionUpdateDemandTask";
import JunctionUpdateElevationTask from "../../tasks/JunctionUpdateElevationTask";
import JunctionUpdateFireflowTask from "../../tasks/JunctionUpdateFireflowTask";
import JunctionUpdateLifetimeTask from "../../tasks/JunctionUpdateLifetimeTask";
import JunctionUpdateModeTask from "../../tasks/JunctionUpdateModeTask";
import JunctionUpdateScaleDemandTask from "../../tasks/JunctionUpdateScaleDemandTask";
import UpdateBooleanTaskOptions from "../../tasks/options/UpdateBooleanTaskOptions";
import UpdateValueTaskOptions from "../../tasks/options/UpdateValueTaskOptions";

export default function JunctionUpdates(): Challenge {
  const taskGroups: TaskGroup[] = [
    new TaskGroup([
      new JunctionUpdateCommentTask(),
      new JunctionUpdateDemandTask(new UpdateValueTaskOptions<number>({ year: 2030, id: 'junction_1' })),
      new JunctionUpdateElevationTask(),
      new JunctionUpdateFireflowTask(),
      new JunctionUpdateLifetimeTask(),
      new JunctionUpdateScaleDemandTask(new UpdateBooleanTaskOptions({ targetValue: true, updateTaskType: UpdateTaskType.ExactValue })),
      new JunctionUpdateModeTask()
    ])
  ];

  const options = new ChallengeOptions({
  });

  return new Challenge(taskGroups, options);
}
