import { Close } from "@mui/icons-material"
import { Box, CircularProgress, Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { EWAResult, EWASimulationDTO } from "../../api/client"
import EventManager from "../../events/EventManager"
import { EWAModelWrapper } from "./EWAModelWrapper"
import { SimulationResultsPlot } from "./simulation/SimulationResultsPlot"

export interface LastSimulationDialogProps {
    open: boolean
    onClose: () => void
    operatingCondition?: string
    ewaSimulation?: EWASimulationDTO
    ewaResult?: EWAResult
    modelWrapper: EWAModelWrapper
}

export default function LastSimulationDialog(props: LastSimulationDialogProps) {
    const {
        open,
        onClose,
        operatingCondition,
        ewaSimulation,
        ewaResult,
        modelWrapper
    } = props

    const { t } = useTranslation()

    useEffect(() => {
        if (open) {
            EventManager.getInstance().onSimulationResultsDialogOpened({
                model: modelWrapper.model,
                simulations: [ewaSimulation!]
            })
        }
    }, [open])

    function renderPlot() {
        if (ewaSimulation === undefined || ewaResult === undefined) {
            return <Box sx={{ display: 'flex', height: 400, alignItems: 'center', justifyContent: 'center'}}>
                <CircularProgress />
            </Box>
        }

        const ewaSimulations: EWASimulationDTO[] = [
            {
                ...ewaSimulation,
                result: ewaResult
            }
        ]

        return <SimulationResultsPlot
            ewaSimulations={ewaSimulations}
            operatingCondition={operatingCondition!}
            modelWrapper={modelWrapper}
        />
    }

    return <Dialog open={open} maxWidth="lg" fullWidth onClose={() => onClose()}>
        <DialogTitle sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            {t('model.simulation_results_dialog.title')}
            <IconButton aria-label="close" onClick={() => onClose()}>
                <Close />
            </IconButton>
        </DialogTitle>
        <DialogContent>
            {renderPlot()}
        </DialogContent>
    </Dialog>
}
