import { ShowResults } from "../../../data/ShowResults";
import { HeatmapLayer } from "@deck.gl/aggregation-layers";

export interface DemandLayerProps {
    showResults: ShowResults
    junctions: any[]
    currentDate: number
    junctionMinDemand: number
    junctionMaxDemand: number
}

export default function createDemandLayer(props: DemandLayerProps) {
    const {
        showResults,
        junctions,
        currentDate,
        junctionMinDemand,
        junctionMaxDemand,
    } = props

    const layer = new HeatmapLayer({
        id: 'demand',
        visible: showResults === ShowResults.Demand,
        data: junctions,
        pickable: false,
        getWeight: (feature: any) => {
            if (showResults === ShowResults.Demand) {
                let value = feature._timedProperties.getValue(currentDate)
                if (value !== undefined && value.demand !== undefined) {
                    let diff = junctionMaxDemand - junctionMinDemand;
                    return ((value.demand - junctionMinDemand) / diff);
                }
            }
            return 0
        },
        getPosition: (feature: any) => feature.geometry.coordinates,
        aggregation: 'SUM'
    });

    return layer
}
