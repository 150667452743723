import EventManager from "../../events/EventManager";
import EventType from "../../events/EventType";
import EconomyBudgetChangedEvent from "../../events/events/EconomyBudgetChangedEvent";
import i18n from "../../i18n";
import Task from "./base/Task";
import TaskOptions, { ITaskOptions } from "./options/TaskOptions";

export default class StayWithinBudgetTask extends Task {
  protected _type: string = 'StayWithinBudgetTask';

  public constructor(options?: TaskOptions) {
    super(options);

    this.onBudgetChanged = this.onBudgetChanged.bind(this);


    // force requiredCount to be 1 - any other value makes no sense
    this.requiredCount = 1

    // initialize as finished
    this.currentCount = 1

    this._completed = true;
  }

  public static fromPlainTaskOptions(plainOptions: ITaskOptions) {
    const options = new TaskOptions(plainOptions)
    return new StayWithinBudgetTask(options)
  }

  protected onBudgetChanged(e: EconomyBudgetChangedEvent) {
    this.currentCount = (e.detail.newValue >= 0 ? 1 : 0)

    this.checkCompletion()
    this.triggerTaskViewUpdate()
  }

  public get description(): string {
    return i18n.t('challenge.task.stay_within_budget');
  }

  public activate(): void {
    super.activate()
    EventManager.getInstance().attachListener(EventType.EconomyBudgetChanged, this.onBudgetChanged);
  }

  public deactivate(): void {
    super.deactivate()
    EventManager.getInstance().detachListener(EventType.EconomyBudgetChanged, this.onBudgetChanged)
  }
}
