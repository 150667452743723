import { useEffect, useState } from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import {
    EWASimulationDTO,
    ModellingService
} from "../../../api/client";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { SimulationCard } from "./SimulationCard";
import { Box, Button, Container, LinearProgress, Toolbar, useTheme } from "@mui/material";
import TaskRunDialog from "./TaskRunDialog";
import SimulationResultsPlotDialog from "./SimulationResultsPlotDialog";
import { EWAModelWrapper } from "../EWAModelWrapper";
import SimulationResultsDetailsDialog from "./SimulationResultsDetailsDialog";
import { Clear, Delete, QueryStats } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import SimulationLogsDialog from "./SimulationLogsDialog";
import EventManager from "../../../events/EventManager";
import SimulationDeleteDialog from "./SimulationDeleteDialog";

export const useStyles = makeStyles((theme) => ({
    mainContainer: {
        height: "100vh",
        width: "100vw",
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch"
    },
    mainContent: {
        justifyContent: "center",
        alignItems: "center",
        paddingTop: "50px",
        paddingLeft: "50px",
        paddingBottom: "50px",
        display: "flex",
        flexDirection: "column"
    }
}));

export interface SimulationTableProps {
    modelId: string;
    simulating: boolean
    debugMode: boolean
    modelWrapper: EWAModelWrapper
    lastSimulation: EWASimulationDTO | undefined
    setLastSimulation: (_?: EWASimulationDTO) => void
    loadModelData: (modelId: string, versionId?: string) => Promise<void>
}

export function SimulationTable(props: SimulationTableProps) {
    const {
        modelId,
        simulating,
        debugMode,
        modelWrapper,
        lastSimulation,
        setLastSimulation,
        loadModelData
    } = props;
    const { enqueueSnackbar } = useSnackbar();
    const theme = useTheme()
    const { t } = useTranslation()

    const [simulations, setSimulations] = useState<EWASimulationDTO[]>([])
    const [selectedSimulations, setSelectedSimulations] = useState<Map<string, EWASimulationDTO>>(new Map<string, EWASimulationDTO>())
    const [fetchInterval, setFetchInterval] = useState<number | undefined>(undefined)
    const [taskRunListItem, setTaskRunListItem] = useState<EWASimulationDTO | undefined>(undefined)
    const [simulationDeleteItems, setSimulationDeleteItems] = useState<EWASimulationDTO[]>([])
    const [simulationResultsPlotItems, setSimulationResultsPlotItems] = useState<EWASimulationDTO[]>([])
    const [simulationResultsDetailsItem, setSimulationResultsDetailsItem] = useState<EWASimulationDTO | undefined>()
    const [simulationLogsItem, setSimulationLogsItem] = useState<EWASimulationDTO | undefined>()

    useEffect(() => {
        if (simulationResultsPlotItems.length > 0) {
            EventManager.getInstance().onSimulationResultsDialogOpened({
                model: modelWrapper.model,
                simulations: simulationResultsPlotItems
            })
        }
    }, [simulationResultsPlotItems])

    async function fetchSimulations() {
        if (modelId === undefined) {
            setSimulations([])
            return
        }

        try {
            const simulations = await ModellingService.simulationsV1SimulationsModelIdGet(modelId)
            setSimulations(simulations)
        } catch (e: any) {
            if (e.message.indexOf("Not Found") > -1) {
                setSimulations([])
            } else {
                enqueueSnackbar(e.message, {
                    variant: 'error',
                });
            }
        }
    }

    useEffect(() => {
        fetchSimulations()
    }, [modelId]);

    useEffect(() => {
        if (simulating) {
            setFetchInterval(window.setInterval(fetchSimulations, 5000))
        } else {
            clearFetchInterval()
            fetchSimulations()
        }

        return () => clearFetchInterval()
    }, [simulating])

    useEffect(() => {
        if (taskRunListItem !== undefined) {
            setTaskRunListItem(simulations.find(_ => _.id === taskRunListItem.id))
        }
    }, [simulations])

    function clearFetchInterval() {
        if (fetchInterval !== undefined) {
            window.clearInterval(fetchInterval)
            setFetchInterval(undefined)
        }
    }

    function selectSimulation(ewaSimulation: EWASimulationDTO, isSelected: boolean) {
        if (isSelected) {
            selectedSimulations.set(ewaSimulation.id, ewaSimulation)
        } else {
            selectedSimulations.delete(ewaSimulation.id)
        }

        setSelectedSimulations(new Map(selectedSimulations))
    }

    function simulationDeleteOnDeleted() {
        const deletedSimulations = simulationDeleteItems
        for (const deletedSimulation of deletedSimulations) {
            selectedSimulations.delete(deletedSimulation.id)
        }

        setSelectedSimulations(new Map(selectedSimulations))
        setSimulationDeleteItems([])

        if (!simulating) {
            fetchSimulations()
        }
    }

    function renderDialogs() {
        return <>
            {taskRunListItem !== undefined && <TaskRunDialog selectedSimulation={taskRunListItem} onClose={() => setTaskRunListItem(undefined)} />}
            {simulationResultsPlotItems.length > 0 && <SimulationResultsPlotDialog
                ewaSimulations={simulationResultsPlotItems}
                onClose={() => setSimulationResultsPlotItems([])}
                modelWrapper={modelWrapper}
            />}
            {simulationResultsDetailsItem !== undefined && <SimulationResultsDetailsDialog
                ewaSimulation={simulationResultsDetailsItem}
                onClose={() => setSimulationResultsDetailsItem(undefined)}
            />}
            {simulationLogsItem !== undefined && <SimulationLogsDialog
                ewaSimulation={simulationLogsItem}
                onClose={() => setSimulationLogsItem(undefined)}
            />}
            {simulationDeleteItems.length > 0 && <SimulationDeleteDialog
                ewaSimulations={simulationDeleteItems}
                onClose={() => setSimulationDeleteItems([])}
                onDeleted={() => simulationDeleteOnDeleted()}
                lastSimulation={lastSimulation}
                setLastSimulation={setLastSimulation}
            />}
        </>
    }

    function renderSimulations() {
        const rows: JSX.Element[] = []

        for (const simulation of simulations) {
            rows.push(<SimulationCard
                isSelected={selectedSimulations.has(simulation.id)}
                selectSimulation={selectSimulation}
                debugMode={debugMode}
                ewaSimulation={simulation}
                onOpenTaskRunList={(_: EWASimulationDTO) => setTaskRunListItem(_)}
                onOpenSimulationResultsPlot={(_: EWASimulationDTO) => setSimulationResultsPlotItems([_])}
                onOpenSimulationResultsDetails={(_: EWASimulationDTO) => setSimulationResultsDetailsItem(_)}
                onOpenSimulationLogs={(_: EWASimulationDTO) => setSimulationLogsItem(_)}
                onOpenSimulationDelete={(_: EWASimulationDTO) => setSimulationDeleteItems([_])}
                lastSimulation={lastSimulation}
                setLastSimulation={setLastSimulation}
                loadModelData={loadModelData}
                key={simulation.id}
            />)
        }

        return rows
    }

    function renderSelectionActions() {
        const actionsDisabled = selectedSimulations.size === 0
        return <Toolbar sx={{ backgroundColor: theme.palette.darkBlue.main, gap: 1 }}>
            <Button
                variant="contained"
                disabled={actionsDisabled}
                onClick={() => setSelectedSimulations(new Map())}
                title={t('general.clear') as string}
            >
                <Clear />
            </Button>
            <Button
                variant="contained"
                disabled={actionsDisabled}
                onClick={() => setSimulationResultsPlotItems(Array.from(selectedSimulations).map(_ => _[1]))}
                title={t('simulation_view.simulation_results_plot') as string}
            >
                <QueryStats />
            </Button>
            <Button
                variant="contained"
                color="error"
                disabled={actionsDisabled}
                onClick={() => setSimulationDeleteItems(Array.from(selectedSimulations).map(_ => _[1]))}
                title={t('simulation_view.delete_simulation') as string}
            ><Delete /></Button>
        </Toolbar>
    }

    return <Container>
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%",
            paddingTop: 2,
            paddingBottom: 2
        }}>
            {renderSelectionActions()}
            <Box sx={{ width: '100%', minHeight: 4 }}>
                {simulating && <LinearProgress />}
            </Box>
            <Box sx={{ flexGrow: 1, overflow: 'auto' }}>
                <AutoSizer>
                    {({ height, width }: { height: number, width: number }) => (
                        <Box sx={{ width, display: 'flex', flexDirection: 'column', gap: 2 }}>
                            {renderSimulations()}
                        </Box>
                    )}
                </AutoSizer>
            </Box>
            {renderDialogs()}
        </Box>
    </Container>
}
