import { ShowResults } from "../../../data/ShowResults";
import Challenge from "../../Challenge";
import TaskGroup from "../../TaskGroup";
import ShowResultsTaskOptions from "../../tasks/options/ShowResultsTaskOptions";
import ShowResultsTask from "../../tasks/ShowResultsTask";

export default function ShowResultsTasks(): Challenge {
  const taskGroups: TaskGroup[] = [
    new TaskGroup([
      new ShowResultsTask(),
      new ShowResultsTask(new ShowResultsTaskOptions({ showResults: ShowResults.None })),
      new ShowResultsTask(new ShowResultsTaskOptions({ showResults: ShowResults.Demand })),
      new ShowResultsTask(new ShowResultsTaskOptions({ showResults: ShowResults.HeatMap }))
    ])
  ]

  return new Challenge(taskGroups);
}
