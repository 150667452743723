import { WithTranslation } from "react-i18next"
import { OperatingConditionType } from "../../../../api/client"
import { EWAComponentType } from "../../../../data/EWAComponentType"
import { OrthoLayer } from "../../../../data/OrthoLayer"
import { ShowResults } from "../../../../data/ShowResults"
import ComparisonMode from "../../../../utils/ComparisonMode"
import UpdateTaskType from "../../../misc/UpdateTaskType"
import { ITaskOptions } from "../../../tasks/options/TaskOptions"
import React from "react"

type ChangeComponentVisibilityExtras = {
    componentType: EWAComponentType
    updateTaskType: UpdateTaskType
    visible: boolean
}

type OpenEditDialogExtras = {
    id?: string
    year?: number
    componentType?: EWAComponentType
}

type PerformanceIndicatorExtras = {
    id?: string
    year?: number
    scenario?: string
    operatingConditionType?: OperatingConditionType
    threshold?: number
    comparisonMode: ComparisonMode
}

type ShowResultsExtras = {
    showResults?: ShowResults
}

type CreateDeleteOperatingConditionExtras = {
    type?: OperatingConditionType
}

type ChangeOperatingConditionExtras = {
    updateTaskType?: UpdateTaskType
    type?: OperatingConditionType
}

type ChangeTabExtras = {
    updateTaskType: UpdateTaskType
    tab: number
}

type ChangeOrthoLayerExtras = {
    updateTaskType: UpdateTaskType
    orthoLayer: OrthoLayer
}

type UpdateTaskOptionExtras<T> = {
    id?: string
    year?: number
    updateTaskType?: UpdateTaskType
    targetValue?: T
}

type ComponentTaskOptionExtras = {
    componentType: EWAComponentType
    year?: number
}

export type ExtractOptionsResultType = UpdateTaskOptionExtras<any> |
    ComponentTaskOptionExtras |
    CreateDeleteOperatingConditionExtras |
    ChangeOperatingConditionExtras |
    ShowResultsExtras |
    ChangeTabExtras |
    PerformanceIndicatorExtras |
    OpenEditDialogExtras |
    ChangeOrthoLayerExtras |
    ChangeComponentVisibilityExtras

export interface ITaskOptionExtrasForm {
    extractOptions: () => ExtractOptionsResultType
}

export interface TaskSubformProps<R = ITaskOptionExtrasForm, O = ITaskOptions> extends WithTranslation {
    ref: React.RefObject<R>
    options: O
    setIsDirty: (_: boolean) => void
    taskIndex: number
}
// small hack to not have to replace R for every case
export interface TaskSubformPropsAlias<O = ITaskOptions> extends TaskSubformProps<ITaskOptionExtrasForm, O> { }

export default abstract class TaskOptionsExtrasForm<S, O = ITaskOptions> extends React.Component<TaskSubformPropsAlias<O>,S> implements ITaskOptionExtrasForm
{
    constructor(props: TaskSubformPropsAlias<O>) {
        super(props)

        this.state = this.stateFromProps(props)

        this.extractOptions = this.extractOptions.bind(this)
        this.stateFromProps = this.stateFromProps.bind(this)
    }

    componentDidUpdate(prevProps: Readonly<TaskSubformPropsAlias<O>>, prevState: Readonly<S>, snapshot?: any): void {
        if (prevProps.taskIndex !== this.props.taskIndex || prevProps.options !== this.props.options) {
            this.setState(this.stateFromProps(this.props))
        }
    }

    public abstract extractOptions(): ExtractOptionsResultType

    abstract stateFromProps(props: TaskSubformPropsAlias<O>): S;
}
