import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Snackbar, SnackbarCloseReason, TextField } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

interface Props extends WithTranslation {
  content: string
  onClose: () => void
  open: boolean
}

interface State {
  snackbarOpen: boolean
  dataUrl: string
}

class ChallengeExportDialog extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);

    this.state = {
      snackbarOpen: false,
      dataUrl: this.computeDataUrl()
    };

    this.computeDataUrl = this.computeDataUrl.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleCopy = this.handleCopy.bind(this);
    this.closeSnackbar = this.closeSnackbar.bind(this);
  }


  componentDidUpdate(prevProps: Props) {
    if (prevProps.content !== this.props.content) {
      URL.revokeObjectURL(this.state.dataUrl);

      this.setState({
        dataUrl: this.computeDataUrl()
      });
    }
  }

  componentWillUnmount() {
    URL.revokeObjectURL(this.state.dataUrl);
  }

  computeDataUrl() {
    const blob = new Blob([this.props.content], { type: 'application/json' });
    return URL.createObjectURL(blob);
  }

  handleClose() {
    this.props.onClose();
  }

  async handleCopy() {
    await navigator.clipboard.writeText(this.props.content);

    this.setState({
      snackbarOpen: true
    });
  }

  closeSnackbar(event: Event | React.SyntheticEvent<any, Event>, reason?: SnackbarCloseReason) {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({
      snackbarOpen: false
    });
  }

  render() {
    const { open, content, t } = this.props;
    const { snackbarOpen, dataUrl } = this.state;

    return <>
      <Dialog
        open={open}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <span>{t('challenge.editor.export.title')}</span>
          <IconButton
            aria-label="close"
            onClick={this.handleClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <TextField InputProps={{ readOnly: true }} fullWidth multiline rows={20} value={content} />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" startIcon={<FileDownloadIcon />} href={dataUrl} download="challenge.json" sx={{ marginRight: '8px' }}>{t('general.download')}</Button>
          <Button variant="contained" startIcon={<ContentCopyIcon />} onClick={this.handleCopy}>{t('general.copy')}</Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={snackbarOpen} autoHideDuration={5000} onClose={this.closeSnackbar}>
        <Alert severity="success" onClose={(event) => this.closeSnackbar(event)}>{t('challenge.editor.export.copy_alert')}</Alert>
      </Snackbar>
    </>
  }
}

export default withTranslation()(ChallengeExportDialog)
