import { Button } from "@mui/material"
import { useTranslation } from "react-i18next"

export interface SectionNavButtonProps {
    targetId: string
}

export default function SectionNavButton(props: SectionNavButtonProps) {
    const {
        targetId
    } = props
    const {t} = useTranslation('landing')

    function onClick() {
        const targetElement = document.getElementById(targetId)

        targetElement?.scrollIntoView({
            block: 'center',
            inline: 'center',
            behavior: 'smooth'
        })

        const newLocation = (""+window.location).replace(/#[\w\d_]*$/, '') + '#'+targetId
        window.history.replaceState(null, '', newLocation)
    }

    return <Button
        sx={{color: 'white'}}
        onClick={() => onClick()}
    >
        {t(`sections.${targetId}.nav`)}
    </Button>
}
