import { useTranslation } from "react-i18next";
import { EWASimulationDTO } from "../../../../api/client";
import { EWAResultType } from "../EWAResultType";
import ResilienceIndexPlot from "./ResilienceIndexPlot";
import UnsatisfiedNodesPlot from "./UnsatisfiedNodesPlot";
// @ts-ignore
import * as plotlyDe from 'plotly.js/lib/locales/de.js'
import { Scenario } from "../Scenario";
import InflowDemandRatioPlot from "./InflowDemandRatioPlot";
import AveragePipeAgePlot from "./AveragePipeAgePlot";

interface PlotlyPlotWrapperProps {
    ewaSimulations: EWASimulationDTO[]
    width: number
    height: number
    operatingCondition: string | undefined
    ewaResultType: EWAResultType
    enabledScenarios: Scenario[]
}

export default function PlotlyPlotWrapper(props: PlotlyPlotWrapperProps) {
    const {
        ewaSimulations,
        width,
        height,
        operatingCondition,
        ewaResultType,
        enabledScenarios
    } = props;

    const { i18n } = useTranslation()

    function createCommonConfig(): Partial<Plotly.Config> {
        const config: Partial<Plotly.Config> = {}

        config.locale = i18n.language
        config.locales = {
            de: plotlyDe
        }

        return config
    }

    function renderPlot() {
        const plotProps = {
            ewaSimulations,
            width,
            height,
            operatingCondition,
            enabledScenarios,
            config: createCommonConfig()
        }
        switch (ewaResultType) {
            case EWAResultType.ResilienceIndex:
                return <ResilienceIndexPlot {...plotProps} />
            case EWAResultType.UnsatisfiedNodes:
                return <UnsatisfiedNodesPlot {...plotProps} />
            case EWAResultType.InflowDemandRatio:
                return <InflowDemandRatioPlot {...plotProps} />
            case EWAResultType.AveragePipeAge:
                return <AveragePipeAgePlot {...plotProps} />
        }
    }

    return <>
        {renderPlot()}
    </>
}
