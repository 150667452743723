import { Button } from "@mui/material"
import { EWAComponentType } from "../../../data/EWAComponentType"
import { ValveComponentType } from "../../../data/ValveComponentType";
import LassoImage from "../../../assets/lasso_white.png";
import { useTranslation } from "react-i18next";

export interface ComponentLassoButtonProps {
  componentType: EWAComponentType | ValveComponentType
  setComponentLassoMode: (_: EWAComponentType | ValveComponentType) => void
}

export default function ComponentLassoButton(props: ComponentLassoButtonProps) {
  const {
    setComponentLassoMode,
    componentType
  } = props
  const { t } = useTranslation()
  const title = t(`model.controls.edit_lasso_mode.${componentType.toLowerCase()}`) as string

  return <Button
    value={componentType}
    onClick={() => setComponentLassoMode(componentType)}
    title={title}
    style={{ padding: '11px' }}
  >
    <span>
      <img src={LassoImage} height={24} width={24} alt={title} />
    </span>
  </Button>
}
