import { OperatingConditionType } from "../../api/client";
import { EWAModel } from "../../data/EWAModel";
import EventType from "../EventType";

export interface OperatingConditionDeletedEventArgs {
  id?: string
  type?: OperatingConditionType
  model: EWAModel
}

export default class OperatingConditionDeletedEvent extends CustomEvent<OperatingConditionDeletedEventArgs> {
  public constructor(detail: OperatingConditionDeletedEventArgs) {
    super(EventType.OperatingConditionDeleted, { detail })
  }
}
