import { useCurrentUser } from "../../api/auth";
import { makeStyles } from "@mui/styles";
import { WelcomeText } from "./WelcomeText";
import { Box, Button, Portal } from "@mui/material";
import { AddTask } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useHref } from "react-router";
import Models from "./Models";

export const useStyles = makeStyles((theme) => ({
    mainContainer: {
        height: "100vh",
        width: "100vw",
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch"
    },
    mainContent: {
        justifyContent: "center",
        alignItems: "center",
        paddingTop: "50px",
        paddingLeft: "50px",
        paddingBottom: "50px",
        display: "flex",
        flexDirection: "column"
    }
}));

export interface HomeProps {
    portalContainer?: Element
}

export function Home(props: HomeProps) {
    const {
        portalContainer
    } = props

    const { t } = useTranslation()
    const classes = useStyles();
    const user = useCurrentUser();
    const challengeEditorHref = useHref('/tool/challenges/edit')
    return (<>
        <Portal container={portalContainer}>
            <Box sx={{ paddingLeft: 2, paddingRight: 2 }}>
                <Button variant="contained" startIcon={<AddTask />} href={challengeEditorHref}>{t('home.challenge_editor_link')}</Button>
            </Box>
        </Portal>
        <WelcomeText user={user} />
        <div className={classes.mainContainer}>
            <Models />
        </div>
    </>
    )
}
