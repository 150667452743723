import EventManager from "../../events/EventManager";
import EventType from "../../events/EventType";
import OperatingConditionDeletedEvent from "../../events/events/OperatingConditionDeletedEvent";
import Task from "./base/Task";
import CreateDeleteOperatingConditionTaskOptions, { ICreateDeleteOperatingConditionTaskOptions } from "./options/CreateDeleteOperatingConditionTaskOptions";
import i18n from "../../i18n";

export default class DeleteOperatingConditionTask extends Task {
  protected _type: string = 'DeleteOperatingConditionTask';
  public constructor(options?: CreateDeleteOperatingConditionTaskOptions) {
    if (!options) {
      options = new CreateDeleteOperatingConditionTaskOptions();
    }

    super(options);

    this.onOperatingConditionDeleted = this.onOperatingConditionDeleted.bind(this);
  }

  public get optionOperatingConditionType(): string | undefined {
    const type = (this._options as CreateDeleteOperatingConditionTaskOptions).type;

    return type;
  }

  public static fromPlainTaskOptions(plainOptions: ICreateDeleteOperatingConditionTaskOptions) {
    const options = new CreateDeleteOperatingConditionTaskOptions(plainOptions);
    return new DeleteOperatingConditionTask(options);
  }

  protected onOperatingConditionDeleted(e: OperatingConditionDeletedEvent) {
    const options = this._options as CreateDeleteOperatingConditionTaskOptions;

    if (options.type !== undefined && e.detail.type !== options.type) return;

    this.currentCount++;
    this.checkCompletion();
    this.triggerTaskViewUpdate();
  }

  public get description(): string {
    return i18n.t('challenge.task.delete_operating_condition')
  }

  public activate() {
    super.activate();
    EventManager.getInstance().attachListener(EventType.OperatingConditionDeleted, this.onOperatingConditionDeleted);
  }

  public deactivate() {
    super.deactivate();
    EventManager.getInstance().detachListener(EventType.OperatingConditionDeleted, this.onOperatingConditionDeleted);
  }

}
