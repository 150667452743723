import { Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { BaseFeature } from "../../data/BaseFeature"
import { componentTypeToImage } from "./util"

export interface FormTitleProps {
    features: BaseFeature<any,any,any>[]
}

export default function FormTitle(props: FormTitleProps) {
    const {
        features
    } = props

    const {t} = useTranslation()
    const isMulti = features.length > 1
    const displayFeature = features[0]
    if (displayFeature === undefined) return <></>

    const img = componentTypeToImage[displayFeature.componentType]
    const alt = t(`ewa_component_type.${displayFeature.componentType}`, { count: (isMulti ? 0 : 1) })
    let subheader = <></>

    if (displayFeature.valveType !== undefined) {
        subheader = <div>
            <Typography variant={"body2"}>{t(`valve_component_type.${displayFeature.valveType}`)}</Typography>
        </div>
    }

    const title = isMulti ? t(`ewa_component_type.${displayFeature.componentType}WithCount`, { count: features.length }) : displayFeature.id

    return <>
        <div style={{ display: "flex", alignItems: "center"}}>
            <img style={{ marginRight: "5px" }} src={img} alt={alt} height={32} width={32} />
            <Typography variant={"h6"}>{title}</Typography>
        </div>
        {subheader}
    </>
}
