import { useState } from "react";
import { ValveFCV } from "../../data/ValveFCV";
import { TextField, InputAdornment } from "@mui/material";
import { FCVTimedProps } from "../../api/client";
import { useTranslation } from "react-i18next";
import parseFloatOrUndefined from "../../utils/parseFloatOrUndefined";
import validNumber from "../../utils/validNumber";
import { useFormContext } from "./FormContext";
import { findComputedTimedMulti, findTimedIfDefinedMulti, findTimedMulti } from "../../utils/findTimedMulti";
import { multiFormComputeIfFirstYear } from "./util";
import { MultiValveForm } from "./MultiValveForm";

export interface MultiValveFCVFormProps {
    valves: ValveFCV[]
}

export function MultiValveFCVForm(props: MultiValveFCVFormProps) {
    const { valves } = props;
    const context = useFormContext();
    const currentDate = context.currentDate as number

    const [isFirstYear, setIsFirstYear] = useState<boolean>(multiFormComputeIfFirstYear(valves, currentDate))
    const [maximumFlow, setMaximumFlow] = useState<string>(findTimedMulti({
        items: valves,
        get: _ => _.maximum_flow
    }, context.currentDate, undefined)?.toString() ?? '');
    const [fallbackMaximumFlow, setFallbackMaximumFlow] = useState<number | undefined>(findComputedTimedMulti({
        items: valves,
        get: _ => _.maximum_flow
    }, currentDate, undefined))

    const { t } = useTranslation();

    function updateTimedState() {
        setMaximumFlow(findTimedIfDefinedMulti({
            items: valves,
            get: _ => _.maximum_flow
        }, currentDate, undefined)?.toString() ?? '')
        setFallbackMaximumFlow(findComputedTimedMulti({
            items: valves,
            get: _ => _.maximum_flow
        }, currentDate, undefined))
    }

    function validate() {
        if (isFirstYear || maximumFlow !== '') {
            const parsedMaximumFlow = parseFloatOrUndefined(maximumFlow)

            if (!validNumber(parsedMaximumFlow) || parsedMaximumFlow! < 0) {
                return false
            }
        }

        return true
    }

    function computeTimedApply(): Partial<FCVTimedProps> {
        const timed: Partial<FCVTimedProps> = {}
        const applyMaximumFlow = parseFloatOrUndefined(maximumFlow)
        if (applyMaximumFlow !== undefined) {
            timed.maximum_flow = applyMaximumFlow
        }
        return timed
    }


    return <MultiValveForm
        valves={valves}
        isFirstYear={isFirstYear}
        setIsFirstYear={setIsFirstYear}
        validate={validate}
        computeTimedApply={computeTimedApply}
        updateTimedState={updateTimedState}
        timedChildren={<>
            <TextField
                required
                type="number"
                value={(maximumFlow)}
                onChange={(event) => setMaximumFlow((event.target.value))}
                style={{ flexGrow: 1, marginBottom: "10px" }}
                label={t('model.components.valve.fcv.maximum_flow')}
                InputProps={{ endAdornment: <InputAdornment position="end">L/s</InputAdornment> }}
                helperText={isFirstYear ? undefined : fallbackMaximumFlow?.toString()}
            />
        </>}></MultiValveForm>
}
