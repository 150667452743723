import { Button, Checkbox, FormControl, FormControlLabel, FormHelperText, TextField } from "@mui/material";
import React from "react";
import EditIcon from '@mui/icons-material/Edit';
import { ITaskGroupOptions } from "../../../TaskGroupOptions";
import { WithTranslation, withTranslation } from "react-i18next";

interface Props extends WithTranslation {
  ref: React.RefObject<any>
  taskGroupIndex: number
  options: ITaskGroupOptions
  locale: string
  onOptionsChange: (options: ITaskGroupOptions) => void
  onEditDescription: () => void
  setIsDirty: (_:boolean) => void
}

interface LocalizableState {
  name: string
}

export interface TaskGroupOptionFormState extends LocalizableState {
  autoShowDescription: boolean
}

class TaskGroupOptionForm extends React.Component<Props, TaskGroupOptionFormState> {
  constructor (props: Props) {
    super(props);

    this.state = this.stateFromProps(props);
  }

  localizedStateFromProps(props: Props): LocalizableState {
    const locale = props.locale

    return {
      name: (props.options.name ?? {})[locale] ?? ''
    }
  }

  stateFromProps (props: Props): TaskGroupOptionFormState {
    const state = {
      ...this.localizedStateFromProps(props),
      autoShowDescription: props.options.autoShowDescription ?? false
    }

    return state
  }

  componentDidUpdate (prevProps: Props) {
    if (prevProps.taskGroupIndex !== this.props.taskGroupIndex || prevProps.options !== this.props.options) {
      this.setState(this.stateFromProps(this.props))
    }

    if (prevProps.locale !== this.props.locale) {
      this.setState(this.localizedStateFromProps(this.props))
    }
  }

  render () {
    const {t} = this.props
    return <>
      <TextField
        fullWidth
        label={t('general.name')}
        value={this.state.name}
        onChange={e => {
          this.setState({name: e.target.value})
          this.props.setIsDirty(true)
        }}
        helperText={t('challenge.editor.task_group_options.name_hint')}
      />
      <FormControl fullWidth margin="normal">
        <FormControlLabel
          control={<Checkbox
            checked={this.state.autoShowDescription}
            onChange={e => {
              this.setState({autoShowDescription: e.target.checked})
              this.props.setIsDirty(true)
            }}
          />}
          label={t('challenge.editor.task_group_options.auto_show_description')}
        />
        <FormHelperText>{t('challenge.editor.task_group_options.auto_show_description_hint')}</FormHelperText>
      </FormControl>
      <Button variant="contained" onClick={this.props.onEditDescription} startIcon={<EditIcon />}>{t('general.description')}</Button>
    </>
  }
}

export default withTranslation('translation', { withRef: true })(TaskGroupOptionForm);
